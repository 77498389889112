import React from 'react';
import TextInput from './TextInput';

/**
 * Component for email inputs
 */
const EmailInput = (props) => {

  return (
    <TextInput
      {...props}
      type="text"
    />
  );
}

export default EmailInput;