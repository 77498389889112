import * as yup from "yup";

export function createYupSchema(schema, config) {
  const { name, validationType, validations = [] } = config;

  if (!yup[validationType]) {
    return schema;
  }

  let validator = yup[validationType]();

  validations.forEach(validation => {
    let { params, type } = validation;

    if (config.type === "Display") {
      type = "not required"
    }
    
    if (!validator[type]) {
      if (validator[type.toLowerCase()]) {
        type = type.toLowerCase();
      } else {
        return;
      }
    }
    validator = validator[type](...params);
  });

  schema[name] = validator;

  return schema;
}