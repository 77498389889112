import Question from './Question';
import { inputTypes } from '../../constants';

export default class OptionQuestion extends Question {

  constructor(options = {}) {
    super(options);

    // 'options' and 'optionsCategory' are mutually exclusive.  In practice, only one should be difined at a time.
    // It is okay if they both have values, but just know that if 'optionsCategory' is being used, 'options' should be left
    // alone and vice versa.
    this.usesOwnOptions = options.usesOwnOptions;
    this.options = options.options; // This contains the user defined options
    this.optionsCategory = options.optionsCategory; // This is used to define which keyword category the list of options comes from
  }

  parseSchema(questionSchema) {
    super.parseSchema(questionSchema);

    const inputObj = questionSchema.input || {};

    this.options = (inputObj.options || []).map(opt => opt.text);
    this.optionsCategory = (inputObj.props || {}).category;
    this.usesOwnOptions = this.optionsCategory ? 'no' : this.options ? 'yes' : null;
    this.placeholder = this.getPlaceholder(inputObj);
    return this;
  }


  getPlaceholder(inputObj) {
    return inputObj.props ? inputObj.props.defaultOptionText || '' : '';
  }

  getDisplayProps() {
    let options = [{ text: this.placeholder, value: '' }];
    return {
      ...this,
      options
    };
  }

  getSchemaObj() {
    const info = {
      type: this.type,
      props: {}
    };

    const saveAsBoolean = this.saveAsBoolean || false;
    const x = this.id;

    if (this.usesOwnOptions === 'yes') {
      info.options = (this.options || []).map(opt => {
        if (saveAsBoolean && opt) {
          const optionValue = opt === 'Yes';
          return { text: opt, value: optionValue }
        } else {
          return { text: opt, value: opt }
        }
      });
    } else {
      info.props = {
        category: this.optionsCategory
      }
    }
    info.props.defaultOptionText = this.placeholder || undefined;

    if (this.type === inputTypes.CHECKBOX_GROUP) {
      info.props.optionWidth = this.size;
    }

    return info;
  }

  clone(options = {}) {
    return new OptionQuestion({ ...this, ...options });
  }
}