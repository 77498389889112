import { inputSizes } from '../../constants';

/**
 * Validation objects and types 
 */
const validationTypes = {
  EXISTS: 'exists',
  TEXT_LENGTH: 'isLength',
  RANGE: 'isInRange',
}

const validationBuilders = {
  buildExists: () => ({
    type: validationTypes.EXISTS,
    message: 'Value is required'
  }),

  buildTextLength: (maxLength) => ({
    type: validationTypes.TEXT_LENGTH,
    message: `Value must be less than ${maxLength + 1} characters`,
    params: [
      {
        min: 0,
        max: maxLength
      }
    ]
  }),

  buildRange: (min, max) => {
    const message = !isNaN(min) && !isNaN(max) ? `Value must be between ${min} and ${max}`
      : !isNaN(min) ? `Value must be greater than ${min}`
        : `Value must be less than ${max}`;

    return {
      type: validationTypes.RANGE,
      message: message,
      params: [
        {
          min: isNaN(min) ? undefined : min,
          max: isNaN(max) ? undefined : max
        }
      ]
    }
  }
}

/********************************************/


/************ Util Functions ************/

const createId = (name, postfix = '') => {
  if (!name) return '';
  return `${name} ${postfix}`.trim().replace(/ /g, '-').toLowerCase();
}

const utils = {
  createPanelId: (name) => {
    return createId(name, 'panel');
  },
  createSetId: (name) => {
    return createId(name, 'set');
  }
}

/********************************************/

/************* Layout Manager *****************/


class LayoutManager {
  constructor(options = {}) {
    this.panelLayoutInfo = {
      x: 0,
      y: 0
    }
    this.maxColumns = options.maxColumns || 12;
  }

  getColWidthFromSize(size) {
    switch (size) {
      case inputSizes.XS:
        return 3;
      case inputSizes.S:
        return 4;
      case inputSizes.M:
        return 6;
      case inputSizes.L:
        return 8;
      case inputSizes.XL:
        return 12;
      default:
        return 6;
    }
  }

  getLayoutObj(size, newLine) {
    const width = this.getColWidthFromSize(size);
    if (newLine || this.panelLayoutInfo.x + width > this.maxColumns) {
      this.panelLayoutInfo.y++;
      this.panelLayoutInfo.x = 0;
    }

    const layout = {
      x: this.panelLayoutInfo.x,
      y: this.panelLayoutInfo.y,
      w: width,
      h: 1,
      newLine: newLine
    };
    this.panelLayoutInfo.x += width;

    return layout;
  }
}

/********************************************/


/******** Schema Engine ****************/

/**
 * This class provides the logic to build out a Winterfell demograhics schema JSON object
 * given a DemograhpicsSchema object.
 * 
 */
export default class SchemaEngine {

  static GOTO = 'GOTO';


  createFormPanelObject(panel, index) {
    return {
      index: index,
      panelId: panel.panelId
    }
  }

  createQuestionPanelObject(panel, index, allPanels) {
    const action = {};
    if (index - 1 >= 0) {
      action.previous = {
        action: SchemaEngine.GOTO,
        target: allPanels[index - 1].panelId
      };
    }
    if (index + 1 < allPanels.length) {
      action.next = {
        action: SchemaEngine.GOTO,
        target: allPanels[index + 1].panelId
      };
    }

    return {
      panelId: panel.panelId,
      tabText: panel.name || `Panel ${index + 1}`,
      action: action,
      questionSets: [
        {
          questionSetId: utils.createSetId(panel.panelId)
        }
      ]
    }
  }

  createQuestionSetObject(panel) {
    const questions = panel.questions;
    const layoutManager = new LayoutManager();

    return {
      questionSetId: utils.createSetId(panel.panelId),
      questions: questions.map(q => this.createQuestionObject(layoutManager, q))
    }

  }

  createQuestionObject(layoutManager, question) {
    return {
      questionId: question.id,
      question: question.question,
      questionLabel: question.label,
      layout: layoutManager.getLayoutObj(question.size, question.newLine === 'yes'),
      meta: this.getMetaObj(question),
      input: this.getInputObj(question),
      validations: this.getValidationsObj(question),
    }
  }

  getMetaObj(question) {
    let retObj = {
      userDefined: question.userDefined,
      required: question.required === 'yes',
      cannotDelete: !question.canDelete,
      maxLength: question.maxLength || undefined,
      reportName: question.reportName || '',
      isPII: question.isPII === 'yes',
      //shareSponsors: question.shareSponsors === 'yes',
      //sharedSponsorIds: question.sharedSponsorIds || [],
      //sponsorIds: question.sharedSponsorIds ? question.sharedSponsorIds.map(org => org.value) : []
  };

    if (question.saveAsBoolean) {
      retObj.saveAsBoolean = question.saveAsBoolean;
    }

    return retObj;
  }

  getInputObj(question) {
    return question.getSchemaObj();
  }

  getValidationsObj(question) {
    // Get the base validations.  This will make sure any validations that cannot be changed (like noSpecialCharacters) are kept.
    const updatableValidationTypes = Object.values(validationTypes);
    const validations = question.validations.filter(v => !updatableValidationTypes.includes(v.type));

    // Add the 'exists' validation if the question is required.
    if (question.required === 'yes') {
      validations.unshift(validationBuilders.buildExists());
    }

    // Add the 'isLength' validation if one is set
    const maxLength = parseInt(question.maxLength, 10);
    if (!isNaN(maxLength)) {
      let maxLengthValidationObj = validationBuilders.buildTextLength(maxLength);

      let existingIndex = validations.findIndex(val => val.type === validationTypes.TEXT_LENGTH);
      if (existingIndex >= 0) {
        validations.splice(existingIndex, 1, maxLengthValidationObj);
      } else {
        validations.push(maxLengthValidationObj);
      }
    }

    // Add the 'isInRange' validation if it should be set
    const parsedMin = parseFloat(question.minValue);
    const parsedMax = parseFloat(question.maxValue);
    if (!isNaN(parsedMin) || !isNaN(parsedMax)) {
      const rangeValidationObj = validationBuilders.buildRange(parsedMin, parsedMax);

      let existingIndex = validations.findIndex(val => val.type === validationTypes.RANGE);
      if (existingIndex >= 0) {
        validations.splice(existingIndex, 1, rangeValidationObj);
      } else {
        validations.push(rangeValidationObj);
      }
    }

    return validations;
  }

  /**
   * Public Methods
   */

  generate(demographicsSchema) {
    const { panels } = demographicsSchema;

    let formPanels = new Array(panels.length);
    let questionPanels = new Array(panels.length);
    let questionSets = new Array(panels.length);

    // Generate the schema
    for (let i = 0; i < panels.length; i++) {
      let panel = panels[i];
      formPanels[i] = this.createFormPanelObject(panel, i);
      questionPanels[i] = this.createQuestionPanelObject(panel, i, panels);
      questionSets[i] = this.createQuestionSetObject(panel);
    }

    const schema = {
      formPanels,
      questionPanels,
      questionSets,
    };

    return schema;
  }
}
