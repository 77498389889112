import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Toast } from 'primereact/toast';

import { withPickListSelection } from '../../shared/withPickListSelection';
import AssignStaff from './AssignStaff';
import DisplayStaffForAssignment from './DisplayStaffForAssignment';

import api from '../../../scripts/api';
import { message } from '../../shared/Message';
import IndexItem from '../../../DTOs/shared/IndexItem';
import AssignStaffToManagerDto from '../../../DTOs/Staff/AssignStaffToManagerDto';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../../constants';
import { hasRequiredPermissions } from '../../../scripts/permissionHelper.js';

const assignStaffPermissions = {
  rolePermission: rolePermissionLevels.ORG_MANAGER,
  licenseLevelPermission: [licenseLevels.FREE, licenseLevels.PAID],
  licenseTypePermission: [licenseTypes.SPONSOR, licenseTypes.INDIVIDUAL]
};

export class StaffForAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: [],
      listData: [],
      showEdit: false,
    };

    this.saveErrorMessageId = null;
    this.getErrorMessageId = null;
    this.toast = React.createRef();
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveStaff = this.handleSaveStaff.bind(this);
  }

  initializeComponent = async(id) => {
    message.dismiss(this.getErrorMessageId);
    if (id && parseInt(id, 10) !== 0) {
      try {
        const getAssignedStaff = await api.getAssignedStaffForManager(id);
        const getAllStaff = await api.getStaffForAssignment();

        const selectedStaff = getAssignedStaff.assignedStaff.map((staff) => ({
          value: staff.id, 
          label: `${staff.lastName}, ${staff.firstName}`
        }))

        const availableStaff = getAllStaff.applicationUsers.map(staff => ({
          label: `${staff.lastName}, ${staff.firstName}`,
          value: staff.id 
        })).filter(item => (
          !selectedStaff.some((staff) => staff.value === item.value)
        ))
        this.setState({
          staff: getAssignedStaff.assignedStaff,

          listData: getAssignedStaff.assignedStaff.map(staff => new IndexItem({
            title: `${staff.lastName}, ${staff.firstName}`,
            description: '',
            itemId: staff.id,
            url: `/Staff/${staff.id}`,
            isDisabled: false
          })),
        })

        this.props.fetchSelectData(selectedStaff);
        this.props.fetchAvailableData(availableStaff)

      } catch(error) {
        console.log(`Error getting staff for manager: ${error}`);
        this.getErrorMessageId = message.error(`Error getting staff for manager: ${error.message}`);
      }
    }
  }

  handleSaveStaff() {
    message.dismiss(this.saveErrorMessageId);
    const dto = new AssignStaffToManagerDto({
      managerId: this.props.managerId,
      staffIdsToAssign: this.props.selected.map(staff => staff.value)
    });

    api.updateAssignedStaffForManager(dto.stringify()).then(() => {
      this.initializeComponent(this.props.managerId);
      this.setState({ showEdit: false })
      this.toast.current.show({
        severity: 'success',
        summary: "Update Success",
        detail: 'Staff assignment was successfully updated',
        life: 3000
      })
    }).catch(err => {
      this.setState({ showEdit: false });
      this.toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `An error occurred while updating staff assignment: ${err}`,
        life: 3000
      })
    });
  }

  handleEditClick() {
    this.setState({ showEdit: true })
  }

  handleCancelClick() {
    this.setState({ showEdit: false })
    this.initializeComponent(this.props.managerId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.managerId !== prevProps.managerId) {
      this.initializeComponent(this.props.managerId);
    }
  }

  componentDidMount() {
    this.initializeComponent(this.props.managerId);
  }

  render() {
    const { showEdit, listData } = this.state;
    const { userPermissions, available, selected, handleAddToSelected, handleRemoveFromSelected } = this.props;
    const showAssignStaff = hasRequiredPermissions(assignStaffPermissions, userPermissions);

    return (
      <Container className="pb-3">
        <Row className="my-3">
          <Col md={6} className="align-self-end">
            <h5>Assigned Staff</h5>
          </Col>

          <Col md={6} className="d-flex justify-content-end">

            { !showEdit && showAssignStaff
                ? <Button variant="outline-primary" onClick={() => this.handleEditClick()}>Assign Staff</Button>
                : null
            }

          </Col>
        </Row>

        {showEdit
          ? <AssignStaff 
              defaultStaff={selected}
              availableStaff={available}
              handleCancelClick={this.handleCancelClick}
              handleAddToSelected={handleAddToSelected}
              handleRemoveFromSelected={handleRemoveFromSelected}
              handleSaveStaff={this.handleSaveStaff}
            />
          : <DisplayStaffForAssignment listData={listData} />
        }

        <Toast ref={this.toast} position='bottom-left' />

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.permissions
  };
}

export default connect(
  mapStateToProps
)(withPickListSelection(StaffForAssignment));
