/**
 * Returns the specified value as an integer format (stripping out any non-numeric chars)
 * Useful for removing leading 0's in the integer portion of the value
 * This will work with both positive and negative integers
 *
 * @param {String} value the raw value that needs to be parsed & formatted
 * @return {String} the formatted string (i.e. 1234)
 */

const getIntString = (value) => {
  const num = value.toString().replace(/[^0-9-]/g, '');

  if (!num) {
    return '';
  }

  // Only parse if there is a number present.  This could be the beginning of a negative number with only '-' present
  if (RegExp('[0-9]', 'g').test(num)) {
    return `${parseInt(num, 10)}`;
  }

  return num;
}

export default getIntString;