import { localStorageCacheKeys } from '../constants'

// Last Run
export const getCacheIsValid = (lastRunStatus, orgId, email, cacheKey) => {
  let cachedLastRun = getCachedValue(orgId, email, cacheKey + localStorageCacheKeys.LAST_RUN_TIME, true);
  return cachedLastRun === lastRunStatus;
}

export const cacheLastRunStatus = (lastRunStatus, orgId, email, cacheKey) => {
  cacheValue(orgId, email, cacheKey + localStorageCacheKeys.LAST_RUN_TIME, lastRunStatus);
}

// Clients Served
export const cacheClientsServed = (clientsServed, orgId, email) => {
  cacheValue(orgId, email, localStorageCacheKeys.CLIENTS_SERVED, clientsServed);
};

export const getCachedClientsServed = (orgId, email) => {
  return getCachedValue(orgId, email, localStorageCacheKeys.CLIENTS_SERVED);
};

// Service Hours
export const cacheServiceHours = (serviceHours, orgId, email) => {
  cacheValue(orgId, email, localStorageCacheKeys.SERVICE_HOURS, serviceHours);
};

export const getCachedServiceHours = (orgId, email) => {
  return getCachedValue(orgId, email, localStorageCacheKeys.SERVICE_HOURS);
};

// Program Count
export const cacheProgramCount = (programCount, orgId, email) => {
  cacheValue(orgId, email, localStorageCacheKeys.PROGRAM_COUNT, programCount);
};

export const getCachedProgramCount = (orgId, email) => {
  return getCachedValue(orgId, email, localStorageCacheKeys.PROGRAM_COUNT);
};

// Outcome Summaries
export const cacheOutcomeSummaries = (outcomeSummaries, orgId, email) => {
  cacheValue(orgId, email, localStorageCacheKeys.OUTCOME_SUMMARIES, outcomeSummaries.stringify());
};

export const getCachedOutcomeSummaries = (orgId, email) => {
  return getCachedValue(orgId, email, localStorageCacheKeys.OUTCOME_SUMMARIES);
};

// Utilities
export const clearLocalStorageCache = () => {
  localStorage.clear();
}

const getFullCacheKey = (orgId, email, cacheKey) => {
  return `${cacheKey}${orgId}-${email}-${process.env.REACT_APP_BUILD}`;
}

export const cacheValue = (orgId, email, cacheKey, value) => {
  localStorage.setItem(getFullCacheKey(orgId, email, cacheKey), value);
}

export const getCachedValue = (orgId, email, cacheKey, raw = false) => {
  let cachedValue = localStorage.getItem(getFullCacheKey(orgId, email, cacheKey));
  return raw
    ? cachedValue
    : cachedValue
      ? JSON.parse(cachedValue)
      : null;
}

//Api Filters
export const cacheApiFilters = (apiFilters, orgId, email) => {
  cacheValue(orgId, email, localStorageCacheKeys.API_FILTERS, apiFilters.stringify());
}

export const getCachedApiFilters = (orgId, email) => {
  return getCachedValue(orgId, email, localStorageCacheKeys.API_FILTERS);
}

//Needs Update 
export const cacheNeedsUpdate = (value) => {
  localStorage.setItem(localStorageCacheKeys.LOCAL_VERSION_OUTDATED, value);
}

export const getCachedNeedsUpdate = () => {
  return localStorage.getItem(localStorageCacheKeys.LOCAL_VERSION_OUTDATED);
}
