import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';

import MeasureDto from '../../DTOs/Measure/MeasureDto';
import KeywordDto from '../../DTOs/Keyword/KeywordDto';
import api from '../../scripts/api';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants'
import { hasRequiredPermissions } from '../../scripts/permissionHelper';

import EditMeasure from './EditMeasure';
import DisplayMeasure from './DisplayMeasure';

const EDITING_PERMISSIONS = {
    rolePermission: rolePermissionLevels.ORG_ADMIN || rolePermissionLevels.SITE_ADMIN,
    licenseLevelPermission: licenseLevels.PAID,
    licenseTypePermission: [licenseTypes.INDIVIDUAL, licenseTypes.SPONSOR]
}

function Measure({demographicsKeywords, ...props}) {
    const [measure, setMeasure] = useState(new MeasureDto());
    const [showEdit, setShowEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const canEdit = hasRequiredPermissions(EDITING_PERMISSIONS, props.userPermissions)
    const editAction = "Edit";
    const outcomeCategoryKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === "MeasureOutcomeCategory" && new KeywordDto(kw))
    const measureTypeKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === 'MeasureType' && new KeywordDto(kw));
    const cutoffScoreComparisonKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === 'MeasureCutoffComparison' && new KeywordDto(kw));
    
    const handleEditClick = () => {
        setShowEdit(true)
    }
    
    const handleCancelClick = () => {
        setShowEdit(false);
    }
      
    const updateMeasure = (values) => {
        const dto = new MeasureDto({
            ...values,
            outcomeCategory: outcomeCategoryKeywords.find(kw => kw.value === values.outcomeCategoryValue),
            fieldDefinitions: {
                fields: values.fieldDefinitions.fields
            },
            type: measureTypeKeywords.find(kw => kw.value === values.typeValue),
            cutoffScoreComparison: cutoffScoreComparisonKeywords.find(kw => kw.value === values.cutoffScoreComparisonValue)
          })

        api.updateMeasure(dto.stringify())
          .then(result => {
            setMeasure(result)
            setShowEdit(false)
          })
          .catch(error => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: `An error occurred while attempting to update this measure: ${error.message}`,
                life: 3000
            })
          })
    }

    const publishMeasure = () => {
        setIsLoading(true);

        const dto = new MeasureDto({
            ...measure,
            isDraft: false,
        })

        api.updateMeasure(dto.stringify())
            .then(result => {
                setMeasure(result);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: `An error occurred while attempting to publish this measure: ${error.message}`,
                    life: 3500
                })
            })
  }

  const unpublishMeasure = () => {
    setIsLoading(true);

    api.setMeasureToDraft(measure.id)
      .then(result => {
        setMeasure(result);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `An error occurred while attempting to set this measure to draft: ${error.message}`,
          life: 3500
        })
      })
  }

    useEffect(() => {
        api.getMeasureById(props.match.params.id)
        .then(result => {
          setMeasure(result);
        })
        .catch(err => {
            console.log({err})
        });
      }, [props.match.params.id]);
  
    return (
    <>
        {showEdit && canEdit ? (
            <EditMeasure
                measure={measure}
                editAction={editAction}
                handleFormSubmit={updateMeasure}
                outcomeCategory={outcomeCategoryKeywords}
                measureType={measureTypeKeywords}
                cutoffScoreComparison={cutoffScoreComparisonKeywords}
                handleCancelClick={handleCancelClick}
            />
        ) : (
            <DisplayMeasure
                measure={measure}
                handleEditClick={handleEditClick}
                canEdit={canEdit}
                publishMeasure={publishMeasure}
                unpublishMeasure={unpublishMeasure}
                isLoading={isLoading}
                userPermissions={props.userPermissions}
            />
        )
        }

        <Toast ref={toast} position='bottom-left' />
    </>
  )
}

export default connect(
    state => ({
        demographicsKeywords: state.user.demographicsKeywords,
        userPermissions: state.user.permissions
    })
)(Measure);
