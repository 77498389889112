import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { inputTypes } from '../../../constants';
import { TextInput, RadioButtonGroupInput, RadioButtonInput } from '../../shared/inputs/index';
import NumberInputFields from './additionalFields/NumberInputFields';
import TextInputFields from './additionalFields/TextInputFields';

import api from '../../../scripts/api';
import { message } from '../../shared/Message';

import MultiSelect from '../../shared/asyncSelect/MultiSelect';

// Determines any additional components that need to be displayed based on the given input type
const getAdditionalFields = (type, props) => {
  let FieldsComponent;
  if (type === inputTypes.INTEGER || type === inputTypes.DECIMAL) {
    FieldsComponent = <NumberInputFields {...props} />;
  } else if (type === inputTypes.TEXT || type === inputTypes.TEXT_AREA) {
    FieldsComponent = <TextInputFields {...props} />;
  }

  return FieldsComponent;
}

const TextInputEditor = ({ inputInfo, values, handleChange, touched, errors, setFieldValue }) => {

  const additionalFields = getAdditionalFields(inputInfo.type, { inputInfo, values, handleChange, touched, errors });

  const [sponsoringOrgs, setSponsoringOrgs] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState(values.sharedSponsorIds);
  const [pageSize, setPageSize] = useState(50);
  const [pageNum, setPageNum] = useState(50);

  const searchOrgs = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
    let result;
    try {
      result = await api.getSponsoringOrganizationsAsync(searchTerm, pageNum, pageSize);

    } catch (err) {
      message.error(`Error retrieving Partner Organizations: ${err}`);
      return { options: [] };
    }

    const searchResults = result.searchResults.map(org => ({ value: org.id, label: org.name }));
    const hasMore = result.totalMatches > pageNum * pageSize + searchResults.length;

    return {
      options: searchResults,
      hasMore: hasMore,
      additional: {
        pageNum: pageNum + 1, //next page
        pageSize: pageSize
      }
    };
  }

  const handleSelectOrgs = (selected) => {
    // selected will be sent as null instead of an empty array when the user backspaces away all search text and every selection
    const tempOrgs = selected ? selected : [];
    setSelectedOrgs(tempOrgs);
    setFieldValue('sharedSponsorIds', tempOrgs);
  }

  return (
    <React.Fragment>
      <Row>
        <Form.Group as={Col} xs={8}>
          <Form.Label>Label Text</Form.Label>
          <TextInput
            name="label"
            value={values.label}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} xs={8}>
          <Form.Label>Placeholder Text</Form.Label>
          <TextInput
            name="placeholder"
            value={values.placeholder}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>Is the question required?</Form.Label>

          <RadioButtonGroupInput
            wrapClasses="d-flex flex-wrap"
            error={errors.required}
            touched={touched.required}
          >
            <RadioButtonInput
              name="required"
              id="required-yes"
              value="yes"
              onChange={handleChange}
              checked={values.required === 'yes'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="Yes"
            />
            <RadioButtonInput
              name="required"
              id="required-no"
              value='no'
              onChange={handleChange}
              checked={values.required === 'no'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="No"
            />
          </RadioButtonGroupInput>
            
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Form.Label>Is this personal identifiable information (PII)?</Form.Label>
          <RadioButtonGroupInput
            wrapClasses="d-flex flex-wrap"
            error={errors.isPII}
            touched={touched.isPII}
          >
            <RadioButtonInput
              name="isPII"
              id="isPII-yes"
              value="yes"
              onChange={handleChange}
              checked={values.isPII === 'yes'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="Yes"
            />
            <RadioButtonInput
              name="isPII"
              id="isPII-no"
              value='no'
              onChange={handleChange}
              checked={values.isPII === 'no'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="No"
            />

          </RadioButtonGroupInput>

        </Form.Group>
      </Row>

      {/*<Row>
        <Form.Group as={Col}>
          <Form.Label>Share this information with partners?</Form.Label>
          <RadioButtonGroupInput
            wrapClasses="d-flex flex-wrap"
            error={errors.shareSponsors}
            touched={touched.shareSponsors}
          >
            <RadioButtonInput
              name="shareSponsors"
              id="shareSponsors-yes"
              value="yes"
              onChange={handleChange}
              checked={values.shareSponsors === 'yes'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="Yes"
            />
            <RadioButtonInput
              name="shareSponsors"
              id="shareSponsors-no"
              value='no'
              onChange={handleChange}
              checked={values.shareSponsors === 'no'}
              className="input-editor__radio-option input-editor__radio-option--small"
              label="No"
            />

          </RadioButtonGroupInput>

        </Form.Group>
      </Row>*/}

    {/*{values.shareSponsors === 'yes'
        ? (
          <React.Fragment>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Add one or more Partner Organizations</Form.Label>
                <Col className="px-0">
                  <MultiSelect
                    name="sharedSponsorIds"
                    id="sharedSponsorIds"
                    placeholder="Search"
                    defaultValue={selectedOrgs}
                    closeMenuOnSelect={false}
                    onChange={handleSelectOrgs}
                    loadOptions={searchOrgs}
                    additional={{ pageNum: pageNum, pageSize: pageSize }}
                  />
                </Col>
              </Form.Group>
            </Row>

          </React.Fragment>)
        : null
      }*/}

      {additionalFields}

    </React.Fragment>
  );
}

export default TextInputEditor;
