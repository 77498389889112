import FilterOption from './FilterOption';

export default class ApiFilterDto {
  constructor(options = {}) {
    this.name = options.name || '';
    this.displayName = options.displayName || '';
    this.inputType = options.inputType || 0;
    this.filterOptions = options.filterOptions ? options.filterOptions.map(op => new FilterOption(op)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
