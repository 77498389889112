import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DragDropContext } from 'react-beautiful-dnd';
import SectionHeader from '../SectionHeader';
import InputLibrary from './InputLibrary';
import PageElementsList from './PageElementsList';
import { InputsDisplay } from './displayInputs';
import DemographicsBuilderContext from '../demographicsBuilderContext';


/***** Util functions for Drag and Drop *****/
function copy(source, destination, sourceIndex, destinationIndex) {
  const sourceClone = [...source];
  const destinationClone = [...destination];

  const id = `u${Date.now()}`;

  const item = sourceClone[sourceIndex];
  const question = item.clone({ userDefined: true, id: id, name: '' });
  destinationClone.splice(destinationIndex, 0, question);
  return destinationClone;
}

function move(source, sourceIndex, destinationIndex) {
  const sourceClone = [...source];

  const [item] = sourceClone.splice(sourceIndex, 1);
  sourceClone.splice(destinationIndex, 0, item);
  return sourceClone;
}

const ColumnIds = {
  LIBRARY: 'LIBRARY',
  PAGE_ELEMENTS: 'ELEMENTS'
};

/*************************/


class Elements extends Component {

  static contextType = DemographicsBuilderContext

  constructor(props) {
    super(props);

    this.state = {
      isDragging: false
    };

    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragEnd(result) {
    const { source, destination } = result;
    const { schema, currentPanelId, isSchemaSelected, isPanelSelected } = this.context;

    this.setState({ isDragging: false });
    if (!isSchemaSelected() || !isPanelSelected()) return;

    const formElements = schema.getQuestions(currentPanelId);

    if (
      !destination ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      return;
    }

    let nextFormElements = [];
    switch (source.droppableId) {
      case ColumnIds.LIBRARY:
        nextFormElements = copy(InputsDisplay, formElements, source.index, destination.index);
        break;
      default:
        nextFormElements = move(formElements, source.index, destination.index);
        break;
    }

    schema.updatePanelQuestions(currentPanelId, nextFormElements);
  }

  render() {
    const { schema, currentPanelId, isSchemaSelected } = this.context;
    const { isDragging } = this.state;

    let reportFieldNames = [];
    let formElements = [];
    if (isSchemaSelected()) {
      formElements = schema.getQuestions(currentPanelId);
      reportFieldNames = schema.getReportFieldNames();
    }

    return (
      <DragDropContext
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
      >
        <Row className={`demographics-builder__row ${isDragging ? 'dragging--active' : ''}`}>
          <Col xs={6} className="demographics-builder__row__col">
            <SectionHeader
              header="Drag and Drop Form Elements"
              subHeader="Drag right to place"
            />
            <InputLibrary
              droppableId={ColumnIds.LIBRARY}
              formElements={InputsDisplay}
              isDragging={isDragging}
            />
          </Col>
          <Col xs={6} className="demographics-builder__row__col">
            <SectionHeader
              header="Drop Elements Here"
              subHeader="Move up/down to reorder"
            />
            <PageElementsList
              droppableId={ColumnIds.PAGE_ELEMENTS}
              formElements={formElements}
              reportFieldNames={reportFieldNames}
              isDragging={isDragging}
            />
          </Col>
        </Row>
      </DragDropContext>
    );
  }
}

export default Elements;