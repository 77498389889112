import React from 'react';
import { Button } from 'primereact/button';
import { getCachedNeedsUpdate, cacheNeedsUpdate } from '../../scripts/localStorageHelper'

function VersionCheck() {
    const checkVersion = () => {
        return getCachedNeedsUpdate() === 'true';
    }

    const reload = () => {
        cacheNeedsUpdate(false);
        window.location.reload();
    }

    return (
        <>
            {checkVersion() &&
                <div className="version-checker">
                <div className="buttons">
                <Button type="button" label="Update" className="mr-2" onClick={reload} />
                <span>New version available!  Please update when you finish your current task.</span>
                </div>
                </div>
            }
        </>
    );
}

export default VersionCheck;
