import React from "react";

/**
 * Button wrapper to create a button with an outline
 */
const OutlineButton = props => {
  
  const onClick = props.onClick || (() => {});

  return (
    <React.Fragment>
      <button 
        name={props.name} 
        id={props.id}
        onClick={onClick}
        disabled={props.disabled}
        className="btn btn-outline-primary" >
            {props.label}
        </button>
    </React.Fragment>
  );
};

export default OutlineButton;
