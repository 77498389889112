import React, { Component } from 'react';
import { Container, Row, Col, Button, ButtonToolbar, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { withRouter } from '../shared/RouterShim';
import { connect } from 'react-redux';
import api from '../../scripts/api';
import SearchSelect from '../shared/asyncSelect/SearchSelect';
import { message } from '../shared/Message';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants';
import { hasRequiredPermissions } from '../../scripts/permissionHelper.js';

const createPermissions = {
  rolePermission: rolePermissionLevels.ORG_MANAGER,
  licenseLevelPermission: licenseLevels.PAID,
  licenseTypePermission: licenseTypes.INDIVIDUAL
};

const PERMISSIONS_FOR_ROLLUP_EXPORT = {
  rolePermission: rolePermissionLevels.ORG_ADMIN || rolePermissionLevels.SITE_ADMIN,
  licenseLevelPermission: [licenseLevels.PAID, licenseLevels.FREE],
  licenseTypePermission: licenseTypes.SPONSOR
};

export class ServiceIndexActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noOptionsMessage: 'No matching services found',
      pageSize: 50,
      pageNum: 0
    };

    this.toggleImportBar = this.toggleImportBar.bind(this);
    this.errorMessageId = null;
    this.searchServices = this.searchServices.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
  }

  toggleImportBar() {
    this.props.history.push('/OrganizationService/Import');
  }

  searchServices = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
    let result;
    try {
      result = await api.searchServices(searchTerm, pageNum, pageSize);
    } catch (err) {
      console.log(`Error searching for client: ${err}`);
      message.dismiss(this.errorMessageId);
      this.errorMessageId = message.error(`Error searching for service: ${err}`);
      return { options: [] };
    }

    const searchResults = result.searchResults.map(dto => ({
			label: dto.name,
			value: dto.id,
		}))
    const hasMore = result.totalMatches > (pageNum * pageSize) + searchResults.length;

    return {
      options: searchResults,
      hasMore: hasMore,
      additional: {
        pageNum: pageNum + 1,
        pageSize: pageSize
      }
    }
  }

  onSearchSelect = (selected) => {
    this.props.history.push(`/OrganizationService/${selected.value}`);
  }


  render() {
    const { pageNum, pageSize, noOptionsMessage } = this.state;
    const {
      handleAddServiceClick,
      userPermissions,
      handleExportDataClick,
      showButtonToolbar,
      apiFilters,
      availableFilterTypes
    } = this.props;
    const canExportRollup = hasRequiredPermissions(PERMISSIONS_FOR_ROLLUP_EXPORT, userPermissions);
    const hasFilters = apiFilters.length > 0 && availableFilterTypes.length > 0;
    return (
      <Container className="d-inline-block">
        <Row>
          <Col className="pl-0">
                <SearchSelect
                  placeholder="Search services"
                  noOptionsMessage={() => noOptionsMessage}
                  loadOptions={this.searchServices}
                  additional={{ pageNum: pageNum, pageSize: pageSize }}
                  onChange={this.onSearchSelect}
                />
          </Col>

          <Col md={6}>
  
            {showButtonToolbar && <ButtonToolbar className="d-flex justify-content-end">
              <DropdownButton
                as={ButtonGroup}
                variant="outline-primary"
                title="Import/Export Data" >
                <Dropdown.Item onClick={() =>
                  handleExportDataClick(hasFilters, 'Filter Service Data', false)}>
                  Export Service Data
                </Dropdown.Item>
                {canExportRollup &&
                  (<Dropdown.Item onClick={() =>
                    handleExportDataClick(hasFilters, 'Filter Service Roll-Up Data', true)}>
                    Export Service Roll-Up Data
                  </Dropdown.Item>)}
                <Dropdown.Item onClick={this.toggleImportBar}>Bulk Import Service Data</Dropdown.Item>
              </DropdownButton>
              {hasRequiredPermissions(createPermissions, userPermissions) && <Button
                variant="primary"
                onClick={handleAddServiceClick} >
                Add Service
              </Button>
              }
            </ButtonToolbar>}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(
  connect((state) => ({
    userPermissions: state.user.permissions,
    apiFilters: state.user.apiFilters
  }))(ServiceIndexActions)
);
