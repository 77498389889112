import OrganizationServiceDto from '../OrganizationService/OrganizationServiceDto';
import MeasureCategoryDto from '../Measure/MeasureCategoryDto';

export default class ClientCategoriesDto {
  constructor(options = {}) {
    this.activityCategories = options.activityCategories.map(dto => new OrganizationServiceDto(dto)) || [];
    this.measureCategories = options.measureCategories.map(dto => new MeasureCategoryDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}