import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Toast } from 'primereact/toast';

import AssignClients from './AssignClients';
import DisplayClients from './DisplayClients';
import { withPickListSelection } from '../../shared/withPickListSelection';

import api from '../../../scripts/api';
import { message } from '../../shared/Message';
import IndexItem from '../../../DTOs/shared/IndexItem';
import StaffAssignedClientsDto from '../../../DTOs/Staff/StaffAssignedClientsDto';

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      listData: [],
      showEdit: false,
      isLoading: false
    };

    this.getErrorMessageId = null;
    this.toast = React.createRef();

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClients = this.handleSaveClients.bind(this);
  }

  initializeComponent = async(id) => {
    message.dismiss(this.getErrorMessageId);
    if (id && parseInt(id, 10) !== 0) {
      try {
        const clientsAssignedToStaff = await api.getAllClientsForStaff(id);
        const allClientsForAssignment = await api.getAllClientsForUser();
        
        const selectedClients = clientsAssignedToStaff.allClients.map(client => ({
          value: client.id,
          label: `${client.lastName}, ${client.firstName}`,
          birthDate: client.dateOfBirth
        }));

        const availableClients = allClientsForAssignment.allClients.map(client => ({
          value: client.id,
          label: `${client.lastName}, ${client.firstName}`,
          birthDate: client.dateOfBirth
        })).filter(item => (
          !selectedClients.some(selected => selected.value === item.value)
        ))
        
        this.props.fetchSelectData(selectedClients)
        this.props.fetchAvailableData(availableClients);

        this.setState({
          clients: clientsAssignedToStaff.allClients,

          listData: clientsAssignedToStaff.allClients.map(client => new IndexItem({
            title: `${client.lastName}, ${client.firstName}`,
            description: `DOB: ${client.dateOfBirth}`,
            itemId: client.id,
            url: `/Client/${client.id}`,
            isDisabled: false
          })),
        })

      } catch(err) {
        this.getErrorMessageId = message.error(`Error getting clients for staff: ${err}`)
      }
    }
  }

  handleSaveClients() {
    this.setState({ isLoading: true })
    const dto = new StaffAssignedClientsDto({
      staffId: this.props.staffId,
      clientIds: this.props.selected.map(client => client.value)
    });

    api.updateClientsForStaff(dto.stringify())
      .then(result => {
        this.setState({ isLoading: false, showEdit: false })
        this.initializeComponent(this.props.staffId);
        this.toast.current.show({
          severity: 'success',
          summary: 'Update Success',
          detail: `${result.firstName} ${result.lastName}'s clients were successfully updated`,
          life: 3000
        })
      }).catch(err => {
        this.setState({ isLoading: false, showEdit: false })
        this.toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `Error updating clients for staff: ${err}`,
          life: 3000
        })
      });
  }

  handleEditClick() {
    this.setState({ showEdit: true })
  }

  handleCancelClick() {
    this.setState({ showEdit: false })
    this.initializeComponent(this.props.staffId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.staffId !== prevProps.staffId) {
      this.initializeComponent(this.props.staffId);
    }
  }

  componentDidMount() {
    this.initializeComponent(this.props.staffId);
  }

  render() {
    const { showEdit, listData, isLoading } = this.state;
    const { selected, available, handleAddToSelected, handleRemoveFromSelected } = this.props;

    return (
      <>
        <Container className="basic-card pb-3">
          <Row className="my-3">
            <Col md={6} className="align-self-end">
              <h5>Assigned Clients</h5>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              {!showEdit && <Button variant="outline-primary" onClick={() => this.handleEditClick()}>Assign Clients</Button>}
            </Col>
          </Row>

          {showEdit
            ? <AssignClients
                defaultClients={selected}
                availableClients={available}
                handleCancelClick={this.handleCancelClick}
                handleAddToSelected={handleAddToSelected}
                handleRemoveFromSelected={handleRemoveFromSelected}
                handleSaveClients={this.handleSaveClients}
              />
            : 
            <DisplayClients listData={listData} position='bottom-left' />
          }
        </Container>

        <Toast ref={this.toast} position='bottom-left' />
      </>
    );
  }
}

export default withPickListSelection(Clients);