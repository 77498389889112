import { format } from 'date-fns';
import { parseDate } from '../../scripts/safeDateParse';
import ClientRelationshipDto from './ClientRelationshipDto';

function parseJson(rawJson) {
  let parsed = null;
  if (!rawJson) return parsed;
  try {
    parsed = JSON.parse(rawJson);
  } catch (err) {
    // do nothing
  }
  return parsed;
}


export default class ClientDto {

  /*
   * These are properties in the dto object that do not map to a corresponding property in
   * the demographics object.
   * NOTE - If you add a new property to this ClientDto object that is not in the demographics
   * object, add the property name here.
   */
  static DemographicNonMappedProperties = [
    'id',
    'organizationId',
    'displayName',
    'displayState',
    'displayGenderIdentity',
    'hasAddress',
    'demographicsJson',
    'allSelectedKeywords',
    'isDisabled',
    'isActive'
  ];

  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.isDisabled = options.isDisabled;
    this.isActive = !options.isDisabled;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.dateOfBirth = options.dateOfBirth ? format(parseDate(options.dateOfBirth), 'MM/dd/yyyy') : '';
    this.canEdit = options.canEdit || false;

    //******** demographics Json *************
    this.demographicsJson = parseJson(options.demographicsJson);
    this.reportJson = parseJson(options.reportJson);
    //****************************************

    //******** Other information *************
    this.relationships = options.relationships ? options.relationships.map(r => new ClientRelationshipDto(r)) : [];
    //****************************************

    //******** data that will be computed from reportJson if available ********
    this.displayGenderIdentity = '';
    this.streetAddress1 = '';
    this.streetAddress2 = '';
    this.city = '';
    this.displayState = '';
    this.postalCode = '';
    this.county = '';
    this.phoneNumber = '';
    this.emailAddress = '';

    let genderIdentityText = '';
    let genderOther = '';

    if (this.reportJson) {
      const rJson = this.reportJson;
      genderIdentityText = rJson.genderIdentity ? rJson.genderIdentity.genderIdentityValue : '';
      genderOther = rJson.genderOther || '';

      this.displayState = rJson.state ? rJson.state.stateValue : '';
      this.displayGenderIdentity =
        `${genderIdentityText}${(genderIdentityText && genderOther) ? ' - ' : ''}${genderOther}`;

      this.streetAddress1 = rJson.streetAddress1 || '';
      this.streetAddress2 = rJson.streetAddress2 || '';
      this.city = rJson.city || '';
      this.postalCode = rJson.postalCode || '';
      this.county = rJson.county || '';
      this.phoneNumber = rJson.phoneNumber || '';
      this.emailAddress = rJson.emailAddress || '';
    }
    //****************************************

    this.displayName = `${this.lastName}, ${this.firstName}`;
    this.hasAddress = this.streetAddress1 || this.streetAddress2 || this.city || this.displayState || this.postalCode;
  }
  
  stringify() {
    return JSON.stringify({
      ...this,
      demographicsJson: JSON.stringify(this.demographicsJson),
      reportJson: JSON.stringify(this.reportJson)
    });
  }
}
