import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import OrganizationEdit from './OrganizationEdit.js';
import { message } from '../shared/Message';

import { licenseLevels, licenseTypes } from '../../constants.js';
import OrganizationDto from '../../DTOs/Organization/OrganizationDto.js';
import api from '../../scripts/api.js';

export class OrganizationAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: new OrganizationDto({
        licenseLevel: licenseLevels.PAID,
        licenseType: licenseTypes.INDIVIDUAL,
        isDisabled: false
      }),
      apiResult: false,
      editAction: 'Add'
    }

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.addOrganizationSubmit = this.addOrganizationSubmit.bind(this);
  }

  handleCancelClick() {
    this.props.history.push('/Organization');
  }

  addOrganizationSubmit(values, actions) {
    message.dismissAll();
    const dto = new OrganizationDto({
      ...values,
      isDisabled: !values.isActive
    });
    api.addOrganization(dto.stringify())
      .then(() => {
        this.setState({ apiResult: true });
      }).catch(err => {
        message.error(`Error submitting new organization: ${err}`);
        actions.setSubmitting(false);
      });
  }

  render() {
    const { editAction, organization, apiResult } = this.state;

    return (
      <React.Fragment>
        <Container className="basic-card pb-3">
          <OrganizationEdit
            editAction={editAction}
            organization={organization}
            redirect={apiResult}
            enableMfaControl={true}
            handleCancelClick={this.handleCancelClick}
            handleSubmitClick={this.addOrganizationSubmit}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default OrganizationAdd;
