import MeasureCategoryDto from '../Measure/MeasureCategoryDto';

export default class ProgramCategoriesDto {
  constructor(options = {}) {
    this.measureCategories = options.measureCategories.map(dto => new MeasureCategoryDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}