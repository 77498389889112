import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import EditService from './EditService';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import OrganizationServiceDto from '../../DTOs/OrganizationService/OrganizationServiceDto';

class ServiceAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: new OrganizationServiceDto(),
      editAction: 'Add',
      apiResult: false
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.addServiceSubmit = this.addServiceSubmit.bind(this);
  }

  handleCancelClick() {
    this.props.history.push('/OrganizationService');
  }

  addServiceSubmit(values, actions) {
    message.dismissAll();
    const dto = new OrganizationServiceDto({
      ...values,
      isDisabled: !values.isActive
    });

    api.addService(dto.stringify()).then(() => {
      this.setState({ apiResult: true });
    }).catch(err => {
      actions.setSubmitting(false);
      message.error(`Problem submitting new organization service: ${err}`);
    });
  }

  render() {
    const { service, editAction, apiResult } = this.state;

    return (
      <React.Fragment>
        <Container className="basic-card pb-3">

          <EditService
            service={service}
            editAction={editAction}
            redirect={apiResult}
            handleCancelEditClick={this.handleCancelClick}
            handleFormSubmit={this.addServiceSubmit}
          />

        </Container>
      </React.Fragment>
    );
  }
}

export default ServiceAdd;
