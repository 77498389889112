import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Button, Row, Col } from 'react-bootstrap';
import FormElement from './FormElement';
import EditModal from '../inputEditor/EditModal';
import DemographicsBuilderContext from '../demographicsBuilderContext';


class PageElementsList extends Component {

  static contextType = DemographicsBuilderContext

  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
      selectedInput: null,
    };

    this.showEditModal = this.showEditModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  showEditModal(obj) {
    this.setState({
      showEditModal: true,
      selectedInput: obj
    });
  }

  handleCancel() {
    this.setState({
      showEditModal: false
    });
  }

  handleSave(infoObj) {
    const { formElements } = this.props;
    const { selectedInput } = this.state;
    const { schema, currentPanelId, updateSchema } = this.context;

    const nextQuestions = [...formElements];
    let indexToUpdate = nextQuestions.findIndex(q => q.id === selectedInput.id);
    nextQuestions[indexToUpdate] = infoObj;

    updateSchema(() => schema.updatePanelQuestions(currentPanelId, nextQuestions));

    this.setState({
      showEditModal: false
    });
  }

  handleDelete() {
    const { formElements } = this.props;
    const { selectedInput } = this.state;
    const { schema, currentPanelId, updateSchema } = this.context;

    if (!selectedInput.canDelete) return;

    const nextQuestions = [...formElements];
    let indexToRemove = nextQuestions.findIndex(q => q.id === selectedInput.id);
    nextQuestions.splice(indexToRemove, 1);

    updateSchema(() => schema.updatePanelQuestions(currentPanelId, nextQuestions));

    this.setState({
      showEditModal: false
    });
  }

  render() {
    const { showEditModal, selectedInput } = this.state;
    const { formElements, reportFieldNames, droppableId, isDragging } = this.props;
    const { isPanelSelected, showPreview, saveAsDraft } = this.context;

    return (
      <React.Fragment>

        <EditModal
          show={showEditModal}
          inputInfo={selectedInput}
          reportFieldNames={reportFieldNames}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          onCancel={this.handleCancel}
        />

        <Row className="mb-3">
          <Col lg={6}>
            <Button
              onClick={() => showPreview(true)}
              disabled={!isPanelSelected()}
            >
              Preview with Changes
            </Button>
          </Col>
          {isPanelSelected() && (
            <Col lg={6} className="d-flex align-items-center">
              <a
                className="info-link info-link--large"
                onClick={saveAsDraft}
              >
                Save as a Draft
              </a>
            </Col>
          )}
        </Row>


        <Droppable
          droppableId={droppableId}
          isDropDisabled={!isPanelSelected()}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="form-elements-list"
            >
              {formElements.map((item, index) => (
                <FormElement
                  key={item.id}
                  index={index}
                  showDragHandle
                  isDragging={isDragging}
                  onClick={this.showEditModal.bind(this, item)}
                  question={item}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </React.Fragment>
    );
  }
}

export default PageElementsList;