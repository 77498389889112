import MeasureDto from './MeasureDto';

export default class MeasureListDto {
  constructor(options = {}) {
    this.allMeasures = options.allMeasures ? options.allMeasures.map(dto => new MeasureDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}