import StaffDto from './StaffDto';

export default class StaffListDto {
  constructor(options = {}) {
    this.staff = options.staff.map(dto => new StaffDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}