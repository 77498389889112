import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import FilterForm from './FilterForm';
import add from '../../../images/Add.png';

export class BaseApiRequestFilter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      displayFilters,
      clearFilters,
      applyFilters,
      showFilters,
      submitButtonText,
      cancelButtonText,
      apiFilters,
      includeAddFiltersButton,
      filterHeader,
      filterValues,
      availableFilterTypes
    } = this.props;

    return (
      <React.Fragment>
        {apiFilters.length > 0 && availableFilterTypes.length > 0
          ? showFilters
            ? <div className="border-bottom mb-4 d-print-none">
              <Row className="pb-3">
                <Col>
                  <h4 className="font-weight-bold pt-2">{filterHeader ? filterHeader : 'Filters'}</h4>
                </Col>
              </Row>
              <FilterForm
                displayFilters={displayFilters}
                clearFilters={clearFilters}
                filterValues={filterValues || { }}
                applyFilters={applyFilters}
                availableFilterTypes={availableFilterTypes}
                submitButtonText={submitButtonText ? submitButtonText : 'Apply Filters'}
                cancelButtonText={cancelButtonText ? cancelButtonText : 'Clear All Filters'}
              />
            </div>
            : includeAddFiltersButton &&
            <div className="mb-3 cursor-pointer d-print-none" onClick={displayFilters}>
              <span className="pt-2 add-filters-toggle d-flex align-items-center"> Add Filters &nbsp;<img src={add} alt="Add Filters" /> </span>
            </div>
          : null
        }
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ apiFilters: state.user.apiFilters })
)(BaseApiRequestFilter);
