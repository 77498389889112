function parseJson(rawJson) {
  if (typeof rawJson === 'object') return rawJson;

  let parsed = null;
  if (!rawJson) return parsed;
  try {
    parsed = JSON.parse(rawJson);
  } catch (err) {
    // do nothing
  }
  return parsed;
}

export default class DemographicSchemasDto {
  constructor(options = {}) {
    this.activeDemographicsSchema = parseJson(options.activeDemographicsSchema);
    this.draftDemographicsSchema = parseJson(options.draftDemographicsSchema);
    this.effectiveOrganizationLicenseType = options.licenseType;
    this.effectiveOrganizationLicenseLevel = options.licenseLevel;
  }

  stringify() {
    return JSON.stringify({
      activeDemographicsSchema: this.activeDemographicsSchema ? JSON.stringify(this.activeDemographicsSchema) : null,
      draftDemographicsSchema: this.draftDemographicsSchema ? JSON.stringify(this.draftDemographicsSchema) : null
    });
  }
}
