import React from 'react';
import { SelectInput } from '../index';
import withKeywordCategory from '../withKeywordCategory';

/**
 * Component for displaying and selecting the different types of relationships a client can have with another client
 */
const RelationshipTypeInput = withKeywordCategory(({ options: relationshipTypes, ...props }) => {
  return (
    <SelectInput
      placeholder="Relationship to Participant"
      options={relationshipTypes}
      {...props}
    />
  );
}, { category: 'RelationshipType', defaultOptionText: 'Relationship to Participant', valueProperty: 'text' });

export default RelationshipTypeInput