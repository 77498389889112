import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Row, Col, ButtonToolbar, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import Switch from '../shared/Switch';
import EditActions from '../shared/EditActions';

const schema = yup.object({
  firstName: yup.string().ensure().trim()
    .required('First Name is required')
    .max(255, 'First Name cannot be greater than 255 chars'),
  lastName: yup.string().ensure().trim()
    .required('Last Name is required')
    .max(255, 'Last Name cannot be greater than 255 chars'),
  userRoleId: yup.string() // not sure if this is correct or not
    .required('Role is required'),
  startDate: yup.date()
    .typeError('Hire Date is not a valid date')
    .required('Hire Date is required'),
  userId: yup.string().ensure().trim()
    .required('Email is required')
    .email('Please enter a valid email address')
    .max(255, 'Email cannot be longer than 255 chars')
});

export class EditStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '/Staff'
    };
  }

  render() {
    const { staff, editAction, handleCancelEditClick, redirect, handleFormSubmit, licenseTypes, staffEducationLevels, currentUserRole } = this.props;
    const { redirectTo } = this.state;

    const allowEmailChange = editAction === "Add" || currentUserRole === "SiteAdmin";

    if (redirect) {
      return <Navigate to={redirectTo} replace />;
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleFormSubmit}
        initialValues={{ ...staff }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mt-3 mb-3">
              <Col md={6}>
                <h5>{editAction} Staff</h5>
              </Col>

              <Form.Group as={Col} md={6} className="d-flex justify-content-end">
                <label htmlFor="isActive">Active&nbsp;</label>
                <Switch
                  value={values.isActive}
                  name="isActive"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md={6}>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  isInvalid={touched.firstName && !!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  isInvalid={touched.lastName && !!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>


            <Row>

              <Form.Group as={Col} md={3}>
                <Form.Control
                  as="select"
                  className={`sure-impact__select ${values.userRoleId === '' ? 'sure-impact__select-default' : ''}`}
                  name="userRoleId"
                  value={values.userRoleId}
                  onChange={handleChange}
                  isInvalid={touched.userRoleId && !!errors.userRoleId}
                >
                  <option value="" disabled className="sure-impact__select-default">Role</option>
                  <option value="4">Staff</option>
                  <option value="3">Manager</option>
                  <option value="2">Administrator</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.userRoleId}
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group as={Col} md={3}>
                <Form.Control
                  type="text"
                  name="employeeNumber"
                  value={values.employeeNumber}
                  placeholder="Employee ID"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} lg={8} md={12}>
                <Form.Control
                  as="select"
                  className={`sure-impact__select ${values.licenseTypeValue === '' ? 'sure-impact__select-default' : ''}`}
                  name="licenseTypeValue"
                  value={values.licenseTypeValue}
                  onChange={handleChange}
                >
                  <option value="" className="sure-impact__select-default">License Type</option>
                  {licenseTypes.map(kw => (
                    <option
                      key={kw.id}
                      value={kw.value}
                    >
                      {kw.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Control
                  type="text"
                  name="licenseNumber"
                  value={values.licenseNumber}
                  placeholder="License Number"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row>
              <Col lg={3} md={4}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      Hire Date
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <InputMask mask="99/99/9999" value={values.startDate} onChange={handleChange} touched={touched} errors={errors}>
                    <Form.Control
                      type="text"
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                      isInvalid={touched.startDate && !!errors.startDate}
                    />
                  </InputMask>
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Form.Group as={Col} lg={3} md={4}>
                <Form.Control
                  as="select"
                  className={`sure-impact__select ${values.educationLevelValue === '' ? 'sure-impact__select-default' : ''}`}
                  name="educationLevelValue"
                  value={values.educationLevelValue}
                  onChange={handleChange}
                >
                  <option value="" className="sure-impact__select-default">EducationLevel</option>
                  {staffEducationLevels.map(kw => (
                    <option
                      key={kw.id}
                      value={kw.value}
                    >
                      {kw.text}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} lg={6} md={12}>
                <Form.Control
                  type="text"
                  name="userId"
                  value={values.userId}
                  placeholder="Email"
                  onChange={handleChange}
                  isInvalid={touched.userId && !!errors.userId}
                  disabled={!allowEmailChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userId}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group as={Row}>
              <Form.Label column lg="2" md="12" htmlFor="certifications">
                Certifications
              </Form.Label>
              <Col>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Certifications"
                  name="certifications"
                  value={values.certifications}
                  onChange={handleChange} />
              </Col>
            </Form.Group>

            <EditActions>
              <ButtonToolbar className="d-flex justify-content-between">
                <Button variant="outline-primary" className="mr-2" onClick={() => handleCancelEditClick()}>Cancel</Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>Save</Button>
              </ButtonToolbar>
            </EditActions>

          </Form>
        )}
      </Formik>
    );
  }
}

export default EditStaff;
