import getNumCharsAdded from './getNumCharsAdded';

/**
 * Calculate where to place the cursor after the nextDisplayValue has been formatted for UI use
 * 
 * NOTE: using the delete key still has some issues when the cursor has a ',' directly to the right
 *
 * @param {Event} e the event which caused the update
 * @param {String} curDisplayValue the current number (formatted) used in the UI
 * @param {String} nextDisplayValue the newly entered number (formatted) used in the UI
 */

const updateCursor = (e, curDisplayValue, nextDisplayValue, leadingCharacters = null) => {
  const start = e.target.selectionStart;
  const element = e.target;

  // if user entered a decimal without a zero integer part, getFloatString will add the leading 0
  // move the cursor 1 space forward if we started with '.'
  const addedLeadingZero = curDisplayValue === '.' ? 1 : 0;

  // if the input value has some kind of leading char(s) such as $ for currency
  const addedLeadingChars = nextDisplayValue.indexOf(leadingCharacters) >= 0 && curDisplayValue.indexOf(leadingCharacters) < 0 ? leadingCharacters.length : 0;

  const curNumCommasBeforeCursor = getNumCharsAdded(curDisplayValue, start, ',');
  const nextNumCommasBeforeCursor = getNumCharsAdded(nextDisplayValue, start, ',');
  const numCommasRemoved = curNumCommasBeforeCursor - nextNumCommasBeforeCursor;  // handles case ( '$123,|123' --> '$123|,123' ) when using backspace
  const numCharactersRemoved = curDisplayValue.length - nextDisplayValue.length; // handles all other cases when using backspace.  Goes one pos too far left on case ( '$1|,234' --> '$|234')

  const cursorAdjustment = Math.max(numCharactersRemoved, numCommasRemoved);

  // Set the new cursor position after the component has had a chance to render
  window.requestAnimationFrame(() => {
    let pos = start + addedLeadingChars + addedLeadingZero - cursorAdjustment;
    pos = Math.max(leadingCharacters ? leadingCharacters.length : 0, pos);  // Max with leading characters length due to case described above when deleting at the front and losing comma
    element.setSelectionRange(pos, pos);
  });
}

export default updateCursor;