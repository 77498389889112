import api from '../scripts/api';
import { localStorageCacheKeys, userRoles } from '../constants';
import { getCachedApiFilters, cacheApiFilters, cacheLastRunStatus, getCacheIsValid } from '../scripts/localStorageHelper';

const beginInitialization = 'BEGIN_INITIALIZATION';
const endInitialization = 'END_INITIALIZATION';

const requestCurrentUserType = 'REQUEST_CURRENT_USER';
const receiveCurrentUserSuccessType = 'RECEIVE_CURRENT_USER_SUCCESS';
const receiveCurrentUserFailureType = 'RECEIVE_CURRENT_USER_FAILURE';

const beginImpersonation = 'BEGIN_IMPERSONATING_ORGANIZATION';
const endImpersonation = 'END_IMPERSONATING_ORGANIZATION';

const requestDemographicsInfoType = 'REQUEST_DEMOGRAPHICS_INFO';
const receiveDemographicsInfoTypeSuccess = 'RECEIVE_DEMOGRAPHICS_INFO_SUCCESS';
const receiveDemographicsInfoTypeFailure = 'RECEIVE_DEMOGRAPHICS_INFO_FAILURE';

const requestSchemasType = 'REQUEST_SCHEMAS';
const receiveSchemasTypeSuccess = 'RECEIVE_SCHEMAS_SUCCESS';
const receiveSchemasTypeFailure = 'RECEIVE_SCHEMAS_FAILURE';

const schemaParseSuccess = 'SCHEMA_PARSE_SUCCESS';
const schemaParseFailure = 'SCHEMA_PARSE_FAILURE';

const requestApiFiltersType = 'REQUEST_API_FILTERS';
const receiveApiFiltersSuccess = 'RECEIVE_API_FILTERS_SUCCESS';
const receiveApiFiltersFailure = 'RECEIVE_API_FILTERS_FAILURE';

const initialState = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  permissions: {},
  activeDemographicsSchema: null,
  draftDemographicsSchema: null,
  demographicsKeywords: [],
  organizationId: null,
  effectiveOrganizationId: null,
  effectiveLicenseType: null,
  effectiveLicenseLevel: null,
  apiFilters: [],

  isPopulated: false,
  initializing: false,

  demographicsInfoLoadError: false,
  schemaLoadError: false,
  schemaParseError: false,

  retrievingSchemas: false,

  apiFilterLoadError: false
};

export const actionCreators = {
  initializeApp: () => async (dispatch, getState) => {
    dispatch({ type: beginInitialization });
    await dispatch(actionCreators.lookupUser());
    dispatch({ type: endInitialization });

    // These dispatches are outside the 'initialization' area because the info returned here is not needed for user authorization, etc.
    await dispatch(actionCreators.getDemographicsInfo());

    await dispatch(actionCreators.getApiFilters());
  },

  lookupUser: () => async (dispatch, getState) => {
    if (getState().user.isPopulated) {
      // Don't need to get the data again
      return;
    }

    dispatch({ type: requestCurrentUserType });
    let userInfo;
    try {
      userInfo = await api.getCurrentUser();
      if (userInfo.activeDemographicsSchema === null) {
        dispatch({ type: schemaParseFailure });
      } else {
        dispatch({ type: schemaParseSuccess });

      }
    } catch (err) {
      console.warn(err);
      dispatch({ type: receiveCurrentUserFailureType });
      return;
    }

    let effectiveOrgId = null;
    if (userInfo.roles.includes(userRoles.ROOT_ADMIN)) {
      const localStorageOrgId = localStorage.getItem('effectiveOrganizationId');
      if (localStorageOrgId) {
        effectiveOrgId = Number(localStorageOrgId)
      }
    }

    dispatch({
      type: receiveCurrentUserSuccessType,
      payload: {
        id: userInfo.id,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        organizationId: userInfo.organizationId,
        roles: userInfo.roles,
        licenseExportPermission: userInfo.licenseType > 1,
        effectiveLicenseType: userInfo.licenseType,
        effectiveLicenseLevel: userInfo.licenseLevel,
        permissions: userInfo.permissions,
        activeDemographicsSchema: userInfo.activeDemographicsSchema,
        effectiveOrganizationId: effectiveOrgId
      }
    });

    if (effectiveOrgId) {
      await dispatch(actionCreators.getDemographicSchemas());
    }
  },

  startImpersonating: (effectiveOrgId) => async (dispatch, getState) => {
    localStorage.clear();

    // set in persistent storage
    localStorage.setItem('effectiveOrganizationId', effectiveOrgId);

    // dispatch to update the EffectiveOrgId
    await dispatch({
      type: beginImpersonation,
      payload: {
        effectiveOrganizationId: effectiveOrgId
      }
    });

    // using the impersonation effectiveOrgId, get schemas
    await dispatch(actionCreators.getDemographicSchemas());

    // using the impersonation effectiveOrgId, refresh api filters
    await dispatch(actionCreators.getApiFilters());
  },

  stopImpersonating: () => async (dispatch, getState) => {
    // remove from persistent storage
    localStorage.removeItem('effectiveOrganizationId');

    // dispatch to reset effectiveOrgId
    await dispatch({
      type: endImpersonation
    });

    // reload the schema with the SiteAdmin's org Id
    await dispatch(actionCreators.getDemographicSchemas());

    //reload the filters with the SiteAdmin's org Id
    await dispatch(actionCreators.getApiFilters());
  },

  getDemographicsInfo: () => async (dispatch, getState) => {
    dispatch({ type: requestDemographicsInfoType });
    let keywords;

    const getKeywords = async () => { keywords = (await api.getAllKeywords()).keywords; };

    try {
      await Promise.all([getKeywords()]); // leaving this as an array so it can be extended in the future if need be
    } catch (err) {
      // Since we need both the keywords and the schema for demographics to be properly created, we still fail if only one call fails
      console.warn(err);
      dispatch({ type: receiveDemographicsInfoTypeFailure });
      return;
    }

    dispatch({
      type: receiveDemographicsInfoTypeSuccess,
      payload: {
        demographicsKeywords: keywords
      }
    });
  },
  /**
   * Gets both the active and draft schemas from the the API
   */
  getDemographicSchemas: () => async (dispatch, getState) => {
    dispatch({ type: requestSchemasType });

    let demographicSchemas;
    try {
      demographicSchemas = await api.getDemographicSchemas();
      if (demographicSchemas.activeDemographicsSchema === null) {
        dispatch({ type: schemaParseFailure });
      } else {
        dispatch({ type: schemaParseSuccess });
      }
    } catch (err) {
      console.warn(err);
      dispatch({ type: receiveSchemasTypeFailure });
      return;
    }

    dispatch({
      type: receiveSchemasTypeSuccess,
      payload: {
        activeDemographicsSchema: demographicSchemas.activeDemographicsSchema,
        draftDemographicsSchema: demographicSchemas.draftDemographicsSchema,
        effectiveLicenseType: demographicSchemas.effectiveOrganizationLicenseType,
        effectiveLicenseLevel: demographicSchemas.effectiveOrganizationLicenseLevel,
        licenseExportPermission: demographicSchemas.effectiveOrganizationLicenseType > 1
      }
    });
  },

  /**
   * Gets all filter options from the the API
  */
  getApiFilters: () => async (dispatch, getState) => {
    dispatch({ type: requestApiFiltersType });
    const user = getState().user;
    const orgId = user.effectiveOrganizationId ? user.effectiveOrganizationId : user.organizationId;
    let filters;
    try {
      let cachedFilters = getCachedApiFilters(orgId, user.email);
      let lastRunStatus = (await api.getLastDashboardUpdate()).lastRunStatus; //getLastDashboardUpdate needs to be renamed
      const cacheIsValid = lastRunStatus && getCacheIsValid(lastRunStatus, orgId, user.email, localStorageCacheKeys.API_FILTERS);

      if (cachedFilters && cacheIsValid) {
        filters = cachedFilters;
      } else {
        filters = await api.getApiFilters();
        cacheApiFilters(filters, orgId, user.email);
        cacheLastRunStatus(lastRunStatus, orgId, user.email, localStorageCacheKeys.API_FILTERS);
      }
    } catch (err) {
      console.warn(err);
      dispatch({ type: receiveApiFiltersFailure });
      return;
    }

    dispatch({
      type: receiveApiFiltersSuccess,
      payload: {
        apiFilters: filters ? filters.apiFilters : []
      }
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  // Initialization
  if (action.type === beginInitialization) {
    return {
      ...state,
      initializing: true
    }
  }
  if (action.type === endInitialization) {
    return {
      ...state,
      initializing: false,
    }
  }

  // requesting user information
  if (action.type === requestCurrentUserType) {
    return {
      ...state,
      initializing: true,
      isPopulated: false
    };
  }
  if (action.type === receiveCurrentUserSuccessType) {
    return {
      ...state,
      ...action.payload,
      isPopulated: true,
      initializing: false,
    };
  }
  if (action.type === receiveCurrentUserFailureType) {
    return {
      ...state,
      isPopulated: false,
      initializing: false
    };
  }
  if (action.type === beginImpersonation) {
    return {
      ...state,
      ...action.payload,
      isPopulated: true,
      initializing: false
    }
  }
  if (action.type === endImpersonation) {
    return {
      ...state,
      effectiveOrganizationId: null,
      isPopulated: true,
      initializing: false
    }
  }

  // Other information needed for the demographics
  if (action.type === requestDemographicsInfoType) {
    return {
      ...state
    }
  }
  if (action.type === receiveDemographicsInfoTypeSuccess) {
    return {
      ...state,
      ...action.payload,
      demographicsInfoLoadError: false
    }
  }
  if (action.type === receiveDemographicsInfoTypeFailure) {
    return {
      ...state,
      demographicsInfoLoadError: true
    }
  }

  // Demographic Schemas
  if (action.type === schemaParseSuccess) {
    return {
      ...state,
      schemaParseError: false
    }
  }
  if (action.type === schemaParseFailure) {
    return {
      ...state,
      schemaParseError: true
    }
  }

  if (action.type === requestSchemasType) {
    return {
      ...state,
      retrievingSchemas: true
    }
  }
  if (action.type === receiveSchemasTypeSuccess) {
    return {
      ...state,
      ...action.payload,
      retrievingSchemas: false,
      schemaLoadError: false
    }
  }
  if (action.type === receiveSchemasTypeFailure) {
    return {
      ...state,
      retrievingSchemas: false,
      schemaLoadError: true
    }
  }

  if (action.type === requestApiFiltersType) {
    return {
      ...state
    }
  }

  if (action.type === receiveApiFiltersSuccess) {
    return {
      ...state,
      ...action.payload,
      apiFilterLoadError: false
    }
  }

  if (action.type === receiveApiFiltersFailure) {
    return {
      ...state,
      apiFilterLoadError: true
    }
  }

  return state;
}
