import OrganizationSearchDto from './OrganizationSearchDto';

export default class OrganizationSearchResultDto {
  constructor(options = {}) {
    this.searchResults = options.searchResults.map(dto => new OrganizationSearchDto(dto)) || [];
    this.searchTerm = options.searchTerm || '';
    this.pageNum = options.pageNum || '0';
    this.pageSize = options.pageSize || '0';
    this.totalMatches = options.totalMatches || '0';
  }

  stringify() {
    return JSON.stringify(this);
  }
}