import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';

function Loading({ message, data }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3rem' }}>
      <div>
        <Spinner animation="border" className="sureimpact__spinner_export" style={{ display: 'block' }}></Spinner>
        <Row className="justify-content-center">
        <Col xs="auto">{message ? message : `Fetching ${data ? data : "data"}`}</Col>
        </Row>
      </div>
    </div>
  );
}

export default Loading;
