import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { schemaVersions } from '../../constants';
import SectionHeader from './SectionHeader';
import { RadioButtonGroupInput, RadioButtonInput } from '../shared/inputs/index';
import DemographicsBuilderContext from './demographicsBuilderContext';




const labelTexts = {
  [schemaVersions.DRAFT]: 'Saved Draft',
  [schemaVersions.ACTIVE]: 'Current Published Version',
}


export default class SchemaSelection extends Component {

  static contextType = DemographicsBuilderContext;

  render() {
    const { currentSchemaVersion, updateCurrentSchemaVersion } = this.context;
    const { versions } = this.props;

    return (
      <Row>
        <Col className="demographics-builder__row__col mb-3">
          <Row>
            <Col>
              <SectionHeader
                header="Which version would you like to edit?"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RadioButtonGroupInput>
                {versions.map(version => (
                  <RadioButtonInput
                    key={version}
                    name="schemaVersion"
                    id={`schemaVersion-${version}`}
                    value={version}
                    label={labelTexts[version]}
                    checked={currentSchemaVersion === version}
                    onChange={() => updateCurrentSchemaVersion(version)}
                  />
                  
                ))}
              </RadioButtonGroupInput>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}