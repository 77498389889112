import React from 'react';

const paragraphStyle = {
  'textIndent': '1.5rem',
  'maxWidth': '1040px'
};

export default () => (
  <div className="container-fluid col-xl-12">
    <h4 className="text-center">MRC IMPACT, LLC</h4>
    <h4 className="text-center">PRIVACY POLICY</h4>

    <div>
      <ol style={paragraphStyle} className="list-unstyled">
        <li>
          <p>
            MRC Impact, LLC, an Ohio limited liability company (the “Company”, “we”, “us”, or “our”) thanks you for accessing and using our website.
            This Privacy Policy (this “Policy”), governs your use of the Site and the manner in which we collect, use, maintain, and disclose information provided, collected, or downloaded by you and other users of the Site.
            The Company respects your privacy and the privacy of your and our clients and will take reasonable steps to protect the data and information it receives and collects from you when you use the Site.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">1.</span>
            <strong className="pr-2"><u>Scope.</u></strong>
            This Policy  describes the types of data and information that we collect from you as a user of the Site and how we use, maintain, handle, and
            disclose such data and information,including with whom we may share such data and information. This Policy does not apply to any website, product,
            or service of any third-party, even if any such website, product, or service links to (or is linked from) the Site.  By accessing and/or using the Site,
            you expressly consent, represent, and warrant to us that the end-user of the Site consents to our collection, use, maintenance, handling, and retention
            of the date and information submitted to the Site.If you do not agree with the terms and conditions of this Policy, you should
            delete all cookies from your browser cache after visiting the Site and should not access, visit or use the Site again.
            Your continued access and/or use of the Site signifies and shall be deemed your acceptance of this Policy.
            We reserve the right to change this Policy from time to time, at our sole discretion.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">2.</span>
            <strong className="pr-2"><u>Information We Collect.</u></strong>
            We may collect data and information from users of the Site in a variety of ways, including, without limitation, when users visit or access the Site,
            register or log-on the Site, place an order, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site.
            We may collect all or some the following data and information from you when you visit, access or use the Site: (a) personal identifying information voluntarily provided by you when you sign up
            for an account or subscription with the Company, including, without limitation, your name, phone number, mailing address,
            email address, social security number, password, and credit card information (collectively, the “Personal Information”);
            (b) client and customer service communications that are reported to the Company about the operation of the Site;
            (c) technical information, including, without limitation, the user’s browser or domain name,
            the user’s IP address, the name of the web page from which the user entered the Site, the locations the user visits within the Site, the amount of time the user spend on each page of the Site, the type of
            computer used to access the Site, information relating to a user’s means of connecting to the Site (e.g., the operating system used, the Internet service provider used, and other similar information); and
            (d) other content that is collected from or stored by you on the Site, including, without limitation, any data you may provide.  Users may always refuse to provide data and information to the Site, including,
            without limitation, Personal Information, except that such refusal may prevent the users from engaging in certain services or activities otherwise available on the Site.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">3.</span>
            <strong className="pr-2"><u>Use and Retention.</u></strong>
            <span className="pr-3">(a)</span>
            <span className="pr-2"><u>Operate Our Site.</u></span>
            We use the data and information collected from you and other users of the Site to operate the Site and provide services to our clients.
            In this respect, our use of such data and information includes, without limitation, (i) responding to our clients’ requests
            (e.g., payment processing, registration to our payment portal, porting in end user’s Personal Information, etc.);
            (ii) providing customer service (e.g., resolving problems with our clients’ accounts and profiles or the Site);
            (iii) improving the Site; and (iv) providing information about our clients’ accounts.
          </p>
          <p>
            <span className="pr-3">(b)</span>
            <span className="pr-2"><u>Analyze and Aggregate Non-Personal Information.</u></span>
            We use aggregate data and information collected from the users of the Site and non-Personal Information to (i) analyze user behavior as it relates to access and log-in attempts;
            (ii) analyze, operate, develop, correct, enhance, and improve the Site and our services and offerings; (iii) conduct internal studies, analysis, and reports for the purpose of developing,
            correcting, enhancing, and/or improving our services and software programs; and (iv) generally publish samples, examples, and information relating to the our services and software programs.
          </p>
          <p>
            <span className="pr-3">(c)</span>
            <span className="pr-2"><u>Prevent Fraud.</u></span>
            We use information from the Site to prevent, detect, and investigate fraud, security breaches, potentially prohibited or illegal activities, and enforce our Terms of Use.
          </p>
          <p>
            <span className="pr-3">(d)</span>
            <span className="pr-2"><u>Communicate with You.</u></span>
            We use your Personal Information to contact you to maintain your account, fulfill service requests, and for other purposes authorized by law.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">4.</span>
            <span className="pr-2"><u>Disclosure of Information.</u></span>
            Except as provided below, we will not sell, rent, trade or otherwise transfer any collected data or information to any third party without your explicit permission,
            unless we are obliged to do so under applicable laws or by order of the competent authorities. We may share your data and information with our affiliates and other website providers
            (e.g., email notification and/or payment processing websites as applicable to your registration) which are providing websites relating to your use of the Site.
            The registration information that you provide to us may be sent to those providers in order to deliver their websites; similarly, Personal Information that you
            provide to those providers may be sent to us in order to operate the Site.
          </p>
          <p>
            As we continue to develop our business, we may buy or sell subsidiaries or business interests. In such transactions, as well as in the event all or substantially all of our assets are
            acquired by a third party, Personal Information of our users will generally be one of the transferred business assets. We reserve the right to include your Personal Information,
            collected as an asset, in any such transfer to a third party.  The use of any Personal Information by a third-party transferee shall continue to be subject to applicable law.
            In the event of any such transfer, notice will be posted, and you may elect to discontinue your use of the Site and/or request removal of your Personal Information.
          </p>
          <p>
            We reserve the right to access, use, preserve or disclose any data or information we have access to if we have a good faith belief that such access, use, preservation or disclosure is reasonably necessary to:
            (a) satisfy any applicable law, regulation, legal process or enforceable governmental request;
            (b) enforce our contractual agreements, including, without limitation, investigation of potential violations;
            (c) detect, prevent, or otherwise address fraud, security or technical issues; or
            (d) protect against imminent harm to the rights, property or safety of us, our users or the public as required or permitted by law.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">5.</span>
            <span className="pr-2"><u>Accessing, Reviewing, and Changing Your Personal Information.</u></span>
            You can review and change your Personal Information at any time by accessing your account on the Site. You should promptly update your Personal Information if it changes or becomes inaccurate.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">6.</span>
            <span className="pr-2"><u>Information Security.</u></span>
            We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of your Personal Information.
            We only keep collected data and information for as long as reasonably necessary and use it only for the purposes for which it was collected or to comply with any
            applicable legal or ethical reporting or document retention requirements.  We limit access to your Personal Information to specific employees, contractors and
            agents who have a reasonable need to access your Personal Information.
          </p>
          <p>
            We store Personal Information using generally accepted encryption and data-storage technology.  We cannot guarantee that any data or information is totally secure.
            Although we review our encryption, data storage, data processing practices and security measures from time to time, third parties may unlawfully access, intercept
            or otherwise become aware of Personal Information or private communications.  We do not represent or warrant that the Site is protected from such unlawful interception or other harmful events,
            such as computer viruses, malware, data mining and security or privacy threats.  We are not responsible for intercepted information sent via electronic means, including, without limitation, e-mail or e-commerce transactions.
            You hereby release the Company and its officers, directors, members, managers, employees, agents and assigns from any and all claims, actions, liabilities, damages, costs and expenses arising out of or relating to the use
            of data or information intercepted or accessed by third parties in an unauthorized or unlawful manner.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">7.</span>
            <span className="pr-2"><u>Tracking Cookies/Web Beacons/Social Media Widgets.</u></span>
            An internet tracking cookie (or HTTP cookie) or web beacon is a small piece of text or code stored on a user’s device by a web browser (such as Internet Explorer®, Apple® Safari®, Google® Chrome®, or Mozilla® Firefox®).
            Web pages (like those incorporating the Site) use tracking cookies for authentication, session navigation tracking and storing Site preferences.
            We may use tracking cookies to better serve you when you return to the Site.  If you do not want to enable the Site to place tracking cookies on your computer,
            you can adjust your web browser’s security settings to block tracking cookie placement.  You can also adjust your web browser settings to notify you when your
            computer receives a tracking cookie, which gives you a chance to decide whether or not to accept the tracking cookie before it is placed on your device.
            If you decide not to accept a tracking cookie from the Site, you may not be able to use all of the features of the Site.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">8.</span>
            <span className="pr-2"><u>Third-Party Site Links.</u></span>
            We may provide HTML links to third party websites.  We are not affiliated with third parties to whom we provide such links,
            and we are not responsible for the content, accuracy or privacy practices of the websites to which such HTML links are directed.
            This Policy applies only to the Site and not to any third-party websites, web pages or domains operated by third parties.
            As a general rule, you should always consider checking the privacy policies of third-party websites before submitting Personal Information to such websites.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">9.</span>
            <span className="pr-2"><u>Governing Law; Individual Nature of Claims.</u></span>
            This Policy is governed by and construed in accordance with the laws of the State of Ohio, without giving effect to any principles of conflict of laws.
          </p>
          <p>
            All claims, disputes and disagreements arising under or relating to this Policy shall be submitted to and settled by binding arbitration in Columbus, Ohio.
            The arbitration shall be conducted on a confidential basis pursuant to the Commercial Arbitration Rules of the American Arbitration Association.
            The parties agree to be bound by the arbitrators’ decision, and they further agree that a judgment upon the award may be entered in any court of competent jurisdiction.
            <strong>
              THE PARTIES HEREBY UNCONDITIONALLY WAIVE THEIR RESPECTIVE RIGHTS TO A JURY TRIAL, TRIAL BY JUDGE OR CLASS ACTION OF ANY CLAIM OR CAUSE OF ACTION BASED UPON OR ARISING OUT OF,
              DIRECTLY OR INDIRECTLY, THESE TERMS, ANY OF THE RELATED DOCUMENTS, AND/OR DEALINGS BETWEEN THEM RELATING TO THE SUBJECT MATTER OF THESE TERMS.
            </strong>
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">10.</span>
            <span className="pr-2"><u>Changes to this Policy.</u></span>
            We reserve the right to change this Policy at any time, at our own discretion.  If we change this Policy, we will adjust the “Last Modified” date at the bottom of this Policy and post such changed Policy on the Site.
            For this reason, you should check the Policy each time you use or visit the Site to keep informed of any changes to this Policy.
            Your continued access and/or use of the Site after such change will constitute your: (a) acknowledgment of the modified Policy;
            and (b) your agreement to abide and be bound by this Policy, as revised and amended.
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">11.</span>
            <span className="pr-2"><u>Contact Us.</u></span>
            If you have any questions, you may contact us at <u>614-947-8899.</u>
          </p>
        </li>
        <li>
          <p>
            <span className="pr-4">12.</span>
            <span className="pr-2"><u>Acceptance.</u></span>
            By accessing and/or using the Site, you signify your acceptance of this Policy.  If you do not agree to this Policy and the terms and conditions hereof, you should not access or use the Site.
            Your continued access and/or use of the Site following the posting of changes to this Policy will be deemed your acceptance of those changes.
          </p>
          <p className="privacy-page__last-modified">
            Last Modified:  April 24, 2019
          </p>
        </li>
      </ol>
    </div>
  </div>
)