import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from './SectionHeader';
import EditPage from './EditPage';
import { SelectInput } from '../shared/inputs/index';
import DemographicsBuilderContext from './demographicsBuilderContext';


const addPageValue = 'ADD_NEW_PAGE';
const defaultNewPageName = 'New Page';


class Pages extends Component {

  static contextType = DemographicsBuilderContext

  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(e) {
    const { schema, updateSchema, updateCurrentPanel } = this.context;
    let panelId = e.target.value;

    if (panelId === addPageValue) {
      const newPanelName = defaultNewPageName;
      updateSchema(() => {
        panelId = schema.addPanel(newPanelName);
      });
    }
    updateCurrentPanel(panelId);
  }

  render() {
    const { schema, currentPanelId, isSchemaSelected } = this.context;

    if (!isSchemaSelected()) return null;

    const panel = schema.getPanel(currentPanelId);

    // Create the items in the select input
    const panelSelectItems = schema.panels.map((p, index) => {
      return {
        text: p.name || `Panel ${index + 1}`,
        value: p.panelId
      };
    });
    panelSelectItems.unshift({ text: 'Choose Page to Edit', value: '' });
    panelSelectItems.push({ text: 'Add New Page', value: addPageValue, className: 'font-weight-bold' });

    let editInfo = null;
    if (currentPanelId && !panel.required) {
      editInfo = (
        <Row className="mt-3">
          <Col lg={12}>
            <EditPage
              panelId={currentPanelId}
            />
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className="demographics-builder__row__col mb-3">
          <Row>
            <Col>
              <SectionHeader
                header="Individual Pages"
                subHeader="Click to edit, delete, or add new page"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={9}>
              <SelectInput
                value={currentPanelId}
                onChange={this.handlePageChange}
                options={panelSelectItems}
              />
            </Col>
          </Row>

          {editInfo}

        </Col>
      </Row>
    );
  }
}

export default Pages