
/**
 * Returns a new functin which calls each function passed in.
 * 
 * @param  {...any} fns Any number of functions
 * @returns {function} the new function
 */
'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.createAction = createAction;
exports.createGoToAction = createGoToAction;
exports.createSubmitAction = createSubmitAction;
exports.getQuestionSets = getQuestionSets;
var callAll = function callAll() {
  for (var _len = arguments.length, fns = Array(_len), _key = 0; _key < _len; _key++) {
    fns[_key] = arguments[_key];
  }

  return function () {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return fns.forEach(function (fn) {
      return fn && fn.apply(undefined, args);
    });
  };
};

exports.callAll = callAll;
/**
 * Determines if the passed in object is a React Synthetic event. These objects are created
 * when an event happens on an element.
 * 
 * @param {Object} obj Object to test if it is a sythetic event
 * @returns {Boolean} true if the object is a React SyntheticEvent; false otherwise
 */
var isEvent = function isEvent(obj) {
  return obj && obj.nativeEvent !== undefined;
};

exports.isEvent = isEvent;
/***** Actions *****/

function createAction(action, target) {
  return {
    action: action,
    target: target
  };
}

function createGoToAction(panelId) {
  return createAction('GOTO', panelId);
}

function createSubmitAction() {
  return createAction('SUBMIT');
}

/***** Schema traversal functions *****/

function getQuestionSets(schema, questionPanelId) {
  var currentPanelDefiniton = schema.questionPanels.find(function (qp) {
    return qp.panelId === questionPanelId;
  });
  var questionSetIds = currentPanelDefiniton.questionSets.map(function (qS) {
    return qS.questionSetId;
  });
  var questionSetDefinitions = schema.questionSets.filter(function (qs) {
    return questionSetIds.includes(qs.questionSetId);
  });
  return questionSetDefinitions;
}

/**
 * Get active questions from an array of questions,
 * recursively. Follows active conditions.
 *
 * @param  array  questions       Questions to run through
 * @param  object questionAnswers Current answers for questions
 * @param  array  activeQuestions
 * @return array                  All active questions
 */
var getActiveQuestions = function getActiveQuestions(questions, questionAnswers, activeQuestions) {
  return questions.reduce(function (activeQuestions, question) {
    activeQuestions.push({
      questionId: question.questionId,
      validations: question.validations
    });

    var inputOptions = (question.input || {}).options;
    if (inputOptions && inputOptions.length > 0) {
      question.input.options.forEach(function (option) {
        if (typeof option.conditionalQuestions === 'undefined' || option.conditionalQuestions.length === 0 || questionAnswers[question.questionId] != option.value) {
          return;
        }

        activeQuestions = getActiveQuestions(option.conditionalQuestions, questionAnswers, activeQuestions);
      });
    }

    return activeQuestions;
  }, activeQuestions || []);
};

exports.getActiveQuestions = getActiveQuestions;
/**
 * Get active questions from multiple question sets
 *
 * @param  array  questionSets    All question sets
 * @param  object questionAnswers Current answers for questions
 * @return array                  All active questions
 */
var getActiveQuestionsFromQuestionSets = function getActiveQuestionsFromQuestionSets(questionSets, questionAnswers) {
  return questionSets.reduce(function (activeQuestions, questionSet) {
    return activeQuestions.concat(getActiveQuestions(questionSet.questions, questionAnswers));
  }, []);
};
exports.getActiveQuestionsFromQuestionSets = getActiveQuestionsFromQuestionSets;