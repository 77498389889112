import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Container, Row, Col, Button } from 'react-bootstrap'
import { Toast } from 'primereact/toast';

import { getOrgsWithSponsoredExternalAnalytics } from '../../../scripts/api/sponsoredAnalyticsApi'
import IndexItemDto from '../../../DTOs/shared/IndexItem';
import { message } from '../../shared/Message';
import useToast from '../../shared/hooks/useToast';

import DisplaySponsoredOrganizations from './DisplaySponsoredOrganizations'
import AddSponsoredOrgs from './AddSponsoredOrgs';
import SponsoredAnalyticsHeader from './SponsoredAnalyticsHeader';


function ExternalAnalyticsSponsorship({ externalAnalyticsId }) {
    const [organizationList, setOrganizationList] = useState([]);
    const [editSponsoredList, setEditSponsoredList] = useState(false);
    const { toast, successToast, errorToast } = useToast();

    const handleAddPartnersClick = () => {
        setEditSponsoredList(true);
    }

    const handleCancelClick = () => {
        setEditSponsoredList(false);
    }

    const handleSponsorshipUpdate = (result) => {
        setOrganizationList(result?.map(org => new IndexItemDto({
            title: org.name,
            itemId: org.id
        })));
        setEditSponsoredList(false);
        successToast("Successfully updated sponsorship")
    }


    useEffect(() => {
        const fetchCurrentSponsoredOrgs = async () => {
            try {
                const result = await getOrgsWithSponsoredExternalAnalytics(externalAnalyticsId);
                const sponsoredList = result.organizations.map(org => new IndexItemDto({
                    title: org.name,
                    itemId: org.id
                }))

                setOrganizationList(sponsoredList)
            } catch (error) {
                message.error(`Error occurred while fetching sponsored organizations: ${error}`)
            }
        }

        fetchCurrentSponsoredOrgs()
    }, [])

    return (
        <>
            <Tabs
                defaultActiveKey="partners"
                className="mt-5"
            >
                <Tab eventKey="partners" title="Partners">
                    <Container className="basic-card pb-3">
                        <SponsoredAnalyticsHeader
                            handleAddPartnersClick={handleAddPartnersClick}
                            editSponsoredList={editSponsoredList}
                        />
                        {editSponsoredList ?
                            <AddSponsoredOrgs
                                currentSponsoredOrgs={organizationList}
                                handleCancelClick={handleCancelClick}
                                externalAnalyticsId={externalAnalyticsId}
                                handleSponsorshipUpdate={handleSponsorshipUpdate}
                                errorToast={errorToast}
                            />
                            :
                            <DisplaySponsoredOrganizations
                                organizationList={organizationList}
                                handleAddPartnersClick={handleAddPartnersClick}
                            />
                        }
                    </Container>
                </Tab>
            </Tabs>

            <Toast ref={toast} position="bottom-left" />
        </>
    )
}

export default ExternalAnalyticsSponsorship;