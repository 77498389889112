import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from '../shared/Message';
import { getEmbedParameters } from '../../scripts/api/analyticsApi';
import { EmbedType } from 'powerbi-client-react';
import { models, service, factories } from 'powerbi-client';
import Loading from '../shared/Loading';
import $ from 'jquery';

function PaginatedReportIndex() {
  const [embedConfig, setEmbedConfig] = useState(null);
  const [report, setReport] = useState(null);
  const [isEmbedding, setIsEmbedding] = useState(false);
  const { id } = useParams();
  const currentUser = useSelector(state => state.user);
  const { effectiveOrganizationId, organizationId } = currentUser;

  const handleEmbed = () => {
    const powerbi = new service.Service(
      factories.hpmFactory,
      factories.wpmpFactory,
      factories.routerFactory
    );

    powerbi.embed(report, embedConfig);
  }
  

  const getFilterTarget = (target, orgId) => ({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: target.table,
      column: target.column
    },
    operator: "In",
    values: [orgId]
  });

  useEffect(() => {
    const fetchAnalytics = async (id) => {
      try {
        let response = await getEmbedParameters(id);
        let orgId = effectiveOrganizationId;

        if (!orgId) {
          orgId = organizationId;
        }

        const FILTER_TARGETS = [
          { table: 'ROLLUP_ORGANIZATION_DIMENSION', column: 'Rollup Organization Id' }
        ];

        const filters = FILTER_TARGETS?.map((target) => getFilterTarget(target, orgId));

        var reportContainer = $("#report-container").get(0);
        setReport(reportContainer);

        let reportLoadConfig = {
          type: EmbedType.Report,
          id: response.embedReport.reportId,
          embedUrl: response.embedReport.embedUrl,
          accessToken: response.embedToken.token,
          tokenType: models.TokenType.Embed,
          filters: filters,
          settings: {
            filterPaneEnabled: false,
          }
        };
        setEmbedConfig(reportLoadConfig);
      }
      catch (err) {
        message.error(`Error getting report: ${err.message}`);
      }
    }

    fetchAnalytics(id);
  }, [id, effectiveOrganizationId, organizationId]);

  if (!isEmbedding && report != null && embedConfig != null) {
    setIsEmbedding(true);
    handleEmbed();
  }

  return (
    <>
      {!embedConfig ? <Loading data="Analytics" /> : <></>}

      <section id="report-container" class="pbi-report">
      </section>
    </>
  );
}

export default PaginatedReportIndex;
