import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';

import api from '../../scripts/api';
import MeasureDto from '../../DTOs/Measure/MeasureDto';
import KeywordDto from '../../DTOs/Keyword/KeywordDto';
import EditMeasure from './EditMeasure';

const MeasureAdd = ({ demographicsKeywords, history, ...props }) => {
  const [measure, setMeasure] = useState(new MeasureDto({isDraft: true}));
  const toast = useRef(null);

  const outcomeCategoryKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === "MeasureOutcomeCategory" && new KeywordDto(kw))
  const measureTypeKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === 'MeasureType' && new KeywordDto(kw));
  const cutoffScoreComparisonKeywords = demographicsKeywords && demographicsKeywords.filter(kw => kw.category === 'MeasureCutoffComparison' && new KeywordDto(kw));

  const handleCancelClick = () => {
    history.push("/Measures")
  }

  const addNewMeasure = (values) => {
    const dto = new MeasureDto({
      ...values,
      outcomeCategory: outcomeCategoryKeywords.find(kw => kw.value === values.outcomeCategoryValue),
      type: measureTypeKeywords.find(kw => kw.value === values.typeValue),
      cutoffScoreComparison: cutoffScoreComparisonKeywords.find(kw => kw.value === values.cutoffScoreComparisonValue),
    })

    api.addMeasure(dto.stringify())
      .then(result => {
        history.push(`/Measures/${result.id}`)
      })
      .catch(error => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `An error occurred while attempting to create this measure: ${error.message}`,
          life: 3000
        })
      })
  }

  return (
    <>
      <EditMeasure
        measure={measure}
        editAction="Create"
        handleFormSubmit={addNewMeasure}
        outcomeCategory={outcomeCategoryKeywords}
        measureType={measureTypeKeywords}
        cutoffScoreComparison={cutoffScoreComparisonKeywords}
        handleCancelClick={handleCancelClick}
      />

      <Toast ref={toast} position='bottom-left' />
    
    </>
  )
}

export default connect(
  state => ({
    demographicsKeywords: state.user.demographicsKeywords
  })
)(MeasureAdd);
