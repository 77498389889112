import React, { useState } from 'react';
import { withRouter } from '../shared/RouterShim';
import { connect } from 'react-redux';
import { Container, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import { hasRequiredPermissions } from '../../scripts/permissionHelper';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants';
import SearchSelect from '../shared/asyncSelect/SearchSelect';

const ROLES_FOR_CREATE_MEASURES = {
  rolePermission: rolePermissionLevels.ORG_ADMIN || rolePermissionLevels.SITE_ADMIN,
  licenseLevelPermission: licenseLevels.PAID,
  licenseTypePermission: [licenseTypes.INDIVIDUAL, licenseTypes.SPONSOR]
}

function MeasuresIndexActions(props) {
    const [noSearchOptions] = useState('No matching measures found.');
    const [pageSize] = useState(50);
    const [pageNum] = useState(0);
    const { history } = props;

	let errorMessageId = null;

    const canViewCreateAndDrafted = hasRequiredPermissions(
        ROLES_FOR_CREATE_MEASURES, 
        props.userPermissions
    );

    const searchMeasures = async(searchTerm, loadedOptions, { pageNum, pageSize}) => {
        let result;

        try {
            result = await api.searchMeasuresForOrganization(searchTerm, pageNum, pageSize, true);
        } catch (error) {
            message.dismiss(errorMessageId);
            errorMessageId = message.error(`Error searching for measures: ${error.title}`);
            return { options: [] };        
        }

        const searchResults = result.searchResults.map(dto => ({
            label: dto.name,
            value: dto.id
        }))

        const hasMore = result.totalMatches > (pageNum * pageSize) + searchResults.length;

        return {
            options: searchResults,
            hasMore: hasMore,
            additional: {
                pageNum: pageNum + 1,
                pageSize: pageSize
            }
        }
    }

    const handleSearchSelect = (selected) => {
        props.history.push(`/Measures/${selected.value}`)
    }

    const handleAddMeasureClick = () => {
        history.push('/Measures/AddMeasure')
    };
    
    return (
        <Container>
            <Row>
                <Col className="pl-0" md="6">
                    <SearchSelect
                        placeholder="Search measures"
                        noOptionsMessage={() => noSearchOptions}
                        loadOptions={searchMeasures}
                        additional={{ pageNum: pageNum, pageSize: pageSize }}
                        onChange={handleSearchSelect}
                    />
                </Col>
                <Col className="pr-0" md="6">
                    <ButtonToolbar className="d-flex justify-content-end">
                        { canViewCreateAndDrafted &&
                            <Button variant="primary" onClick={handleAddMeasureClick}>Create Measure</Button>
                        }
                    </ButtonToolbar>
                </Col>
            </Row>
        </Container>
    )
}



export default withRouter(
  connect(state => ({
    userPermissions: state.user.permissions
  }))(MeasuresIndexActions)
);
