import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

/**
 * Grid for displaying forms using bootstrap form classes and bootstrap grid.
 * This allows you to specify the x and y position and the width for each item in the grid.
 * 
 * This is accomplished by passing in a layout array which contains the layout information for each
 * item in the grid. The item's layout object must have an 'i' which matches an id prop given to grid item.
 * This id property should also match the key for that item as well.
 * Do note that if no layout information is given for a particular item, that item will NOT be
 * rendered in the grid.
 */
export default class FormGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  /**
   * Generates a more useful object for getting the information for each item in a particular row.
   * The array returned has an array for each index which contains of the items in that particular
   * row.
   * 
   * @param {Array<Object>} layout the layout array containing the position of each element, etc.
   * @param {Array<ReactComponent>} children the children elements that will be placed in the grid
   * @return {Array<Array<Object>>} information for each item in each row
   */
  generateRowInfos(layout, children) {
    // Determine how many rows there will be
    const numRows = layout.length > 0 ? Math.max(...layout.map(l => l.y)) + 1 : 0; // add 1 because 0 based
    // Sort each item into the correct row
    const elementsByRow = new Array(numRows).fill().map(() => []);
    layout.forEach(l => {
      const element = children.find(el => el.props.gridId === l.i);
      elementsByRow[l.y].push({
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h,
        id: l.i,
        noColumnWrapper: l.noColumnWrapper,
        element: element
      });
    });

    return elementsByRow;
  }

  /**
   * Generates the jsx elements based on the layout and the given children 
   */
  createDom() {
    const { children, layout } = this.props;

    // Get the information about the row
    const rowInfos = this.generateRowInfos(layout, React.Children.toArray(children));

    // Create the actual jsx elements that will be inserted into the dom
    const domElements = rowInfos.map(rowInfo => {
      const rowChildren = this.generateRow(rowInfo);
      return this.createRowElement(rowChildren);
    });

    return domElements;
  }

  /**
   * 
   * @param {Array<RowInfo>} rowInfo the information about the row.  Each element in the array is an item
   *                                 in the row, and each item has position, size, and the element information.
   * @returns {Array<ReactComponent>} the array of elements to add to a row.
   */
  generateRow(rowInfo) {
    const sortedRow = rowInfo.sort((a, b) => a.x - b.x);

    let rowSpaceTaken = 0;
    const rowChildren = sortedRow.map(el => {
      let leftOffset = el.x - rowSpaceTaken;
      rowSpaceTaken += leftOffset + el.w;

      return this.createGridElement({
        element: el.element,
        noColumnWrapper: el.noColumnWrapper,
        id: el.id,
        offset: leftOffset,
        width: el.w
      })
    });

    return rowChildren;

  }

  /**
   * Creates the elements needed to render a row.
   * 
   * @param {Array<ReactComponent>} children the array of elements to be rendered in this row
   * @returns {ReactComponent} the row element
   */
  createRowElement(children) {
    if (children.length <= 0) return null;
    let rowId = children.reduce((acc, child) => acc + child.key, '');
    return (
      <Row key={rowId}>
        {children}
      </Row>
    );

  }

  /**
   * Creates the grid element that should be placed inside a row when creating the grid markup.
   * 
   * @param {Object} options options for creating the grid element. Should have 
   *                         a 'width', 'offset', and 'element' properties
   * @returns {ReactComponent} the grid element
   */
  createGridElement(options) {
    const gridObj = {
      span: options.width,
      offset: options.offset && options.offset > 0 ? options.offset : null
    };

    let gridElement = (
      <Form.Group as={Col} md={gridObj} key={options.id}>
        {options.element}
      </Form.Group>
    );

    // options.render()
    if (options.noColumnWrapper) {
      gridElement = (
        <React.Fragment key={options.id}>
          {options.element}
        </React.Fragment>
      );
    }

    return gridElement;
  }

  render() {
    const { mounted } = this.state;
    if (!mounted) return null;

    return this.createDom();
  }
}


FormGrid.defaultProps = {
  layout: []
}