export default class SuccessMeasureDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.name = options.name || '';
    this.currentPercent = options.currentPercent || 0;
    this.currentNumerator = options.currentNumerator || 0;
    this.currentDenominator = options.currentDenominator || 0;
    this.measureType = options.measureType || 0;

    this.successLabel = 'Successful';
    this.currentPercentDetails = `${this.currentPercent}% (${this.currentNumerator}/${this.currentDenominator})`;
  }
}