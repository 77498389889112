export default class ServiceActivityResultsDto {
  constructor(options = {}) {
    this.serviceName = options.serviceName || '';
    this.clientNames = options.clientNames || [];
    this.clientNamesDisplay = this.clientNames.join(', ');
  }
  
  stringify() {
    return JSON.stringify(this);
  }
}
