import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export default class ServiceActivityDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.activityDateUTC = options.activityDateUTC || '';
    this.activityTimeZone = options.activityTimeZone || '';
    this.durationHours = options.durationHours || '';
    this.durationMinutes = options.durationMinutes || '';
    this.notes = options.notes || '';
    this.applicationUserId = options.applicationUserId || 0;
    this.clientId = options.clientId || 0;
    this.organizationServiceId = options.organizationServiceId || 0;
    this.staffName = options.staffName || '';
    this.additionalClients = options.additionalClients || [];  // used only to persist new activities
    this.activityDatetimeLocal = options.activityDatetimeLocal  || '';

    if (this.activityDateUTC === '') {
      const now = new Date();
      const offset = now.getTimezoneOffset();
      const dateLocalTimeZone = new Date(now.getTime() - (offset * 60 * 1000));
      this.activityDatetimeLocal = dateLocalTimeZone.toISOString().slice(0, 16);
    } else {
      const addZulu =
        this.activityDateUTC[this.activityDateUTC.length - 1] === 'Z' ? '' : 'Z'; 
      const utcDate = new Date(`${this.activityDateUTC}${addZulu}`);
      const formattedUtc = format(utcDate, "yyyy-MM-dd'T'HH:mm");
      this.activityDatetimeLocal = formattedUtc;
    }
  }

  setActivityDateAndTimeZone(utcDate) {
    // need to pass in and use activityDate/Time here because the constructor method (setting these values)
    // is used primarily for editing an existing record resulting in erroneous data here
    this.activityTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.activityDateUTC = utcDate;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
