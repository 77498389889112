export default class OrganizationInvalidDataDto {
  constructor(options = {}) {
      this.id = options.id || 0;
      this.organizationId = options.organizationId || 0;
      this.organizationName = options.organizationName || '';
      this.measureId = options.measureId|| null;
      this.measureName = options.measureName || null;
      this.fieldName = options.fieldName || '';
      this.fieldValue = options.fieldValue || '';
      this.fieldDefinition = options.fieldDefinition || null;
      this.status = options.status || '';
      this.validationMessage = options.validationMessage || '';
      this.startedUtc = options.startedUtc || null;
      this.errorUtc = options.errorUtc || null;
      this.errorMessage = options.errorMessage|| null;
    }
}
