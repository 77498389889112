import ApplicationRoleDto from '../ApplicationRole/ApplicationRoleDto';
import { format } from 'date-fns'
import { parseDate } from '../../scripts/safeDateParse';

export default class ApplicationUserWithRolesDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.externalId = options.externalId || '';
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.userId = options.userId || ''; // this is the user email
    this.startDate = options.startDate ? format(parseDate(options.startDate), 'MM/dd/yyyy') : '';
    this.isDisabled = options.isDisabled || false;
    this.roles = options.roles.map(dto => new ApplicationRoleDto(dto)) || [];

    this.isActive = !options.isDisabled;  // reverse of isDisabled for UI purposes
  }

  stringify() {
    return JSON.stringify(this);
  }
}
