import React, { Component } from 'react';
import { Nav, ButtonToolbar, Tab, InputGroup } from 'react-bootstrap'
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import Winterfell from 'awh-winterfell';

import { INPUT_LIST_TYPES, inputTypes } from '../../../constants';
import FormGrid from '../FormGrid';
import * as inputs from '../inputs/index';
import validations from './validations';
import QuestionText from './QuestionText';
import withKeywordCategory from '../inputs/withKeywordCategory';

/********** Set up Winterfell object **********/
Winterfell.addInputTypes({
  [inputTypes.TEXT]: inputs.TextInput,
  [inputTypes.TEXT_AREA]: inputs.TextAreaInput,
  [inputTypes.SELECT]: withKeywordCategory(inputs.SelectInput),
  [inputTypes.CHECKBOX_GROUP]: withKeywordCategory(inputs.CheckboxGroupInput),
  [inputTypes.CHECKBOX]: inputs.CheckboxInput,
  [inputTypes.EMAIL]: inputs.EmailInput,
  [inputTypes.CURRENCY]: inputs.CurrencyInput,
  [inputTypes.INTEGER]: inputs.IntegerInput,
  [inputTypes.DECIMAL]: inputs.DecimalInput,
  [inputTypes.DATE]: inputs.DateInput,
  [inputTypes.PHONE_NUMBER]: inputs.PhoneNumberInput,
  [inputTypes.RELATIONSHIPS]: inputs.RelationshipsInput,
  [inputTypes.POSTAL_CODE]: inputs.PostalCodeInput,
});

Winterfell.addValidationMethods(validations);

/**********************************************/


/**
 * The Demographics component for showing the demographics questions
 */
class Demographics extends Component {

  static defaultProps = {
    clientId: 0,
    onChange: () => { },
    onControlsCreated: () => { },
    name: 'demographics',
    panelId: undefined,
    noApiWrites: false,
  }

  render() {
    const { clientId, schema, panelId, noApiWrites } = this.props;

    if (!schema) return null;

    const actualPanelId = !panelId ? undefined : panelId;

    return (
      <Winterfell
        schema={schema}
        questionAnswers={this.props.value}
        onUpdate={this.props.onChange}
        onControlsCreated={this.props.onControlsCreated}
        panelId={actualPanelId}
        showInvalidPanel
      >
        {panel({ clientId, noApiWrites })}
      </Winterfell>
    );
  }
}

/********* Panel ********/
const panel = parentProps => ({ getPanelProps, getTabProps }) => (
  <React.Fragment>
    {tabs(getTabProps)}
    <Winterfell.QuestionPanel {...getPanelProps()}>
      {questionSet(parentProps)}
    </Winterfell.QuestionPanel>
  </React.Fragment>
);

/****** Tabs *******/
const tabs = (getTabProps) => (
  <Nav variant="tabs" className="demographics__nav-tabs">
    <Winterfell.Tabs {...getTabProps()}>
      {tab}
    </Winterfell.Tabs>
  </Nav>
);

const tab = ({ text, active, getProps }) => (
  <Nav.Item>
    <Nav.Link
      {...getProps()}
      className={active ? 'active' : null}
    >
      {text}
    </Nav.Link>
  </Nav.Item>
);


/****** Buttons *********/
const buttons = (getButtonProps) => (
  <ButtonToolbar className="demographics__navigation d-flex justify-content-end mt-3">
    <Winterfell.BackButton {...getButtonProps()}>
      {props => (
        <NavigationLeft {...props.getProps()}>
          {props.text}
        </NavigationLeft>
      )}
    </Winterfell.BackButton>
    <Winterfell.MainButton {...getButtonProps()}>
      {props => (
        <NavigationRight {...props.getProps()}>
          {props.text}
        </NavigationRight>
      )}
    </Winterfell.MainButton>
  </ButtonToolbar>
);

const NavigationLeft = ({ children, ...props }) => (
  <a {...props} className="d-flex align-items-center justify-content-center btn">
    <FaAngleLeft className="mr-3" />
    {children}
  </a>
);

const NavigationRight = ({ children, ...props }) => (
  <a {...props} className="d-flex align-items-center justify-content-center btn">
    {children}
    <FaAngleRight className="ml-3" />
  </a>
);


/******** Question Set ********/
const questionSet = parentProps => ({ getQuestionSetProps, getButtonProps }) => (
  <Tab.Content className="mt-3">
    <Winterfell.QuestionSet {...getQuestionSetProps()} noRenderHelp>
      {questions(parentProps)}
    </Winterfell.QuestionSet>
    {buttons(getButtonProps)}
  </Tab.Content>
);


/****** Question ******/
const createGridLayout = (questions) => {
  const gridLayout = [];
  questions.forEach(q => {
    let info = q.question;
    info.input = info.input || {};
    gridLayout.push({
      ...info.layout,
      i: info.questionId,
      noColumnWrapper: INPUT_LIST_TYPES.includes(info.input.type)
    });
  });

  return gridLayout;
}

const questions = parentProps => ({ questions }) => {
  const gridLayout = createGridLayout(questions);

  return (
    <FormGrid layout={gridLayout}>
      {questions.map(({ question, getQuestionProps }) => (
        <Winterfell.Question {...getQuestionProps()} gridId={question.questionId} key={question.questionId}>
          {input(parentProps)}
        </Winterfell.Question>
      ))}
    </FormGrid>
  );
}


/******* Input ******/
const input = parentProps => ({ Input, props, getInputProps }) => {
  const { questionLabel } = props.extra;

  let element = null;
  if (Input) {
    const inputElement = <Input {...getInputProps(parentProps)} />;

    element = inputElement;
    if (questionLabel) {
      element = (
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {questionLabel}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {inputElement}
        </InputGroup>
      );
    }
  }

  return (
    <React.Fragment>
      <QuestionText text={props.question} inputType={props.extra.input.type} />
      {element}
    </React.Fragment>

  )
};



export default Demographics;