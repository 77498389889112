import KeywordDto from '../Keyword/KeywordDto';

export default class MeasureDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || null;
    this.name = options.name || '';
    this.description = options.description || '';
    this.outcomeCategory = options.outcomeCategory || new KeywordDto();
    this.type = options.type || new KeywordDto();
    this.assessmentUsed = options.assessmentUsed || '';
    this.cutoffScoreValue = options.cutoffScoreValue || '';
    this.cutoffScoreComparison = options.cutoffScoreComparison || new KeywordDto();
    this.cutoffScoreField = options.cutoffScoreField || '';
    this.additionalMetadata = options.additionalMetadata || '';
    this.fieldDefinitions = options.fieldDefinitions ? this.parseFieldJson(options.fieldDefinitions) : null;
    this.hasOverride = options.hasOverride || false;
    this.overrideId = options.overrideId || 0;
    this.isDraft = options.isDraft || false;
  }

  parseFieldJson(fieldDefinitions) {
    if (typeof fieldDefinitions === "string") {
      return JSON.parse(fieldDefinitions);
    } else {
      return fieldDefinitions;
    }
  }

  stringify() {
    this.fieldDefinitions = JSON.stringify(this.fieldDefinitions);
    return JSON.stringify(this);
  }
} 
