import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';

import api from '../../../scripts/api';
import FilterSelectInput from './FilterSelectInput';
import FilterDateInput from './FilterDateInput';
import FilterMultiSelectInput from './FilterMultiSelectInput';
import { apiFilterInputTypes, apiFilterNames } from '../../../constants';

class FilterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableFilters: props.apiFilters.filter(x => props.availableFilterTypes.includes(x.displayName)),
      programIsLoading: false,
      organizationFilterIsLoading: false
    }
  }

  updateProgramFilters = async (organizationIds) => {
    const collaborativeOrganizationId = this.props.values.collaborativeOrganization ? this.props.values.collaborativeOrganization : null;

    await this.loadProgramFilter(collaborativeOrganizationId, organizationIds.map(x => x.value));
  }

  collaborativeOrganizationFilterUpdate = async (collaborativeOrganizationId) => {
    const organizationIds = this.props.values.organization;

    await this.loadProgramFilter(collaborativeOrganizationId, organizationIds);
    await this.loadOrganizationFilter(collaborativeOrganizationId);
  }

  loadProgramFilter = async (collaborativeOrganizationId, organizationIds) => {
    this.setState({ programIsLoading: true });

    const newFilters = JSON.parse(JSON.stringify(this.state.availableFilters));
    let programFilter = newFilters.find(x => x.name === 'program');
    if (programFilter && programFilter.filterOptions) {
      const programOptions = await api.getProgramFiltersByOrganizations(collaborativeOrganizationId, organizationIds);
      newFilters.find(x => x.name === 'program').filterOptions = programOptions.filterOptions;
    }

    this.setState({
      availableFilters: newFilters,
      programIsLoading: false
    })
  }

  loadOrganizationFilter = async (collaborativeOrganizationId) => {
    this.setState({ organizationFilterIsLoading: true });

    const newFilters = JSON.parse(JSON.stringify(this.state.availableFilters));
    let organizationFilter = newFilters.find(x => x.name === 'organization');
    if (organizationFilter && organizationFilter.filterOptions) {
      const apiFilter = await api.getDashboardOrganizations(collaborativeOrganizationId);
      newFilters.find(x => x.name === 'organization').filterOptions = apiFilter.filterOptions;
    }

    this.setState({
      availableFilters: newFilters,
      organizationFilterIsLoading: false
    })
  }

  handleFilterUpdate = (filterName, value) => {
    switch (filterName) {
      case apiFilterNames.ORGANIZATION:
        this.updateProgramFilters(value);
        break;
      case apiFilterNames.COLLABORATIVE_ORGANIZATION:
        this.collaborativeOrganizationFilterUpdate(value);
        break;
      default:
        return;
    }
  }

  render() {
    const {
      handleBlur,
      errors,
      values
    } = this.props;

    const { availableFilters, programIsLoading, organizationFilterIsLoading } = this.state;

    return (
      <React.Fragment>
        {availableFilters.map(filter => (
          <Field key={filter.name}>
            {({ field, form, meta }) => {
              if (filter.inputType === apiFilterInputTypes.SINGLE_SELECT) {
                return <FilterSelectInput
                  filter={filter}
                  onFilterChange={this.handleFilterUpdate}
                  form={form}
                  values={values} />;
              } else if (filter.inputType === apiFilterInputTypes.DATE) {
                return <FilterDateInput
                  filter={filter}
                  form={form}
                  onFilterChange={this.handleFilterUpdate}
                  values={values}
                  handleBlur={handleBlur}
                  errors={errors} />;
              } else if (filter.inputType === apiFilterInputTypes.MULTI_SELECT) {
                return <FilterMultiSelectInput
                  filter={filter}
                  form={form}
                  isLoading={(filter.name === 'program' && programIsLoading) || (filter.name === 'organization' && organizationFilterIsLoading)}
                  isDisabled={filter.filterOptions.length === 0}
                  onFilterChange={this.handleFilterUpdate}
                  values={values} />;
              }
              return null;
            }}
          </Field>
        ))}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ apiFilters: state.user.apiFilters })
)(FilterInput);
