import React, { Component } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import RelationshipsIndex from './RelationshipsIndex';
import CreateRelationshipInput from './CreateRelationshipInput';


/**
 * The top level component for creating and displaying a client's relationships 
 */
class RelationshipsInput extends Component {

  static defaultProps = {
    clientId: 0,
    value: [],
    noApiWrites: false
  }

  constructor(props) {
    super(props);

    this.state = {
      relationships: props.value || [],
      apiError: ''
    };

    this.handleRelationshipAdded = this.handleRelationshipAdded.bind(this);
    this.handleRelationshipRemoved = this.handleRelationshipRemoved.bind(this);
    this.setError = this.setError.bind(this);
  }

  handleRelationshipAdded(clientDto) {
    const nextValue = [clientDto, ...this.state.relationships]
    this.setState({
      relationships: nextValue
    });
    this.props.onChange(nextValue);
  }

  handleRelationshipRemoved(removedClientId) {
    // Remove the client from the list
    const nextValue = this.state.relationships.filter(r => r.clientId !== removedClientId);
    this.setState({
      relationships: nextValue
    });
    this.props.onChange(nextValue);
  }

  setError(message) {
    this.setState({ apiError: message });
  }

  render() {
    const { clientId, noApiWrites } = this.props;
    const { relationships, apiError } = this.state;

    return (
      <div>
        {apiError && <Alert variant="danger">{apiError}</Alert>}
        <div className="mb-3">
          <CreateRelationshipInput
            noApiWrites={noApiWrites}
            fromClientId={clientId}
            relationships={relationships}
            onError={this.setError}
            onRelationshipAdded={this.handleRelationshipAdded}
          />
        </div>
        <div>
          <Row className="pb-1">
            <Col md={8}>
              <div>
                <h5>View Family Relations</h5>
              </div>
            </Col>
          </Row>
          <RelationshipsIndex
            relationships={relationships}
            fromClientId={clientId}
            onError={this.setError}
            onRelationshipRemoved={this.handleRelationshipRemoved}
          />

        </div>
      </div>
    );
  }
}




export default RelationshipsInput;