import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

function ConfirmMeasureUpdate({ showModal, hideModal, confirmUpdate }) {
  return (
    <Modal
        show={showModal}
        onHide={hideModal}
        centered
    >
        <Modal.Header className='modal__header'>
            <span>Confirm Updates to Published Measure</span>
            <span
                className='modal__header__btn'
                onClick={hideModal}
                aria-label='Exit'
            >
                X
            </span>
        </Modal.Header>

          <Modal.Body>

              <p style={{ textAlign: "center" }}>
              Making changes to a published measure may affect existing analytics for that measure and any newly created fields will not be able to be deleted. Are you sure you want to update this measure?              </p>
          </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
                <Button variant="primary" onClick={confirmUpdate}>Update Measure</Button>
            </Modal.Footer>
    </Modal>
  )
}

export default ConfirmMeasureUpdate
