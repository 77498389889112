import React, { useEffect } from 'react'
import usePickListUpdate from '../../shared/hooks/usePickListUpdate'
import {ButtonToolbar, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import api from '../../../scripts/api';
import { addExternalAnalyticsSponsorship, updateExternalAnalyticsSponsorship } from '../../../scripts/api/sponsoredAnalyticsApi';
import { message } from '../../shared/Message';

import PickListSelect from '../../shared/asyncSelect/PickListSelect';

function AddSponsoredOrgs({currentSponsoredOrgs, handleCancelClick, externalAnalyticsId, handleSponsorshipUpdate, ...props}) {
    const { available, selected, setSelectedList, setAvailableList, addToSelected, removeFromSelected} = usePickListUpdate();
    const currentUser = useSelector(state => state.user)
    const userOrgId = currentUser.effectiveOrganizationId ? currentUser.effectiveOrganizationId : currentUser.organizationId

    const updateSponsorship = async () => {
        const currentDto = {
            externalAnalyticsId: externalAnalyticsId,
            organizationIds: selected.map(orgId => orgId.value)
        }

        try {
            let result = currentSponsoredOrgs.length === 0 ?
                await addExternalAnalyticsSponsorship(JSON.stringify(currentDto))
                :
                await updateExternalAnalyticsSponsorship(JSON.stringify(currentDto));

            handleSponsorshipUpdate(result.organizations);

        } catch (error) {
            props.errorToast(`error occurred while sponsoring analytics: ${error}`)
        }
    }

    useEffect(() => {
        const getAvailableOrgs = async () => {
            const currentSelected = currentSponsoredOrgs.map(org => ({
                label: org.title,
                value: org.itemId
            }))
            setSelectedList(currentSelected);

            try {
                let response = await api.getActivelySponsoredOrganizations(userOrgId);
                const filteredresponse = response.organizations
                    .filter(org => !currentSelected.some(
                        selected => org.id === selected.value
                    ))
                    .map(org => ({
                        label: org.organizationName,
                        value: org.id
                    }))
                setAvailableList(filteredresponse);
            } catch (error) {
                message.error(`Error occured while fetching sponsored organizations: ${error}`);
            }
        }

        getAvailableOrgs()
    },  [])

    return (
        <>
            <PickListSelect
                source={available}
                target={selected}
                removeFromTarget={removeFromSelected}
                addToTarget={addToSelected}
            />

            <ButtonToolbar className="d-flex justify-content-end">
                <Button variant="outline-primary" onClick={handleCancelClick}>Cancel</Button>
                <Button variant="primary" onClick={updateSponsorship}>Save</Button>
            </ButtonToolbar>
        </>
    )
}

export default AddSponsoredOrgs;