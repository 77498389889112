import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';

export default class FilterSelectInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: ''
    }
  }

  handleChange = (filterName, selectedItem) => {
    this.setState({ selectedItem: selectedItem });
    this.props.form.setFieldValue(filterName, selectedItem, false);
    this.props.onFilterChange(filterName, selectedItem);
  }

  render() {
    const { filter, values } = this.props;

    return (
      filter.filterOptions.length !== 0
        ? <Form.Group as={Col} md={4} className="pr-3">
          <Form.Control
            as="select"
            className={`sure-impact__select ${values[filter.name] ? '' : 'sure-impact__select-default'}`}
            value={values[filter.name]}
            name={filter.name}
            onChange={(e) => this.handleChange(filter.name, e.target.value)}>
            <option value="" className="sure-impact__select-default">{filter.displayName}</option>
            {filter.filterOptions.map(op => (
              <option value={op.value} key={op.value}>{op.text}</option>
            ))}
          </Form.Control>
        </Form.Group>
        : null
    );
  }
}
