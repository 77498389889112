import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { IntegerInput } from '../../../shared/inputs/index';

const NumberInputFields = ({ inputInfo, values, handleChange, touched, errors }) => {

  // If the field is one that is required to be a part of the schema, we will not let the user
  // changes any of the props
  if (!inputInfo.userDefined) return null;

  return (
    <Row>
      <Form.Group as={Col} xs={8}>
        <Form.Label>Maximum Length</Form.Label>
        <IntegerInput
          name="maxLength"
          value={values.maxLength}
          onChange={handleChange}
          isInvalid={touched.maxLength && errors.maxLength}
          errorMessage={errors.maxLength}
          maxValue="5000"
          minValue="0"
        />
      </Form.Group>
    </Row>
  );
}

export default NumberInputFields;