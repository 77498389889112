import React, { Component } from 'react';
import { Row, Col, Form, Button, ButtonToolbar } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import api from '../../../../scripts/api';
import SearchSelect from '../../asyncSelect/SearchSelect';
import ClientRelationshipDto from '../../../../DTOs/Client/ClientRelationshipDto';
import RelationshipTypeInput from './RelationshipTypeInput';

/**
 * Component for showing a list of clients and allowing the user to choose a client and create
 * a new relationship to.
 */
export default class CreateRelationshipSearchClientInput extends Component {

  static schema = yup.object({
    toClient: yup.mixed().test({
      name: 'exists',
      message: 'Client is required',
      test: value => {
        return value !== undefined && value !== null
      }
    }),
    relationship: yup.string().ensure().trim()
      .required('Relationship Type is required')
  });

  static defaultProps = {
    onSubmit: (value, extra) => { },
    fromClientId: 0,
    disabledClients: []
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  searchClients = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
    const { fromClientId, disabledClients } = this.props;

    let result;
    try {
      result = await api.searchAllClients(searchTerm, true, pageNum, pageSize);
    } catch (err) {
      console.log(`Error searching for client: ${err}`);
      return {};
    }

    // Shape results to how we want to display them and filter out any unnecessary items
    const searchResults = result.searchResults
      .filter(dto => dto.id !== fromClientId)
      .map(dto => {
        let relationshipDto = disabledClients.find(r => r.clientId === dto.id);
        return {
          value: dto,
          label: `${dto.lastName}, ${dto.firstName}${relationshipDto ? ' (' + relationshipDto.relationship + ')' : ''}`,
          isDisabled: !!relationshipDto
        }
      });
    const numItemsRemoved = result.searchResults.length - searchResults.length;
    const hasMore = result.totalMatches - numItemsRemoved > (pageNum * pageSize) + searchResults.length;

    return {
      options: searchResults,
      hasMore: hasMore,
      additional: {
        pageNum: pageNum + 1,
        pageSize: pageSize
      }
    }
  }

  getInitialValues(options) {
    return {
      ...new ClientRelationshipDto(options),
      searchDisplay: null // We need to explicitly set the value for the search input.  This is an object with value and label
    }
  }

  handleSubmit(values, extra) {
    const mappedValues = {
      clientId: values.toClient.id,
      firstName: values.toClient.firstName,
      lastName: values.toClient.lastName,
      relationship: values.relationship
    }
    this.props.onSubmit(new ClientRelationshipDto(mappedValues), extra);
    extra.setSubmitting(false);
    extra.resetForm();
  }

  render() {
    const { disabledClients } = this.props;

    return (
      <Formik
        validationSchema={CreateRelationshipSearchClientInput.schema}
        onSubmit={this.handleSubmit}
        initialValues={this.getInitialValues()}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          values,
          touched,
          errors,
          submitForm,
          isSubmitting
        }) => (
            <React.Fragment>
              <Row>
                <Form.Group as={Col} md={8}>
                  <Field name="searchDisplay" value={values.searchDisplay}>
                    {({ field, form }) => (
                      <SearchSelect
                        key={disabledClients.length}
                        value={values.searchDisplay}
                        placeholder="Search System"
                        noOptionsMessage={() => 'No matching clients found'}
                        loadOptions={this.searchClients}
                        onChange={selected => {
                          form.setFieldValue(field.name, selected);
                          form.setFieldValue('toClient', selected.value);
                        }}
                        isInvalid={touched.searchDisplay && !!errors.toClient}
                        errorMessage={errors.toClient}
                      />
                    )}
                  </Field>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <RelationshipTypeInput
                    value={values.relationship}
                    name="relationship"
                    onChange={handleChange}
                    isInvalid={touched.relationship && !!errors.relationship}
                    errorMessage={errors.relationship}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={12}>
                  <ButtonToolbar className="d-flex justify-content-end">
                    <Button variant="primary" onClick={submitForm} disabled={isSubmitting}>Save</Button>
                  </ButtonToolbar>
                </Form.Group>
              </Row>
            </React.Fragment>

          )}
      </Formik>
    );
  }

}