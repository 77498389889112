import React from 'react';
import InputMask from 'react-input-mask';
import TextInput from './TextInput'

/**
 * Component for allowing phone number inputs
 *
 * This input ensures the phone number returned is in the format
 * 999-999-9999 where 9 is any digit.
 */
const PhoneNumberInput = ({ onChange, onBlur, value, ...props }) => {
  return (
    <InputMask
      mask="999-999-9999"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <TextInput
        type="tel"
        {...props}
      />
    </InputMask>
  );
}

export default PhoneNumberInput;