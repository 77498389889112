export default class StaffSearchDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}