import React, { Component } from 'react';
import { Row, Col, Button, ButtonToolbar, ListGroupItem } from 'react-bootstrap';
import { Toast } from 'primereact/toast';

import AssignSponsorships from './AssignSponsorships.js';
import IndexList from '../../shared/indexList/IndexList.js';
import IndexItem from '../../../DTOs/shared/IndexItem.js';
import SponsoredOrganizationUpdateDto from '../../../DTOs/Organization/SponsoredOrganizationUpdateDto.js';
import SponsoredOrganizationUpdateListDto from '../../../DTOs/Organization/SponsoredOrganizationUpdateListDto.js';
import IndexActions from '../../shared/indexList/IndexActions.js';
import { withPickListSelection } from '../../shared/withPickListSelection.js';
import { message } from '../../shared/Message';
import api from '../../../scripts/api';

export class SponsoredOrganizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      assigningSponsorships: false
    };

    this.toast = React.createRef();
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveSponsorships = this.handleSaveSponsorships.bind(this);
  }

  updateStateLists(sponsoredOrganizations, allOrganizations) {
    const selectedOrgs = sponsoredOrganizations.organizations.map(organization => ({
      value: organization.id,
      label: organization.organizationName
    }));

    const availableOrgs = allOrganizations.organizations.map(organization => ({
      value: organization.id,
      label: organization.organizationName
    })).filter(item => (
      !selectedOrgs.some(org => org.value === item.value)
    ));

    this.props.fetchSelectData(selectedOrgs);
    this.props.fetchAvailableData(availableOrgs);
    
    this.setState({
      listData: sponsoredOrganizations.organizations.map(organization =>
        new IndexItem({
          title: organization.organizationName,
          itemId: organization.id,
          url: `/Organization/${organization.id}`,
          isDisabled: false
        })),
    });
  }
  
  initializeComponent = async(id) => {
    message.dismissAll();

    if (id && parseInt(id, 10) !== 0) {
      try {
        const getSponsoredOrgs = await api.getActivelySponsoredOrganizations(id);
        const getAllOrgs = await api.getCollaborative(id);

        this.updateStateLists(getSponsoredOrgs, getAllOrgs);
      } catch(error) {
        message.error(`Error getting sponsored organizations: ${error}`);
      }
    }
  }

  handleSaveSponsorships() {
    message.dismiss(this.saveErrorMessageId);

    const options = {
      organizations: this.props.selected.map(organization =>
        new SponsoredOrganizationUpdateDto({
          sponsorId: this.props.organizationId,
          id: organization.value,
          name: organization.label
        })),
      organizationId: this.props.organizationId
    };
    const dto = new SponsoredOrganizationUpdateListDto(options);

    api.updateSponsoredOrganizations(dto.stringify()).then(result => {
      this.setState({ assigningSponsorships: false })
      this.initializeComponent(this.props.organizationId)
      this.toast.current.show({
        severity: 'success',
        summary: 'Update Successful',
        detail: 'Sponsored organizations was successfully updated',
        life: 3000
      })
    }).catch(err => {
      this.toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `An error occurred while updating sponsorship for organization: ${err}`, 
        life: 3000
      })
    });
  }

  handleCancelClick() {
    this.setState({ assigningSponsorships: false});
    this.initializeComponent(this.props.organizationId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.initializeComponent(this.props.organizationId);
    }
  }

  componentDidMount() {
    this.initializeComponent(this.props.organizationId);
  }

  render() {
    const { listData, assigningSponsorships } = this.state;
    const { selected, available, handleAddToSelected, handleRemoveFromSelected} = this.props;

    return (
      <React.Fragment>

        {assigningSponsorships

          ? <div className="m-3">
            <AssignSponsorships
              defaultOrganizations={selected}
              availableOrganizations={available}
              handleAddToSelected={handleAddToSelected}
              handleRemoveFromSelected={handleRemoveFromSelected}
              cancelHandler={this.handleCancelClick}
              saveHandler={this.handleSaveSponsorships}
              organizationId={this.props.organizationId} />
          </div>

          : <IndexActions>

            <Row className="pt-3 pl-3 pr-3">
              <Col md={6} className="align-self-end">
                <h6>Sponsored Organizations</h6>
              </Col>

              <Col md={6} className="d-flex justify-content-end">

                <ButtonToolbar>
                  <Button
                    variant="outline-primary"
                    onClick={() => this.setState({ assigningSponsorships: true })}>
                    Assign Sponsorships
                </Button>
                </ButtonToolbar>

              </Col>
            </Row>
          </IndexActions>
        }

        {!assigningSponsorships && listData && listData.length > 0

          ? <Row className="no-gutters">
            <Col>
              <IndexList listData={listData} variant="flush" noBasicCard />
            </Col>
          </Row>

          : (!assigningSponsorships &&
            <ListGroupItem>
              <Row>
                <Col>
                  No sponsored organizations found
              </Col>
              </Row>
            </ListGroupItem>
          )
        }

        <Toast ref={this.toast} position='bottom-left' />
      </React.Fragment>
    );
  }
}

export default withPickListSelection(SponsoredOrganizations);
