import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { parseDate } from '../../../scripts/safeDateParse';

import ServiceActivityDto from '../../../DTOs/ServiceActivity/ServiceActivityDto';
import { editServiceActivitySchema } from '../formValidations/editServiceActivityValidations';

import useFetchGroupsForServices from './custom hooks/useFetchGroupsForServices';
import useServiceActivityUsers from './custom hooks/useServiceActivityUsers';
import useClientSearch from '../custom hooks/useClientSearch';
import EditServiceDurationForm from './EditServiceDurationform';
import ClientGroupsForServiceForm from './ClientGroupsForServiceForm';
import ClientsAndStaffForServiceForm from './ClientsAndStaffForServiceForm';
import EditServiceActivityButtonToolbar from './EditServiceActivityButtonToolbar';

function EditServiceActivity({ serviceActivity, selectClients, handleCancelClick, requireClients, ...props }) {
  // This ref is used to provide a way to update formik state outside of the formik callback. 
  // If complexity grows, we may need to move to formik hooks
  const setFieldValueRef = useRef();

  const currentUser = useSelector((state) => state.user);
  const { currentGroupId, updateGroupId, pagedGroupsState, 
    selected, available, addToSelected, removeFromSelected 
  } = useFetchGroupsForServices(props.allowGroups);

  const { applicationUsers } = useServiceActivityUsers(currentUser, serviceActivity);
  const { pageNum, pageSize, searchAllClients } = useClientSearch();
  const formSchema = editServiceActivitySchema(requireClients);

  useEffect(() => {
    if (setFieldValueRef.current) {
      if (currentGroupId > 0) {
        setFieldValueRef.current.setFieldValue("additionalClients", selected.map(client => client.value));
      } else {
        setFieldValueRef.current.setFieldValue("additionalClients", [])
      }
    }
  }, [currentGroupId, selected]);

  const handleFormSubmit = (values, actions) => {
    const dto = new ServiceActivityDto({
      ...values,
      durationHours: values.durationHours === '' ? '0' : values.durationHours,
      durationMinutes: values.durationMinutes === '' ? '0' : values.durationMinutes
    });

    let date;
    try {
      date = parseDate(values.activityDatetimeLocal);
    } catch {
      throw new Error("fail");
    }
    let utcDate = new Date(Date.UTC(date.getFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())).toISOString();

    dto.setActivityDateAndTimeZone(utcDate);

    props.handleFormSubmit(dto, actions);
  }

  const checkIfCurrentUserIsSiteAdmin = currentUser.roles[0] === "SiteAdministrator" ? true : false;
  const defaultApplicationUserId = applicationUsers.length > 0 && !checkIfCurrentUserIsSiteAdmin ? applicationUsers[0].value : ""

  return (
    <Formik
      innerRef={setFieldValueRef}
      validationSchema={formSchema}
      onSubmit={handleFormSubmit}
      initialValues={{
        ...serviceActivity,
        applicationUserId: serviceActivity.applicationUserId || defaultApplicationUserId,
      }}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue
      }) => {
        return (
          <Form onSubmit={handleSubmit} className="mt-3 mb-3">
            <EditServiceDurationForm
              handleChange={handleChange}
              values={values}
              touched={touched}
              errors={errors}
            />

            {requireClients ?
              <ClientGroupsForServiceForm
                clientGroupList={pagedGroupsState?.allClientGroups}
                currentGroupId={currentGroupId}
                updateGroupId={updateGroupId}
                selectedClients={selected}
                availableClients={available}
                removeClientsFromList={removeFromSelected}
                addToClientList={addToSelected}
                touched={touched}
                errors={errors}
              />
              : null}

            <ClientsAndStaffForServiceForm
              values={values}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              searchClients={searchAllClients}
              pageNum={pageNum}
              pageSize={pageSize}
              checkIfCurrentUserIsSiteAdmin={checkIfCurrentUserIsSiteAdmin}
              userList={applicationUsers}
              currentGroupId={currentGroupId}
            />

            <EditServiceActivityButtonToolbar
              handleCancelClick={handleCancelClick}
              isSubmitting={isSubmitting}
            />

          </Form>
        )
      }
      }
    </Formik>
  );
}

EditServiceActivity.defaultProps = {
  requireClients: false,
  selectClients: false,
  serviceActivity: {},
  handleCancelClick: () => { },
  handleFormSubmit: () => { }
}

export default EditServiceActivity;
