export default class KeywordDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.sortOrder = options.sortOrder || '0';
    this.category = options.category || '';
    this.value = options.value || '';
    this.text = options.text || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}