import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row, Col, Button } from "react-bootstrap";

import Loading from "../shared/Loading";

function ExternalAnalyticsDataTable({ listData, handleRowClick, pageSize, isLoading, handleAddClick, currentUserRole }) {
    return (
        <>
            <Row className="my-4">
                <Col md={6} className="align-self-end">
                    <h4>Custom Analytics</h4>
                </Col>

                {currentUserRole === "SiteAdministrator" ?
                    <Col md={6} className="d-flex justify-content-end">
                        <Button onClick={handleAddClick}>Add</Button>
                    </Col>
                    : null}
            </Row>
            {isLoading
                ? <Loading data={"Analytics"} />
                : (
                    <DataTable
                        value={listData}
                        stripedRows
                        rows={pageSize}
                        emptyMessage="No analytics found"
                        onRowClick={(e) => handleRowClick(e)}
                        className="client-data-table"
                    >
                        <Column field="displayName" header="Name" />
                        <Column field="externalId" header="External ID" />
                        <Column field="replacesDefaultAnalytics" header="Replaces Default Analytics" />
                    </DataTable>
                )
            }
        </>
    )
}

export default ExternalAnalyticsDataTable;
