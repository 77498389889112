import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

/**
 * Module provides a withRouter replacement that *actually works*...
 */

export function withRouter(Component) {
  function WrappedComponent(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        // modern APIs you SHOULD use
        location={location}
        navigate={navigate}
        params={params}
        // backwards compatibility
        history={historyCompat(navigate)}
        match={{ params }}
      />
    );
  }

  return WrappedComponent;
}

function historyCompat(navigate) {
  return {
    push: (path) => navigate(path),
    replace: (path) => navigate(path, { replace: true }),
  };
}
