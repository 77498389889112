export default class OrganizationSearchDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.name = options.name || '';
    this.isDisabled = options.isDisabled || false;
  }

  stringify() {
    return JSON.stringify(this);
  }
}