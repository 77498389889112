import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CreateRelationshipSearchClientInput from './CreateRelationshipSearchClientInput';
import CreateRelationshipNewClientInput from './CreateRelationshipNewClientInput';

/**
 * Component for creating a new relationship between the currently selected client and either an
 * existing client or a new client that will be created when creating the relationship
 */
export default class CreateRelationshipInput extends Component {

  static defaultProps = {
    onRelationshipAdded: () => { },
    onError: (message) => { },
    fromClientId: 0,
    relationships: [],
    noApiWrites: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      createNewClient: false
    };

    this.toggleRelationshipCreator = this.toggleRelationshipCreator.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleRelationshipCreator() {
    this.setState({ createNewClient: !this.state.createNewClient });
  }

  handleSubmit(relationshipDto) {
    this.setState({
      createNewClient: false
    });
    this.props.onRelationshipAdded(relationshipDto);
  }

  render() {
    const { createNewClient } = this.state;
    const { fromClientId, relationships, onError, noApiWrites } = this.props;

    const input = createNewClient
      ?
      <CreateRelationshipNewClientInput
        noApiWrites={noApiWrites}
        onCancel={this.toggleRelationshipCreator}
        onSubmit={this.handleSubmit}
        onError={onError}
      />
      :
      <CreateRelationshipSearchClientInput
        fromClientId={fromClientId}
        disabledClients={relationships}
        onNotFound={this.toggleRelationshipCreator}
        onSubmit={this.handleSubmit}
      />

    return (
      <React.Fragment>
        <Row className="pb-1">
          <Col md={8}>
            <div className="d-flex justify-content-between align-items-end">
              <h5>Add Family Relation</h5>
              {!createNewClient && <a className="info-link" onClick={this.toggleRelationshipCreator}>Not Found?</a>}
            </div>
          </Col>
        </Row>
        {input}
      </React.Fragment>
    );
  }

}