import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns-tz';
import { parseDate } from '../../scripts/safeDateParse';
import { useLocation } from 'react-router';


const Footer = () => {
  const curDate = new Date();
  const build = process.env.REACT_APP_BUILD;

  const location = useLocation();
  let hideFooter = location.pathname != "/Dashboard";

  return (
    <React.Fragment>  
      {hideFooter ? <></> :
        <React.Fragment>
          <footer
            className="footer text-muted d-flex align-items-center justify-content-center d-print-none"
          >
            © {curDate.getFullYear()} - SureImpact {build} -&nbsp;<Link to="/Home/Privacy">Privacy</Link>
          </footer>
          <footer
            className="d-none d-print-flex justify-content-center print-footer"
          >
            © {curDate.getFullYear()} - SureImpact - {format(parseDate(curDate), 'MM/dd/yyyy hh:mm aaa')}
          </footer>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Footer;
