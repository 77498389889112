import React, { useState, useEffect } from 'react';
import { withRouter } from '../shared/RouterShim';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import IndexList from '../shared/indexList/IndexList';
import IndexItem from '../../DTOs/shared/IndexItem';

function MeasuresList({ showPublished }) {
  const [measureListData, setMeasureListData] = useState([]);

  const getMeasures = async () => {
    let result;

    try {
      if (showPublished) {
        result = await api.getAllMeasures();
      } else {
        result = await api.getDraftedMeasures();
      }
    } catch (error) {
      console.log("ERROR", error)
      message.error(`Error getting measures: ${error}`);
    }

    const measureIndexList = result.allMeasures.map(measure => new IndexItem({
      title: `${measure.name}${measure.organizationId === null ? " (Global)" : ""}`,
      description: measure.description,
      itemId: measure.id,
      url: `/Measures/${measure.id}`
    }))

    setMeasureListData(measureIndexList);
  }
  
  useEffect(() => {
    getMeasures();
  }, [])  
  
  return (
    <IndexList listData={measureListData} />
  );
}

export default withRouter(MeasuresList);
