import SponsoringOrganizationListDto from "../../DTOs/Organization/SponsoringOrganizationListDto";
import { apiCall } from "../api";

const baseUrl = "externalanalyticsorganization/";

export const getOrgsWithSponsoredExternalAnalytics = async (externalAnalyticsId) => {
    let url = `${baseUrl}getsponsoredexternalanalyticsorganizations?externalanalyticsid=${externalAnalyticsId}`;
    let response = await apiCall(url);

    return new SponsoringOrganizationListDto(response.result);
}

export const addExternalAnalyticsSponsorship = async (externalAnalyticsDto) => {
    let url = `${baseUrl}addsponsoredexternalanalytics`;
    let response = await apiCall(url, { method: 'POST', body: externalAnalyticsDto });

    return new SponsoringOrganizationListDto(response.result);
}

export const updateExternalAnalyticsSponsorship = async (externalAnalyticsDto) => {
    let url = `${baseUrl}updatesponsoredexternalanalytics`;
    let response = await apiCall(url, { method: 'PUT', body: externalAnalyticsDto });

    return new SponsoringOrganizationListDto(response.result);
}
