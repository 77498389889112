import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';

export default class FilterDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null
    }
  }

  handleChange = (filterName, e) => {
    const dateSelection = e.target.value;
    this.setState({ date: dateSelection });
    this.props.form.setFieldValue(filterName, dateSelection, false);
    this.props.onFilterChange(filterName, dateSelection);
  }

  render() {
    const { filter, errors, handleBlur, values } = this.props;
    let initialValue = (values && filter) ? values[filter.name] : null;

    return (
      <Form.Group as={Col} md={4} className="pr-3">
        <input
          type="date"
          className="w-100"
          inputClassName={`sure-impact__calendar-input ${errors[filter.name] ? 'is-invalid' : ''}`}
          panelClassName="sure-impact__calendar-panel"
          name={filter.name}
          value={initialValue}
          onChange={(e) => this.handleChange(filter.name, e)}
          onBlur={handleBlur}
          placeholder={filter.displayName}
        />
        {errors[filter.name] && <div className="d-block invalid-feedback">{errors[filter.name]}</div>}
      </Form.Group>
    );
  }
}
