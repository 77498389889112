import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import InputEditor from './InputEditor';


class EditModal extends Component {

  render() {
    const { show, onCancel, ...props } = this.props;

    return (
      <Modal
        show={show}
        centered
        onHide={onCancel}
      >
        <InputEditor
          {...props}
          onCancel={onCancel}
        />
      </Modal>
    );
  }
}

export default EditModal;