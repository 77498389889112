import React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames'

const DISABLED_OPTION_CLASS = 'sure-impact__select-default';

/**
 * Returns the children of the component (most likely an array of <option> elements) with
 * the correct classNames applied to each element
 * 
 * @param {Array<ReactElement>} children the children of this component
 * @return {Array<ReactElement>} array of cloned elements to be used as the children for the select component
 */
function getElementsFromChildren(children) {
  return React.Children.map(children, option => {
    return React.cloneElement(option, {
      className: classNames(
        {
          [DISABLED_OPTION_CLASS]: option.props.disabled || option.props.value === ''
        },
        option.props.className)
    });
  });
}

/**
 * Returns the array of option elements with correct properties that should be used as the children of the
 * Select component
 * 
 * @param {Array<Objects>} options the array of objects which each contain the props for the option element.  These should
 *                                 include a text and value property, with optional disabled property\
 * @param {<Boolean>}      defaultOption If provided, an option with a value of empty string will be provided
 * @return {Array<ReactElement>} array of elements to be used as the children for the select component
 */
function getElementsFromOptions(options, includeDefaultOption) {
  let optionElements = options.map(option => {
    const disabled = !!option.disabled;
    const className = classNames({
      [DISABLED_OPTION_CLASS]: disabled || option.value === ''
    }, option.className);
    return (
      <option
        key={String(option.value)}
        value={option.value}
        disabled={disabled}
        className={className}
      >
        {option.text}
      </option>
    );
  });

  if (includeDefaultOption) {
    optionElements = [
      <option key="default" value=""></option>,
      ...optionElements
    ];
  }

  return optionElements;
}

/**
 * Determines whether a disabled or empty option is selected.
 * 
 * @param {Array<ReactElement>} optionElements the current option elements of the select
 * @param {String} currentValue the current value for the select element
 * @returns {Boolean} true if a disabled option is selected; false otherwise
 */
function disabledOrEmptyOptionSelected(optionElements, currentValue) {
  const selectedOption = optionElements.find(option => String(option.props.value) === currentValue);
  return !selectedOption || (selectedOption.props.disabled || selectedOption.props.value === '');
}

/**
 * The select input component for selecting from a dropdown list
 * 
 * This component can be used by either passing in an options prop or by placing children elements
 * nested inside the component in the JSX.
 * - If using the options prop, the prop value should be an array whose elements are objects containing at least
 * a 'text' property and a 'value' property. An optional 'disabled' property may also be in the object
 * specifying if the option is disabled.
 * - If using children, you can use any element you would like, but <option> would be preferred.  You may also add the
 * disabled attribute to the children as you normally would.
 * NOTE: This component assumes that any disabled options has a value of an empty string.
 */
const SelectInput = (props) => {

  const options = props.children ?
    getElementsFromChildren(props.children) :
    getElementsFromOptions(props.options || [], props.includeDefaultOption);

  // If the value is undefined, set it to the empty string.  We assume that any placeholder/disabled option is an empty string
  // This is because when the value is undefined, the select chooses the first item in the options
  // that is not disabled.  We may not want this behavior (such as we want to show the disabled option when the form first loads)
  const value = props.value !== undefined && props.value !== null ? String(props.value) : '';

  const classes = classNames(
    'sure-impact__select',
    {
      [DISABLED_OPTION_CLASS]: disabledOrEmptyOptionSelected(options, value)
    },
    props.className
  );

  return (
    <React.Fragment>
      <Form.Control
        as="select"
        name={props.name}
        value={value}
        label={props.label}
        placeholder={props.placeholder}
        isInvalid={props.isInvalid}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={classes}
        style={props.style || null}
        readOnly={props.readOnly}
      >
        {options}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default SelectInput;