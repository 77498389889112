import React from 'react';
import TextInput from './TextInput';
import { getFloatString, formatDisplayCurrency } from '../../../scripts/numHelpers';
import { updateCursor, cleanInput, copyEvent } from './inputHelpers';


/**
 * The CurrencyInput component for inputting currency.
 * This component allows any number of integers before the decimal place and 
 * up to 2 integers to the right of the decimal place.
 * 
 * While the input is formatted, the value returned from this component is a valid decimal value.
 *    Display: 1,000.50 ------ Returned value: 1000.50
 * 
 * Also note that a number may end with a decimal.  This means 1000. is a valid return value from this input
 */
const CurrencyInput = ({ value, onChange, onBlur, ...props }) => {

  // Need to hold a reference to the O.G. value so we can reset if the new value is out of range
  const originalValue = value;

  const getCleanInput = (inputValue) => {
    let options = props;
    options.maxDecimals = 2;

    return cleanInput(getFloatString(inputValue), originalValue, options);
  };

  // Handles when the input is changed
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);
    updateCursor(e, inputValue, formatDisplayCurrency(cleanedInput), '$');

    e.target.value = cleanedInput;

    onChange(e);
  }

  // Handles when the input is blurred
  const handleBlur = (e) => {
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);

    let eventCopy = copyEvent(e);
    eventCopy.target.value = cleanedInput;

    onBlur(eventCopy);
  }

  const displayValue = formatDisplayCurrency(value);

  return (
    <TextInput
      {...props}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export default CurrencyInput;