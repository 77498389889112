import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../shared/RouterShim';
import { Container, Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth0Context } from './Auth0';
import logo from '../../images/Horizontal.png';
import userImage from '../../images/ProfileIcon.png'
import { rolePermissionLevels, licenseLevels, licenseTypes, userRoles as systemRoles } from '../../constants';
import { hasRequiredPermissions } from '../../scripts/permissionHelper.js';
import { Navigate } from 'react-router-dom';

const links = [
  {
    text: 'Dashboard',
    url: '/Dashboard',
    permissions: {
      rolePermission: rolePermissionLevels.ANALYTICS_USER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Client',
    url: '/Client',
    permissions: {
      rolePermission: rolePermissionLevels.ORG_STAFF,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Services',
    url: '/OrganizationService',
    permissions: {
      rolePermission: rolePermissionLevels.ORG_STAFF,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Programs',
    url: '/Program',
    permissions: {
      rolePermission: rolePermissionLevels.ORG_MANAGER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Measures',
    url: '/Measures',
    permissions: {
      rolePermission: rolePermissionLevels.ORG_MANAGER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Staff',
    url: '/Staff',
    permissions: {
      rolePermission: rolePermissionLevels.ORG_MANAGER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Analytics',
    url: '/Reports',
    permissions: {
      rolePermission: rolePermissionLevels.ANALYTICS_USER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  }
];

const dropdownItems = [
  {
    text: 'Manage Organizations',
    action: component => component.props.history.push('/Organization'),
    rolePermissionOnly: true
  },
  {
    text: 'My Profile',
    action: component => {
      let userId = component.props.user.id;
      component.props.history.push(`/Staff/${userId}`)
    },
    permissions: {
      rolePermission: rolePermissionLevels.ORG_STAFF,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Edit Client Template',
    action: component => component.props.history.push('/DemographicsBuilder'),
    permissions: {
      rolePermission: rolePermissionLevels.ORG_ADMIN,
      licenseLevelPermission: licenseLevels.PAID,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Service Hours',
    action: component => component.props.history.push('/ServiceHours'),
    permissions: {
      rolePermission: rolePermissionLevels.ANALYTICS_USER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Custom Analytics',
    action: component => component.props.history.push('/ExternalAnalytics'),
    permissions: {
      rolePermission: rolePermissionLevels.ORG_ADMIN,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Organization Settings',
    action: component => component.props.history.push('/Organization/Settings'),
    permissions: {
      rolePermission: rolePermissionLevels.ORG_ADMIN,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  },
  {
    text: 'Log out',
    action: component => {
      // clear impersonation if applicable
      localStorage.removeItem('effectiveOrganizationId')
      component.context.logout({ returnTo: window.location.origin })
    },
    permissions: {
      rolePermission: rolePermissionLevels.ANALYTICS_USER,
      licenseLevelPermission: licenseLevels.FREE,
      licenseTypePermission: licenseTypes.INDIVIDUAL
    }
  }
];

export class NavMenu extends Component {
  static contextType = Auth0Context;

  render() {
    const { user, userPermissions, userRoles } = this.props;
    const self = this;
    const headerClass = process.env.REACT_APP_ENVIRONMENT_NAME !== 'Production' ? 'header' : 'header mb-lg-4 mb-md-3';

    if (userRoles.includes('IsDisabled')) {
      return <Navigate to={'/InactiveOrganization'} replace />;
    };

    return (
      <header className={headerClass}>
        <Navbar expand="lg">
          <Container>
            <LinkContainer to="/Dashboard" data-rb-event-key="/Dashboard">
              <Navbar.Brand>
                <img src={logo} alt="SureImpact logo" />
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
              {links.map(link => {
                  let permissions = link.permissions;
                  const hasPermission = hasRequiredPermissions(permissions, userPermissions);
                  
                  if (hasPermission) {
                    return (
                      <LinkContainer to={link.url} key={link.url}>
                        <Nav.Link
                          active={false /*setting this to false ensures programmatically switching routes causes active link to update*/}
                        >
                          {link.text}
                        </Nav.Link>
                      </LinkContainer>
                    );
                  } else {
                    return null;
                  }
                })}
              </Nav>
            </Navbar.Collapse>
            <NavDropdown
              alignRight
              className="dropdown-toggle__none"
              id="user-actions-dropdown"
              title={<img src={userImage} alt="user icon" />}
            >
              {dropdownItems.map((item, index) => {
                let permissions = item.permissions;
                if (item.rolePermissionOnly ? userRoles.includes(systemRoles.ROOT_ADMIN) : hasRequiredPermissions(permissions, userPermissions)) {
                  return (
                    <React.Fragment key={item.text}>
                      <NavDropdown.Item className="dropdown-menu__item">
                        <div onClick={item.action.bind(null, self)}>{item.text}</div>
                      </NavDropdown.Item>
                      {index === dropdownItems.length - 2 && <Dropdown.Divider />}
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </NavDropdown>
          </Container>
        </Navbar >
      </header >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userPermissions: state.user.permissions,
    userRoles: state.user.roles
  };
}

export default withRouter(connect(
  mapStateToProps
)(NavMenu));
