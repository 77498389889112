import React from 'react';
import { Form, Col } from 'react-bootstrap';

const DEFAULT_OPTION_WIDTH = 6; // 1- 12 columns

// Helper function to determine if a certain value is a part of the values array
function isChecked(value, allValues = []) {
  return allValues.includes(String(value));
}

function getElementsFromChildren(children, allValues) {
  return children.map(el => {
    return React.cloneElement(el,
      {
        checked: isChecked(el.value, allValues)
      });
  });
}


/**
 * The CheckboxInput component
 */
const CheckboxGroupInput = ({ onChange, children, options, value: inputValue = [], ...props }) => {

  // Handles when a checkbox has been changed
  const handleChange = (e) => {
    const value = e.target.value;
    let newValue = null;

    if (isChecked(value, inputValue)) {
      newValue = inputValue.filter(v => v !== value);
    } else {
      newValue = [value, ...inputValue];
    }
    if (onChange) onChange(newValue);
  }

  function getElementsFromOptions(options = [], allValues) {
    return options.map(option => (
      <Form.Group as={Col} md={option.width || props.optionWidth || DEFAULT_OPTION_WIDTH} key={option.value}>
        <Form.Check
          key={option.value}
          custom
          type="checkbox"
          name={props.name}
          id={props.id + option.value}
          value={option.value}
          label={option.text}
          checked={isChecked(option.value, allValues)}
          onChange={handleChange}
        />
      </Form.Group>
    ));
  }

  inputValue = inputValue.map(v => String(v));
  const optionElements = children ? getElementsFromChildren(children, inputValue) : getElementsFromOptions(options, inputValue);

  const feedbackStyle = props.isInvalid ? { display: 'block' } : null;

  return (
    <React.Fragment>
      {optionElements}
      <Form.Control.Feedback type="invalid" style={feedbackStyle} className="ml-3 mb-3">
        {props.errorMessage}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default CheckboxGroupInput;
