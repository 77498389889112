import React, { Component } from 'react';
import { rolePermissionLevels, userRoleIds } from '../../constants'
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import Email from '../shared/Email';
import ApiKey from './ApiKey';

export class DisplayStaff extends Component {
  hasEditPermission(roles, staff) {
    //Staff users can't edit anyone
    if (roles.length === 1 && roles.includes(rolePermissionLevels.ORG_STAFF)) {
      return false;
    }

    // This check is included to make sure managers can't edit their own info (which would give them access to making themselves org admins)
    return !roles.includes(rolePermissionLevels.ORG_MANAGER) || staff.userRoleId !== userRoleIds.ORG_MANAGER;
  }

  render() {
    const { staff, handleEditClick, roles, user } = this.props;
    const showApiKeyControl = staff.id === user.id && process.env.REACT_APP_ENVIRONMENT_NAME !== 'Production';

    return (
      <React.Fragment>
        <Row className="mb-3">
          <Col md={9} className="align-self-end text-truncate">
            <h5>{staff.displayName}</h5>
          </Col>
          {this.hasEditPermission(roles, staff) && 
            <Col md={3} className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => handleEditClick()}>Edit Staff</Button>
            </Col>}
          {staff.isDisabled &&
            <Col>
              <span className="font-italic display-component__not-active">Not Active</span>
            </Col>
          }
        </Row>
        <Row>
          <Col>
          <Row className="mb-3">
              <Col xs={2}>User ID</Col>
              <Col>{staff.id}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>Role</Col>
              <Col>{staff.userRoleName}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>Start Date</Col>
              <Col>{staff.startDate}</Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>Education</Col>
              <Col>
                {staff.educationLevel && staff.educationLevel.text ?
                  staff.educationLevel.text :
                  'None Entered'}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>Email</Col>
              <Col><Email emailAddress={staff.userId} /></Col>
            </Row>
            {showApiKeyControl &&
              <Row className="mb-3">
                <Col xs={2}>API Key</Col>
                <Col><ApiKey /></Col>
              </Row>
            }
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    user: state.user,
    roles: state.user.roles
  })
)(DisplayStaff);
