export default class AddClientRelationshipDto {
  constructor(options = {}) {
    this.fromClientId = options.fromClientId || 0;
    this.relationship = options.relationship || '';

    // This only needs to be set if the other client already exists
    this.toClientId = options.toClientId || 0;

    // The following properties only need to be set when a new client should be created ('toClientId' is 0)
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.dateOfBirth = options.dateOfBirth || '';
    this.phoneNumber = options.phoneNumber || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
