import React, { Component } from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';

import { AdminEdit } from '../../staff/AdminEdit.js';
import StaffDto from '../../../DTOs/Staff/StaffDto';
import { message } from '../../shared/Message';

import api from '../../../scripts/api';

export class AdminIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curKey: null
    }

    this.cancelEditAdmin = this.cancelEditAdmin.bind(this);
    this.saveAdminData = this.saveAdminData.bind(this);
  }

  saveAdminData(values, actions) {
    message.dismissAll();
    const dto = new StaffDto({
      ...values,
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.updateOrganizationAdmin(dto.stringify()).then(response => {
      this.props.reloadAdminList(response.organizationId);
      this.cancelEditAdmin();
    }).catch(err => {
      actions.setSubmitting(false);
      message.error(`Error updating admin: ${err}`);
    });
  }

  clickAdmin(item) {
    this.setState({ curKey: item.id });
  }

  cancelEditAdmin() {
    this.setState({ curKey: null });
  }

  render() {
    const { listData, licenseTypes, staffEducationLevels } = this.props;
    const { curKey } = this.state;

    return (
      <ListGroup variant="flush">

        {listData && listData.length > 0
          ? listData.map(item => (
            <ListGroup.Item
              key={item.id}
              action={!(curKey && curKey === item.id) /* only make this ListGroup.Item an "action" if we're not currently adding an admin */}
              onClick={curKey && curKey === item.id ? null : () => this.clickAdmin(item) /* only bind onClick if we're not currently adding an admin */}
            >
              <Row>
                {curKey && curKey === item.id
                  ? <Col sm={12}>
                    <AdminEdit
                      staff={item}
                      editAction="Edit"
                      licenseTypes={licenseTypes}
                      staffEducationLevels={staffEducationLevels}
                      handleCancelEditClick={this.cancelEditAdmin}
                      handleFormSubmit={this.saveAdminData}
                      orgAdmin
                    />
                  </Col>
                  :
                  <React.Fragment>
                    <Col>
                      <div className="text-truncate font-weight-normal">{`${item.lastName}, ${item.firstName}`}</div>
                      <div className="text-truncate font-weight-lighter">
                        {item.userId}
                      </div>
                    </Col>
                  </React.Fragment>
                }
              </Row>
            </ListGroup.Item>
          ))
          : <ListGroup.Item>
            <Row>
              <Col>
                No assigned Admins found
              </Col>
            </Row>
          </ListGroup.Item>
        }

      </ListGroup>
    );
  }
}

export default AdminIndex;
