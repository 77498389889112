import { useState } from "react";

const usePickListUpdate = (source = [], target = []) => {
    const [available, setAvailable] = useState(source);
    const [selected, setSelected] = useState(target);

    const setSelectedList = (listData) => {
        setSelected(listData);
    }

    const setAvailableList = (listData) => {
        setAvailable(listData);
    } 

    const addToSelected = (itemToAdd) => {
        setSelected([...selected, itemToAdd]);
        setAvailable(available.filter(item => item !== itemToAdd))
    }

    const removeFromSelected = (itemToRemove) => {
        setSelected(selected.filter(item => item !== itemToRemove))
        setAvailable([...available, itemToRemove])
    }

    return {
        selected,
        available,
        setSelectedList,
        setAvailableList,
        addToSelected,
        removeFromSelected
    }
}

export default usePickListUpdate;