import React, { Component } from 'react';
import { Row, Col, InputGroup, ButtonToolbar, Button } from 'react-bootstrap';
import { TextInput } from '../shared/inputs/index';
import DemographicsBuilderContext from './demographicsBuilderContext';


class EditPage extends Component {

  static contextType = DemographicsBuilderContext

  constructor(props) {
    super(props);

    this.state = {
      isAttemptingDelete: false
    }

    this.removePanel = this.removePanel.bind(this);
    this.updatePanel = this.updatePanel.bind(this);
    this.showConfimation = this.showConfimation.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panelId !== this.props.panelId) {
      this.setState({
        isAttemptingDelete: false
      });
    }
  }

  removePanel(panelId) {
    const { updateCurrentPanel, updateSchema, schema } = this.context;
    updateSchema(() => schema.removePanel(panelId));
    updateCurrentPanel('');
  }

  updatePanel(e) {
    const { updateSchema, schema } = this.context;
    const { panelId } = this.props;
    const panelName = e.target.value;

    // The page name cannot begin with white space
    if (/^\s+/.test(panelName)) return;

    updateSchema(() => schema.updatePanel(panelId, { name: panelName }));
  }

  showConfimation(value) {
    this.setState({
      isAttemptingDelete: value
    });
  }

  render() {
    const { schema } = this.context;
    const { panelId } = this.props;
    const { isAttemptingDelete } = this.state;

    const panel = schema.getPanel(panelId);

    return (
      <React.Fragment>
        <Row>
          <Col md={9} xs={12}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  Edit Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <TextInput
                value={panel.name}
                onChange={this.updatePanel}
                placeholder="Enter Page Name"
              />
            </InputGroup>
          </Col>
          {!panel.required && (
            <Col md={3} className="d-flex align-items-center">
              <a className="info-link" onClick={this.showConfimation.bind(null, true)}>Delete Page</a>
            </Col>
          )}
        </Row>

        {!panel.required && isAttemptingDelete && (
          <div className="mt-1">
            <Row>
              <Col>
                Are you sure you want to delete this page? Any client information saved on this page will no longer be accessible.
              </Col>
            </Row>

            <ButtonToolbar className="d-flex justify-content-start mt-2">
              <Button variant="outline-primary" onClick={this.showConfimation.bind(null, false)}>Cancel</Button>
              <Button variant="primary" onClick={this.removePanel.bind(null, panelId)}>Delete</Button>
            </ButtonToolbar>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EditPage;