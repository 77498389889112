import React, { Component } from 'react';
import { withRouter } from '../../shared/RouterShim';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import PickListSelect from '../../shared/asyncSelect/PickListSelect';
import WarningModal from '../../shared/WarningModal';

class AssignClients extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showModal: false,
    };
  
    this.confirmUpdate = this.confirmUpdate.bind(this);
  }

  confirmUpdate() {
    this.props.handleSaveClients();

    this.setState({ showModal: false})
  }

  render() {
    const { defaultClients, availableClients, handleAddToSelected, handleRemoveFromSelected } = this.props;

    return (
      <React.Fragment>
        <Row className="mb-2">
          <Col>
            Add/remove clients for this staff member (currently selected): {defaultClients.length}
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col>
            <PickListSelect
              target={defaultClients}
              source={availableClients}
              addToTarget={handleAddToSelected}
              removeFromTarget={handleRemoveFromSelected}
            />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <ButtonToolbar>
              <Button variant="outline-primary" onClick={() => this.props.handleCancelClick()}>Cancel</Button>
              <Button vartiant="primary" onClick={() => this.setState( {showModal: true} )}>Save</Button>
            </ButtonToolbar>
          </Col>
        </Row>

        { this.state.showModal && 
          <WarningModal
            show={this.state.showModal}
            onCancel={() => this.setState({ showModal: false })}
            title={'Confirm Client Assignment'}
            onConfirm={this.confirmUpdate}
          >
            Are you sure that you want to make these changes to the clients assigned to this staff member?
          </WarningModal>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(AssignClients);