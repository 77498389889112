import React, { useState } from 'react';
import { Button, ButtonToolbar, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

import useToast from '../shared/hooks/useToast';
import { addAnalytics } from '../../scripts/api/analyticsApi';
import ExternalAnalyticsDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsDto';
import { externalAnalyticsType } from '../../constants';

function AddExternalAnalytics({ showModal, hideModal, isLoading, setIsLoading, handleRefresh, ...props }) {
  const [displayName, setDisplayName] = useState('');
  const [externalId, setExternalId] = useState('');
  const [type, setType] = useState('');
  const [disableReplacesDefaultAnalytics, setDisableReplacesDefaultAnalytics] = useState(false);
  const [disableDatasetName, setDisableDatasetName] = useState(true);
  const [externalDatasetId, setExternaDatasetId] = useState('');
  const [replacesDefaultAnalytics, setReplacesDefaultAnalytics] = useState(false);
  const { toast, errorToast } = useToast();
  const currentUser = useSelector(state => state.user);
  const { effectiveOrganizationId } = currentUser;

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  }

  const handleExternalIdChange = (e) => {
    setExternalId(e.target.value);
  }

  const handleTypeChange = (e) => {
    setType(e.target.value);

    let isPaginatedReport = Number(e.target.value) === externalAnalyticsType.PAGINATEDREPORT;

    setExternaDatasetId('');
    setReplacesDefaultAnalytics(false);

    setDisableReplacesDefaultAnalytics(isPaginatedReport);
    setDisableDatasetName(!isPaginatedReport);
  }

  const handleExternalDatasetIdChange = (e) => {
    setExternaDatasetId(e.target.value);
  }

  const handleReplacesDefaultAnalyticsChange = (e) => {
    setReplacesDefaultAnalytics(e.target.checked);
  }

  const handleErrorMessage = (message) => {
    errorToast(message);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!displayName) {
      handleErrorMessage("Display Name cannot be empty");
      return;
    }

    if (!externalId) {
      handleErrorMessage("External Name cannot be empty");
      return;
    }

    const dto = new ExternalAnalyticsDto({
      organizationId: effectiveOrganizationId,
      displayName: displayName,
      externalId: externalId,
      type: type,
      externalDatasetId: externalDatasetId,
      replacesDefaultAnalytics: replacesDefaultAnalytics
    });

    try {
      await addAnalytics(JSON.stringify(dto));
      hideModal();
      setIsLoading(false);
      handleRefresh()
    } catch (error) {
      setIsLoading(false);
      handleErrorMessage(`Error adding custom analytics: ${error}`)
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={hideModal}
        centered
      >
        <Modal.Header className="modal__header">
          <span>Add Custom Analytics</span>
          <span
            className="modal__header__btn"
            onClick={hideModal}
            aria-label="Exit"
          >
            X
          </span>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Label htmlFor="displayName">Display Name</Form.Label>
            <Form.Control
              type="text"
              name="displayName"
              onChange={handleDisplayNameChange}
              isInvalid={props.errorMessage}
            />            
            <Form.Label htmlFor="externalId">External Name</Form.Label>
            <Form.Control
              type="text"
              name="externalId"
              onChange={handleExternalIdChange}
              isInvalid={props.errorMessage}
            />
            <Form.Label htmlFor="type">Embed Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              onChange={handleTypeChange}
            >
              <option value={externalAnalyticsType.REPORT}>Report</option>
              <option value={externalAnalyticsType.PAGINATEDREPORT}>Paginated Report</option>
            </Form.Control>
            <Form.Label htmlFor="externalDatasetId">Dataset Name</Form.Label>
            <Form.Control
              type="text"
              name="externalDatasetId"
              disabled={disableDatasetName}
              onChange={handleExternalDatasetIdChange}
              value={externalDatasetId}
              isInvalid={props.errorMessage}
            />
            <Form.Check
              label="Replaces Default Analytics"
              checked={replacesDefaultAnalytics}
              disabled={disableReplacesDefaultAnalytics}
              name="replacesDefaultAnalytics"
              onChange={handleReplacesDefaultAnalyticsChange} />
            <Form.Control.Feedback type="invalid">{props.errorMessage}</Form.Control.Feedback>            
            <ButtonToolbar className="mt-4">
              <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
              <Button variant="primary" disabled={props.isLoading} type="submit">
                {props.isLoading ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  "Save"
                )}
              </Button>
            </ButtonToolbar>
          </Form>
        </Modal.Body>
      </Modal>

      <Toast ref={toast} position="bottom-left" />
    </React.Fragment>
  )
}

export default AddExternalAnalytics
