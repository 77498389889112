import ApiFilterDto from './ApiFilterDto';

export default class ApiFilterListDto {
  constructor(options) {
    this.apiFilters = options.apiFilters ? options.apiFilters.map(x => new ApiFilterDto(x)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
