import React from 'react';
import { Row, Col} from 'react-bootstrap';
import { ErrorTableRow } from './ErrorTableRow';

export const ErrorTable = ({ data }) => {
    return (
        <React.Fragment>
            <Row className="error-table-row">
                <Col xs={2} className="error-table-header error-table-border">
                    <b>Line #</b>
                </Col>
                <Col xs={10} className="error-table-header error-table-border">
                    <b>Error Message</b>
                </Col>
            </Row>
            {data.map(r => r.didError ? <ErrorTableRow key={r.lineNumber} lineNumber={r.lineNumber} exception={r.exception} /> : null)}
        </React.Fragment>
    );
}

export default ErrorTable;
