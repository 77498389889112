import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import IndexList from '../../shared/indexList/IndexList';

class DisplayStaffForAssignment extends Component {
  render() {
    return (
      <Row className="no-gutters">
        <Col>
          <IndexList listData={this.props.listData} variant="flush" noBasicCard />
        </Col>
      </Row>
    );
  }
}

export default DisplayStaffForAssignment;