import React from 'react';
import { Row, Button, Container, Form } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function FieldDataTable(props) {
    const tableActionsTemplate = (field) => (
        <div className='d-flex'>
            <button className='field__hyperlink__btn' onClick={(event) => props.handleShowEditField(event, field)}>Edit</button>
            { props.isDraft ? 
                <button 
                    className='field__hyperlink__btn' 
                    onClick={(event) => props.removeField(event, field)}
                >Delete</button> 
            : null }
        </div>
    );

    return (
        <>
            <Row className='d-flex justify-content-between ml-0 mr-0 mb-3'>
                <h5 className='font-weight-bold'>Measure Fields</h5>
                <Button variant='outline-primary' onClick={props.handleShowFieldEditor}>Add Field</Button>
            </Row>

            <Container className="basic-card mb-5">
                {props.fields.length > 0 ?
                    <DataTable
                        value={props.fields}
                        tableStyle={{ minWidth: '50rem' }}
                        scrollable
                        scrollHeight='400px'
                        reorderableRows
                        onRowReorder={(e) => props.reorderField(e)}
                    >
                        <Column rowReorder style={{ width: "3em" }} />
                        <Column field="name" header="Name" />
                        <Column field="type" header="Field Type" />
                        <Column field="label" header="Field Label" />
                        <Column header="Actions" body={tableActionsTemplate} />

                    </DataTable>
                : null }
            </Container>
        </>
    )
}

export default FieldDataTable
