import { format } from 'date-fns';
import { parseDate } from '../../scripts/safeDateParse';

export default class ProgramMeasureDataDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.programId = options.programId || 0;
    this.measureId = options.measureId || 0;
    this.organizationId = options.organizationId || 0;
    this.scoreDate = options.scoreDate ? format(parseDate(options.scoreDate), 'MM/dd/yyyy') : '';
    this.scoreComparisonResult = options.scoreComparisonResult || false;
    this.measureData = options.measureData || '';
    this.fields = options.fields || [];

    this.cutoffScoreValue = options.cutoffScoreValue || '';
    this.cutoffScoreField = options.cutoffScoreField || '';
    this.cutoffScoreComparison = options.cutoffScoreComparison || '';
  }

  stringify() {
    return JSON.stringify(this);
  }

  getMeasureData() {
    if (this.measureData === '') {
      return null;
    }

    return JSON.parse(this.measureData);
  }

  setMeasureData(fieldValues = {}) {
    if (this.fields.length === 0) {
      return '';
    }

    if (this.cutoffScoreField && this.cutoffScoreComparison) {
      const fieldVal = fieldValues[this.cutoffScoreField];
      const parsedVal = parseFloat(fieldVal);
      const parsedCutoffVal = parseFloat(this.cutoffScoreValue);

      switch (this.cutoffScoreComparison) {
        case 'EqualTo':
          // explicitly doing the comparison without type checking to insure we handle both numeric or text
          this.scoreComparisonResult = this.cutoffScoreValue == fieldVal; //eslint-disable-line eqeqeq
          break;

        case 'GreaterThan':
          this.scoreComparisonResult = isNaN(parsedVal) ? false : parsedVal > parsedCutoffVal;
          break;

        case 'LessThan':
          this.scoreComparisonResult = isNaN(parsedVal) ? false : parsedVal < parsedCutoffVal;
          break;

        default:
          break;
      }
    }

    const measureData = {
      fields: this.fields,
      date: this.scoreDate,
      values: this.fields.map(field => (
        {
          'fieldName': field.name,
          'fieldValue': fieldValues[field.name]
        }
      ))
    };

    this.measureData = JSON.stringify(measureData);
  }
}
