import KeywordDto from '../Keyword/KeywordDto';
import { format } from 'date-fns'
import { keywordCategories } from '../../constants';
import { parseDate } from '../../scripts/safeDateParse';

export default class StaffDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.externalId = options.externalId || '';
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.userRoleId = options.userRoleId || '';
    this.userRoleName = options.userRoleName || '';
    this.userId = options.userId || ''; // this is the user email
    this.employeeNumber = options.employeeNumber || '';
    this.licenseNumber = options.licenseNumber || '';
    this.startDate = options.startDate ? format(parseDate(options.startDate), 'MM/dd/yyyy') : '';
    this.isDisabled = options.isDisabled || false;
    this.educationLevel = options.educationLevel ? new KeywordDto(options.educationLevel) : new KeywordDto();
    this.licenseTypes = options.licenseTypes ? options.licenseTypes.map(dto => new KeywordDto(dto)) : [];
    this.certifications = options.certifications || '';

    // for mapped keywords
    this.allSelectedKeywords = options.allSelectedKeywords ? options.allSelectedKeywords.map(dto => new KeywordDto(dto)) : [];

    this.isActive = !this.isDisabled;  // reverse of isDisabled for UI purposes
    this.displayName = `${this.lastName}, ${this.firstName}`;

    const licenseType = this.allSelectedKeywords   // currently only supporting 1 license per user (from the UI)
      ? this.allSelectedKeywords.filter(kw => kw.category === keywordCategories.LICENSE_TYPE)[0]
      : new KeywordDto();

    this.educationLevelValue = this.educationLevel ? this.educationLevel.value : '';
    this.educationLevelText = this.educationLevel ? this.educationLevel.text : '';

    this.licenseTypeValue = licenseType ? licenseType.value : '';
    this.licenseTypeText = licenseType ? licenseType.text : '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
