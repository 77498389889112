import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth0Context } from '../shared/Auth0';

class LoginCallback extends Component {
  render() {
    const { isAuthenticated, redirectTo } = this.context;
    if (isAuthenticated === true) {
      return <Navigate to={redirectTo ?? '/Dashboard'} replace />;
    } else if (isAuthenticated === false) {
      return <Navigate to='/' replace />
    }

    return <div>Loading...</div>
  }
}

LoginCallback.contextType = Auth0Context;

export default LoginCallback;
