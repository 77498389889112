import ServiceHoursDetailsDto from './ServiceHoursDetailsDto';

export default class ServiceHoursDetailsListDto {
  constructor(options = {}) {
    this.serviceHoursDetails = options.serviceHoursDetails ? options.serviceHoursDetails.map(dto => new ServiceHoursDetailsDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
