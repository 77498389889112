import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import EditStaff from './EditStaff';
import AdminEdit from './AdminEdit.js';
import withStaffLookups from '../shared/withStaffLookups';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import StaffDto from '../../DTOs/Staff/StaffDto';

export class StaffAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: new StaffDto(),
      editAction: 'Add',
      apiResult: false,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.addStaffSubmit = this.addStaffSubmit.bind(this);
    this.addSiteAdminSubmit = this.addSiteAdminSubmit.bind(this);
  }

  handleCancelClick() {
    this.props.history.push('/Staff');
  }

  addStaffSubmit(values, actions) {
    message.dismissAll();
    const dto = new StaffDto({
      ...values,
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.addStaff(dto.stringify()).then(() => {
      this.setState({ apiResult: true });
    }).catch(err => {
      message.error(`Error submitting new staff: ${err}`);
      actions.setSubmitting(false);
    });
  }

  addSiteAdminSubmit(values, actions) {
    message.dismissAll();
    const dto = new StaffDto({
      ...values,
      organizationId: 1,
      userRoleId: 1,
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.addStaff(dto.stringify()).then(() => {
      this.setState({ apiResult: true });
    }).catch(err => {
      message.error(`Error submitting new site admin: ${err}`);
      actions.setSubmitting(false);
    });
  }

  render() {
    const { staff, editAction, apiResult } = this.state;
    const { licenseTypes, staffEducationLevels, effectiveOrganizationId, currentUserOrganizationId } = this.props;
    const addingSiteAdmin = (currentUserOrganizationId === 1 && effectiveOrganizationId === null);

    return (
      <React.Fragment>
        <Container className="basic-card pb-3">

          {addingSiteAdmin

            ? <AdminEdit
              staff={staff}
              editAction={editAction}
              redirect={apiResult}
              licenseTypes={licenseTypes}
              staffEducationLevels={staffEducationLevels}
              handleCancelEditClick={this.handleCancelClick}
              handleFormSubmit={this.addSiteAdminSubmit}
              siteAdmin
            />
            : <EditStaff
              staff={staff}
              editAction={editAction}
              redirect={apiResult}
              licenseTypes={licenseTypes}
              staffEducationLevels={staffEducationLevels}
              handleCancelEditClick={this.handleCancelClick}
              handleFormSubmit={this.addStaffSubmit}
            />
          }

        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    effectiveOrganizationId: state.user.effectiveOrganizationId,
    currentUserOrganizationId: state.user.organizationId
  })
)(withStaffLookups(StaffAdd));
