import React from 'react';
import { INPUT_LIST_TYPES } from '../../../constants'

const QuestionText = ({ text, inputType }) => {
  if (!text) return null;

  const questionStyle = {
    marginLeft: INPUT_LIST_TYPES.includes(inputType) ? '15px' : null
  };

  return <div className="question-text w-100 col-form-label" style={questionStyle}>{text}</div>;
}

export default QuestionText;