import { inputTypes, inputSizes } from '../../constants';

export default class Question {
  constructor(options = {}) {
    this.id = options.id;
    this.userDefined = options.userDefined;
    this.question = options.question;
    this.name = options.name;
    this.label = options.label;
    this.type = options.type;
    this.placeholder = options.placeholder;
    this.required = options.required === null || options.required === undefined ? 'no' : options.required;
    this.canDelete = options.canDelete === null || options.canDelete === undefined ? true : options.canDelete;
    this.reportName = options.reportName || '';
    this.isPII = options.isPII === null || options.isPII === undefined ? 'yes' : options.isPII;
    this.shareSponsors = options.shareSponsors === null || options.shareSponsors === undefined ? 'no' : options.shareSponsors;
    this.sharedSponsorIds = options.sharedSponsorIds || [];

    // Options related to text inputs (text and text area)
    this.maxLength = options.maxLength;

    // Options related to number inputs (integer and decimal)
    this.minValue = options.minValue;
    this.maxValue = options.maxValue;

    this.size = options.size;
    this.newLine = options.newLine === null || options.newLine === undefined ? 'no' : options.newLine;
    this.validations = options.validations || [];
  }

  getSizeFromSchema(layout) {
    let result;

    const width = layout.w;
    if (width <= 3) {
      result = inputSizes.XS
    } else if (width === 4) {
      result = inputSizes.S;
    } else if (width <= 6) {
      result = inputSizes.M;
    } else if (width <= 9) {
      result = inputSizes.L;
    } else {
      result = inputSizes.XL;
    }

    return result;
  }

  parseSchema(questionSchema) {
    const inputObj = questionSchema.input || {};
    const inputPropsObj = inputObj.props || {};
    const metaObj = questionSchema.meta || {};


    this.id = questionSchema.questionId;
    this.reportName = metaObj.reportName || '';
    this.userDefined = !!metaObj.userDefined;
    this.question = questionSchema.question;
    this.label = questionSchema.questionLabel;
    this.type = inputObj.type || inputTypes.NO_INPUT;
    this.placeholder = inputObj.placeholder || '';

    // only add saveAsBoolean if it's been (hand) coded into the schema
    if (metaObj.saveAsBoolean) {
      this.saveAsBoolean = metaObj.saveAsBoolean;
    }

    this.maxLength = metaObj.maxLength;

    this.minValue = inputPropsObj.minValue;
    this.maxValue = inputPropsObj.maxValue;

    this.required = metaObj.required ? 'yes' : 'no';
    this.canDelete = !metaObj.cannotDelete;
    this.isPII = metaObj.isPII ? 'yes' : 'no';
    this.shareSponsors = metaObj.shareSponsors ? 'yes' : 'no';
    this.sharedSponsorIds = metaObj.sharedSponsorIds || [];
    this.size = questionSchema.layout ? this.getSizeFromSchema(questionSchema.layout) : null;
    this.newLine = questionSchema.layout ? questionSchema.layout.newLine ? 'yes' : 'no' : 'no';
    this.validations = questionSchema.validations || [];

    return this;
  }

  getDisplayProps() {
    return this;
  }

  getSchemaObj() {
    if (this.type === inputTypes.NO_INPUT) return undefined;

    let props = {
      minValue: this.minValue,
      maxValue: this.maxValue
    }

    return {
      type: this.type,
      placeholder: this.placeholder,
      props
    }
  }

  clone(options = {}) {
    return new Question({ ...this, ...options });
  }

}
