import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { DecimalInput } from '../../../shared/inputs/index';

const NumberInputFields = ({ values, handleChange, touched, errors }) => {

  return (
    <Row>
      <Form.Group as={Col} col={6}>
        <Form.Label>Minimum Value (optional)</Form.Label>
        <DecimalInput
          name="minValue"
          value={values.minValue}
          onChange={handleChange}
          isInvalid={touched.minValue && errors.minValue}
          errorMessage={errors.minValue}
        />
      </Form.Group>

      <Form.Group as={Col} col={6}>
        <Form.Label>Maximum Value (optional)</Form.Label>
        <DecimalInput
          name="maxValue"
          value={values.maxValue}
          onChange={handleChange}
          isInvalid={touched.maxValue && errors.maxValue}
          errorMessage={errors.maxValue}
        />
      </Form.Group>
    </Row>
  );
}

export default NumberInputFields;