export default class ImportDemographicOptionsDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.text = options.text || '';
        this.value = options.value || '';
    }

    stringify() {
        return JSON.stringify(this);
    }
}
