import React, { useState } from 'react';
import { Form, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { inputTypes, inputSizes } from '../../../constants';
import { RadioButtonGroupInput, RadioButtonInput } from '../../shared/inputs/index';
import { InputsDictionary } from '../pageElementsEditor/displayInputs';

import InfoInputEditor from './InfoInputEditor';
import TextInputEditor from './TextInputEditor';
import OptionInputEditor from './OptionInputEditor';

// Returns the proper component to use for the given input type
const getInputCategoryFields = (type) => {
  let FieldsComponent;
  if (type === inputTypes.CHECKBOX_GROUP || type === inputTypes.SELECT) {
    FieldsComponent = OptionInputEditor;
  } else if (type === inputTypes.NO_INPUT || type === inputTypes.RELATIONSHIPS) {
    FieldsComponent = InfoInputEditor;
  } else {
    FieldsComponent = TextInputEditor;
  }

  return props => <FieldsComponent {...props} />;
}

const sizeDisplayNames = {
  [inputSizes.XS]: '1/4 Page',
  [inputSizes.S]: '1/3 Page',
  [inputSizes.M]: '1/2 Page',
  [inputSizes.L]: '2/3 Page',
  [inputSizes.XL]: 'Full Page'
}

const schema = yup.object({
  required: yup.string().ensure().trim()
    .required('This question is required'),
  size: yup.string().ensure().trim()
    .required('Element Size is required'),
  maxLength: yup.number()
    .typeError('Maximum Length must be an integer')
    .positive('Maximum Length must be positive'),
  minValue: yup.number()
    .typeError('Minimum Value must be an integer')
    .test('lessThanMax',
      'Value must be less than Maximum Value',
      function (minValue) {
        const maxValue = this.parent.maxValue;
        const minValueValid = !isNaN(parseFloat(minValue));
        const maxValueValid = !isNaN(parseFloat(maxValue));
        if (minValueValid && maxValueValid) {
          return minValue < maxValue;
        } else {
          return true;
        }
      }),
  maxValue: yup.number()
    .typeError('Maximum Value must be an integer')
});


const InputEditor = ({ inputInfo, reportFieldNames, onCancel, onSave, onDelete }) => {

  const [isAttemptingDelete, setIsAttemptingDelete] = useState(false);

  const inputGeneralInfo = (InputsDictionary[inputInfo.type] || {});
  const InputCategoryFields = getInputCategoryFields(inputInfo.type);
  const noReportName = inputInfo.type === inputTypes.NO_INPUT || inputInfo.type === inputTypes.RELATIONSHIPS;

  // have to add reportName field validation here as we require the existing list of reportFieldNames
  const reportNameSchema = yup.object({
    reportName: yup.string().ensure().lowercase()
      .required('Element name is required')
      .matches(/^[a-zA-Z0-9]+$/, 'Only numbers or characters')
      .notOneOf(reportFieldNames, 'Element name must be unique')
  });

  return (
    <Formik
      validationSchema={noReportName || inputInfo.reportName ? schema : schema.concat(reportNameSchema)}
      initialValues={inputInfo}
      onSubmit={onSave}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue
      }) => (
          <Form onSubmit={handleSubmit}>

            <div className="input-editor__header">
              <Row className="mb-3">
                <Col>
                  <strong>{inputGeneralInfo.name} Template</strong>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={8}>
                  <inputGeneralInfo.display {...values.getDisplayProps()} />
                </Col>
                {inputInfo.canDelete &&
                  (
                    <Col xs={4} className="d-flex align-items-center">
                      <a
                        className="info-link"
                        onClick={() => setIsAttemptingDelete(true)}
                      >
                        Delete Element
                      </a>
                    </Col>
                  )
                }
              </Row>
              {isAttemptingDelete && (
                <React.Fragment>
                  <Row className="mt-1">
                    <Col>
                      Are you sure you want to delete this element? Any client information related to this element will no longer be accessible.
                    </Col>
                  </Row>
                  <ButtonToolbar className="d-flex justify-content-center mt-2">
                    <Button variant="outline-primary" onClick={() => setIsAttemptingDelete(false)}>Cancel</Button>
                    <Button variant="primary" onClick={onDelete}>Delete</Button>
                  </ButtonToolbar>
                </React.Fragment>
              )}
            </div>

            {(inputInfo.type !== inputTypes.NO_INPUT && inputInfo.type !== inputTypes.RELATIONSHIPS) &&
              <div className="input-editor__field-name-body">
                <Row>
                  {inputInfo.reportName
                    ? <React.Fragment>
                      <Col xs={12}>
                        <strong>Element Name: </strong>{inputInfo.reportName}
                      </Col>
                      <Col xs={12} className="input-editor__field-name">
                        The name of this field for reporting purposes.
                    </Col>
                    </React.Fragment>
                    : <React.Fragment>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>
                          <strong>Element Name</strong>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="reportName"
                          value={values.reportName}
                          placeholder="Element Name"
                          onChange={handleChange}
                          isInvalid={touched.reportName && !!errors.reportName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.reportName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Col xs={12} className="input-editor__field-name mb-2">
                        This name should be descriptive of the field and is used for reporting purposes. It should contain no spaces or special characters.
                    </Col>
                    </React.Fragment>
                  }
                </Row>
              </div>
            }

            <div className="input-editor__body">
              <Row className="mb-2">
                <Col>
                  <strong>Edit Template</strong>
                </Col>
              </Row>

              {!inputInfo.canDelete ? (
                <Row>
                  <Col className="text-danger mb-1">*This field cannot be removed.</Col>
                </Row>
              ) : null}

              <InputCategoryFields
                inputInfo={inputInfo}
                values={values}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
              />

              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Element Size</Form.Label>
                  {inputGeneralInfo.staticSize ?
                    (
                      <div className="input-editor__radio-option">
                        {sizeDisplayNames[inputInfo.size]}
                      </div>
                    )
                    :
                    (
                      <RadioButtonGroupInput
                        wrapClasses="d-flex flex-wrap"
                        error={errors.size}
                        touched={touched.size}
                      >
                        <RadioButtonInput
                          name="size"
                          id="size-xs"
                          value={inputSizes.XS}
                          onChange={handleChange}
                          checked={values.size === inputSizes.XS}
                          className="input-editor__radio-option"
                          label={sizeDisplayNames[inputSizes.XS]}
                        />
                        <RadioButtonInput
                          name="size"
                          id="size-s"
                          value={inputSizes.S}
                          onChange={handleChange}
                          checked={values.size === inputSizes.S}
                          className="input-editor__radio-option"
                          label={sizeDisplayNames[inputSizes.S]}
                        />
                        <RadioButtonInput
                          name="size"
                          id="size-m"
                          value={inputSizes.M}
                          onChange={handleChange}
                          checked={values.size === inputSizes.M}
                          className="input-editor__radio-option"
                          label={sizeDisplayNames[inputSizes.M]}
                        />
                        <RadioButtonInput
                          name="size"
                          id="size-l"
                          value={inputSizes.L}
                          onChange={handleChange}
                          checked={values.size === inputSizes.L}
                          className="input-editor__radio-option"
                          label={sizeDisplayNames[inputSizes.L]}
                        />
                        <RadioButtonInput
                          name="size"
                          id="size-xl"
                          value={inputSizes.XL}
                          onChange={handleChange}
                          checked={values.size === inputSizes.XL}
                          className="input-editor__radio-option"
                          label={sizeDisplayNames[inputSizes.XL]}
                        />
                      </RadioButtonGroupInput>

                    )
                  }
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Start question on new line?</Form.Label>
                  <RadioButtonGroupInput
                    wrapClasses="d-flex flex-wrap"
                    error={errors.newLine}
                    touched={touched.newLine}
                  >
                    <RadioButtonInput
                      name="newLine"
                      id="newLine-yes"
                      value="yes"
                      onChange={handleChange}
                      checked={values.newLine === 'yes'}
                      className="input-editor__radio-option input-editor__radio-option--small"
                      label="Yes"
                    />
                    <RadioButtonInput
                      name="newLine"
                      id="newLine-no"
                      value='no'
                      onChange={handleChange}
                      checked={values.newLine === 'no'}
                      className="input-editor__radio-option input-editor__radio-option--small"
                      label="No"
                    />
                  </RadioButtonGroupInput>
                </Form.Group>
              </Row>

              <ButtonToolbar className="d-flex justify-content-center mt-4">
                <Button variant="outline-primary" onClick={onCancel}>Cancel</Button>
                <Button variant="primary" disabled={isSubmitting} type="submit">Save</Button>
              </ButtonToolbar>
            </div>

          </Form>
        )}
    </Formik>
  );
}

export default InputEditor;
