import React from 'react';
import '../../styles/switch.css'

const Switch = ({ value, name, onChange }) => {
  return (
    <React.Fragment>
      <input
        checked={value}
        onChange={onChange}
        className="react-switch-checkbox"
        id={name}
        type="checkbox"
      />
      <label
        style={{ background: value && '#3fc788' }}
        className="react-switch-label mt-1"
        htmlFor={name}
      >
        <span className="react-switch-button" />
      </label>
    </React.Fragment>
  );
};

export default Switch;