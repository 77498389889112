/**
 * https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-currency-string-in-javascript
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) { // eslint-disable-line no-extend-native
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\D' : '$'})`;
  const num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

const FormatCurrency = (num, options = {}) => {
  const formatNum = parseFloat(num);

  if (isNaN(formatNum)) {
    return '';
  }
  return `$${formatNum.format(options)}`;
};

export default FormatCurrency;
