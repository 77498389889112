import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import MultiSelect from '../../shared/asyncSelect/MultiSelect';
import api from '../../../scripts/api';
import { message } from '../../shared/Message';

class AssignOrganizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOptionsMessage: 'No matching partners found',
      pageSize: 50,
      pageNum: 0,
      apiError: null
    };

    this.errorMessageId = null;
  }

  searchOrganizations = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
    message.dismiss(this.errorMessageId);

    let result;
    try {
      result = await api.searchSponsored(searchTerm, pageNum, pageSize);
    } catch (err) {
      this.errorMessageId = message.error(`Error searching for organizations: ${err}`);
      return { options: [] };
    }

    const searchResults = result.searchResults.map(dto => ({ value: dto.id, label: `${dto.name}${dto.isDisabled ? ' (Inactive)' : ''}` }));
    const hasMore = result.totalMatches > pageNum * pageSize + searchResults.length;

    return {
      options: searchResults,
      hasMore: hasMore,
      additional: {
        pageNum: pageNum + 1, //next page
        pageSize: pageSize
      }
    };
  };

  render() {
    const { pageNum, pageSize, noOptionsMessage } = this.state;
    const { defaultOrganizations, selectOrganizations } = this.props;

    return (
      <React.Fragment>
        <Row className="mb-2">
          <Col>
            Add one or more Partner Organizations
          </Col>
        </Row>
        <Row>
          <Col>
            <MultiSelect
              placeholder="Search partners"
              noOptionsMessage={() => noOptionsMessage}
              defaultValue={defaultOrganizations}
              closeMenuOnSelect={false}
              loadOptions={this.searchOrganizations}
              additional={{ pageNum: pageNum, pageSize: pageSize }}
              onChange={selectOrganizations}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default AssignOrganizations;