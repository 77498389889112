import { LOGIN_REDIRECT_URL } from '../constants';
const createAuth0Client = window.createAuth0Client;

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const redirect_uri = window.location.origin + LOGIN_REDIRECT_URL;
const leeway = 300; // should be no need to set this to 5 minutes but Auth0 is acting screwy

let auth0Client;

const init = async () => {
  return await createAuth0Client({
    domain,
    client_id,
    audience,
    redirect_uri,
    leeway
  });
}

export default {
  async getAuth0Client() {
    if (!auth0Client) {
      auth0Client = await init();
    }
    return auth0Client;
  },

  async getHeaders() {
    const auth0Client = await this.getAuth0Client()
    const token = await auth0Client.getTokenSilently();
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  }
}
