export default class EmbedTokenDto {
  constructor(options = {}) {
    this.token = options.token || '';
    this.tokenId = options.tokenId || '';
    this.expiration = options.expiration || '';
  }
  stringify() {
    return JSON.stringify(this);
  }
}
