import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import withClientPaging from '../paging/withClientPaging';
import ActionIcon from '../ActionIcon.js';

import arrow from '../../../images/FlyoutArrow.png'

export const IndexList = ({ listData, variant, noBasicCard, normalTitle }) => {

  const displayItemGroup = (item) => {
    return (
      <ListGroup.Item action className="d-flex justify-content-between">
        <div className="d-flex flex-column text-truncate pr-3">

          {normalTitle
            ? <div className="text-truncate">{item.title}</div>
            : <strong className="text-truncate">{item.title}</strong>
          }

          <div className="text-truncate">
            {item.description}
          </div>
        </div>

        <div className="d-flex align-self-center">
          <div className="d-flex index-list__end-text">
            <strong className="d-flex">{item.endTextDisplay}</strong>
          </div>

          <div className="">
            <img className="index-list__arrow" src={arrow} alt={`View ${item.title}`} />
          </div>
        </div>
      </ListGroup.Item>
    );
  };

  return (
    <ListGroup className={noBasicCard ? '' : 'basic-card'} variant={variant}>
      {listData.map(item => (
        item.url
          ? <LinkContainer key={item.itemId} to={item.url}>
            {displayItemGroup(item)}
          </LinkContainer>
          : <div key={item.itemId}>
            {displayItemGroup(item)}
          </div>
      ))
      }
    </ListGroup >
  );
}

export default withClientPaging(IndexList);

