import ImportDemographicOptionsDto from './ImportDemographicOptionsDto';
import ImportDemographicValidationsDto from './ImportDemographicValidationsDto';

export default class ImportDemographicDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.multiSelect = options.multiSelect || false;
        this.questionId = options.questionId || '';
        this.reportName = options.reportName || '';
        this.required = options.required || false;
        this.inputType = options.inputType || '';
        this.importDemographicValidations = options.importDemographicValidations.map(d => new ImportDemographicValidationsDto(d)) || [];
        this.importDemographicOptions = options.importDemographicOptions.map(d => new ImportDemographicOptionsDto(d)) || [];
        this.keywordCategory = options.keywordCategory || '';
    }

    stringify() {
        return JSON.stringify(this);
    }
}
