import React, { useState } from 'react';
import { Row, Col, Form, Container, Button, ButtonToolbar, FormControl } from 'react-bootstrap';
import { Formik } from 'formik';

import { addMeasureSchema } from './validations/EditMeasureValidations';
import FieldDataTable from './FieldDataTable';
import PreviewMeasureModal from './modals/PreviewMeasureModal';
import FieldEditorModal from './modals/FieldEditorModal';
import ConfirmMeasureUpdate from './modals/ConfirmMeasureUpdate';

function EditMeasure({ measure, editAction, handleFormSubmit, ...props }) {
    const [showPreview, setShowPreview] = useState(false);
    const [showFieldEditor, setShowFieldEditor] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [fieldData, setFieldData] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const handleShowFieldEditor = () => {
        setIsDisabled(false);
        setShowFieldEditor(true)
    };

    const handleShowEditField = (event, field) => {
        event.preventDefault();

        if (!measure.isDraft) {
            setIsDisabled(true)
        };

        setShowFieldEditor(true);
        setFieldData(field);
    }

    const handleFieldEditorCancel = () => {
        setShowFieldEditor(false);
        setFieldData({});
    }

    const handleMeasureSubmit = (values, { setFieldError, setSubmitting }) => {
        // doing custom validation when the cutoff score field is a dropdown
        const currentCutoffScoreField = values.fieldDefinitions.fields.find(x => {
            return values.cutoffScoreField === x.name
          })

          if (currentCutoffScoreField && currentCutoffScoreField.type === "DropDown") {
            let options = currentCutoffScoreField.options.map(o => o.value)
            
            if (!options.includes(values.cutoffScoreValue)) {
               setFieldError("cutoffScoreValue", "When the cutoff score field is a dropdown, the cutoff score value must be a valid dropdown option.");
               setSubmitting(false);
               return;
            }
          }
        
        if (!measure.isDraft) {
            setShowConfirmationModal(true);
        } else {
            handleFormSubmit(values)
        }
    }

    return (
        <Formik
            initialValues={{
                ...measure,
                outcomeCategoryValue: measure.outcomeCategory.value || "",
                typeValue: measure.type.value || "",
                fieldDefinitions: {
                    fields: (measure.fieldDefinitions && measure.fieldDefinitions.fields) || []
                },
                cutoffScoreComparisonValue: measure.cutoffScoreComparison.value || ""
            }}
            validationSchema={addMeasureSchema}
            onSubmit={handleMeasureSubmit}
        >
            {({
                handleChange,
                handleSubmit,
                values,
                touched,
                errors,
                setFieldValue
            }) => {
                const handleAddField = (newField) => {
                    setFieldValue("fieldDefinitions.fields", [...values.fieldDefinitions.fields, newField])
                }

                const handleUpdateField = (field, updatedFieldValue) => {
                    const currentFieldIndex = values.fieldDefinitions.fields.findIndex(item => item === field);
                    const allFields = [...values.fieldDefinitions.fields];
                    allFields[currentFieldIndex] = updatedFieldValue;

                    setFieldValue("fieldDefinitions.fields", allFields);
                }

                const removeField = (event, field) => {
                    event.preventDefault();

                    if (!measure.isDraft) {
                        return;
                    } else {
                        const filteredFields = values.fieldDefinitions.fields.filter((item) => {
                            return item !== field;
                        });
    
                        setFieldValue("fieldDefinitions.fields", filteredFields);
                    }
                }

                const reorderField = (event) => {
                    setFieldValue("fieldDefinitions.fields", event.value)
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <h3 className="mb-3">{editAction} Measure</h3>
                        <Container className='d-flex justify-content-between mb-5 pl-0 pr-0'>
                            <Col md={5}>
                                <Row className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Measure Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={touched.name && !!errors.name}
                                        disabled={!measure.isDraft}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="assessmentUsed"
                                        placeholder="Assessment Used"
                                        value={values.assessmentUsed}
                                        onChange={handleChange}
                                        disabled={!measure.isDraft}
                                    />
                                </Row>
                                <Row className="mb-3">
                                    <Form.Control
                                        as="select"
                                        className={`sure-impact__select ${values.outcomeCategoryValue === "" ? 'sure-impact__select-default' : ''}`}
                                        value={values.outcomeCategoryValue}
                                        onChange={handleChange}
                                        placeholder="Outcome Category"
                                        name="outcomeCategoryValue"
                                        isInvalid={touched.outcomeCategoryValue && !!errors.outcomeCategoryValue}
                                        disabled={!measure.isDraft}
                                    >
                                        <option value="" className="sure-impact__select-default">Outcome Category</option>
                                        {props.outcomeCategory && props.outcomeCategory.map(item => (
                                            <option key={item.id} value={item.value}>{item.text}</option>))}
                                    </Form.Control>
                                    <Form.Control.Feedback type='invalid'>{errors.outcomeCategoryValue}</Form.Control.Feedback>
                                </Row>
                                <Row>
                                    <Form.Control
                                        as="select"
                                        className={`sure-impact__select ${values.typeValue === '' ? 'sure-impact__select-default' : ''}`}
                                        placeholder='Measure Type'
                                        value={values.typeValue}
                                        onChange={handleChange}
                                        name="typeValue"
                                        isInvalid={touched.typeValue && !!errors.typeValue}
                                        disabled={!measure.isDraft}
                                    >
                                        <option value="" className="sure-impact__select-default">Measure Type</option>
                                        {props.measureType && props.measureType.map(item => (
                                            <option key={item.id} value={item.value}>{item.text}</option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.typeValue}</Form.Control.Feedback>
                                </Row>
                            </Col>

                            <Col md={5}>
                                <Row>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Measure Description"
                                        style={{ height: '194px' }}
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={touched.description && !!errors.description}
                                        disabled={!measure.isDraft}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                                </Row>
                            </Col>
                        </Container>

                        <FieldDataTable
                            fields={values.fieldDefinitions && values.fieldDefinitions.fields}
                            handleShowFieldEditor={handleShowFieldEditor}
                            removeField={removeField}
                            handleShowEditField={handleShowEditField}
                            reorderField={reorderField}
                            isDraft={measure.isDraft}
                        />
                        
                        {errors.fieldDefinitions && errors.fieldDefinitions.fields && touched.fieldDefinitions && touched.fieldDefinitions.fields && (
                            <p className="text-danger">{errors.fieldDefinitions.fields}</p>
                        )}

                        <h5 className='mb-3 font-weight-bold'>Measure Criteria</h5>
                        <Form.Row className='mb-5'>
                            <Col md={4}>
                                <Form.Control
                                    as="select"
                                    className={`sure-impact__select ${values.cutoffScoreField === '' ? 'sure-impact__select-default' : ''}`}
                                    value={values.cutoffScoreField}
                                    onChange={handleChange}
                                    name="cutoffScoreField"
                                    placeholder="Cutoff Score Field"
                                    isInvalid={touched.cutoffScoreField && !!errors.cutoffScoreField}
                                    disabled={!measure.isDraft}
                                >
                                    <option value="" className="sure-impact__select-default">Cutoff Score Field</option>
                                    {values.fieldDefinitions?.fields?.map((field, index) => (
                                        <option key={index} value={field.name}>{field.name}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.cutoffScoreField}</Form.Control.Feedback>
                            </Col>
                            <Col md={4}>
                                <Form.Control
                                    type="text"
                                    name="cutoffScoreValue"
                                    placeholder="Cutoff Score Value"
                                    value={values.cutoffScoreValue}
                                    onChange={handleChange}
                                    isInvalid={touched.cutoffScoreValue && !!errors.cutoffScoreValue}
                                    disabled={!measure.isDraft}
                                />
                                <Form.Control.Feedback type="invalid">{errors.cutoffScoreValue}</Form.Control.Feedback>
                            </Col>
                            <Col md={4}>
                                <Form.Control
                                    as="select"
                                    className={`sure-impact__select ${values.cutoffScoreComparisonValue === '' ? 'sure-impact__select-default' : ''}`}
                                    placeholder='Cutoff Score Comparison'
                                    value={values.cutoffScoreComparisonValue}
                                    onChange={handleChange}
                                    name="cutoffScoreComparisonValue"
                                    isInvalid={touched.cutoffScoreComparisonValue && !!errors.cutoffScoreComparisonValue}
                                    disabled={!measure.isDraft}
                                >
                                    <option value="">Cutoff Score Comparison</option>
                                    {props.cutoffScoreComparison && props.cutoffScoreComparison.map(item => (
                                        <option key={item.id} value={item.value}>{item.text}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.cutoffScoreComparisonValue}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>

                        <ButtonToolbar className='d-flex justify-content-between'>
                            <Button variant='outline-primary' onClick={() => setShowPreview(true)}>Preview</Button>
                            <div className='d-flex justify-content-between'>
                                <Button className='mr-3' variant='outline-primary' onClick={props.handleCancelClick}>Cancel</Button>
                                <Button variant='primary' type='submit'>{measure.isDraft ? "Save Draft" : "Update Measure"}</Button>
                            </div>
                        </ButtonToolbar>

                        <PreviewMeasureModal
                            data={JSON.parse(JSON.stringify(values))} // creating a deep copy of formik's values obj to prevent the actuals values from being modified
                            show={showPreview}
                            onCancel={() => setShowPreview(false)}
                        />

                        <FieldEditorModal
                            show={showFieldEditor}
                            currentAction={Object.keys(fieldData).length > 0 ? 'Edit' : 'Add'}
                            onClose={handleFieldEditorCancel}
                            data={fieldData}
                            handleAddField={handleAddField}
                            handleUpdateField={handleUpdateField}
                            isDisabled={isDisabled}
                        />

                        <ConfirmMeasureUpdate
                            showModal={showConfirmationModal}
                            hideModal={() => setShowConfirmationModal(false)}
                            confirmUpdate={() => handleFormSubmit(values)}
                        />
                    </Form>
                )
            }
            }
        </Formik>
    )
}

export default EditMeasure;