import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Demographics from '../../shared/demographics/Demographics';
import '../../../styles/modal.css';

class PreviewModal extends Component {

  render() {
    const { show, onCancel, schema, panelId } = this.props;

    return (
      <Modal
        dialogClassName="modal--full-width"
        show={show}
        centered
        onHide={onCancel}
      >
        <Modal.Header className="modal__header">
          <span>Preview</span>
          <span
            className="modal__header__btn"
            onClick={onCancel}
            aria-label="Exit"
          >
            X
          </span>
        </Modal.Header>
        <Modal.Body>
          <Demographics
            schema={schema}
            panelId={panelId}
            noApiWrites
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default PreviewModal;