import SchemaEngine from './SchemaEngine';
import Question from './Question';
import OptionQuestion from './OptionQuestion';
import { inputTypes } from '../../constants';

// Some util functions
function updateMajorVersion(str) {
  let [major] = str.split('.');
  return `${parseInt(major, 10) + 1}.0`;
}

function updateMinorVersion(str) {
  let [major, minor] = str.split('.');
  return `${major}.${parseInt(minor, 10) + 1}`;
}


// Winterfell template
const winterfellTemplate = {
  id: 'template',
  meta: {
    version: '1.0',
    timestamp: 0,
    userId: '',
  },
  formPanels: [],
  questionPanels: [],
  questionSets: []
};


// Template for defining what panels / questions are required
const schemaTemplate = {
  panels: [
    {
      panelId: 'panel-identification',
      name: 'Identification',
      required: true,
      questions: []
    },
    {
      panelId: 'panel-general',
      name: 'General',
      required: true,
      questions: []
    }
  ]
}


/**
 * Class for manipulating and creating demographic schema objects
 */
export default class DemographicsSchema {

  static schemaTemplate = schemaTemplate;

  constructor(schema) {
    this.schemaEngine = new SchemaEngine();
    // This bool indicates whether this is a blank object (false), or that an object was passed which is an actual demographics schema (true)
    this.initialized = false;

    this.parseSchema(schema);

  }

  /**** Private methods ****/
  _requiredPanel(panelId) {
    return (DemographicsSchema.schemaTemplate.panels.find(p => p.panelId === panelId) || {}).required;
  }

  /*************************/


  parseSchema(schema) {
    this.initialized = false;

    this.originalSchema = JSON.parse(JSON.stringify(schema || winterfellTemplate));

    this.id = this.originalSchema.id;
    this.meta = this.originalSchema.meta;
    this.panels = this.originalSchema.questionPanels.map(qp => {
      const questionSetId = qp.questionSets[0].questionSetId;
      const questions = this.parseQuestions(this.originalSchema.questionSets.find(qs => qs.questionSetId === questionSetId).questions);
      return {
        panelId: qp.panelId,
        name: qp.tabText,
        required: this._requiredPanel(qp.panelId),
        questions,
      }
    });

    if (schema !== undefined && schema !== null) {
      this.initialized = true;
    }
  }

  parseQuestions(questions) {
    if (!Array.isArray(questions))
      throw new Error('parseQuestions: questions parameter must be an array.');

    return questions.map(q => {
      let inputObj = q.input || {};
      let instance;
      if (inputObj.type === inputTypes.SELECT || inputObj.type === inputTypes.CHECKBOX_GROUP) {
        instance = new OptionQuestion();
      } else {
        instance = new Question();
      }
      return instance.parseSchema(q);
    });
  }

  /****** Generate schema json *******/
  generateSchema() {
    return this.schemaEngine.generate(this);
  }

  prepareDraft(userId, currentVersionStr = null) {
    let schema = this.generateSchema();

    // Need to update this objects info as well as the returned objects meta object
    this.meta = {
      version: updateMinorVersion(currentVersionStr || this.meta.version),
      timestamp: Date.now(),
      publisherId: userId
    };

    schema.meta = this.meta;

    return schema;
  }

  prepareActive(userId) {
    let schema = this.generateSchema();

    this.meta = {
      version: updateMajorVersion(this.meta.version),
      timestamp: Date.now(),
      publisherId: userId
    };

    schema.meta = this.meta;

    return schema;
  }
/****************************/

  /***** Meta data *****/
  getMeta() {
    return this.meta;
  }

  setMeta(meta) {
    if (typeof meta !== 'object') throw new Error('Metadata must be of type object');
    this.meta = meta;
  }

  getVersion() {
    return this.meta.version;
  }
  /******************/

  /***** Panels *****/

  getPanel(panelId) {
    return this.panels.find(p => p.panelId === panelId) || {};
  }

  addPanel(panelName, panelId) {
    if (!panelId) panelId = `panel-${Date.now()}`;
    this.panels.push({
      panelId: panelId,
      name: panelName,
      questions: []
    });
    return panelId;
  }

  updatePanel(panelId, updateObj) {
    const { name } = updateObj;
    const panel = this.getPanel(panelId);

    if (name !== undefined) panel.name = name;
  }

  removePanel(panelId) {
    if (!this._requiredPanel(panelId)) {
      this.panels = this.panels.filter(p => p.panelId !== panelId);
    }
  }

/***************/

  /** Questions **/
  getQuestions(panelId) {
    const panel = this.panels.find(p => p.panelId === panelId) || {};
    return panel.questions ? [...panel.questions] : [];
  }

  getReportFieldNames() {
    let allNames = [];

    this.panels.forEach(p => {
      let panelReportNames = p.questions.reduce((acc, elem) => {
        if (elem.reportName) {
          return acc.concat(elem.reportName.toLowerCase());
        }

        return acc;
      }, []);

      allNames = allNames.concat(panelReportNames);
    });

    return allNames;
  }
  updatePanelQuestions(panelId, newQuestions) {
    if (!Array.isArray(newQuestions)) throw new Error('updatePanelQuestions: newQuestions parameter must be an array');
    const panel = this.panels.find(p => p.panelId === panelId) || {};
    panel.questions = newQuestions;
  }
}