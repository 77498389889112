import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Auth0Context } from '../shared/Auth0';
import { LOGIN_REDIRECT_URL } from '../../constants';
import logo from '../../images/Horizontal@6x.png';


export default class Login extends Component {

    render() {
        const { loginWithRedirect } = this.context;

        return (
            <Container className="d-flex flex-column flex-grow-1 justify-content-center">
                <Row noGutters className="mb-lg-5 mb-md-3">
                    <Col className="d-flex align-content-center justify-content-center">
                        <img src={logo} className="login__image" alt="SureImpact" />
                    </Col>
                </Row>
                <Row noGutters>
                    <Col>
                        <h3 className="d-flex align-content-center justify-content-center">
                            Measurement moves missions.
                        </h3>
                        <p className="d-flex align-content-center justify-content-center">
                            We help you gather and use the right data to change actions, outcomes, and lives.
                        </p>
                    </Col>
                </Row>
                <Row noGutters>
                    <Col className="d-flex align-content-center justify-content-center">
                        <Button
                            variant="primary"
                            onClick={() => loginWithRedirect({ redirect_uri: window.location.origin + LOGIN_REDIRECT_URL })}
                        >
                            Log in
                        </Button>
                    </Col>
                </Row>

                
            </Container>
        );
    }
}

Login.contextType = Auth0Context;