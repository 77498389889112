import React, { Component } from 'react';
import { withRouter } from '../shared/RouterShim';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row, Button } from 'react-bootstrap';

import { actionCreators } from '../../store/User';
import { licenseLevelStrings, licenseTypeStrings } from '../../constants';

export class OrganizationDisplay extends Component {
  constructor(props) {
    super(props);
  }

  handleImpersonationClick(id) {
    this.props.startImpersonating(id);
    this.props.history.push('/Dashboard');
  }

  render() {
    const { organization, handleEditClick } = this.props;

    return (
      <React.Fragment>
        <Row>
          <Col xl={9} lg={8} md={6}>

            <Row>
              <Col className="align-self-end text-truncate mb-4">
                <h5>{organization.organizationName}</h5>
              </Col>
            </Row>

            <Row id="licenseDetailsHeader" className="mb-1">
              <Col>
                License Details
              </Col>
            </Row>

            <Row>
              <Col id="licenseLevel" lg={2} md={4} className="font-weight-bold">
                {licenseLevelStrings[organization.licenseLevel]}
              </Col>
              <Col id="licenseType" lg={2} md={4} className="font-weight-bold">
                {licenseTypeStrings[organization.licenseType]}
              </Col>
            </Row>

          </Col>

          <Col xl={3} lg={4} md={6} className="text-right">
            <Row>
              <Col>
                <Button id="editButton" variant="primary" className="mb-3" onClick={handleEditClick}>Edit Organization</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="outline-primary" onClick={() => this.handleImpersonationClick(organization.id)}>Impersonate</Button>
              </Col>
            </Row>
          </Col>

          <Col xl={9} lg={8} md={6}>

            <Row id="licenseDetailsHeader" className="pt-3">
              <Col>
                Multi-Factor Authentication
              </Col>
            </Row>

            <Row>
              <Col id="licenseLevel" lg={2} md={4} className="font-weight-bold pt-2">
                {organization.mfaIsEnabled ? 'Enabled' : 'Disabled'}
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(
  state => ({ effectiveId: state.user.effectiveOrganizationId }),
  dispatch => bindActionCreators(actionCreators, dispatch)
)(OrganizationDisplay));
