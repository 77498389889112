export default class EditMeasureDataDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.clientId = options.clientId || 0;
    this.programId = options.programId || 0;
    this.measureId = options.measureId || 0;
    this.organizationId = options.organizationId || 0;
    this.scoreDate = options.scoreDate || '';
    this.scoreComparisonResult = options.scoreComparisonResult || false;
    this.measureData = options.measureData || '';
    this.fields = options.fields || [];
    this.cutoffScoreValue = options.cutoffScoreValue || '';
    this.cutoffScoreField = options.cutoffScoreField || '';
    this.cutoffScoreComparison = options.cutoffScoreComparison || '';
    this.assessmentUsed = options.assessmentUsed || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}