import React from 'react';
import { Row, Col } from 'react-bootstrap';
import IndexList from '../../shared/indexList/IndexList';
import { Message } from 'primereact/message';

function DisplaySponsoredOrganizations({ organizationList }) {
    return (
        <Row className="no-gutters">
            <Col>
                {organizationList.length === 0 ?
                    <Message
                        severity="info"
                        text='There are currently no sponsored partners for this external report. 
                            Click "Add Partners" to add sponsorship.'
                        className="info-style w-100"
                    />
                    :
                    <IndexList listData={organizationList} variant="flush" noBasicCard />
                }
            </Col>
        </Row>
    )
}

export default DisplaySponsoredOrganizations;