import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * The CheckboxInput component
 */
const CheckboxInput = ({ onChange, onBlur, value, checkedValue, uncheckedValue, ...props }) => {
  checkedValue = checkedValue !== undefined ? checkedValue : true;
  uncheckedValue = uncheckedValue !== undefined ? uncheckedValue : false;

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = checked ? checkedValue : uncheckedValue;
    onChange(value);
  }

  const checked = value === checkedValue;

  return (
    <Form.Check
      type="checkbox"
      custom
      name={props.name}
      id={checkedValue + ''}
      value={checkedValue + ''}
      label={props.text}
      checked={checked}
      onChange={handleChange}
    />
  );
}

export default CheckboxInput;