import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import DisplayStaff from './DisplayStaff';
import EditStaff from './EditStaff';
import BottomTabs from './BottomTabs';
import AdminEdit from './AdminEdit.js';
import withStaffLookups from '../shared/withStaffLookups';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import StaffDto from '../../DTOs/Staff/StaffDto';
import { userRoles } from '../../constants';

export class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: new StaffDto(),
      editAction: 'Edit',
      fatalError: false,
      showEdit: false,
    };

    this.saveErrorMessageId = null;
    this.getErrorMessageId = null;

    this.handleEditClick = this.handleEditClick.bind(this);
    this.editStaffSubmit = this.editStaffSubmit.bind(this);
    this.editSiteAdminSubmit = this.editSiteAdminSubmit.bind(this);
  }

  handleEditClick() {
    this.setState({ showEdit: !this.state.showEdit });
  }

  editStaffSubmit(values, actions) {
    message.dismiss(this.saveErrorMessageId);
    const dto = new StaffDto({
      ...values,
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.updateStaff(dto.stringify()).then(response => {
      this.setState({ staff: response, showEdit: !this.state.showEdit });
    }).catch(err => {
      actions.setSubmitting(false);
      this.saveErrorMessageId = message.error(`Error submitting staff: ${err}`);
    });
  }

  editSiteAdminSubmit(values, actions) {
    message.dismiss(this.saveErrorMessageId);
    const dto = new StaffDto({
      ...values,
      organizationId: 1,
      userRoleId: 1,
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.updateStaff(dto.stringify()).then(response => {
      this.setState({ staff: response, showEdit: !this.state.showEdit });
    }).catch(err => {
      actions.setSubmitting(false);
      this.saveErrorMessageId = message.error(`Error submitting site admin: ${err}`);
    });
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.initializeComponent(id);
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.initializeComponent(id);
  }

  initializeComponent(id) {
    message.dismiss(this.getErrorMessageId);

    if (id && parseInt(id, 10) !== 0) {
      api.getStaffById(id).then(result => {
        this.setState({ staff: result });
      }).catch(err => {
        this.getErrorMessageId = message.error(`Error getting staff: ${err.message}`);
        this.setState({ fatalError: true });
      });
    }
  }

  render() {
    const { staff, editAction, showEdit, fatalError } = this.state;
    const { licenseTypes, staffEducationLevels, effectiveOrganizationId, currentUserOrganizationId } = this.props;
    const editSiteAdmin = (currentUserOrganizationId === 1 && effectiveOrganizationId === null);
    const currentUserIsStaff = this.props.userPermissions.rolePermission === userRoles.STAFF;
    const currentUserRole = this.props.userPermissions.rolePermission;

    if (fatalError)
      return null;

    return (
      <React.Fragment>
        <Container className="basic-card pb-3 mb-5">
          {!showEdit
            ? <DisplayStaff staff={staff} handleEditClick={this.handleEditClick} />

            : (editSiteAdmin
              ? <AdminEdit
                staff={staff}
                editAction={editAction}
                redirect={false}
                licenseTypes={licenseTypes}
                staffEducationLevels={staffEducationLevels}
                handleCancelEditClick={this.handleEditClick}
                handleFormSubmit={this.editSiteAdminSubmit}
                siteAdmin
              />
              : <EditStaff
                staff={staff}
                editAction={editAction}
                redirect={false}
                licenseTypes={licenseTypes}
                staffEducationLevels={staffEducationLevels}
                handleCancelEditClick={this.handleEditClick}
                handleFormSubmit={this.editStaffSubmit}
                currentUserRole={currentUserRole}
              />
            )
          }
        </Container>

        {currentUserIsStaff ?
          <React.Fragment /> :
          <BottomTabs staff={staff} />
        }

      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    effectiveOrganizationId: state.user.effectiveOrganizationId,
    currentUserOrganizationId: state.user.organizationId
  })
)(withStaffLookups(Staff));
