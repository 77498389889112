export default class EmbedReportDto {
  constructor(options = {}) {
    this.reportId = options.reportId || '';
    this.reportName = options.reportName || '';
    this.embedUrl = options.embedUrl || '';
    this.isCustomReport = options.isCustomReport || false;
  }
  stringify() {
    return JSON.stringify(this);
  }
}
