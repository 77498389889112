import React, { Component } from 'react';

import api from '../../scripts/api';
import KeywordDto from '../../DTOs/Keyword/KeywordDto';
import { keywordCategories } from '../../constants';

export default (WrappedComponent) => {
  return class WithLookups extends Component {
    state = {
      licenseTypes: [],
      staffEducationLevels: []
    }

    componentDidMount() {
      api.getKeywordsByCategory(keywordCategories.LICENSE_TYPE).then(result => {
        this.setState({ licenseTypes: result.keywords.map(dto => new KeywordDto(dto)) });
      }).catch(err => {
        console.log(`Error getting keywords(${keywordCategories.LICENSE_TYPE}): ${err}`);
        this.setState({ apiError: `Error getting keywords(${keywordCategories.LICENSE_TYPE}): ${err}` });
      });

      api.getKeywordsByCategory(keywordCategories.STAFF_EDUCATION_LEVEL).then(result => {
        this.setState({ staffEducationLevels: result.keywords.map(dto => new KeywordDto(dto)) });
      }).catch(err => {
        console.log(`Error getting keywords(${keywordCategories.STAFF_EDUCATION_LEVEL}): ${err}`);
        this.setState({ apiError: `Error getting keywords(${keywordCategories.STAFF_EDUCATION_LEVEL}): ${err}` });
      });
    }

    render() {
      const { licenseTypes, staffEducationLevels } = this.state;

      return (
        <React.Fragment>
          <WrappedComponent
            licenseTypes={licenseTypes}
            staffEducationLevels={staffEducationLevels}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  }
}
