import React from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import DateInput from '../../shared/inputs/DateInput';
import { getMeasureInputField, createYupSchema } from '../../shared/inputs/inputHelpers';

function PreviewMeasureModal({ show, onCancel, data }) {
  const { name, assessmentUsed, fieldDefinitions} = data;
  const largeFields = ['text', 'dropdown', 'display'];

  let initialValues = { ...data };
  fieldDefinitions?.fields.forEach(field =>{
    initialValues[field.name] = field.defaultValue !== null ? field.defaultValue : '';
  })

  let formSchema = {
    scoreDate: yup.date()
      .typeError('Measure Date is not a valid date')
      .required('Measure Date is required')
      .max(new Date(), 'Measure Date may not be in the future')
  };

  const updatedformSchema = data.fieldDefinitions?.fields.reduce(createYupSchema, formSchema)

  const schema = yup.object().shape(updatedformSchema);

  return (
      <Modal
        dialogClassName="modal--full-width"
        show={show}
        centered
        onHide={onCancel}
      >
        <Modal.Header className="modal__header">
          <span>Preview</span>
          <button
            className="modal__header__btn"
            onClick={onCancel}
            aria-label="Exit"
          >
            X
          </button>
        </Modal.Header>

        <Modal.Body>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
          >
            {({
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
            <Form className="my-3">
              <Row className="mb-3">
                <Col className='text-truncate' sm={11}>
                  <h5 style={{ fontWeight: 400}}>{name}</h5>
                </Col> 
              </Row>
              <Row className="mb-3">
                <Col>
                  <strong>Assessment Used:</strong> {assessmentUsed}
                </Col>
              </Row>

              <Form.Row>

                <Form.Group as={Col} md={3}>
                  <Form.Label className="mb-0">Measure Date</Form.Label>
                  <DateInput
                    name="scoreDate"
                    label="Measure Date"
                    placeholder="Measure Date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.scoreDate}
                    errorMessage={errors.scoreDate}
                    isInvalid={touched.scoreDate && !!errors.scoreDate}
                  />
                </Form.Group>
              
                <Col>
                  <Form.Row>
                    { values?.fieldDefinitions?.fields.map((field, index) => {
                      const colSize = largeFields.includes(field.type.toLowerCase()) ? 6 : 4;

                      return (
                        <Form.Group as={Col} md={colSize} sm={colSize + 2} xs={12} key={index}>
                          {field.label && 
                            <Form.Label className='mb-0'>{field.label}</Form.Label>
                          }

                          { getMeasureInputField(field,
                            {
                              value: values && values[field.name],
                              error: errors[field.name],
                              touched: touched[field.name],
                              onChange: handleChange,
                              onBlur: handleBlur,
                              errorMessage: errors[field.name],
                              isInvalid: touched[field.name] && !!errors[field.name]
                            })}
                        </Form.Group>
                      )
                    })}
                  </Form.Row>
                </Col>  

              </Form.Row>
            </Form>
            )}
          </Formik>
        </Modal.Body>
    </Modal>
  )
}

export default PreviewMeasureModal;