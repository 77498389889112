import ClientDto from './ClientDto';

export default class ApplicationUserClientListDto {
  constructor(options = {}) {
    this.allClients = options.allClients.map(dto => new ClientDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}