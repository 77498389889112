import React, { Component } from 'react';

import Paging from './Paging';

import { pagingOptions } from '../../../constants';

export default (WrappedComponent) => {
  return class WithClientPaging extends Component {

    state = {
      currentPage: 1
    }

    static defaultProps = {
      itemsPerPage: pagingOptions.DEFAULT_INDEX_ITEMS,
      maxPages: pagingOptions.DEFAULT_MAX_PAGES
    }

    handlePageClick(nextPage) {
      this.setState({
        currentPage: nextPage
      });
    }

    render() {
      // listData comes from parent component
      const { listData, itemsPerPage, maxPages, embedded, ...props } = this.props;
      const { currentPage } = this.state;

      let pageItems = listData.slice(((currentPage - 1) * itemsPerPage), ((currentPage - 1) * itemsPerPage) + itemsPerPage);

      return (
        <React.Fragment>
          <WrappedComponent listData={pageItems} {...props} />
          <Paging
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            maxPages={maxPages}
            totalItems={listData.length}
            onPageClick={(nextPage) => this.handlePageClick(nextPage)}
            embedded={embedded}
          />
        </React.Fragment>
      );
    }
  }
}