import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

function PublishConfirmationModal({ showModal, hideModal, loading, confirmPublish }) {
    const publishHandler = () => {
        confirmPublish();
        hideModal();
    }

    return (
        <Modal
            show={showModal}
            onHide={hideModal}
            centered
        >
            <Modal.Header className="modal__header">
                <span>Ready to Publish?</span>
                <span
                    className="modal__header__btn"
                    onClick={hideModal}
                    aria-label='Exit'
                >
                    X
                </span>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex justify-content-center">
                    {loading ?
                        <Spinner animation='border' style={{ textAlign: "center" }} />
                        :
                        <p style={{ textAlign: "center" }}>
                            Are you ready to publish this Measure? Once published, you’ll be unable to delete fields and editing fields will be limited.
                        </p>
                    }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
                <Button variant="primary" onClick={publishHandler}>Publish Measure</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PublishConfirmationModal;