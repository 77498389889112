import React, { Component } from "react";
import { Row, Col, Container, Button, ButtonToolbar} from "react-bootstrap";
import { ErrorTable } from "./ErrorTable";
import EditActions from '../../shared/EditActions';

class ImportErrorCard extends Component {
    render() {
        const { onClick, result, importType, totalProcessed } = this.props;
        return (
            <Container className="basic-card p-4">
                <Row className="mb-3">
                    <Col>
                        <h5><strong>Bulk Import Data</strong></h5>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-4">
                        You successfully imported {totalProcessed} {importType} data lines. Your import had {result.errorCount} errors. Below are the lines that failed and the reasons why. <br />
                        <br />
                        Re-upload a file with just those lines once they've been fixed.
                    </Col>
                </Row>
                <ErrorTable data={result.results} />
                <EditActions>
                    <ButtonToolbar className="d-flex justify-content-end">
                        <Button variant="primary" onClick={onClick}>Back to Import</Button>
                    </ButtonToolbar>
                </EditActions>
            </Container>
        );
    }
}

export default ImportErrorCard;
