import ProgramDto from './ProgramDto';

export default class ProgramListDto {
  constructor(options = {}) {
    this.programs = options.programs ? options.programs.map(dto => new ProgramDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}