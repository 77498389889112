import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/User';
import { message } from '../shared/Message';
import { schemaVersions } from '../../constants';
import DemographicsBuilderContext from './demographicsBuilderContext';
import SchemaSelection from './SchemaSelection';
import Pages from './Pages';
import Elements from './pageElementsEditor/Elements';
import PreviewModal from './preview/PreviewModal';
import WarningModal from '../shared/WarningModal';
import DemographicsSchema from '../../scripts/demographicsSchema/DemographicsSchema';
import api from '../../scripts/api';
import DemographicSchemasDto from '../../DTOs/Organization/DemographicSchemasDto';

class DemographicsBuilderPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isShowingPreview: false,
      isAttemptingPublish: false,
      schemaJson: null,

     /*
      * Object holding all of the demographics schema objects
      * When the user selects another version, we will get that object from this map
      */
      schemaVersions: {
        [schemaVersions.ACTIVE]: new DemographicsSchema(this.props.activeSchema),
        [schemaVersions.DRAFT]: new DemographicsSchema(this.props.draftSchema),
      },

      contextValue: {
        currentSchemaVersion: null,
        schema: null,
        currentPanelId: '',

        updateCurrentSchemaVersion: this.updateCurrentSchemaVersion.bind(this),
        updateCurrentPanel: this.updateCurrentPanel.bind(this),
        updateSchema: this.updateSchema.bind(this),
        isSchemaSelected: this.isSchemaSelected.bind(this),
        isPanelSelected: this.isPanelSelected.bind(this),

        showPreview: this.showPreview.bind(this),
        saveAsDraft: this.saveAsDraft.bind(this),
      }
    }

    this.messageId = null;

    this.saveAsDraft = this.saveAsDraft.bind(this);
    this.publish = this.publish.bind(this);
  }

  componentDidMount() {
    this.props.getDemographicSchemas();
  }

  // Updates the cached versions of active and draft schemas
  updateSchemas(activeSchema, draftSchema) {
    const { currentSchemaVersion } = this.state.contextValue;
    const nextSchemaVersions = {
      [schemaVersions.ACTIVE]: new DemographicsSchema(activeSchema),
      [schemaVersions.DRAFT]: new DemographicsSchema(draftSchema),
    }
    this.setState({
      schemaVersions: nextSchemaVersions
    });

    const nextSchema = currentSchemaVersion === schemaVersions.ACTIVE ? nextSchemaVersions[schemaVersions.ACTIVE] :
      currentSchemaVersion === schemaVersions.DRAFT ? nextSchemaVersions[schemaVersions.DRAFT] : null
    this._updateContext({
      schema: nextSchema
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeSchema !== prevProps.activeSchema || this.props.draftSchema !== prevProps.draftSchema) {
      this.updateSchemas(this.props.activeSchema, this.props.draftSchema);
    }
    if (this.props.schemaLoadError !== prevProps.schemaLoadError && this.props.schemaLoadError) {
      message.error('There was an issue getting the demographics schema. Please refresh the page to try again.');
    }
  }

  /******* Methods for updating and reading the context value *******/

  // Helper method to set the state for the nested contextValue object
  _updateContext(obj) {
    this.setState({
      contextValue: {
        ...this.state.contextValue,
        ...obj
      }
    });
  }

  updateCurrentSchemaVersion(version) {
    this._updateContext({
      currentSchemaVersion: version,
      schema: this.state.schemaVersions[version],
      currentPanelId: '',
    });
  }

  updateCurrentPanel(panelId) {
    this._updateContext({ currentPanelId: panelId });
  }

  isSchemaSelected() {
    const { currentSchemaVersion } = this.state.contextValue;
    return currentSchemaVersion !== null && currentSchemaVersion !== undefined;
  }

  isPanelSelected() {
    return this.state.contextValue.currentPanelId !== '';
  }

  updateSchema(func) {
    // We need to do this this way because we need to call setState at some point so we get the UI to rerender.
    // Since we have a nested object that we update, when we update it, React does not know it needs to rerender.
    if (func) func();
    this._updateContext({ schema: this.state.contextValue.schema });
  }

  showPreview(show) {
    let schemaJson = this.state.schemaJson;
    if (show) {
      const { schema } = this.state.contextValue;
      schemaJson = schema.generateSchema();
    }

    this.setState({
      isShowingPreview: show,
      schemaJson: schemaJson
    });
  }

  showPublishWarning(show) {
    this.setState({
      isAttemptingPublish: show
    });
  }


  saveAsDraft() {
    const { schema, currentSchemaVersion } = this.state.contextValue;
    const { userId, getDemographicSchemas } = this.props;
    const { isSubmitting } = this.state;

    if (isSubmitting) return;
    this.setState({ isSubmitting: true });

    message.dismiss(this.messageId);

    let generatedSchema;
    let previousMetadata = schema.getMeta();

    // Need to to determine the next draft version
    let currentDraftVersionStr;
    let draftSchema = this.state.schemaVersions[schemaVersions.DRAFT];
    if (draftSchema.initialized && currentSchemaVersion !== schemaVersions.DRAFT) {
      currentDraftVersionStr = draftSchema.getVersion();
    }

    // Generate the schema object with the correct meta data, question positioning, etc.
    try {
      generatedSchema = schema.prepareDraft(userId, currentDraftVersionStr);
    } catch (err) {
      console.log(err);
      this.messageId = message.error(`An error occurred while creating the demographics schema: ${err}`);
      this.setState({ isSubmitting: false });
      schema.setMeta(previousMetadata);
      return;
    }

    const dto = new DemographicSchemasDto({
      draftDemographicsSchema: generatedSchema
    });

    api.updateDraftDemographicsSchema(dto.stringify()).then(() => {
      this.messageId = message.success(`Successfully saved draft`);
      getDemographicSchemas();
      this._updateContext({ currentSchemaVersion: schemaVersions.DRAFT });
    }).catch(err => {
      console.log(`Error saving: ${err}`);
      this.messageId = message.error(`Error saving: ${err.message}`);
      schema.setMeta(previousMetadata);
    }).finally(() => {
      this.setState({ isSubmitting: false });
    });
  }

  publish() {
    const { schema } = this.state.contextValue;
    const { userId, getDemographicSchemas } = this.props;
    const { isSubmitting } = this.state;

    if (isSubmitting) return;
    this.setState({ isSubmitting: true });

    message.dismiss(this.messageId);

    let generatedSchema;
    let previousMetadata = schema.getMeta();
    try {
      generatedSchema = schema.prepareActive(userId);
    } catch (err) {
      console.log(err);
      this.messageId = message.error(`An error occurred while creating the demographics schema: ${err}`);
      this.setState({ isSubmitting: false, isAttemptingPublish: false });
      schema.setMeta(previousMetadata);
      return;
    }

    const dto = new DemographicSchemasDto({
      activeDemographicsSchema: generatedSchema
    });

    api.publishDemographicsSchema(dto.stringify()).then(() => {
      this.messageId = message.success(`Successfully published demographics schema`);
      getDemographicSchemas();
      this._updateContext({ currentSchemaVersion: schemaVersions.ACTIVE });
    }).catch(err => {
      console.log(`Error publishing: ${err}`);
      this.messageId = message.error(`Error publishing: ${err.message}`);
      schema.setMeta(previousMetadata);
    }).finally(() => {
      this.setState({ isSubmitting: false, isAttemptingPublish: false });
    });
  }
  /****************************************************/

  render() {
    const {
      schemaVersions,
      contextValue,
      isShowingPreview,
      isAttemptingPublish,
      schemaJson
    } = this.state;

    const availableVersions = Object.keys(schemaVersions).filter(v => schemaVersions[v].initialized);

    return (
      <React.Fragment>

        <PreviewModal
          show={isShowingPreview}
          schema={schemaJson}
          panelId={contextValue.currentPanelId}
          onCancel={() => this.showPreview(false)}
        />

        <WarningModal
          show={isAttemptingPublish}
          title="Confirm Publish?"
          confirmText="Publish"
          onCancel={() => this.showPublishWarning(false)}
          onConfirm={this.publish}
        >
          <p>Publishing this version of the client demographics template will replace the old version and delete any saved drafts.</p>
          <p>This cannot be undone.</p>
        </WarningModal>


        <Row className="mb-3">
          <Col>
            <h5>Client Demographic Form Builder</h5>
          </Col>
        </Row>

        <DemographicsBuilderContext.Provider value={contextValue}>
          <Container className="demographics-builder basic-card">
            <Row className="demographics-builder__row">
              <Col xs={6}>
                <SchemaSelection
                  versions={availableVersions}
                />
              </Col>
              <Col xs={6}>
                <Pages />
              </Col>
            </Row>
            <Elements />
          </Container>

          <Row className="mt-3">
            <Col className="d-flex justify-content-end">
              <Button
                disabled={!contextValue.isSchemaSelected() || !contextValue.isPanelSelected()}
                onClick={() => this.showPublishWarning(true)}
              >
                Publish
              </Button>
            </Col>
          </Row>

        </DemographicsBuilderContext.Provider>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  activeSchema: state.user.activeDemographicsSchema,
  draftSchema: state.user.draftDemographicsSchema,
  schemaLoadError: state.user.demographicsInfoLoadError || state.user.schemaParseError,
  userId: state.user.email
});

const mapActionsToProps = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DemographicsBuilderPage);