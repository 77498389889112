import React, { Component } from 'react';
import { Row, Col, Form, Button, ButtonToolbar } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import api from '../../../../scripts/api';
import transform from '../../../../scripts/transformer';
import { TextInput, DateInput, PhoneNumberInput } from '../index';
import AddClientRelationshipDto from '../../../../DTOs/Client/AddClientRelationshipDto';
import ClientDto from '../../../../DTOs/Client/ClientDto';
import RelationshipTypeInput from './RelationshipTypeInput';

/**
 * Component for showing a form for a user to fill out to create a new relationship alsong with a new client.
 */
export default class CreateRelationshipNewClientInput extends Component {

  static schema = yup.object({
    firstName: yup.string().ensure().trim()
      .required('First Name is required'),
    lastName: yup.string().ensure().trim()
      .required('First Name is required'),
    relationship: yup.string().ensure().trim()
      .required('Relationship Type is required'),
    dateOfBirth: yup.date()
      .typeError('Date of Birth is not a valid date')
      .required('Date of Birth is required')
      .max(new Date(), 'Date of Birth may not be in the future')
  });

  static defaultProps = {
    noApiWrites: false,
    onCancel: () => { },
    onSubmit: (value, extra) => { },
    onError: (err) => { }
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, extra) {
    const { noApiWrites, onSubmit, onError } = this.props;
    // use values to generate a demographicsjson and reportjson value, add it to values
    const dto = new ClientDto(values);

    const json = { "firstName": values.firstName, "lastName": values.lastName, "dateOfBirth": values.dateOfBirth, "phoneNumber": values.phoneNumber };

    dto.demographicsJson = json;
    dto.reportJson = json;
    // If 'noApiWrites' is true, we do not want to make the api call to create the new user
    let submit = Promise.resolve(dto);
    if (!noApiWrites) {
      submit = api.addClient(dto.stringify());
    }

    submit.then(result => {
      onSubmit(transform.clientDtoToClientRelationshipDto(result, values));
    }).catch(err => {
      onError(err.message);
    }).finally(() => {
      extra.setSubmitting(false);
      extra.resetForm();
    });
  }

  render() {
    const { onCancel } = this.props;

    return (
      <Formik
        validationSchema={CreateRelationshipNewClientInput.schema}
        onSubmit={this.handleSubmit}
        initialValues={new AddClientRelationshipDto()}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          handleChange,
          values,
          touched,
          errors,
          submitForm,
          isSubmitting
        }) => (
            <React.Fragment>
              <Row>
                <Form.Group as={Col} md={4}>
                  <TextInput
                    value={values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    placeholder="First Name"
                    isInvalid={touched.firstName && !!errors.firstName}
                    errorMessage={errors.firstName}
                  />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <TextInput
                    value={values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    placeholder="Last Name"
                    isInvalid={touched.lastName && !!errors.lastName}
                    errorMessage={errors.lastName}
                  />
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <RelationshipTypeInput
                    value={values.relationship}
                    name="relationship"
                    onChange={handleChange}
                    isInvalid={touched.relationship && !!errors.relationship}
                    errorMessage={errors.relationship}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md={3}>
                  <DateInput
                    value={values.dateOfBirth}
                    name="dateOfBirth"
                    onChange={handleChange}
                    placeholder="Date of Birth"
                    isInvalid={touched.dateOfBirth && !!errors.dateOfBirth}
                    errorMessage={errors.dateOfBirth}
                  />
                </Form.Group>
                <Form.Group as={Col} md={3}>
                  <PhoneNumberInput
                    value={values.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                    placeholder="Phone"
                    isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <ButtonToolbar className="d-flex justify-content-end">
                    <Button variant="outline-primary" onClick={onCancel} disabled={isSubmitting}>Cancel</Button>
                    <Button variant="primary" onClick={submitForm} disabled={isSubmitting}>Save</Button>
                  </ButtonToolbar>
                </Form.Group>
              </Row>
            </React.Fragment>
          )}
      </Formik>
    );
  }
}
