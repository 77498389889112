import React from 'react';
import TextInput from './TextInput'
import { getIntString, formatDisplayNumber } from '../../../scripts/numHelpers';
import { updateCursor, cleanInput, copyEvent } from './inputHelpers';

/**
 * Component for allowing positive integer inputs
 * 
 * This component also allows you to specify a minimum value and/or a maximum value
 * that the input must be in.  If they user attempts to change the input so the value is outside of
 * this range, the input will not let them.
 */
const IntegerInput = ({ value, onChange, onBlur, ...props }) => {

  // Need to hold a reference to the O.G. value so we can reset if the new value is out of range
  const originalValue = value;

  const getCleanInput = (inputValue) => {
    return cleanInput(getIntString(inputValue), originalValue, props);
  }

  const handleOnChange = (e) => {
    // Strip the value of characters that are not numbers
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);
    updateCursor(e, inputValue, formatDisplayNumber(cleanedInput));

    e.target.value = cleanedInput;

    if (onChange) onChange(e);
  }

  // Handles when the input is blurred
  const handleBlur = (e) => {
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);

    let eventCopy = copyEvent(e);
    eventCopy.target.value = cleanedInput;

    if (onBlur) onBlur(eventCopy);
  }

  const displayValue = formatDisplayNumber(value);

  return (
    <TextInput
      value={displayValue}
      onChange={handleOnChange}
      onBlur={handleBlur}
      {...props}
    />
  );
}

export default IntegerInput;