import React, { useState } from 'react';

export const withPickListSelection = (WrappedComponent) => {
    return (props) => {
        const [selected, setSelected] = useState([]);
        const [available, setAvailable] = useState([]);

        const fetchSelectData = (selectData) => {
            setSelected(selectData)
        }
        
        const fetchAvailableData = (availableData) => {
            setAvailable(availableData)
        }

        const handleAddToSelected = (itemToAdd) => {
            setSelected([...selected, itemToAdd]);
            setAvailable(available.filter(item => item !== itemToAdd))
        }

        const handleRemoveFromSelected = (itemToRemove) => {
            setSelected(selected.filter(item => item !== itemToRemove))
            setAvailable([...available, itemToRemove])
        }

        return (
            <WrappedComponent
                {...props}
                selected={selected}
                available={available}
                fetchSelectData={fetchSelectData}
                fetchAvailableData={fetchAvailableData}
                handleAddToSelected={handleAddToSelected}
                handleRemoveFromSelected={handleRemoveFromSelected}
            />
        )
    }
}