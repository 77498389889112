import KeywordDto from './KeywordDto';

export default class KeywordListDto {
  constructor(options){
    this.keywords = options.keywords.map(kw => new KeywordDto(kw));
  }

  stringify() {
    return JSON.stringify(this);
  }
}