import ApplicationUserWithRolesDto from './ApplicationUserWithRolesDto';

export default class ApplicationUserWithRolesListDto {
  constructor(options = {}) {
    this.applicationUsers = options.applicationUsers.map(dto => new ApplicationUserWithRolesDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}