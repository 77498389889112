// Strips the input of any characters that are not valid in decimal (float) input
// optionally limits decimal places to the specified maxDecimal value
const cleanInput = (newValue, originalValue, props) => {
    if (!newValue) {
        return '';
    }

    // Determine if the new value is within range
    // Need to make sure minValue is <= 0 and maxValue is >= 0.  This is so the user can at least input something into the input
    // Extra validation on save should be done elsewhere to ensure the final value is within the specified range for this input
    const maxValue = Math.max(props.maxValue ? props.maxValue : Number.POSITIVE_INFINITY, 0);
    const minValue = Math.min(props.minValue ? props.minValue : Number.NEGATIVE_INFINITY, 0);

    // very simple check to determine if we need to parse as an int or float
    const isInteger = newValue.indexOf('.') < 0;

    const parsedValue = isInteger ? parseInt(newValue, 10) : parseFloat(newValue);

    const parsedMax = maxValue === Number.POSITIVE_INFINITY ? Number.POSITIVE_INFINITY : parseFloat(maxValue);
    const parsedMin = minValue === Number.NEGATIVE_INFINITY ? Number.NEGATIVE_INFINITY : parseFloat(minValue);

    if (parsedValue > parsedMax || parsedValue < parsedMin) {
        newValue = originalValue;
    }

    // limit value to the specified format
    let rx;
    if (isInteger) {
        rx = new RegExp('^-?[0-9]*$');
    }
    else {
        rx = new RegExp(props.maxDecimals ? `^-?[0-9]*.?[0-9]{0,${props.maxDecimals}}$` : '^-?[0-9]*.?[0-9]*$');
    }

    let matchedValue = originalValue;
    const matchedValues = newValue.match(rx);
    if (matchedValues) {
        matchedValue = matchedValues[0];
      }

    return matchedValue;
};

export default cleanInput;