/**
 * Returns the specified value as a float format (stripping out any non-numeric chars) but retains any trailing decimal place.  i.e. 1. => 1.
 * Useful for removing leading 0's in the integer portion of the value
 * This will work with both positive and negative floats
 *
 * @param {String} value the raw value that needs to be parsed & formatted
 * @return {String} the formatted string (i.e. 1234.5678 or 1.)
 */

const getFloatString = (value) => {
  const num = value.toString().replace(/[^0-9.-]/g, '');

  if (!num) {
    return '';
  }

  const parts = num.split('.');

  // Only parse if there is a number present after our preamble (This is what the regex checks for)
  // This could be the beginning of a negative number with only '-' present, or '-.' present, or '0.' present, etc.
  let intPart = parts[0];
  if (!RegExp('^-?0?$').test(intPart)) {
    intPart = `${parseInt(intPart, 10)}`;
  }

  const decPart = parts.length > 1 ? parts[1] : '';

  return `${intPart}${parts.length > 1 ? '.' : ''}${decPart}`;
}

export default getFloatString;