import React from 'react';
import { Navigate } from 'react-router';
import Login from './Login';
import { Auth0Context } from '../shared/Auth0';

export const HomeRedirect = () => (
    <Auth0Context.Consumer>
        {({ isAuthenticated, loading, redirectTo }) => {
            if (loading) {
                return null;
            }

            if (isAuthenticated) {
                return <Navigate to={{
                    pathname: redirectTo ?? '/Dashboard'
                }} replace />;
            } else {
                return <Login />;
            }
        }}
    </Auth0Context.Consumer>
)
