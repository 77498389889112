'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _libUtils = require('./lib/utils');

var _libValidation = require('./lib/validation');

var _libValidation2 = _interopRequireDefault(_libValidation);

var _inputTypesIndex = require('./inputTypes/index');

var _inputTypesIndex2 = _interopRequireDefault(_inputTypesIndex);

var _libErrors = require('./lib/errors');

var _libErrors2 = _interopRequireDefault(_libErrors);

var _libValidation3 = _interopRequireDefault(_libValidation);

var Winterfell = (function (_React$Component) {
  _inherits(Winterfell, _React$Component);

  function Winterfell(props) {
    _classCallCheck(this, Winterfell);

    _get(Object.getPrototypeOf(Winterfell.prototype), 'constructor', this).call(this, props);

    var schema = _extends({
      formPanels: [],
      questionPanels: [],
      questionSets: []
    }, props.schema);

    schema.formPanels = schema.formPanels.sort(function (a, b) {
      return a.index > b.index;
    });

    var panelId = typeof props.panelId !== 'undefined' ? props.panelId : schema.formPanels.length > 0 ? schema.formPanels[0].panelId : undefined;

    var currentPanel = typeof schema !== 'undefined' && typeof schema.formPanels !== 'undefined' && typeof panelId !== 'undefined' ? schema.formPanels.find(function (fp) {
      return fp.panelId === panelId;
    }) : undefined;

    if (!currentPanel) {
      throw new Error('Winterfell: Could not find initial panel and failed to render.');
    }

    this.state = {
      schema: schema,
      currentPanel: currentPanel,
      questionAnswers: props.questionAnswers,
      validationErrors: {} // key is panelId, value is that panel's validation errors
    };

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleValidationError = this.handleValidationError.bind(this);
    this.switchPanel = this.switchPanel.bind(this);
    this.switchPanelWithValidation = this.switchPanelWithValidation.bind(this);
    this.submit = this.submit.bind(this);
    this.validateSurvey = this.validateSurvey.bind(this);
  }

  /**
   * Creates the control object to be used to control actions in the Survey from outside the survey
   */

  _createClass(Winterfell, [{
    key: 'getControls',
    value: function getControls() {
      return {
        switchPanel: this.switchPanelWithValidation,
        submit: this.submit,
        validate: this.validateSurvey
      };
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.onControlsCreated(this.getControls());
      this.props.onRender();
    }
  }, {
    key: 'handleAnswerChange',
    value: function handleAnswerChange(questionId, questionAnswer) {
      var _this = this;

      this.setState(function (state) {
        return {
          questionAnswers: _extends({}, state.questionAnswers, _defineProperty({}, questionId, questionAnswer))
        };
      }, function () {
        return _this.props.onUpdate(_this.state.questionAnswers);
      });
    }
  }, {
    key: 'handleValidationError',
    value: function handleValidationError(panelId, questionId, errors) {
      if (this.props.noValidate) return;
      var validationErrors = this.state.validationErrors;

      var panelErrors = _extends({}, validationErrors[panelId], _defineProperty({}, questionId, errors));
      this.setState({
        validationErrors: _extends({}, validationErrors, _defineProperty({}, panelId, panelErrors))
      });
    }
  }, {
    key: 'switchPanelWithValidation',
    value: function switchPanelWithValidation(panelId) {
      var _state = this.state;
      var schema = _state.schema;
      var currentPanel = _state.currentPanel;
      var questionAnswers = _state.questionAnswers;

      var valid = true;
      // Only validate (and update state) if we should
      if (!this.props.noValidate) {
        var questionSetDefinitions = (0, _libUtils.getQuestionSets)(schema, currentPanel.panelId);

        var invalidQuestions = _libValidation2['default'].getQuestionPanelInvalidQuestions(questionSetDefinitions, questionAnswers);

        this.setState({
          validationErrors: _extends({}, this.state.validationErrors, _defineProperty({}, currentPanel.panelId, invalidQuestions))
        });

        valid = _libValidation2['default'].isPanelValid(invalidQuestions);
      }

      if (valid) {
        this.switchPanel(panelId);
      }
      return valid;
    }
  }, {
    key: 'switchPanel',
    value: function switchPanel(panelId) {
      var schema = this.props.schema;

      var panel = schema.formPanels.find(function (fp) {
        return fp.panelId === panelId;
      });

      if (!panel) {
        throw new Error('Winterfell: Tried to switch to panel "' + panelId + '", which does not exist.');
      }

      this.setState({
        currentPanel: panel
      }, this.props.onSwitchPanel.bind(null, panel));
    }
  }, {
    key: 'submit',
    value: function submit() {
      var surveyValid = this.validateSurvey();

      if (surveyValid) {
        this.props.onSubmit(this.state.questionAnswers);
      }
    }
  }, {
    key: 'validateSurvey',
    value: function validateSurvey() {
      if (this.props.noValidate) return true;
      // Validate the entire form (ie. each questionPanel)
      var _state2 = this.state;
      var schema = _state2.schema;
      var questionAnswers = _state2.questionAnswers;

      var validatedPanels = _libValidation2['default'].getSurveyInvalidQuestions(schema, questionAnswers);
      this.setState({
        validationErrors: validatedPanels
      });

      var surveyValid = _libValidation2['default'].isSurveyValid(validatedPanels);

      if (!surveyValid && this.props.showInvalidPanel) {
        /* See if the current panel has errors.
         * If so, we will stay on that panel.  If not,
         * we will open the first panel with errors
         */
        var panelId = this.state.currentPanel.panelId;

        if (_libValidation2['default'].isPanelValid(validatedPanels[panelId])) {
          var otherPanels = Object.keys(validatedPanels).filter(function (id) {
            return !_libValidation2['default'].isPanelValid(validatedPanels[id]);
          });
          this.switchPanel(otherPanels[0]);
        }
      }

      return surveyValid;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state3 = this.state;
      var schema = _state3.schema;
      var currentPanel = _state3.currentPanel;
      var questionAnswers = _state3.questionAnswers;
      var validationErrors = _state3.validationErrors;

      var currentPanelDefinition = schema.questionPanels.find(function (qp) {
        return qp.panelId === currentPanel.panelId;
      });

      var getPanelProps = function getPanelProps() {
        return {
          schema: schema,
          panel: currentPanelDefinition,
          questionAnswers: questionAnswers,
          validationErrors: validationErrors[currentPanel.panelId],
          onAnswerChange: _this2.handleAnswerChange,
          onSwitchPanel: _this2.switchPanelWithValidation,
          onSubmit: _this2.submit,
          onValidationError: _this2.handleValidationError
        };
      };

      var getTabProps = function getTabProps() {
        return {
          onSwitchPanel: _this2.switchPanelWithValidation.bind(_this2),
          currentActivePanelId: currentPanel.panelId,
          schema: schema,
          validationErrors: validationErrors
        };
      };

      var props = {
        panelHeader: currentPanelDefinition.panelHeader,
        panelText: currentPanelDefinition.panelText,
        isInvalid: !_libValidation2['default'].isPanelValid(validationErrors)
      };

      var element = this.props.children({
        getPanelProps: getPanelProps,
        getTabProps: getTabProps,
        props: props
      });

      return _react2['default'].cloneElement(element, { key: currentPanel.panelId });
    }
  }]);

  return Winterfell;
})(_react2['default'].Component);

;

/**
 * Setting up static methods on the Winterfell class
 */
Winterfell.inputTypes = _inputTypesIndex2['default'];
Winterfell.errorMessages = _libErrors2['default'];
Winterfell.validation = _libValidation3['default'];

Winterfell.addInputType = Winterfell.inputTypes.addInputType;
Winterfell.addInputTypes = Winterfell.inputTypes.addInputTypes;

Winterfell.addErrorMessage = Winterfell.errorMessages.addErrorMessage;
Winterfell.addErrorMessages = Winterfell.errorMessages.addErrorMessages;

Winterfell.addValidationMethod = Winterfell.validation.addValidationMethod;
Winterfell.addValidationMethods = Winterfell.validation.addValidationMethods;

Winterfell.defaultProps = {
  questionAnswers: {},
  panelId: undefined,
  onControlsCreated: function onControlsCreated() {},
  onSubmit: function onSubmit() {},
  onUpdate: function onUpdate() {},
  onSwitchPanel: function onSwitchPanel() {},
  onRender: function onRender() {},
  showInvalidPanel: false,
  noValidate: false
};

exports['default'] = Winterfell;
module.exports = exports['default'];