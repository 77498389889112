export default class ProgramEnrollmentDto {
    constructor(fields = {}) {
        this.programId = fields.programId || 0;
        this.enrollmentStartDate = fields.enrollmentStartDate || null;
    }

    stringify() {
        return JSON.stringify(this);
    }
}
