import React, { Component } from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';

import AdminIndex from './AdminIndex.js';
import IndexActions from '../../shared/indexList/IndexActions.js';
import { message } from '../../shared/Message';
import { AdminEdit } from '../../staff/AdminEdit.js';

import StaffDto from '../../../DTOs/Staff/StaffDto.js';
import withStaffLookups from '../../shared/withStaffLookups';

import api from '../../../scripts/api';

export class Admins extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      addingAdmin: false
    };

    this.addNewAdmin = this.addNewAdmin.bind(this);
    this.initializeComponent = this.initializeComponent.bind(this);
  }

  initializeComponent(id) {
    message.dismissAll();

    if (id && parseInt(id, 10) !== 0) {
      api.getActiveOrganizationAdminsForOrg(id).then(result => {
        this.setState({
          listData: result.staff
        });
      }).catch(err => {
        message.error(`Error getting administrators for organization: ${err}`);
      });
    }
  }

  handleAddClick() {
    this.setState({ addingAdmin: !this.state.addingAdmin });
  }

  addNewAdmin(values, actions) {
    message.dismissAll();
    const dto = new StaffDto({
      ...values,
      organizationId: this.props.organizationId,
      userRoleId: '2',
      isDisabled: !values.isActive,
      educationLevel: this.props.staffEducationLevels.filter(kw => kw.value === values.educationLevelValue)[0],
      licenseTypes: this.props.licenseTypes.filter(kw => kw.value === values.licenseTypeValue)
    });

    api.addOrganizationAdministrator(dto.stringify()).then(response => {
      this.setState({ addingAdmin: !this.state.addingAdmin });
      this.initializeComponent(this.props.organizationId);
    }).catch(err => {
      actions.setSubmitting(false);
      message.error(`Error adding admin: ${err}`);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.initializeComponent(this.props.organizationId);
    }
  }

  componentDidMount() {
    this.initializeComponent(this.props.organizationId);
  }

  render() {
    const { listData, addingAdmin } = this.state;
    const { licenseTypes, staffEducationLevels } = this.props;

    return (
      <React.Fragment>
        {addingAdmin

          ? <div className="m-3">
            <AdminEdit
              staff={new StaffDto()}
              editAction="Add"
              handleCancelEditClick={() => this.handleAddClick()}
              handleFormSubmit={this.addNewAdmin}
              licenseTypes={licenseTypes}
              staffEducationLevels={staffEducationLevels}
              orgAdmin
            />
          </div>

          : <IndexActions>
            <Row className="pt-3 pl-3 pr-3">
              <Col md={6} className="align-self-end">
                <h6>Assigned Admins</h6>
              </Col>
              <Col md={6} className="d-flex justify-content-end">

                <ButtonToolbar>
                  <Button
                    variant="outline-primary"
                    onClick={() => this.handleAddClick()}>
                    Add Admin
                </Button>
                </ButtonToolbar>

              </Col>
            </Row>
          </IndexActions>
        }

        {!addingAdmin &&
          <AdminIndex
            listData={listData}
            reloadAdminList={this.initializeComponent}
            licenseTypes={licenseTypes}
            staffEducationLevels={staffEducationLevels}
          />
        }
      </React.Fragment>
    );
  }
}

export default withStaffLookups(Admins);
