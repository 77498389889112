'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

/**
 * Determines the questions that should be shown on the page for a given state of the 
 * question answers.
 * 
 * @param {Array<Questions>} questions An array of questions for the question set
 * @param {Object} questionAnswers the current question answers object
 * @returns {Array<Questions>} An array of question objects that should be shown
 */
function getAvailableQuestions(questions, questionAnswers) {

  // Nested function to actually do the recursive traversal to find all conditional
  // questions that should be shown.
  function getQuestionAndConditionals(questionObj) {
    if (!questionObj) return [];
    if (!questionObj.input) return [questionObj];

    var conditionalItems = [questionObj];
    var value = questionAnswers[questionObj.questionId];

    if (questionObj.input.options !== undefined) {
      questionObj.input.options.filter(function (option) {
        return value instanceof Array ? value.indexOf(option.value) > -1 : value === option.value;
      }).filter(function (option) {
        return option.conditionalQuestions !== undefined && option.conditionalQuestions.length > 0;
      }).forEach(function (option) {
        return option.conditionalQuestions.forEach(function (condQ) {
          var nestedItems = getQuestionAndConditionals(condQ, questionAnswers);
          conditionalItems = [].concat(_toConsumableArray(conditionalItems), _toConsumableArray(nestedItems));
        });
      });
    }
    return conditionalItems;
  }

  return questions.reduce(function (acc, question) {
    return [].concat(_toConsumableArray(acc), _toConsumableArray(getQuestionAndConditionals(question)));
  }, []);
}

var QuestionSet = (function (_React$Component) {
  _inherits(QuestionSet, _React$Component);

  function QuestionSet() {
    _classCallCheck(this, QuestionSet);

    _get(Object.getPrototypeOf(QuestionSet.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(QuestionSet, [{
    key: 'render',
    value: function render() {
      var _this = this;

      var _props = this.props;
      var validationErrors = _props.validationErrors;
      var questionSet = _props.questionSet;
      var questionAnswers = _props.questionAnswers;
      var onAnswerChange = _props.onAnswerChange;
      var onQuestionBlur = _props.onQuestionBlur;
      var noRenderHelp = _props.noRenderHelp;

      // Determine all the questions that should be shown on the page
      var availableQuestions = getAvailableQuestions(questionSet.questions, questionAnswers);

      // Create the relevant info for each question
      var renderObjs = availableQuestions.map(function (question) {
        var getQuestionProps = function getQuestionProps() {
          return {
            validationErrors: validationErrors,
            question: question,
            value: questionAnswers[question.questionId],
            questionAnswers: questionAnswers,
            onAnswerChange: onAnswerChange,
            onQuestionBlur: onQuestionBlur
          };
        };

        return {
          getQuestionProps: getQuestionProps,
          question: question
        };
      });

      /*
       * If the user of this component wants the array of question render objects, that call the render prop once with
       * the array as the only prop.  The user can then loop through the array to show the children.
       * If they do not want all of the questions at once, then call the render prop for each child. 
       */

      var element = undefined;
      if (noRenderHelp) {
        element = this.props.children({
          questions: renderObjs
        });
      } else {
        element = renderObjs.map(function (obj) {
          var element = _this.props.children({
            getQuestionProps: obj.getQuestionProps,
            question: obj.question
          });
          return _react2['default'].cloneElement(element, { key: obj.question.questionId });
        });
      }

      return element;
    }
  }]);

  return QuestionSet;
})(_react2['default'].Component);

;

QuestionSet.defaultProps = {
  questionSet: {
    questionSetHeader: undefined,
    questionSetText: undefined,
    questions: []
  },
  questionAnswers: {},
  validationErrors: {},
  noRenderHelp: false,
  onAnswerChange: function onAnswerChange() {},
  onQuestionBlur: function onQuestionBlur() {}
};

exports['default'] = QuestionSet;
module.exports = exports['default'];