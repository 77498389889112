import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import { IconContext } from 'react-icons';
import { FaSearch } from 'react-icons/fa';
import { Form } from 'react-bootstrap';

const customStyles = props => ({
  option: (styles) => ({
    ...styles,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: props.isInvalid ? '#dc3545' : state.isFocused ? '#80bdff' : '#bbbcc6',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    '&:hover': {
      borderColor: props.isInvalid ? '#dc3545' : '#80bdff'
    }
  })
})

const defaultAdditionalProps = {
  pageNum: 0,
  pageSize: 50
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconContext.Provider value={{ color: '#485cc7' }}>
        <FaSearch />
      </IconContext.Provider>
    </components.DropdownIndicator>
  )
}

const SearchSelect = ({ additional = {}, isInvalid, errorMessage, ...props }) => {  
  return (
    <React.Fragment>
      <AsyncPaginate
        {...props}
        additional={{ ...defaultAdditionalProps, additional }}
        styles={customStyles({isInvalid})}
        components={{ DropdownIndicator }}
        classNamePrefix="search-component"
      />
      <Form.Control.Feedback type="invalid" style={isInvalid ? { display: 'block' } : null}>
        {errorMessage}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default SearchSelect;
