import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import Admins from './admins/Admins.js';
import SponsoredOrganizations from './collaboration/SponsoredOrganizations.js';

export class BottomTabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { canSponsor } = this.props;

    return (
      <Tab.Container id="organization-tabs" defaultActiveKey="admins">

        <Nav variant="tabs" className="mt-5">

          <Nav.Item className="w-25 text-center">
            <Nav.Link eventKey="admins">Admins</Nav.Link>
          </Nav.Item>

          {canSponsor &&
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="sponsoredOrganizations">Sponsors</Nav.Link>
            </Nav.Item>
          }

        </Nav>

        <Tab.Content className="basic-card">

          <Tab.Pane eventKey="admins">
            <Admins organizationId={this.props.organizationId} />
          </Tab.Pane>

          {canSponsor &&
            <Tab.Pane eventKey="sponsoredOrganizations">
              <SponsoredOrganizations organizationId={this.props.organizationId} />
            </Tab.Pane>
          }

        </Tab.Content>

      </Tab.Container>
    );
  }
}

export default BottomTabs;
