import ClientMeasureDataDto from "../Measure/ClientMeasureDataDto";
import ProgramMeasureDataDto from "../Measure/ProgramMeasureDataDto";

export default class MeasureHistoryDto {
  constructor(options = {}) {
    this.clientId = options.clientId || 0;
    this.programId = options.programId || 0;
    this.measureId = options.measureId || 0;
    this.pageNum = options.pageNum || 0;
    this.pageSize = options.pageSize || 0;
    this.totalMeasureDataCount = options.totalMeasureDataCount || 0;
    this.clientMeasureDataList = options.clientMeasureDataList.map(dto => new ClientMeasureDataDto(dto)) || [];
    this.programMeasureDataList = options.programMeasureDataList.map(dto => new ProgramMeasureDataDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
