export default class ClientRelationshipDto {
  constructor(options = {}) {
    this.clientId = options.clientId || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.relationship = options.relationship || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}