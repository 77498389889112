'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x2, _x3, _x4) { var _again = true; _function: while (_again) { var object = _x2, property = _x3, receiver = _x4; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x2 = parent; _x3 = property; _x4 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _libValidation = require('./lib/validation');

var _libValidation2 = _interopRequireDefault(_libValidation);

var _libUtils = require('./lib/utils');

var Tabs = (function (_Component) {
  _inherits(Tabs, _Component);

  function Tabs() {
    _classCallCheck(this, Tabs);

    _get(Object.getPrototypeOf(Tabs.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(Tabs, [{
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      // Update if not the same panel or there is an error
      return nextProps.currentActivePanelId !== this.props.currentActivePanelId || nextProps.validationErrors !== this.props.validationErrors;
    }
  }, {
    key: 'buildTabs',
    value: function buildTabs(schema, currentActivePanelId, validationErrors) {
      var _this = this;

      var formPanels = schema.formPanels;
      var questionPanels = schema.questionPanels;

      return formPanels.map(function (fp) {
        var panelId = fp.panelId;

        var questionPanel = questionPanels.find(function (panel) {
          return panel.panelId === panelId;
        });
        return {
          panelId: panelId,
          text: questionPanel.tabText || 'Panel ' + fp.index,
          active: panelId === currentActivePanelId,
          isInvalid: !_libValidation2['default'].isPanelValid(validationErrors[panelId]),
          getProps: _this.createGetterProps({
            onClick: _this.handleTabClick.bind(_this, panelId),
            onMouseDown: _this.onMouseDown
          })
        };
      });
    }
  }, {
    key: 'createGetterProps',
    value: function createGetterProps(options) {
      return function () {
        var props = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        return {
          onClick: (0, _libUtils.callAll)(props.onClick, options.onClick),
          onMouseDown: (0, _libUtils.callAll)(props.onMouseDown, options.onMouseDown)
        };
      };
    }
  }, {
    key: 'onMouseDown',
    value: function onMouseDown(e) {
      e.preventDefault();
    }
  }, {
    key: 'handleTabClick',
    value: function handleTabClick(panelId) {
      if (panelId !== this.props.currentActivePanelId) {
        this.props.onSwitchPanel(panelId);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props;
      var schema = _props.schema;
      var currentActivePanelId = _props.currentActivePanelId;
      var validationErrors = _props.validationErrors;

      var tabs = this.buildTabs(schema, currentActivePanelId, validationErrors);

      return tabs.map(function (tab, i) {
        var child = _this2.props.children(tab, i);
        return _react2['default'].cloneElement(child, { key: 'tab-' + i });
      });
    }
  }]);

  return Tabs;
})(_react.Component);

Tabs.defaultProps = {
  schema: {
    formPanels: [],
    questionPanels: []
  },
  currentActivePanelId: '',
  validationErrors: {},
  onSwitchPanel: function onSwitchPanel() {}
};

exports['default'] = Tabs;
module.exports = exports['default'];