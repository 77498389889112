import ImportResult from './ImportResult';

export default class ImportResultsDto {
  constructor(options = {}) {
    this.results = options.results ? options.results.map(dto => new ImportResult(dto)) : [];
    this.hasErrors = options.hasErrors || false;
    this.totalProcessed = options.totalProcessed || 0;
    this.totalImported = options.totalImported || 0;
    this.errorCount = options.errorCount || 0;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
