import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../shared/RouterShim';

import stopImpersonate from '../../images/StopImpersonate.png';
import { message } from './Message';
import ActionIcon from './ActionIcon.js';
import { actionCreators } from '../../store/User';

import api from '../../scripts/api';

export class ImpersonationIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      effectiveOrganizationName: ''
    }
  }

  updateOrganizationName(orgId) {
    api.getOrganizationById(orgId)
      .then(result => {
        this.setState({
          effectiveOrganizationName: result.organizationName
        });
      })
      .catch(err => {
        message.error(`Error getting impersonated organization: ${err}`);
      });
  }

  componentDidUpdate(prevProps) {
    const currentOrgId = this.props.effectiveOrganizationId;
    if (prevProps.effectiveOrganizationId !== currentOrgId) {
      if (this.isValidOrgId(currentOrgId)) {
        this.updateOrganizationName(currentOrgId);
      } else {
        this.setState({
          effectiveOrganizationName: ''
        });
      }
    }
  }

  componentDidMount() {
    const orgId = this.props.effectiveOrganizationId;

    if (this.isValidOrgId(orgId)) {
      this.updateOrganizationName(orgId);
    }
  }

  isValidOrgId(orgId) {
    if (!orgId || isNaN(orgId)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { effectiveOrganizationName } = this.state;

    if (!effectiveOrganizationName) {
      return null;
    } else {
      return (
        <React.Fragment>
          <div className="d-inline-flex justify-content-end mr-3 mb-2 d-print-none">
            <div className="d-flex">
              Impersonating {effectiveOrganizationName}
              <ActionIcon
                src={stopImpersonate}
                toolTipClassName="pl-2"
                toolTipText="Stop Impersonating"
                toolTipPlacement="bottom"
                onClick={(e) => {
                  this.props.stopImpersonating();
                  this.props.history.push('/Organization');
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(connect(
  state => ({ effectiveOrganizationId: state.user.effectiveOrganizationId }),
  dispatch => bindActionCreators(actionCreators, dispatch)
)(ImpersonationIndicator));
