import React, { useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FieldArray, Field } from 'formik';
import { TextInput, RadioButtonGroupInput, RadioButtonInput, SelectInput } from '../../shared/inputs/index';
import { inputTypes, keywordCategoryDisplayNames } from '../../../constants';
import add from '../../../images/Add.png';

import api from '../../../scripts/api';
import { message } from '../../shared/Message';

import MultiSelect from '../../shared/asyncSelect/MultiSelect';

const OptionInputEditor = ({ inputInfo, values, handleChange, touched, errors, setFieldValue }) => {
  const options = values.options || [];

  const { userDefined, type, canDelete } = values;

  const [sponsoringOrgs, setSponsoringOrgs] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState(values.sharedSponsorIds);
  const [pageSize, setPageSize] = useState(50);
  const [pageNum, setPageNum] = useState(50);

  const searchOrgs = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
    let result;
    try {
      result = await api.getSponsoringOrganizationsAsync(searchTerm, pageNum, pageSize);
    } catch (err) {
      message.error(`Error retrieving Partner Organizations: ${err}`);
      return { options: [] };
    }

    const searchResults = result.searchResults.map(org => ({ value: org.id, label: org.name }));
    const hasMore = result.totalMatches > pageNum * pageSize + searchResults.length;

    return {
      options: searchResults,
      hasMore: hasMore,
      additional: {
        pageNum: pageNum + 1, //next page
        pageSize: pageSize
      }
    };
  }

  const handleSelectOrgs = (selected) => {
    // selected will be sent as null instead of an empty array when the user backspaces away all search text and every selection
    const tempOrgs = selected ? selected : [];
    setSelectedOrgs(tempOrgs);
    setFieldValue('sharedSponsorIds', tempOrgs);
  }

  // Determine the the fields needed for letting the user create the options for the input
  let optionFields;
  if (values.usesOwnOptions === 'yes') {
    optionFields = (
      <Form.Group as={Col}>
        <Form.Label>Type the options</Form.Label>
        <FieldArray name="options">
          {arrayHelpers => (
            <React.Fragment>
              {options.map((option, index) => (
                <Form.Group as={Row} key={index}>
                  <Col xs={8}>
                    <Field name={`options.${index}`}>
                      {({ field }) => (
                        <TextInput
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col xs={4} className="d-flex align-items-center">
                    <a className="info-link" onClick={() => arrayHelpers.remove(index)}>
                      Remove
                    </a>
                  </Col>
                </Form.Group>
              ))}
              <Form.Group as={Row}>
                <Col>
                  <span
                    className="d-flex align-items-center input-editor__text--subtle cursor-pointer"
                    onClick={() => arrayHelpers.push('')}
                  >
                    <img src={add} alt="Add option" />
                    <span className="ml-2">Add {options.length > 0 ? 'another' : ''} option</span>
                  </span>
                </Col>
              </Form.Group>
            </React.Fragment>
          )}
        </FieldArray>
      </Form.Group>
    );
  } else if (values.usesOwnOptions === 'no') {
    // Create the options the user can choose from
    const options = Object.entries(keywordCategoryDisplayNames).map(entry => ({ text: entry[1], value: entry[0] }));
    options.unshift({ text: '', value: '' });

    optionFields = (
      <Form.Group as={Col}>
        <Form.Label>Select from the list below</Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              Common Dropdowns
          </InputGroup.Text>
          </InputGroup.Prepend>
          <SelectInput
            name="optionsCategory"
            value={values.optionsCategory}
            onChange={handleChange}
            options={options}
          />
        </InputGroup>
      </Form.Group>
    );
  }


  // Determine the fields needed for the label and placeholder text (select) or the question text (checkbox group)
  let informationFields;
  if (inputInfo.type === inputTypes.SELECT) {
    informationFields = (
      <React.Fragment>
        <Row>
          <Form.Group as={Col} xs={8}>
            <Form.Label>Label Text</Form.Label>
            <TextInput
              name="label"
              value={values.label}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs={8}>
            <Form.Label>Placeholder Text</Form.Label>
            <TextInput
              name="placeholder"
              value={values.placeholder}
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
      </React.Fragment>
    );
  } else {
    informationFields = (
      <Row>
        <Form.Group as={Col} xs={8}>
          <Form.Label>Question Text</Form.Label>
          <TextInput
            name="question"
            value={values.question}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
    );
  }



  return (
    <React.Fragment>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Do you want to use a pre-made dropdown (for example, a country list) or create your own?</Form.Label>
          <RadioButtonGroupInput
            wrapClasses="d-flex flex-wrap"
            error={errors.required}
            touched={touched.required}
          >
            <RadioButtonInput
              name="usesOwnOptions"
              id="usesOwnOptions-no"
              value="no"
              onChange={handleChange}
              checked={values.usesOwnOptions === 'no'}
              className="input-editor__radio-option"
              label="Pre-made list"
            />

            {/* This is added to prevent specific fields from being customized */}
            { userDefined === true && canDelete === true && type !== 'checkboxGroupInput' && type !== 'selectInput' &&
              <RadioButtonInput
                name="usesOwnOptions"
                id="usesOwnOptions-yes"
                value='yes'
                onChange={handleChange}
                checked={values.usesOwnOptions === 'yes'}
                className="input-editor__radio-option"
                label="Create my own"
              />           
            }
          </RadioButtonGroupInput>

          {informationFields}

          <Row>
            {optionFields}
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>Is the question required?</Form.Label>

              <RadioButtonGroupInput
                wrapClasses="d-flex flex-wrap"
                error={errors.required}
                touched={touched.required}
              >
                <RadioButtonInput
                  name="required"
                  id="required-yes"
                  value="yes"
                  onChange={handleChange}
                  checked={values.required === 'yes'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="Yes"
                />
                <RadioButtonInput
                  name="required"
                  id="required-no"
                  value='no'
                  onChange={handleChange}
                  checked={values.required === 'no'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="No"
                />
              </RadioButtonGroupInput>

            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>Is this personal identifiable information (PII)?</Form.Label>
              <RadioButtonGroupInput
                wrapClasses="d-flex flex-wrap"
                error={errors.isPII}
                touched={touched.isPII}
              >
                <RadioButtonInput
                  name="isPII"
                  id="isPII-yes"
                  value="yes"
                  onChange={handleChange}
                  checked={values.isPII === 'yes'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="Yes"
                />
                <RadioButtonInput
                  name="isPII"
                  id="isPII-no"
                  value='no'
                  onChange={handleChange}
                  checked={values.isPII === 'no'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="No"
                />

              </RadioButtonGroupInput>

            </Form.Group>
          </Row>

        {/*<Row>
            <Form.Group as={Col}>
              <Form.Label>Share this information with partners?</Form.Label>
              <RadioButtonGroupInput
                wrapClasses="d-flex flex-wrap"
                error={errors.shareSponsors}
                touched={touched.shareSponsors}
              >
                <RadioButtonInput
                  name="shareSponsors"
                  id="shareSponsors-yes"
                  value="yes"
                  onChange={handleChange}
                  checked={values.shareSponsors === 'yes'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="Yes"
                />
                <RadioButtonInput
                  name="shareSponsors"
                  id="shareSponsors-no"
                  value='no'
                  onChange={handleChange}
                  checked={values.shareSponsors === 'no'}
                  className="input-editor__radio-option input-editor__radio-option--small"
                  label="No"
                />

              </RadioButtonGroupInput>

            </Form.Group>
          </Row>

          {values.shareSponsors === 'yes'
            ? (
              <React.Fragment>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label>Add one or more Partner Organizations</Form.Label>
                    <Col className="px-0">
                      <MultiSelect
                        name="sharedSponsorIds"
                        id="sharedSponsorIds"
                        placeholder="Search"
                        defaultValue={selectedOrgs}
                        closeMenuOnSelect={false}
                        onChange={handleSelectOrgs}
                        loadOptions={searchOrgs}
                        additional={{ pageNum: pageNum, pageSize: pageSize }}
                      />
                    </Col>
                  </Form.Group>
                </Row>

              </React.Fragment>)
            : null
          }*/}

        </Form.Group>
      </Row>

    </React.Fragment>
  );
}

export default OptionInputEditor;
