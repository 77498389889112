import React, { Component } from 'react';
import { withRouter } from '../../shared/RouterShim';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import PickListSelect from '../../shared/asyncSelect/PickListSelect';
import WarningModal from '../../shared/WarningModal';

class AssignStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  
    this.confirmUpdate = this.confirmUpdate.bind(this);
  }

  confirmUpdate() {
    this.setState({ showModal: false });

    this.props.handleSaveStaff();
  }

  render() {
    const { defaultStaff, availableStaff, handleCancelClick, handleAddToSelected, handleRemoveFromSelected} = this.props;

    return (
      <React.Fragment>
        <Row className="mb-2">
          <Col>
            Add/remove staff for this manager (currently selected): {defaultStaff.length}
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <PickListSelect
              target={defaultStaff}
              source={availableStaff}
              addToTarget={handleAddToSelected}
              removeFromTarget={handleRemoveFromSelected}
            />
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-end'>
            <ButtonToolbar>
              <Button variant="outline-primary" onClick={() => handleCancelClick()}>Cancel</Button>
              <Button variant="primary" onClick={() => this.setState({ showModal: true })}>Save Staff</Button>
            </ButtonToolbar>
          </Col>
        </Row>

        { this.state.showModal && 
          <WarningModal
            show={this.state.showModal}
            onCancel={() => this.setState({ showModal: false })}
            title={'Confirm Staff Assignment'}
            onConfirm={this.confirmUpdate}
          >
            Are you sure that you want to make these changes to the staff assigned to this staff member?
          </WarningModal>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(AssignStaff);
