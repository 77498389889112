import * as yup from 'yup';

export const editServiceActivitySchema = (requireClients) => {
    let schema = {
        HrsOrMins: yup.bool()   // testing for combination of Hours or Minutes being selected
            .when(['durationHours', 'durationMinutes'],
                {
                    is: (durationHours, durationMinutes) => (!durationHours && !durationMinutes),
                    then: yup.bool().required('Hours or minutes required'),
                    otherwise: yup.bool()
                })
            .test('valid-hour-minute-combination', 'Hour and minute duration cannot exceed 24 hours.', function (value) {
                const durationHours = parseInt(this.parent.durationHours, 10);
                const durationMinutes = parseInt(this.parent.durationMinutes, 10);
                if (durationHours === 24 && durationMinutes > 0) {
                    return false;
                } else {
                    return true;
                }
            }),
      activityDatetimeLocal: yup.date()
            .typeError('Service Date is not a valid date')
            .required('Service Date is required')
            .max(new Date(), 'Activity may not be in the future'),
        applicationUserId: yup.number()
            .required('A user must be assigned to this Service Activity'),
    };

    if (requireClients) {
        schema.additionalClients = yup.array().required('You must select one or more clients');
    }

    return yup.object(schema);
}
