import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';

import DisplayService from './DisplayService';
import EditService from './EditService';
import BottomTabs from './BottomTabs';
import api from '../../scripts/api';
import { message } from '../shared/Message';
import OrganizationServiceDto from '../../DTOs/OrganizationService/OrganizationServiceDto';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants';
import { hasRequiredPermissions } from '../../scripts/permissionHelper.js';

const editPermissions = {
  rolePermission: rolePermissionLevels.ORG_ADMIN,
  licenseLevelPermission: [licenseLevels.FREE, licenseLevels.PAID],
  licenseTypePermission: licenseTypes.SPONSOR
};

class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: new OrganizationServiceDto(),
      editAction: 'Edit',
      apiResult: false,
      apiError: null,
      showEdit: false
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.editServiceSubmit = this.editServiceSubmit.bind(this);
  }

  handleEditClick() {
    this.setState({ showEdit: !this.state.showEdit });
  }

  editServiceSubmit(values, actions) {
    message.dismissAll();
    const dto = new OrganizationServiceDto({
      ...values,
      isDisabled: !values.isActive,
      isSponsored: false
    });

    api.updateService(dto.stringify()).then(response => {
      this.setState({ service: response, showEdit: !this.state.showEdit });
    }).catch(err => {
      console.log(`Error submitting organization service: ${err}`);
      actions.setSubmitting(false);
      message.error(`Error submitting organization service: ${err}`);
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    api.getOrganizationServiceById(id).then(result => {
      this.setState({ service: result });
    }).catch(err => {
      console.log(`Error getting organization service: ${err}`);
      message.error(`Error getting organization service: ${err}`);
    });
  }

  render() {
    const { service, editAction, showEdit } = this.state;
    const { userPermissions } = this.props;

    return (
      <React.Fragment>
        <Container className="basic-card pb-3">

          {showEdit
            ? <EditService
              service={service}
              editAction={editAction}
              redirect={false}
              handleCancelEditClick={this.handleEditClick}
              handleFormSubmit={this.editServiceSubmit}
            />
            : <DisplayService service={service} handleEditClick={this.handleEditClick} />
          }

        </Container>

        {hasRequiredPermissions(editPermissions, userPermissions) &&
          <BottomTabs service={service} />
        }

      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ userPermissions: state.user.permissions })
)(Service);
