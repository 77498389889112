import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { downloadFile } from '../../scripts/api';
import { message } from '../shared/Message';
import IndexActions from '../shared/indexList/IndexActions';
import StaffList from './lists/StaffList.js';

class StaffIndex extends Component {
  constructor(props) {
    super(props);

    this.handleAddStaffClick = this.handleAddStaffClick.bind(this);
    this.export = this.export.bind(this);
  }

  handleAddStaffClick() {
    this.props.history.push('/Staff/AddStaff');
  }

  async export() {
    try {
      await downloadFile(`export/staff`);
    } catch (err) {
      message.error(err.message);
    }
  }

  render() {
    return (
      <React.Fragment>
        <IndexActions>
          <ButtonToolbar className="d-flex justify-content-end">
            <Button variant="outline-primary" onClick={this.export}>Export All Staff Data</Button>
            <Button variant="primary" onClick={this.handleAddStaffClick}>Add Staff</Button>
          </ButtonToolbar>
        </IndexActions>

        <Tab.Container defaultActiveKey="activeStaff">
          <Nav variant="tabs">
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="activeStaff">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="inactiveStaff">Inactive</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="activeStaff">
              <StaffList showActive />
            </Tab.Pane>
            <Tab.Pane eventKey="inactiveStaff">
              <StaffList />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </React.Fragment>
    );
  }
}

export default StaffIndex;
