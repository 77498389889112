export default class SponsoredOrganizationUpdateDto {
  constructor(options = {}) {
    this.sponsorId = options.sponsorId || 0;
    this.id = options.id || 0;
    this.name = options.name || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}