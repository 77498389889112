import React from 'react';
import { Modal, Col, Row, Button, Spinner } from 'react-bootstrap';


const WarningModal = ({ show, title, onCancel, onConfirm, confirmText, cancelText, ...props }) => {
  confirmText = confirmText || 'Confirm';
  cancelText = cancelText || 'Cancel';

  return (
    <Modal
      show={show}
      centered
      dialogClassName="modal--confirmation"
      onHide={onCancel}
    >
      <Modal.Header className="modal__header">
                <span>{title}</span>
                <span
                    className="modal__header__btn"
                    onClick={onCancel}
                    aria-label='Exit'
                >
                    X
                </span>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex justify-content-center">
                    {props.isLoading ?
                        <Spinner animation='border' style={{ textAlign: "center" }} />
                        :
                        <p style={{ textAlign: "center" }}>
                          {props.children}
                        </p>
                    }
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={onCancel}>{cancelText}</Button>
                <Button variant="primary" onClick={onConfirm}>{confirmText}</Button>
            </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
