import ComparisonMeasureDto from './ComparisonMeasureDto';
import CountMeasureDto from './CountMeasureDto';
import SuccessMeasureDto from './SuccessMeasureDto';

export default class OutcomeSummaryDto {
  constructor(options = {}) {
    this.measureNames = options.measureNames || [];
    this.comparisonMeasures = options.comparisonMeasures ? options.comparisonMeasures.map(dto => new ComparisonMeasureDto(dto)) : [];
    this.countMeasures = options.countMeasures ? options.countMeasures.map(dto => new CountMeasureDto(dto)) : [];
    this.successMeasures = options.successMeasures ? options.successMeasures.map(dto => new SuccessMeasureDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
