import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { inputTypes } from '../../../constants';
import { TextInput } from '../../shared/inputs/index';


const InfoInputEditor = ({ inputInfo, values, handleChange, touched, errors }) => {

  if (inputInfo.type === inputTypes.RELATIONSHIPS) {
    return null;
  }

  return (
    <React.Fragment>
      <Row>
        <Form.Group as={Col} xs={8}>
          <Form.Label>Text</Form.Label>
          <TextInput
            name="question"
            value={values.question}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
    </React.Fragment>
  );
}

export default InfoInputEditor;