'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _survey = require('./survey');

var _survey2 = _interopRequireDefault(_survey);

var _questionPanel = require('./questionPanel');

var _questionPanel2 = _interopRequireDefault(_questionPanel);

var _questionSet = require('./questionSet');

var _questionSet2 = _interopRequireDefault(_questionSet);

var _question = require('./question');

var _question2 = _interopRequireDefault(_question);

var _buttons = require('./buttons');

var _tabs = require('./tabs');

var _tabs2 = _interopRequireDefault(_tabs);

_survey2['default'].QuestionPanel = _questionPanel2['default'];
_survey2['default'].QuestionSet = _questionSet2['default'];
_survey2['default'].Question = _question2['default'];
_survey2['default'].MainButton = _buttons.MainButton;
_survey2['default'].BackButton = _buttons.BackButton;
_survey2['default'].Tabs = _tabs2['default'];

exports['default'] = _survey2['default'];
module.exports = exports['default'];