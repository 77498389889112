import formatDisplayNumber from './formatDisplayNumber';

const formatDisplayCurrency = (value, symbol = '$') => {
  if (value) {
    return `${symbol}${formatDisplayNumber(value)}`;
  }

  return '';
};

export default formatDisplayCurrency;