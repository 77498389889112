import hasRequiredRoleLevel  from './rolePermissionHelpers.js'
import { licenseLevels, licenseTypes } from '../../constants.js';

const hasRequiredPermissions = (requiredPermissions, currentPermissions) => {
  const licenseLevelPassed = licenseLevelEqual(requiredPermissions.licenseLevelPermission,
    currentPermissions.licenseLevelPermission);
  const licenseTypePassed = licenseTypeEqual(requiredPermissions.licenseTypePermission,
    currentPermissions.licenseTypePermission);
  const rolePermissionPassed = hasRequiredRoleLevel(requiredPermissions.rolePermission,
    currentPermissions.rolePermission);

  return (licenseLevelPassed && licenseTypePassed && rolePermissionPassed);
}

const licenseLevelEqual = (required, current) => {
  if (Array.isArray(required)) {
    return required.includes(current);
  }

  if (required === licenseLevels.FREE && current === licenseLevels.PAID) {
    return true;
  }

  return required === current;
}

const licenseTypeEqual = (required, current) => {
  if (current === licenseTypes.SPONSOR && required === licenseTypes.INDIVIDUAL) {
    return true;
  }

  if (Array.isArray(required)) {
    return required.includes(current);
  }

  return required === current;
}

export default hasRequiredPermissions;
