import ClientDto from '../DTOs/Client/ClientDto';
import ClientRelationshipDto from '../DTOs/Client/ClientRelationshipDto';

/*
 * Contains functions to transform one object into another object
 */
const transform = {

  /**
   * Transforms a clientDto object to a a demographics object.  Useful for when a client does not have
   * a demographics object, so the information should be deduced from the clientDto object.
   * 
   * @param {ClientDto} clientDto a clientDto object
   * @returns {Object} a valid demographics object that can be used for the demographics component.
   */
  clientDtoToDemographicsJson(clientDto) {
    if (!clientDto) return {};

    let result = {};

    if (!clientDto.demographicsJson) {
      // Go through each property in the client dto and add it to the result object.
      // If the object is a Keyword Dto, we only need the value
      const mappedProperties = Object.keys(clientDto)
        .filter(prop => ClientDto.DemographicNonMappedProperties.indexOf(prop) < 0);
      mappedProperties.forEach(prop => {
        result[prop] = clientDto[prop];
      });
    } else {
      result = { ...clientDto.demographicsJson };
    }

    // We need to add properties that are not stored in the demographics json but are needed for the Winterfell demographics component
    result.relationships = clientDto.relationships;

    return result;
  },

  /**
   * Transforms a demographics object to a ClientDto object.  Useful for when you need to create
   * a client's information from a demographics object to save/update the client
   *
   * @param {Object} demographicsJson a demographics object
   * @param {Object} schema the Winterfell schema for Client data
   * @param {ClientDto} clientDto an optional parameter so the returned ClientDto has extra properties from the passed in ClientDto
   * @returns {ClientDto} a valid ClientDto object based on the values from the demographics object
   */
  demographicsJsonToClientDto(demographicsJson, schema, keywords, clientDto = null) {
    clientDto = clientDto || {};
    // It's faster to add the properties on one by one afterwards
    const result = new ClientDto(demographicsJson);
    result.id = clientDto.id;
    result.organizationId = clientDto.organizationId;
    result.demographicsJson = demographicsJson;
    result.reportJson = this.getReportJson(demographicsJson, schema, keywords);
    return result;
  },


  /**
   * Transforms a ClientDto object and a AddClientRelationshipDto object into a ClientRelationshipDto object
   * 
   * @param {any} clientDto the clientDto object containing the information about the client
   * @param {any} addClientRelationshipDto the addClientRelationshipDto object containing at least the relationship value
   * @returns {ClientRelationshipDto} a valid clientRelationshipDto object
   */
  clientDtoToClientRelationshipDto(clientDto, addClientRelationshipDto) {
    return new ClientRelationshipDto({
      clientId: clientDto.id,
      firstName: clientDto.firstName,
      lastName: clientDto.lastName,
      relationship: addClientRelationshipDto.relationship
    });
  },

  /**
   * Transforms the Winterfell answers object (demographicsJson) into a human readable (reportJson) object
   *
   * @param {Object} demographicsJson a demographics object
   * @param {Object} schema the Winterfell schema for Client data
   * @returns {Object} a human readable (reportJson) object
   */
  getReportJson(demographicsJson, schema, keywords) {
    const reportJson = {};
    const allQuestions = [];

    // if somehow we don't have any answers, return back an empty object
    if (demographicsJson.length === 0) {
      return reportJson;
    }

    schema.questionSets.forEach(qs => allQuestions.push(...qs.questions));

    const answerKeys = Object.keys(demographicsJson);

    answerKeys.forEach(key => {
      const question = allQuestions.find(q => q.questionId === key);

      if (question) {
        const reportName = (question.meta && question.meta.reportName) ? question.meta.reportName : null;
        const usesKeywords = question.input.props && question.input.props.category;
        const standardRelationships = question.input.type === 'relationshipsInput';
        const saveAsBoolean = question.meta && question.meta.saveAsBoolean;

        let answerVal = '';
        if (usesKeywords) {
          // demographics value uses keyword type data
          answerVal = this.getAnswerKeyword(reportName, demographicsJson[key], keywords, question.input.type !== 'selectInput');
        } else if (standardRelationships) {
          // demographics value is a "relationships" type answer (complex object)
          answerVal = demographicsJson[key];
        } else if (Array.isArray(demographicsJson[key])) {
          // demographics value is an array (uses checkbox input type)
          answerVal = [];
          demographicsJson[key].forEach(elem => answerVal.push(String(elem)));
        } else if (demographicsJson[key]) {
          // demographics value is a simple value (select, integer, date, etc)
          // cast the value to string to insure we don't have issues with Snowflake
          answerVal = saveAsBoolean
            ? demographicsJson[key]
            : String(demographicsJson[key]);
        }

        if (reportName) {
          reportJson[reportName] = answerVal;
        } else {
          reportJson[key] = answerVal;
        }
      }
    });

    return reportJson;
  },

  getAnswerKeyword(reportName, selectedKeywords, allKeywords, multiValue) {
    let values = selectedKeywords;

    if (!values) {
      return null;
    } else if (!Array.isArray(values)) {
      values = [values];
    }

    let retVal = values.reduce((acc, elem) => {
      const integerElem = parseInt(elem, 10); // single value selections have string id values
      const kwVal = allKeywords.filter(kw => kw.id === integerElem);

      if (kwVal.length === 0) {
        return acc;
      }

      const valueObj = {};

      valueObj[`${reportName}Id`] = integerElem;
      valueObj[`${reportName}Value`] = kwVal[0].text;

      return acc.concat(valueObj);
    }, []);

    return multiValue ? retVal : retVal[0];
  }
}

export default transform;