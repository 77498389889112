import OrganizationServiceDto from './OrganizationServiceDto';

export default class OrganizationServiceListDto {
  constructor(options = {}) {
    this.allServices = options.allServices.map(dto => new OrganizationServiceDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}