'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x3, _x4, _x5) { var _again = true; _function: while (_again) { var object = _x3, property = _x4, receiver = _x5; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x3 = parent; _x4 = property; _x5 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _libUtils = require('./lib/utils');

var onMouseDown = function onMouseDown(e) {
  e.preventDefault();
};

var MainButton = (function (_Component) {
  _inherits(MainButton, _Component);

  function MainButton() {
    _classCallCheck(this, MainButton);

    _get(Object.getPrototypeOf(MainButton.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(MainButton, [{
    key: 'render',
    value: function render() {
      var _props = this.props;
      var nextAction = _props.nextAction;
      var onMainButtonClick = _props.onMainButtonClick;
      var mainButtonText = _props.mainButtonText;

      var getProps = function getProps() {
        var props = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        return {
          onClick: (0, _libUtils.callAll)(props.onClick, onMainButtonClick),
          onMouseDown: onMouseDown
        };
      };

      var getStateAndProps = function getStateAndProps() {
        return {
          text: mainButtonText,
          getProps: getProps
        };
      };

      var element = this.props.children(getStateAndProps());

      return nextAction !== undefined && nextAction !== null ? element : null;
    }
  }]);

  return MainButton;
})(_react.Component);

exports.MainButton = MainButton;

var BackButton = (function (_Component2) {
  _inherits(BackButton, _Component2);

  function BackButton() {
    _classCallCheck(this, BackButton);

    _get(Object.getPrototypeOf(BackButton.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(BackButton, [{
    key: 'render',
    value: function render() {
      var _props2 = this.props;
      var previousAction = _props2.previousAction;
      var onBackButtonClick = _props2.onBackButtonClick;
      var backButtonText = _props2.backButtonText;

      var getProps = function getProps() {
        var props = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        return {
          onClick: (0, _libUtils.callAll)(props.onClick, onBackButtonClick),
          onMouseDown: onMouseDown
        };
      };

      var getStateAndProps = function getStateAndProps() {
        return {
          text: backButtonText,
          getProps: getProps
        };
      };

      var element = this.props.children(getStateAndProps());

      return previousAction !== undefined && previousAction !== null ? element : null;
    }
  }]);

  return BackButton;
})(_react.Component);

exports.BackButton = BackButton;

BackButton.defaultProps = MainButton.defaultProps = {
  mainButtonText: 'Next Page',
  backButtonText: 'Previous Page'
};