import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const ActionIcon = (props) => {
  const { src, srcOnHover, srcOnClick, imageClassName, toolTipClassName, toolTipText, toolTipPlacement, onClick, itemId } = props;

  const toolTip = (
    <Tooltip>
      {toolTipText}
    </Tooltip>
  );

  const iconImage = (
    <div>
      <img
        height="16"
        width="16"
        src={src}
        alt={toolTipText}
        className={imageClassName}
        onMouseOver={(e) => {
          srcOnHover && (e.currentTarget.src = srcOnHover || '');
        }}
        onMouseOut={(e) => {
          srcOnHover && (e.currentTarget.src = src || '');
        }}
        onMouseDown={(e) => {
          srcOnClick && (e.currentTarget.src = srcOnClick || '');
        }}
        onClick={(e) => {
          if (itemId) {
            onClick(e, itemId);
          } else {
            onClick(e);
          }
        }}
      />
    </div>
  );

  if (!toolTipText) {
    return iconImage;
  }

  return (
    <div className={toolTipClassName}>
      <OverlayTrigger placement={toolTipPlacement} overlay={toolTip}>
        {iconImage}
      </OverlayTrigger>
    </div>
  );
}

export default ActionIcon;
