import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Component for text inputs
 */
const TextInput = (props) => {

  const value = props.value || '';

  return (
    <React.Fragment>
      <Form.Control
        type={props.type || 'text'}
        as={props.as || 'input'}
        name={props.name}
        value={value}
        label={props.label}
        placeholder={props.placeholder}
        isInvalid={props.isInvalid}
        onChange={props.onChange}
        onBlur={props.onBlur}
        style={props.style || null}
        className={props.className}
        readOnly={props.readOnly}
      />
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default TextInput;