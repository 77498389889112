import React from 'react';
import { InputGroup, Row } from 'react-bootstrap';
import { inputTypes, inputSizes } from '../../../constants';
import Question from '../../../scripts/demographicsSchema/Question';
import OptionQuestion from '../../../scripts/demographicsSchema/OptionQuestion';
import * as Inputs from '../../shared/inputs/index';

const noOp = () => { }
const LABEL_TEXT = 'Label Text';

const withNoPointerEvents = WrappedComponent => {
  return props => {
  
    return (
      <div className="position-relative">
        <div className="no-pointer-events__block" />
        <WrappedComponent {...props} />
      </div>
    );
  }
}

const withLabel = WrappedComponent => {
  return props => {

    if (!props.label)
      return <WrappedComponent {...props} />

    return (
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            {props.label}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <WrappedComponent {...props} />
      </InputGroup>
    );
  }
}


/****** Display Inputs ******/

export const SelectInputDisplay = withLabel(({ options }) => {
  return (
    <Inputs.SelectInput
      value=""
      className="display-only"
      onChange={noOp}
      options={options}
      readOnly
    />
  );
});

export const CheckboxGroupInputDisplay = ({ question }) => {
  const options = [
    { text: 'Option 1', value: '1', width: 12 },
    { text: 'Option 2', value: '2', width: 12 },
    { text: 'Option 3', value: '3', width: 12 }
  ];

  return (
    <div className="no-pointer-events">
      <div className="form-element-display__description mb-1">{question}</div>
      <Row>
        <Inputs.CheckboxGroupInput
          id="display"
          options={options}
        />
      </Row>
    </div>
  );
}

export const IntegerInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.IntegerInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const DecimalInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const PhoneNumberInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const EmailInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const TextInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const TextAreaInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextAreaInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const CurrencyInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const DateInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const PostalCodeInputDisplay = withLabel(({ placeholder }) => {
  return (
    <Inputs.TextInput
      placeholder={placeholder}
      className="display-only"
      readOnly
    />
  );
});

export const RelationshipsInputDisplay = () => {
  return (
    <div className="form-element-display__description">
      <p>This is a large component with fixed sizes and text.</p>
      <p>Allows the client to be linked to another client within the system and labeled with their relation to each other.<br />Recommended to be on its own individual page.</p>
    </div>
  );
}

export const NoInputDisplay = ({ question }) => {
  return (
    <div className="mt-2">{question}</div>
  );
};


/*************************/
export const InputsDictionary = {
  [inputTypes.SELECT]: {
    display: withNoPointerEvents(SelectInputDisplay),
    name: 'Dropdown'
  },
  [inputTypes.CHECKBOX_GROUP]: {
    display: withNoPointerEvents(CheckboxGroupInputDisplay),
    name: 'Checkbox Group'
  },
  [inputTypes.INTEGER]: {
    display: withNoPointerEvents(IntegerInputDisplay),
    name: 'Integer',
    validations: [
      {
        type: 'isNumber',
        message: 'Value must be a number'
      }
    ]
  },
  [inputTypes.DECIMAL]: {
    display: withNoPointerEvents(DecimalInputDisplay),
    name: 'Decimal'
  },
  [inputTypes.PHONE_NUMBER]: {
    display: withNoPointerEvents(PhoneNumberInputDisplay),
    name: 'Phone #',
    validations: [
      {
        type: 'isPhoneNumber',
        message: 'Phone Number must be valid'
      }
    ]
  },
  [inputTypes.EMAIL]: {
    display: withNoPointerEvents(EmailInputDisplay),
    name: 'Email',
    validations: [
      {
        type: 'isEmail',
        message: 'Email must be valid'
      }
    ]
  },
  [inputTypes.TEXT]: {
    display: withNoPointerEvents(TextInputDisplay),
    name: 'Text Field'
  },
  [inputTypes.TEXT_AREA]: {
    display: withNoPointerEvents(TextAreaInputDisplay),
    name: 'Text Area'
  },
  [inputTypes.CURRENCY]: {
    display: withNoPointerEvents(CurrencyInputDisplay),
    name: 'Currency',
    validations: [
      {
        type: 'isNumber',
        message: 'value must be a number'
      },
      {
        type: 'isDecimalLength',
        message: 'Whole number part must be less than 16 digits',
        params: [
          {
            wholeMaxDigits: 15,
            decimalMaxDigits: 2
          }
        ]
      }
    ]
  },
  [inputTypes.DATE]: {
    display: withNoPointerEvents(DateInputDisplay),
    name: 'Date',
    validations: [
      {
        type: 'isDateMMDDYYYY',
        message: 'Value must be a valid date',
        params: [
          {
            min: 0,
            max: 2000
          }
        ]
      }
    ]
  },
  [inputTypes.POSTAL_CODE]: {
    display: withNoPointerEvents(PostalCodeInputDisplay),
    name: 'Postal Code',
    validations: [
      {
        type: 'isLength',
        message: 'Value must be less than 33 characters',
        params: [
          {
            min: 0,
            max: 32
          }
        ]
      }
    ]
  },
  [inputTypes.RELATIONSHIPS]: {
    display: withNoPointerEvents(RelationshipsInputDisplay),
    name: 'Family Relation',
    staticSize: true
  },
  [inputTypes.NO_INPUT]: {
    display: withNoPointerEvents(NoInputDisplay),
    name: 'Display Text'
  }
}



export const InputsDisplay = [
  new OptionQuestion(
    {
      id: 'dropdown-library-display',
      name: 'Dropdown',
      label: LABEL_TEXT,
      type: inputTypes.SELECT,
      required: 'no',
      size: inputSizes.S,
      usesOwnOptions: 'yes',
      options: ['Option 1']
    }
  ),
  new OptionQuestion(
    {
      id: 'checkbox-group-library-display',
      name: 'Checkbox Group',
      question: 'Check All That Apply',
      type: inputTypes.CHECKBOX_GROUP,
      required: 'no',
      size: inputSizes.M,
      usesOwnOptions: 'yes',
      options: ['Option 1']
    }
  ),
  new Question(
    {
      id: 'integer-library-display',
      name: 'Integer',
      label: LABEL_TEXT,
      placeholder: '#',
      type: inputTypes.INTEGER,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.INTEGER].validations
    }
  ),
  new Question(
    {
      id: 'decimal-library-display',
      name: 'Decimal',
      label: LABEL_TEXT,
      placeholder: '#.#',
      type: inputTypes.DECIMAL,
      required: 'no',
      size: inputSizes.S,
    }
  ),
  new Question(
    {
      id: 'phone-number-library-display',
      name: 'Phone #',
      placeholder: '(###) ###-####',
      type: inputTypes.PHONE_NUMBER,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.PHONE_NUMBER].validations
    }
  ),
  new Question(
    {
      id: 'email-library-display',
      name: 'Email',
      placeholder: 'email@email.com',
      type: inputTypes.EMAIL,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.EMAIL].validations
    }
  ),
  new Question(
    {
      id: 'text-library-display',
      name: 'Text Field',
      placeholder: 'Placeholder Text',
      type: inputTypes.TEXT,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.TEXT].validations
    }
  ),
  new Question(
    {
      id: 'text-area-library-display',
      name: 'Text Area',
      placeholder: 'Placeholder Text',
      type: inputTypes.TEXT_AREA,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.TEXT_AREA].validations
    }
  ),
  new Question(
    {
      id: 'currency-library-display',
      name: 'Currency',
      placeholder: '$$$',
      type: inputTypes.CURRENCY,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.CURRENCY].validations
    }
  ),
  new Question(
    {
      id: 'date-library-display',
      name: 'Date',
      placeholder: 'mm/dd/yyyy',
      type: inputTypes.DATE,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.DATE].validations
    }
  ),
  new Question(
    {
      id: 'postal-code-library-display',
      name: 'Postal Code',
      placeholder: '#####',
      type: inputTypes.POSTAL_CODE,
      required: 'no',
      size: inputSizes.S,
      validations: InputsDictionary[inputTypes.POSTAL_CODE].validations
    }
  ),
  new Question(
    {
      id: 'display-text-library-display',
      name: 'Display Text',
      question: 'Informational Text to Display',
      type: inputTypes.NO_INPUT,
      size: inputSizes.S,
    }
  ),
  new Question(
    {
      id: 'relationships-library-display',
      name: 'Family Relation',
      type: inputTypes.RELATIONSHIPS, 
      size: inputSizes.XL,
    }
  )
];

