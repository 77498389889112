import EmbedReportDto from './EmbedReportDto';
import EmbedTokenDto from './EmbedTokenDto';
export default class EmbedParametersDto {
  constructor(options = {}) {
    this.type = options.type || '';
    this.embedReport = options.embedReport ? new EmbedReportDto(options.embedReport) : null;
    this.embedToken = options.embedToken ? new EmbedTokenDto(options.embedToken) : null;
  }
  stringify() {
    return JSON.stringify(this);
  }
}
