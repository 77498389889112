import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import * as yup from 'yup';

import EditActions from '../shared/EditActions';
import { RadioButtonGroupInput, RadioButtonInput } from '../shared/inputs/index';
import Switch from '../shared/Switch';

import { licenseLevels, licenseTypes } from '../../constants.js';

const schema = yup.object({
  organizationName: yup.string().ensure().trim()
    .required('Organization Name is required'),
  licenseType: yup.number().positive().integer()
    .required('Organization must have license type'),
  licenseLevel: yup.number().positive().integer()
    .required('Organization must have a license level')
});

export class OrganizationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '/Organization'
    }
  }

  render() {
    const { editAction, organization, redirect, handleCancelClick, handleSubmitClick, enableMfaControl } = this.props;
    const { redirectTo } = this.state;

    if (redirect) {
      return <Navigate to={redirectTo} replace />;
    }
    
    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmitClick}
        initialValues={{ ...organization }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
          isSubmitting
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-3 mb-3">
                <Col md={6}>
                  <h5>{editAction} Organization</h5>
                </Col>

                <Form.Group as={Col} md={6} className="d-flex justify-content-end">
                  <label htmlFor="isActive">Active&nbsp;</label>
                  <Switch
                    value={values.isActive}
                    name="isActive"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6}>
                  <Form.Control
                    type="text"
                    name="organizationName"
                    placeholder="Organization Name"
                    value={values.organizationName}
                    onChange={handleChange}
                    isInvalid={touched.organizationName && !!errors.organizationName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.organizationName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <Col md={3}>
                  <div>License Level</div>
                  <RadioButtonGroupInput
                    className="program-type"
                    id="licenseLevel"
                    value={values.licenseLevel}
                    error={errors.licenseLevel}
                    touched={touched.licenseLevel}
                    wrapClasses="p-1"
                  >
                    <RadioButtonInput
                      name="licenseLevel"
                      id="paidLicense"
                      label="Paid"
                      value={licenseLevels.PAID}
                      checked={values.licenseLevel === licenseLevels.PAID}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('licenseLevel', licenseLevels.PAID);
                      }}
                    />
                    <RadioButtonInput
                      name="licenseLevel"
                      id="freeLicense"
                      label="Free"
                      value={licenseLevels.FREE}
                      checked={values.licenseLevel === licenseLevels.FREE}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('licenseLevel', licenseLevels.FREE);
                      }}
                    />
                  </RadioButtonGroupInput>
                </Col>

                <Col md={3}>
                  <div>License Type</div>
                  <RadioButtonGroupInput
                    className="program-type"
                    id="licenseType"
                    value={values.licenseType}
                    error={errors.licenseType}
                    touched={touched.licenseType}
                    wrapClasses="p-1"
                  >
                    <RadioButtonInput
                      name="licenseType"
                      id="individualLicense"
                      label="Individual"
                      value={licenseTypes.INDIVIDUAL}
                      checked={values.licenseType === licenseTypes.INDIVIDUAL}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('licenseType', licenseTypes.INDIVIDUAL);
                      }}
                    />
                    <RadioButtonInput
                      name="licenseType"
                      id="collaborativeLicense"
                      label="Collaborative"
                      value={licenseTypes.SPONSOR}
                      checked={values.licenseType === licenseTypes.SPONSOR}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('licenseType', licenseTypes.SPONSOR);
                      }}
                    />
                  </RadioButtonGroupInput>
              </Col>
              </Row>
              <Row>
              <Col md={3} className="pt-3">
                <div>Multi-Factor Authentication</div>
                {enableMfaControl ?
                  (<RadioButtonGroupInput
                    className="program-type"
                    id="mfaAuth"
                    value={values.mfaIsEnabled}
                    touched={touched.mfaIsEnabled}
                    wrapClasses="p-1"
                  >
                    <RadioButtonInput
                      name="mfaAuth"
                      id="enabled"
                      label="Enabled"
                      value={true}
                      checked={values.mfaIsEnabled}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('mfaIsEnabled', true);
                      }}
                    />
                    <RadioButtonInput
                      name="mfaAuth"
                      id="disabled"
                      label="Disabled"
                      value={false}
                      checked={!values.mfaIsEnabled}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('mfaIsEnabled', false);
                      }}
                    />
                  </RadioButtonGroupInput>)
                  :
                  <div className="font-weight-bold">
                    {organization.mfaIsEnabled ? 'Enabled' : 'Disabled'}
                  </div>}
                </Col>
              </Row>

              <EditActions>
                <ButtonToolbar className="d-flex justify-content-end">
                  <Button variant="outline-primary" onClick={handleCancelClick}>Cancel</Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting}>Save</Button>
                </ButtonToolbar>
              </EditActions>
            </Form>
          )}
      </Formik>
    );
  }
}

export default OrganizationEdit;
