import CostPerSuccessDto from './CostPerSuccessDto';

export default class CostPerSuccessListDto {
  constructor(options = {}) {
    this.costPerSuccessList = options.costPerSuccessList ? options.costPerSuccessList.map(dto => new CostPerSuccessDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
