/**
 * Formats an integer or decimal (as a string) to have comma (,) separated thousands and a single decimal pint (.)
 * 
 * @param {String} value the raw value that needs to be formatted
 * @return {String} the formatted string (i.e. 1,234.5678)
 */
const formatDisplayNumber = (value = '') => {
  const numParts = value.toString().split('.');

  const leftHand = numParts[0].replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
  const rightHand = numParts.length > 1 ? numParts[1] : '';

  return `${leftHand}${numParts.length > 1 ? `.${rightHand}` : ''}`;
};

export default formatDisplayNumber;
