import React, { useState } from 'react';
import { Button, ButtonToolbar, Form, Modal, Spinner } from 'react-bootstrap';
import ExternalAnalyticsDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsDto';
import { updateAnalytics } from '../../scripts/api/analyticsApi';
import { externalAnalyticsType } from '../../constants';

function EditExternalAnalytics({ showModal, hideModal, externalAnalytics, handleRefresh, handleErrorMessage, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState(externalAnalytics.displayName);
  const [type, setType] = useState(externalAnalytics.type);
  const [disableReplacesDefaultAnalytics, setDisableReplacesDefaultAnalytics] =
    useState(Number(type) === externalAnalyticsType.PAGINATEDREPORT);
  const [disableDatasetName, setDisableDatasetName] = 
    useState(Number(type) === externalAnalyticsType.REPORT);
  const [externalDatasetId, setExternaDatasetId] = useState(externalAnalytics.externalDatasetId);
  const [externalId, setExternalId] = useState(externalAnalytics.externalId);
  const [replacesDefaultAnalytics, setReplacesDefaultAnalytics] = useState(externalAnalytics.replacesDefaultAnalytics);

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  }

  const handleExternalIdChange = (e) => {
    setExternalId(e.target.value);
  }

  const handleTypeChange = (e) => {
    setType(e.target.value);

    let isPaginatedReport = Number(e.target.value) === externalAnalyticsType.PAGINATEDREPORT;

    setExternaDatasetId('');
    setReplacesDefaultAnalytics(false);

    setDisableReplacesDefaultAnalytics(isPaginatedReport);
    setDisableDatasetName(!isPaginatedReport);
  }

  const handleExternalDatasetIdChange = (e) => {
    setExternaDatasetId(e.target.value);
  }

  const handleReplacesDefaultAnalyticsChange = (e) => {
    setReplacesDefaultAnalytics(e.target.checked);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!displayName) {
      handleErrorMessage("Display Name cannot be empty");
      return;
    }

    if (!externalId) {
      handleErrorMessage("External Name cannot be empty");
      return;
    }

    setIsLoading(true);

    const dto = new ExternalAnalyticsDto({
      ...externalAnalytics,
      displayName: displayName,
      externalId: externalId,
      type: type,
      externalDatasetId: externalDatasetId, 
      replacesDefaultAnalytics: replacesDefaultAnalytics
    });

    try {
      await updateAnalytics(JSON.stringify(dto));
      hideModal();
      setIsLoading(false);
      handleRefresh(externalAnalytics.id)
    } catch (error) {
      setIsLoading(false);
      handleErrorMessage(`Error updating custom analytics: ${error}`)
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      centered
    >
      <Modal.Header className="modal__header">
        <span>Edit Custom Analytics</span>
        <span
          className="modal__header__btn"
          onClick={hideModal}
          aria-label="Exit"
        >
          X
        </span>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Label htmlFor="displayName">Display Name</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            name="displayName"
            onChange={handleDisplayNameChange}
            isInvalid={props.errorMessage}
          />
          <Form.Label htmlFor="externalId">External Name</Form.Label>
          <Form.Control
            type="text"
            value={externalId}
            name="externalId"
            onChange={handleExternalIdChange}
            isInvalid={props.errorMessage}
          />
          <Form.Label htmlFor="type">Embed Type</Form.Label>
          <Form.Control
            as="select"
            name="type"
            value={type}
            onChange={handleTypeChange}
          >
            <option value={externalAnalyticsType.REPORT}>Report</option>
            <option value={externalAnalyticsType.PAGINATEDREPORT}>Paginated Report</option>
          </Form.Control>
          <Form.Label htmlFor="externalDatasetId">Dataset Name</Form.Label>
          <Form.Control
            type="text"
            disabled={disableDatasetName}
            value={externalDatasetId}
            name="externalDatasetId"
            onChange={handleExternalDatasetIdChange}
            isInvalid={props.errorMessage}
          />
          <Form.Check
            label="Replaces Default Analytics"
            disabled={disableReplacesDefaultAnalytics}
            checked={replacesDefaultAnalytics}
            name="replacesDefaultAnalytics"
            onChange={handleReplacesDefaultAnalyticsChange} />

          <Form.Control.Feedback type="invalid">{props.errorMessage}</Form.Control.Feedback>

          <ButtonToolbar className="mt-4">
            <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
            <Button variant="primary" disabled={props.isLoading} type="submit">
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Save"
              )}
            </Button>
          </ButtonToolbar>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default EditExternalAnalytics
