import React, { useState } from 'react';
import { Button, ButtonToolbar, Col, Container, Row } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import PreviewMeasureModal from './modals/PreviewMeasureModal';
import PublishConfirmationModal from './modals/PublishConfirmationModal';

const DisplayMeasure = ({measure, handleEditClick, ...props}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);

  return (
    <React.Fragment>
      <Container className="basic-card pb-3 mb-5" >
        <Row>
          <Col md={6}>
            <h3>
              {measure.name}
              {measure.isDraft && " [Draft]"}
            </h3>
          </Col>
          <Col>
            <span className="measure-header display-component">Outcome Category</span>      
          </Col>
          <Col>
            <span className="measure-header display-component">Measure Type</span>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {measure.description}
          </Col>
          <Col>
            {measure.outcomeCategory.text}
          </Col>
          <Col>
            {measure.type.text}
          </Col>
        </Row>
      </Container>

      <h5 className="mb-3 font-weight-bold">Measure Fields</h5>
      <Container className="basic-card pb-3 mb-5" >
        <DataTable
          value={measure.fieldDefinitions && measure.fieldDefinitions.fields}
          scrollable
          scrollHeight='400px'
          tableStyle={{minWidth: "37.5rem"}}
        >
          <Column field="name" header="Name" />
          <Column field="type" header="Field Type" />
          <Column field="label" header="Field Label" />
        </DataTable>
      </Container>

      <h5 className="mb-3 font-weight-bold">Measure Criteria</h5>
      <Container className="basic-card pb-3 mb-5">
        <Row>
          <Col>
            <span className="measure-header display-component">Cutoff Score Field</span>
          </Col>
          <Col>
            <span className="measure-header display-component">Cutoff Score Value</span>
          </Col>
          <Col>
            <span className="measure-header display-component">Cutoff Score Comparison</span>
          </Col>
        </Row>
        <Row>
          <Col>
            {measure.cutoffScoreField}
          </Col>
          <Col>
            {measure.cutoffScoreValue}
          </Col>
          <Col>
            {measure.cutoffScoreComparison.text}
          </Col>
        </Row>
      </Container>

      <ButtonToolbar className="d-flex justify-content-between">
        <Button variant="outline-primary" onClick={() => setShowPreviewModal(true)}>Preview</Button>
        {(props.canEdit && ( measure.organizationId !== null || props.userPermissions.rolePermission === 'SiteAdmin')) ? (
          <>
            {measure.isDraft ? (
              <div className='d-flex justify-content-between'>
                <Button variant="outline-primary" className='mr-3' onClick={handleEditClick}>Edit Measure</Button>
                <Button variant="primary" onClick={() => setShowPublishModal(true)}>Publish Measure</Button>
              </div>
            ) : (
              <div className='d-flex justify-content-between'>
                {props.userPermissions.rolePermission === 'SiteAdmin' ? 
                    <Button variant="outline-primary"
                      className='mr-3'
                      onClick={props.unpublishMeasure}>
                      Unpublish Measure
                    </Button>
                  : <> </>
                }
                <Button variant="primary" className='mr-3' onClick={handleEditClick}>Edit Measure</Button>
              </div>
            )}
        </>
        ) : null }
      </ButtonToolbar>

      <PreviewMeasureModal
        data={measure}
        show={showPreviewModal}
        onCancel={() => setShowPreviewModal(false)}
      />

      <PublishConfirmationModal
        showModal={showPublishModal}
        hideModal={() => setShowPublishModal(false)}
        confirmPublish={props.publishMeasure}
        loading={props.isLoading}
      />

    </React.Fragment>
  );
}

export default DisplayMeasure;
