import React, { Component } from 'react';
import { ListGroup, Spinner, Alert } from 'react-bootstrap';
import { formatDisplayNumber } from '../../scripts/numHelpers';

export class ServiceHoursDetails extends Component {
  constructor(props) {
    super(props);
  }

  hasData = (data) => {
    return data && data.length > 0;
  }

  render() {
    const { listData, showSpinner } = this.props;

    if (showSpinner) {
      return (
        <Spinner animation="border" className="sureimpact__spinner"></Spinner>
      );
    }

    return (
      <React.Fragment>
        {this.hasData(listData)
          ? <ListGroup className="basic-card">
            {listData.map(details => (
              <ListGroup.Item className="d-flex list-group-item" key={details.organizationServiceId}>
                <span className="text-right col-2">
                  <h2>{formatDisplayNumber(details.serviceHours)}</h2>
                </span>
                <div className="ml-5">
                  <div className="font-weight-bold mt-0">{details.serviceTitle}</div>
                  <div className="description_color">{details.serviceDescription}</div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          : <Alert variant="info" className="print-card"> No Service Activity Data to Display </Alert>
        }
      </React.Fragment>
    );
  }
}

export default ServiceHoursDetails;
