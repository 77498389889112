import ClientIndexDto from './ClientIndexDto';

export default class ClientIndexListDto {
    constructor(options = {}) {
        this.allClients = options.allClients ? options.allClients.map(dto => new ClientIndexDto(dto)) : [];
    }

    stringify() {
        return JSON.stringify(this);
    }
}
