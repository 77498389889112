export default class IndexItemDto {
  constructor(options) {
    this.title = options.title;
    this.description = options.description;
    this.url = options.url;
    this.itemId = options.itemId;
    this.isDisabled = options.isDisabled;
    this.endTextDisplay = options.endText ? options.endText : options.isDisabled ? 'Not Active' : null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}