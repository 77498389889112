import React, { useEffect, useState } from 'react';
import { getAnalyticsForOrg } from '../../scripts/api/analyticsApi';
import { message } from '../shared/Message';
import { useNavigate } from "react-router";

import { Toast } from 'primereact/toast';
import useToast from '../shared/hooks/useToast';

import ExternalAnalyticsListDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsListDto';
import ExternalAnalyticsDataTable from './ExternalAnalyticsDataTable';
import AddExternalAnalytics from './AddExternalAnalytics';
import { useSelector } from 'react-redux';

function ExternalAnalyticsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [externalAnalyticsList, setExternalAnalyticsList] = useState(new ExternalAnalyticsListDto());
  const [showAddModal, setShowAddModal] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const currentUserRole = useSelector((state) => state.user.roles[0])

  const handleRowClick = (event => {
    const { id } = event.data;
    navigate(`/ExternalAnalytics/${id}`);
  });

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const fetchAnalytics = async () => {
    try {
      setIsLoading(true);
      let response = await getAnalyticsForOrg();
      setExternalAnalyticsList(response);
      setIsLoading(false);
    }
    catch (err) {
      message.error(`Error getting report: ${err.message}`);
    }
  }

  useEffect(() => {
    fetchAnalytics();
  }, []);

  return (
    <React.Fragment>

      {externalAnalyticsList ?
        <ExternalAnalyticsDataTable
          isLoading={isLoading}
          listData={externalAnalyticsList.externalAnalytics}
          handleRowClick={handleRowClick}
          handleAddClick={handleAddClick}
          currentUserRole={currentUserRole}
        /> :
        null
      }

      {showAddModal && currentUserRole === "SiteAdministrator" ?
        <AddExternalAnalytics
          showModal={showAddModal}
          hideModal={() => setShowAddModal(false)}
          setIsLoading={setIsLoading}
          handleRefresh={fetchAnalytics}
        />
        : null}

      <Toast ref={toast} position="bottom-left" />
    </React.Fragment>
  );
}

export default ExternalAnalyticsPage;
