import React, { Component } from 'react';
import { Formik } from 'formik';
import { Form, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import FilterInput from './FilterInput';
import { parseDate } from '../../../scripts/safeDateParse';

export default class FilterForm extends Component {
  constructor(props) {
    super(props);
  }
  
  handleFormSubmit = (values, {setSubmitting, setErrors}) => {
    if (this.isValidForm(values, setErrors)) {
      this.props.applyFilters(values, setSubmitting);
    } else {
      setSubmitting(false);
    }
  }

  isValidForm = (values, setErrors) => {
    if (values.startDate && !values.endDate) {
      setErrors({ endDate: 'Please select an end date' });
      return false;
    } else if (values.endDate && !values.startDate) {
      setErrors({ startDate: 'Please select a start date' });
      return false;
    } else if (values.startDate && values.endDate && parseDate(values.endDate) < parseDate(values.startDate)) {
      setErrors({ startDate: 'Start date must be on or before end date', endDate: 'End date must be on or after start date' });
      return false;
    }
    return true;
  }

  render() {
    const {
      clearFilters,
      submitButtonText,
      cancelButtonText,
      availableFilterTypes,
      filterValues
    } = this.props;

    return (
      <React.Fragment>
        <Formik
          initialValues={filterValues}
          onSubmit={this.handleFormSubmit}>
          {({
            handleSubmit,
            handleBlur,
            submitForm,
            errors,
            isSubmitting,
            values
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={9} md={12} className="pr-0">
                  <Form.Row>
                    <FilterInput
                      availableFilterTypes={availableFilterTypes}
                      values={values}
                      handleBlur={handleBlur}
                      errors={errors} />
                  </Form.Row>
                </Col>
                <Col lg={3}>
                  <ButtonToolbar className="d-flex flex-md-row-reverse">
                    <Button variant="primary" type="button" disabled={isSubmitting} className="mr-0 mb-4" onClick={submitForm}>{submitButtonText}</Button>
                    <Button variant="link" className="btn-text-primary mb-4" onClick={clearFilters}>{cancelButtonText}</Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Form>)}
        </Formik>
      </React.Fragment>
    );
  }
}
