import React from 'react';
import { Row, Col } from 'react-bootstrap';

const EditActions = ({ children }) => {

  return (
    <Row className="mt-3">
      <Col>
        {children}
      </Col>
    </Row>
  );
}

export default EditActions;