import React, { useState, useEffect } from 'react';
import { Container, Button, ButtonToolbar } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import OrganizationInvalidDataListDto from '../../DTOs/OrganizationInvalidData/OrganizationInvalidDataListDto.js';
import BackgroundJobDto from '../../DTOs/BackgroundJob/BackgroundJobDto.js';
import api from '../../scripts/api.js';
import { message } from '../shared/Message.js';

const UpdateInvalidData = ({ history, ...props }) => {
  const [invalidData, setInvalidData] = useState(new OrganizationInvalidDataListDto());
  const [currentScan, setCurrentScan] = useState(null);
  const [currentlyScanning, setCurrentlyScanning] = useState(false);

  useEffect(() => {
    api.getInvalidOrganizationData().then(result => {
      setInvalidData(result);      
    }).catch(err => {
      message.error(`Error retrieving invalid data summary: ${err.message}`);
    });
    getCurrentScan();
  }, [])

  function getCurrentScan() {
    api.getCurrentInvalidDataScan().then(result => {
      setCurrentScan(result);
      setCurrentlyScanning(true);
    }).catch(err => {
      setCurrentlyScanning(false);
      setCurrentScan(null);
    });
  }

  function handleInvalidDataSelected(invalidData) {
    history.push(`/Organization/UpdateInvalidData/${invalidData.id}`);
  }

  function handleReturnClick() {
    history.push("/Organization/");
  }

  function handleRescanClick() {
    api.scanAllMeasuresForInvalidData().then(result => {
      setCurrentScan(new BackgroundJobDto({
        statusMessage: "Refresh the page for updates, but you can safely leave this page and the scan will continue in the background."
      }));
      setCurrentlyScanning(true);
    }).catch(err => {
      message.error(`Error starting scan: ${err.message}`);
    });
  }
  
    return (
      <React.Fragment>
        <Container className="basic-card pb-3">
          <p>
            Note:  All columns can be sorted simultaneously
            by holding a meta key and clicking filter (default:  Ctrl on Windows, Command on Mac)</p>
          <DataTable
            value={invalidData.organizationInvalidData}
            tableStyle={{ minWidth: '50rem' }}
            stripedRows
            paginator rows={25}
            sortMode="multiple"
            sortField="organizationName"
            sortOrder={-1}
            filterDisplay="row"
            selectionMode="single"
            onSelectionChange={(e) => handleInvalidDataSelected(e.value)}
            dataKey="id"
          >
            <Column field="id" header="Id"></Column>
            <Column field="organizationName" header="Organization" sortable filter showFilterMenu={true}></Column>
            <Column field="measureName" header="Measure" sortable filter showFilterMenu={true}></Column>
            <Column field="fieldName" header="Field" sortable filter showFilterMenu={true}></Column>
            <Column field="fieldValue" header="Value" sortable filter showFilterMenu={true}></Column>
            <Column field="validationMessage" header="Error" sortable filter showFilterMenu={true}></Column>
          </DataTable>
          {currentlyScanning ?
            <>
              <p>Scan in progress:  {currentScan.statusMessage}</p>
              <ButtonToolbar className="d-flex justify-content-end mt-3">
                <Button variant="outline-primary" onClick={handleReturnClick}>Back</Button>
              </ButtonToolbar>
            </>
            :
            <ButtonToolbar className="d-flex justify-content-end mt-3">
              <Button variant="primary" onClick={handleRescanClick} >Rescan All Measures</Button>
              <Button variant="outline-primary" onClick={handleReturnClick}>Back</Button>
            </ButtonToolbar>
          }
          
        </Container>
      </React.Fragment>
    );
  }

export default UpdateInvalidData;
