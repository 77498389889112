import OrganizationDto from './OrganizationDto';

export default class OrganizationListDto {
  constructor(options = {}) {
    this.organizations = options.organizations ?
      options.organizations.map(dto => new OrganizationDto(dto)) :
      [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
