import React from 'react';
import InputMask from 'react-input-mask';
import TextInput from './TextInput'

/**
 * Component for allowing date inputs
 * 
 * This input ensures the date returned value is in the format
 * 99/99/9999 where 9 is any digit.
 */
const DateInput = ({ onChange, onBlur, value, ...props }) => {
  return (
    <InputMask
      mask="99/99/9999"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <TextInput {...props} />
    </InputMask>
  );
}

export default DateInput;