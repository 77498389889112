export default class ComparisonMeasureDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.name = options.name || '';
    this.startPercent = options.startPercent || 0;
    this.startNumerator = options.startNumerator || 0;
    this.startDenominator = options.startDenominator || 0;
    this.currentPercent = options.currentPercent || 0;
    this.currentNumerator = options.currentNumerator || 0;
    this.currentDenominator = options.currentDenominator || 0;
    this.measureType = options.measureType || 0;
    this.maintainedPercent = options.maintainedPercent || 0;
    this.maintainedNumerator = options.maintainedNumerator || 0;
    this.maintainedDenominator = options.maintainedDenominator || 0;
    this.positiveChange = options.positiveChange || false;

    this.startLabel = 'Start';
    this.startPercentDetails = `${this.startPercent}% (${this.startNumerator}/${this.startDenominator})`;
    this.currentLabel = 'Currently';
    this.currentPercentDetails = `${this.currentPercent}% (${this.currentNumerator}/${this.currentDenominator})`;
    this.maintainedLabel = 'Maintained';
    this.maintainedPercentDetails = `${this.maintainedPercent}% (${this.maintainedNumerator}/${this.maintainedDenominator})`;
  }
}
