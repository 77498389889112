import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import FormElement from './FormElement';


class InputLibrary extends Component {

  render() {
    const { formElements, droppableId, isDragging } = this.props;

    return (
      <Droppable
        droppableId={droppableId}
        isDropDisabled={true}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            className="form-elements-list"
          >
            {formElements.map((item, index) => (
              <FormElement
                key={item.id}
                index={index}
                showPlaceholderOnDrag
                isDragging={isDragging}
                question={item}
              />
            ))}
            {/* react-beautiful-dnd will complain about no placeholder in dev env.  
              We don't want that functionality here since we cannot drop items in this list. */}
          </div>
        )}
      </Droppable>
    );
  }
}

export default InputLibrary;