import { format } from 'date-fns';
import { parseDate } from '../../scripts/safeDateParse';

export default class ClientIndexDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.organizationId = options.organizationId || 0;
        this.isDisabled = options.isDisabled;
        this.firstName = options.firstName || '';
        this.lastName = options.lastName || '';
        this.dateOfBirth = options.dateOfBirth ? format(parseDate(options.dateOfBirth), 'MM/dd/yyyy') : '';

        this.displayName = `${this.lastName}, ${this.firstName}`;
        this.isActive = !options.isDisabled;
    }

    stringify() {
        return JSON.stringify(this);
    }
}
