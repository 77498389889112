export default class ImportFieldDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.fieldName = options.fieldName || '';
        this.questionId = options.questionId || '';
        this.questionValue = options.questionValue || '';
        this.keywordId = options.keywordId || 0;
        this.keywordCategory = options.keywordCategory || '';
        this.include = options.include || false;
        this.multiSelect = options.multiSelect || false;
    }

    stringify() {
        return JSON.stringify(this);
    }
}
