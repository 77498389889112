import ServiceActivityDto from '../ServiceActivity/ServiceActivityDto';

export default class HistoryDto {
  constructor(options = {}) {
    this.clientId = options.clientId || 0;
    this.organizationServiceId = options.organizationServiceId || 0;
    this.pageNum = options.pageNum || 0;
    this.pageSize = options.pageSize || 0;
    this.totalActivityCount = options.totalActivityCount || 0;
    this.activityList = options.activityList.map(dto => new ServiceActivityDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}