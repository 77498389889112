import React, { useContext } from 'react';
import { Container, Row, Col, Button} from 'react-bootstrap';
import { Auth0Context } from './Auth0';
import logo from '../../images/Horizontal@6x.png';

export default () => {

  const context = useContext(Auth0Context);

  return (
    <Container className="d-flex flex-column flex-grow-1 justify-content-center">
      <Row noGutters className="mb-lg-5 mb-md-3">
        <Col className="d-flex align-content-center justify-content-center">
          <img src={logo} className="login__image" alt="SureImpact" />
        </Col>
      </Row>
      <Row noGutters>
        <Col>
          <h3 className="d-flex align-content-center justify-content-center">
            Inactive Organization</h3>
          <p className="d-flex align-content-center justify-content-center">
            Your organization has been disabled by the license administrator. Please logout and contact support.
            </p>
        </Col>
      </Row>
      <Row noGutters>
        <Col className="d-flex align-content-center justify-content-center">
          <Button variant="primary" onClick={() => context.logout({ returnTo: window.location.origin })}>Logout</Button>
        </Col>
      </Row>
    </Container>
  );
}
