import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import AssignOrganizations from './AssignOrganizations';
import DisplayOrganizations from './DisplayOrganizations';

import api from '../../../scripts/api';
import { message } from '../../shared/Message';
import IndexItem from '../../../DTOs/shared/IndexItem';
import SponsoredServicesDto from '../../../DTOs/OrganizationService/SponsoredServicesDto';

import { hasRequiredPermissions } from '../../../scripts/permissionHelper.js';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../../constants';

const editPermissions = {
  rolePermission: rolePermissionLevels.ORG_ADMIN,
  licenseLevelPermission: licenseLevels.PAID,
  licenseTypePermission: licenseTypes.SPONSOR
}

class SponsoredOrganizations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      listData: [],
      showEdit: false,
      selectedOrganizations: [],
      disabled: false
    };

  }

  handleSelectOrganizations = (selected) => {
    // selected will be sent as null instead of an empty array when the user backspaces away all search text and every selection
    this.setState({ selectedOrganizations: selected ? selected : [] });
  };

  handleSaveOrganizations() {
    this.setState({ disabled: true });
    
   message.dismiss(this.saveErrorMessageId);
    const dto = new SponsoredServicesDto({
      serviceId: this.props.serviceId,
      sponsoredOrgIds: this.state.selectedOrganizations.map(org => org.value)
    });

    api.updateSponsoredServices(dto.stringify()).then(result => {
      this.initializeComponent(this.props.serviceId);
      this.handleEditClick();
    }).catch(err => {
      this.saveErrorMessageId = message.error(`Error updating sponsored partners for service: ${err}`);
    }).finally(
      this.setState({ disabled: false })
    );
  }

  handleEditClick() {
    let newState = { showEdit: !this.state.showEdit };

    if (this.state.showEdit) {
      // user cancelling edit so re-set selected organizations to default list
      newState.selectedOrganizations = this.state.organizations.map(org => ({ value: org.id, label: org.name }));
    }

    this.setState(newState);
  }

  initializeComponent(id) {
    message.dismiss(this.getErrorMessageId);
    if (id && parseInt(id, 10) !== 0) {
      api.getSponsoredOrganizations(id).then(result => {
        this.setState({
          organizations: result.organizations,

          listData: result.organizations.map(org => new IndexItem({
            title: org.name,
            itemId: org.id,
            isDisabled: false
          })),

          selectedOrganizations: result.organizations.map(org => ({ value: org.id, label: org.name }))
        });
      }).catch(err => {
        this.getErrorMessageId = message.error(`Error getting associated organizations: ${err}`);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.serviceId !== prevProps.serviceId) {
      this.initializeComponent(this.props.serviceId);
    }
  }

  componentDidMount() {
    this.initializeComponent(this.props.serviceId);
  }

  render() {
    const { showEdit, listData, selectedOrganizations, disabled } = this.state;
    const { userPermissions } = this.props;
    const canEdit = hasRequiredPermissions(editPermissions, userPermissions);

    return (
      <Container className="basic-card pb-3">
        <Row className="my-3">
          <Col md={6} className="align-self-end">
            <h5>Associated Partners</h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">

            {showEdit
              ? <ButtonToolbar>
                <Button variant="outline-primary" onClick={() => this.handleEditClick()}>Cancel</Button>
                <Button
                  variant="primary"
                  disabled={disabled}
                  onClick={() => this.handleSaveOrganizations()}
                >
                  Save Partners
                  </Button>
              </ButtonToolbar>
              : <React.Fragment>
                {canEdit &&
                  <Button variant="outline-primary" onClick={() => this.handleEditClick()}>Add Partners</Button>
                }
              </React.Fragment>
            }

          </Col>
        </Row>

        {showEdit
          ? <AssignOrganizations defaultOrganizations={selectedOrganizations} selectOrganizations={this.handleSelectOrganizations} />
          : <DisplayOrganizations listData={listData} />
        }

      </Container>
    );
  }
};

export default connect(
  state => ({ userPermissions: state.user.permissions, effectiveOrganizationId: state.user.effectiveOrganizationId })
)(SponsoredOrganizations);
