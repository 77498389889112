export default class CostPerSuccessDto {
  constructor(options = {}) {
    this.organizationId = options.organizationId || 0;
    this.organizationName = options.organizationName || '';
    this.programId = options.programId || 0;
    this.programName = options.programName || '';
    this.programDescription = options.programDescription || '';
    this.programCost = options.programCost || 0;
    this.measureId = options.measureId || 0;
    this.measureName = options.measureName || '';
    this.costPerSuccess = options.costPerSuccess || 0;
    this.costPerSuccessNote = options.costPerSuccessNote || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
