import "../../../../styles/SureImpact.radio.css";
import React, { Component } from "react";
import { Row, Col, FormLabel, Button } from "react-bootstrap";

class FilePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenFile: null,
      chosenFileName: '',
      fileIsSelected: false,
      className: ''
    };
    
    this.uploadInput = React.createRef();
    
    this.handleAddFileClick = this.handleAddFileClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleRemoveFileClick = this.handleRemoveFileClick.bind(this);
  }

  handleAddFileClick = event => {
    this.uploadInput.current.click();
  };

  handleRemoveFileClick = event => {    
    this.setState({ 
      chosenFile: null,
      chosenFileName: '',
      fileIsSelected: false 
    });
    
    this.props.onFileRemoved();
  };

  handleFileChange = event => {
    if(!this.uploadInput.current || !this.uploadInput.current.files || this.uploadInput.current.files.length === 0)
      return;

    this.setState({ 
      chosenFile: this.uploadInput.current.files[0],
      chosenFileName: this.uploadInput.current.files[0].name,
      fileIsSelected: true 
    });

    this.props.onFileChosen(this.uploadInput.current.files[0]);
  };

  render() {
    const { className, fileIsSelected, chosenFileName} = this.props;
    return (
      <div className={className}>
        <Row className="p-0">
          <Col className="align-content-start">
            <button
              className="file-picker__button"
              disabled={!fileIsSelected}
              onClick={this.handleRemoveFileClick}
              >
              X
            </button>

            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={this.handleAddFileClick}
            >
              Choose File
            </Button>

            <FormLabel className="ml-3">
              {fileIsSelected ? (
                <span>{chosenFileName}</span>
              ) : (
                <span>No File Chosen</span>
              )}
            </FormLabel>
          </Col>
        </Row>
        
        <input
          type="file"
          ref={this.uploadInput}
          name="file"
          className="d-none"
          onChange={this.handleFileChange}
          />

      </div>
    );
  }
}

export default FilePicker;
