import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const ErrorTableRow = ({lineNumber, exception}) => {

    return (
        <Row key={lineNumber} className="error-table-row">
            <Col xs={2} className="error-table-column error-table-border error-table__text">
                <p>{lineNumber}</p>
            </Col>
            <Col xs={10} className="error-table-column error-table-border error-table__text">
                <p>{exception}</p>
            </Col>
        </Row>
    );
}

export default ErrorTableRow;
