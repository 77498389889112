import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Form, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import Switch from '../shared/Switch';
import EditActions from '../shared/EditActions';


const schema = yup.object({
  name: yup.string().ensure().trim()
    .required('Name is required')
    .max(255, 'Name cannot be greater than 255 chars'),
  description: yup.string().ensure().trim()
    .required('Description is required')
});

class EditService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '/OrganizationService'
    };
  }

  render() {
    const { service, editAction, handleCancelEditClick, redirect, handleFormSubmit } = this.props;
    const { redirectTo } = this.state;

    if (redirect) {
      return <Navigate to={redirectTo} replace />;
    }

    return (
      <Formik
        validationSchema={schema}
        onSubmit={handleFormSubmit}
        initialValues={{ ...service }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-3 mb-3">
                <Col>
                  <h5>{editAction} Service</h5>
                </Col>

                <Form.Group as={Col} md={6} className="d-flex justify-content-end">
                  <label htmlFor="isActive">Active&nbsp;</label>
                  <Switch
                    value={values.isActive}
                    name="isActive"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6}>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Service Name"
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    defaultValue={values.description}
                    placeholder="Description"
                    onChange={handleChange}
                    isInvalid={touched.description && !!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <EditActions>
                <ButtonToolbar className="d-flex justify-content-end">
                  <Button variant="outline-primary" onClick={() => handleCancelEditClick()}>Cancel</Button>
                  <Button variant="primary" type="submit" disabled={isSubmitting}>Save</Button>
                </ButtonToolbar>
              </EditActions>
            </Form>
          )}
      </Formik>
    );
  }
}

export default EditService;
