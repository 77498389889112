import React, { Component } from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import QuestionMark from '../../images/QuestionMark.png';
import BaseFilterRequestDto from '../../DTOs/ApiFilter/BaseFilterRequestDto';
import BaseApiRequestFilter from '../shared/filter/BaseApiRequestFilter';
import { apiFilterTypes, localStorageCacheKeys } from '../../constants';
import CostPerSuccessPage from './CostPerSuccessPage';

//Filters that haven't been implemented by Snowflake yet are commented out here.
const availableFilterTypes = [
  //apiFilterTypes.ORGANIZATION,
  apiFilterTypes.START_DATE,
  apiFilterTypes.END_DATE,
  //apiFilterTypes.PROGRAM,
];

export class CostPerSuccessIndex extends Component {
  constructor(props) {
    super(props);
    let storedFilters = localStorage.getItem(localStorageCacheKeys.COST_PER_SUCCESS_FILTERS);
    localStorage.removeItem(localStorageCacheKeys.COST_PER_SUCCESS_FILTERS);
    storedFilters = storedFilters ? JSON.parse(storedFilters) : {};
    this.state = {
      listData: [],
      showSpinner: false,
      showFilters: this.filterHasValues(storedFilters),
      filterRequestDto: this.getApiRequest(storedFilters),
      storedFilters: storedFilters
    }
  }

  filterHasValues = (filtersDto) => {
    return filtersDto &&
      (filtersDto.activeStatus ||
        (filtersDto.gender && filtersDto.gender.length > 0) ||
        (filtersDto.hispanicLatino && filtersDto.hispanicLatino.length > 0) ||
        (filtersDto.race && filtersDto.race.length > 0));
  }

  componentDidMount() {
    this.getCostPerSuccess(this.state.filterRequestDto.stringify());
  }

  applyFilters = (values, setSubmitting) => {
    this.getCostPerSuccess(this.getApiRequest(values).stringify(), setSubmitting);
  }

  getCostPerSuccess = (filterRequest, setSubmitting) => {
    this.setState({ showSpinner: true });
    api.getCostPerSuccess(filterRequest).then(result => {
      this.setState({ listData: result.costPerSuccessList });
    }).catch(err => {
      message.error(`Error getting Cost Per Success details: ${err}`);
    }).finally(() => {
      this.setState({ showSpinner: false });
      if (setSubmitting) {
        setSubmitting(false);
      }
    });
  }

  displayFilters = () => {
    this.setState({ showFilters: true });
  }

  clearFilters = () => {
    const emptyFilterRequest = new BaseFilterRequestDto();
    this.setState({
      showFilters: false,
      filterRequestDto: emptyFilterRequest
    }, this.getCostPerSuccess(emptyFilterRequest.stringify()));
  }

  getApiRequest = (values) => {
    return new BaseFilterRequestDto({
      organizationIds: values.organization,
      startDate: values.startDate,
      endDate: values.endDate,
      programId: values.program,
      active: values.activeStatus ? values.activeStatus === '1' : null,
      raceIds: values.race,
      hispanicLatino: values.hispanicLatino ? values.hispanicLatino.map(x => x === 1) : [],
      genderIds: values.gender
    });
  }

  render() {
    const { listData, showSpinner, showFilters, storedFilters } = this.state;
    const tooltipText = '';

    return (
      <React.Fragment>
        <div className="container">
          <BaseApiRequestFilter
            availableFilterTypes={availableFilterTypes}
            displayFilters={this.displayFilters}
            clearFilters={this.clearFilters}
            filterValues={storedFilters}
            applyFilters={this.applyFilters}
            showFilters={showFilters}
            includeAddFiltersButton={true}
          />
          <div className="d-flex justify-content-between mb-4">
            <h4 className="font-weight-bold">Cost Per Success &nbsp;
                <img
                className="question-mark"
                src={QuestionMark}
                data-tip={tooltipText}
                data-for={'tooltip'}
                data-effect="solid"
                data-multiline={true}
                data-type="light"
                data-border
              />
            </h4>

            <ButtonToolbar>
              <Button variant="outline-primary d-print-none" onClick={window.print}>Print Cost Per Success</Button>
            </ButtonToolbar>
          </div>

          <CostPerSuccessPage
            listData={listData}
            showSpinner={showSpinner} />
        </div>

        <ReactTooltip id="tooltip" getContent={() =>
          <span>
            <strong>Cost Per Success: </strong>
            Calculation of Program Cost / Number of clients who have maintained a certain<br className="d-lg-inline d-md-none" />
            measure of success based on date range selected.
            </span>} />
      </React.Fragment>
    );
  }
}

export default CostPerSuccessIndex;
