import React from 'react';
import TextInput from './TextInput';
import { copyEvent } from './inputHelpers';

const PostalCodeInput = ({ onChange, ...props }) => {

  const handleChange = e => {
    const inputValue = e.target.value;

    // Zipcode can only contain numbers and have no more than 5 digits
    const regex = /\D/;
    const cleanedValue = inputValue.replace(regex, '').substring(0, 5);

    let eventCopy = copyEvent(e);
    eventCopy.target.value = cleanedValue;

    onChange(eventCopy);
  }


  return (
    <TextInput
      onChange={handleChange}
      {...props}
    />
  );
}

export default PostalCodeInput;