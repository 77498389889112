import React, { Component } from 'react';
import { Tab, Nav, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import ServiceList from './ServiceList';
import ServiceIndexActions from './ServiceIndexActions';
import BaseFilterRequestDto from '../../DTOs/ApiFilter/BaseFilterRequestDto';

import { message } from '../shared/Message';
import IndexActions from '../shared/indexList/IndexActions';
import BaseApiRequestFilter from '../shared/filter/BaseApiRequestFilter';
import { apiFilterTypes } from '../../constants';
import { downloadFilteredFile } from '../../scripts/api';
import { userRoles } from '../../constants';

const ROLES_FOR_VIEWING_INACTIVE = [userRoles.ADMIN, userRoles.MANAGER, userRoles.ROOT_ADMIN];

//Filters that haven't been implemented by Snowflake yet are commented out here.
const baseFilterTypes = [
  apiFilterTypes.START_DATE,
  apiFilterTypes.END_DATE,
  apiFilterTypes.ACTIVE_STATUS,
  apiFilterTypes.RACE,
  apiFilterTypes.HISPANIC_LATINO,
  apiFilterTypes.GENDER,
  apiFilterTypes.PROGRAM
];

const rollupFilterTypes = [
  apiFilterTypes.ORGANIZATION,
  apiFilterTypes.COLLABORATIVE_ORGANIZATION
].concat(baseFilterTypes);

class ServiceIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: false,
      showFilters: false,
      showButtonToolbar: true,
      filterHeader: '',
      isRollupExport: false,
      filterRequestDto: new BaseFilterRequestDto(),
      availableFilterTypes: baseFilterTypes
    }

    this.handleAddServiceClick = this.handleAddServiceClick.bind(this);
    this.export = this.export.bind(this);
  }

  toggleSpinner = () => {
    this.setState({ showSpinner: !this.state.showSpinner });
  };

  handleExportDataClick = (hasFilters, filterHeader, isRollupExport) => {
    if (hasFilters) {
      this.displayFilters(filterHeader, isRollupExport);
    } else {
      this.setState(
        { isRollupExport: isRollupExport },
        this.export
      );
    }
  }

  displayFilters = (filterHeader, isRollupExport) => {
    this.setState({
      showFilters: true,
      showButtonToolbar: false,
      filterHeader: filterHeader,
      isRollupExport: isRollupExport,
      availableFilterTypes: isRollupExport ? rollupFilterTypes : baseFilterTypes
    });
  }

  clearFilters = () => {
    this.setState({
      showFilters: false,
      showButtonToolbar: true
    });
  }

  getApiRequest = (values) => {
    if (!values) {
      return new BaseFilterRequestDto();
    }
    return new BaseFilterRequestDto({
      organizationIds: values.organization,
      startDate: values.startDate,
      endDate: values.endDate,
      active: values.activeStatus ? values.activeStatus === '1' : null,
      genders: values.gender,
      races: values.race,
      hispanicLatinos: values.hispanicLatino,
      programIds: values.program,
      collaborativeOrganizationId: values.collaborativeOrganization
    });
  }

  handleAddServiceClick() {
    this.props.history.push('/OrganizationService/AddService');
  }

  async export(values, setSubmitting) {
    this.toggleSpinner();
    try {
      this.state.isRollupExport
        ? await downloadFilteredFile('export/serviceactivity/rollup', this.getApiRequest(values).stringify())
        : await downloadFilteredFile('export/serviceactivity', this.getApiRequest(values).stringify());
    } catch (err) {
      message.error(err.message);
    } finally {
      this.toggleSpinner();
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  }

  render() {
    const { showSpinner,
      showButtonToolbar,
      showFilters,
      filterHeader,
      availableFilterTypes
    } = this.state;

    const canViewInActive = this.props.roles.some(role => ROLES_FOR_VIEWING_INACTIVE.includes(role));
    
    return (
      <React.Fragment>
        <IndexActions>
          <ServiceIndexActions
            handleAddServiceClick={this.handleAddServiceClick}
            handleExportDataClick={this.handleExportDataClick}
            showButtonToolbar={showButtonToolbar}
            availableFilterTypes={availableFilterTypes}
          />
        </IndexActions>
          <BaseApiRequestFilter
            availableFilterTypes={availableFilterTypes}
            displayFilters={() => this.displayFilters('')}
            applyFilters={this.export}
            clearFilters={this.clearFilters}
            submitButtonText={'Download'}
            cancelButtonText={'Cancel'}
            showFilters={showFilters}
            includeAddFiltersButton={false}
          filterHeader={filterHeader} />

        {showSpinner && <Spinner animation="border" className="sureimpact__spinner_export"></Spinner>}

        <Tab.Container defaultActiveKey="active">
          <Nav variant="tabs">
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="active">Active</Nav.Link>
            </Nav.Item>
            { canViewInActive && 
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="inactive">Inactive</Nav.Link>
            </Nav.Item>
            }
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="active">
              <ServiceList showActive />
            </Tab.Pane>
            { canViewInActive && 
            <Tab.Pane eventKey="inactive">
              <ServiceList />
            </Tab.Pane>            
            }
          </Tab.Content>
        </Tab.Container>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
    roles: state.user.roles,
  }))(ServiceIndex);
