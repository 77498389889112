import { parseISO, isValid } from 'date-fns';

// This is a helper function to parse a date string into a Date object.
// I have found instances where already parsed or incorrectly formatted dates
// are passed in, so this function tries to avoid regressions accordingly.

export function parseDate(date) {
  if (!date) return date;
  if (date instanceof Date) return date;

  try {
    if (/^\d+\/\d+\/\d+$/.test(date)) {
      // date is in mm/dd/yyyy format
      const [month, day, year] = date.split('/');
      return new Date(year, month - 1, day);
    }

    var parsedDate = parseISO(date);
    if (!isValid(parsedDate))
      throw new Error('Date is invalid');

    return parsedDate;
  } catch (ex) {
    console.warn(`Date parse for ${date} failed. Using fallback parsing.`);
    return new Date(date);
  }
}
