import React from 'react';
import { Container } from 'react-bootstrap';
import NavMenu from './NavMenu';
import Footer from './Footer'
import VersionCheck from './VersionCheck'
import { Auth0Context } from './Auth0';
import { MessageDisplay } from './Message';
import Environment from './Environment';
import ImpersonationIndicator from './ImpersonationIndicator.js';

export default ({ children, location }) => (
  <Auth0Context.Consumer>
    {({ isAuthenticated }) => (
      <React.Fragment>
        {isAuthenticated && location.pathname && location.pathname !== '/' && <NavMenu />}
        <Environment />
        <Container className="d-flex flex-column flex-grow-1">
          <ImpersonationIndicator />
          <MessageDisplay />
          {children}
        </Container>
        <VersionCheck />
        <Footer />
      </React.Fragment>
    )}
  </Auth0Context.Consumer>
);
