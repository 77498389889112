import React from 'react';
import { connect } from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import { hasRequiredPermissions } from '../../scripts/permissionHelper';
import { rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants';

import IndexActions from '../shared/indexList/IndexActions';
import MeasuresList from './MeasuresList';
import MeasuresIndexActions from './MeasuresIndexActions';

const ROLES_FOR_CREATE_AND_DRAFTED = {
  rolePermission: rolePermissionLevels.ORG_ADMIN || rolePermissionLevels.SITE_ADMIN,
  licenseLevelPermission: licenseLevels.PAID,
  licenseTypePermission: [licenseTypes.INDIVIDUAL, licenseTypes.SPONSOR]
}

function MeasuresIndex(props) {
  const canViewCreateAndDrafted = hasRequiredPermissions(
    ROLES_FOR_CREATE_AND_DRAFTED, 
    props.userPermissions
  );
  
  return (
    <>
      <IndexActions>
        <MeasuresIndexActions />
      </IndexActions>
      <Tab.Container defaultActiveKey="publishedMeasures">
        <Nav variant="tabs">
          <Nav.Item className="w-25 text-center">
            <Nav.Link eventKey="publishedMeasures">Published</Nav.Link>
          </Nav.Item>
          { canViewCreateAndDrafted && (
            <Nav.Item className="w-25 text-center">
              <Nav.Link eventKey="draftedMeasures">Drafted</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="publishedMeasures">
            <MeasuresList showPublished/>
          </Tab.Pane>
          { canViewCreateAndDrafted && (
            <Tab.Pane eventKey="draftedMeasures">
              <MeasuresList/>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  )
}

export default connect(state => ({
    userPermissions: state.user.permissions
  }))(MeasuresIndex);
