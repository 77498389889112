export default class BackgroundJobDto {
  constructor(options = {}) {
      this.id = options.id || 0;
      this.jobType = options.jobType || 0;
      this.statusMessage = options.statusMessage || null;
      this.startedUtc = options.startedUtc || null;
      this.completedUtc = options.completedUtc || null;
      this.errorUtc = options.errorUtc || null;
    }
}
