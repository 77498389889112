import SponsoredOrganizationUpdateDto from './SponsoredOrganizationUpdateDto';

export default class SponsoredOrganizationUpdateListDto {
  constructor(options = {}) {
    this.organizations = options.organizations ? options.organizations.map(dto => new SponsoredOrganizationUpdateDto(dto)) : [];
    this.organizationId = options.organizationId ? options.organizationId : 0;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
