'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _validator = require('validator');

var _validator2 = _interopRequireDefault(_validator);

var _stringParser = require('./stringParser');

var _stringParser2 = _interopRequireDefault(_stringParser);

var _utils = require('./utils');

var _errors = require('./errors');

var _errors2 = _interopRequireDefault(_errors);

var extraValidators = {

  /*
   * isAccepted Validation Mehod
   */
  isAccepted: function isAccepted(value, expected) {
    return value == expected;
  },

  /*
   * isAllIn Validation Method
   */
  isAllIn: function isAllIn(value, options) {
    if (!value) {
      return false;
    }

    return value.every(function (item) {
      return options.indexOf(item) > -1;
    });
  }

};

/**
 * Validate a value against a validation item
 *
 * @param  any     value          Value being tested
 * @param  object  validationItem Rule set for validator
 * @return Array.  The first position is  a boolean, specifying if the answer is valid.  The second position
 *                 is any other info the validate function wants to return.
 */
function validateAnswer(value, validationItem, questionAnswers) {
  var validationMethod = typeof extraValidators[validationItem.type] !== 'undefined' ? extraValidators[validationItem.type] : _validator2['default'].hasOwnProperty(validationItem.type) && typeof _validator2['default'][validationItem.type] === 'function' ? _validator2['default'][validationItem.type] : undefined;

  if (!validationMethod) {
    throw new Error('Winterfell: Attempted to validate for undefined method "' + validationItem.type + '"');
  }

  /*
   * Clone the validation parameters so it doesn't effect the
   * parameters elsewhere by reference.
   */
  var validationParameters = (validationItem.params || []).slice(0);

  /*
   * Run the parameters through the stringParser with the
   * questionAnswers so that it sets the questionAnswer
   * as the parameter.
   */
  validationParameters = validationParameters.map(function (p) {
    return typeof p === 'string' ? (0, _stringParser2['default'])(p, questionAnswers) : p;
  });

  /*
   * Push the value of the question we're validating to
   * the first parameter of the validationParameters
   */
  validationParameters.unshift(value);

  /*
   * Return the result of the validation method running
   * wtih the validationParameters.
   */
  var result = validationMethod.apply(null, validationParameters);
  return Array.isArray(result) ? result : [result];
};

/**
 * Get all invalid questions from question sets
 *
 * @param  array  questionSets     All question sets
 * @param  object questionAnswers  Current answers for questions
 * @return object                  Set of questions and their invalidations
 */
function getQuestionPanelInvalidQuestions(questionSets, questionAnswers) {
  var questionsToCheck = (0, _utils.getActiveQuestionsFromQuestionSets)(questionSets, questionAnswers).filter(function (question) {
    return question.validations instanceof Array && question.validations.length > 0;
  });

  /*
   * Now we run validations for the questions
   * we need to check for errors.
   *
   * Go through every question, and its validations
   * then run the question and answer through
   * the validation method required.
   */
  var errors = questionsToCheck.reduce(function (errors, _ref) {
    var questionId = _ref.questionId;
    var validations = _ref.validations;

    validations.forEach(function (validation) {
      var _validateAnswer = validateAnswer(questionAnswers[questionId], validation, questionAnswers);

      var _validateAnswer2 = _slicedToArray(_validateAnswer, 2);

      var valid = _validateAnswer2[0];
      var errorInfo = _validateAnswer2[1];

      if (!valid) {
        if (!errors[questionId]) errors[questionId] = [];
        errors[questionId].push(createQuestionError(validation, errorInfo));
      }
    });
    return errors;
  }, {});
  return errors;
};

/**
 * Get all invalidation questions for all question panels
 * 
 * @param array questionPanels - All question panels
 * @param object questionAnswers - Current answers for questions
 * @return object - Set of question panels and their invalidations
 */
function getSurveyInvalidQuestions(schema, questionAnswers) {
  return schema.questionPanels.reduce(function (errors, panel) {
    var questionSets = (0, _utils.getQuestionSets)(schema, panel.panelId);
    errors[panel.panelId] = getQuestionPanelInvalidQuestions(questionSets, questionAnswers);
    return errors;
  }, {});
}

/***** Validity checks *****/
function isQuestionValid(validatedQuestion) {
  return !validatedQuestion || validatedQuestion.length === 0;
}

function isPanelValid(validatedPanel) {
  if (!validatedPanel || typeof validatedPanel !== 'object') return true;

  var valid = true;
  Object.keys(validatedPanel).forEach(function (key) {
    if (!isQuestionValid(validatedPanel[key])) {
      valid = false;
    }
  });
  return valid;
}

function isSurveyValid(validatedPanels) {
  if (!validatedPanels || typeof validatedPanels !== 'object') return true;

  var valid = true;
  Object.keys(validatedPanels).forEach(function (key) {
    if (!isPanelValid(validatedPanels[key])) {
      valid = false;
    }
  });
  return valid;
}

function createQuestionError(validation) {
  var errorInfo = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

  return {
    type: validation.type,
    message: _errors2['default'].getErrorMessage(validation),
    validation: validation,
    info: errorInfo
  };
}

/**
 * 
 * @param {Object} invalidQuestions - the invalid questions for a question panel
 */
function getValidationErrors(invalidQuestions) {
  var validationErrors = {};
  Object.keys(invalidQuestions).forEach(function (questionId) {
    var validations = invalidQuestions[questionId];
    validationErrors[questionId] = validations.map(function (validation) {
      return createQuestionError(validation);
    });
  });
  return validationErrors;
}

/****** Methods for adding validation functions *******/

/**
 * Add a single validation method
 *
 * @param  string   name   Name of validation method
 * @param  function method Validation method
 */
function addValidationMethod(name, method) {
  if (typeof name !== 'string') {
    throw new Error('Winterfell: First parameter of addValidationMethod ' + 'must be of type string');
  }

  if (typeof method !== 'function') {
    throw new Error('Winterfell: Second parameter of addValidationMethod ' + 'must be of type function');
  }

  extraValidators[name] = method;
};

/**
 * Add multiple validation methods
 *
 * @param  array methods Methods to add. name => func
 */
function addValidationMethods(methods) {
  if (typeof methods !== 'object') {
    throw new Error('Winterfell: First parameter of addValidationMethods ' + 'must be of type object');
  }

  for (var methodName in methods) {
    addValidationMethod(methodName, methods[methodName]);
  }
};

exports['default'] = {
  addValidationMethod: addValidationMethod,
  addValidationMethods: addValidationMethods,

  createQuestionError: createQuestionError,
  getSurveyInvalidQuestions: getSurveyInvalidQuestions,
  getQuestionPanelInvalidQuestions: getQuestionPanelInvalidQuestions,
  validateAnswer: validateAnswer,

  isSurveyValid: isSurveyValid,
  isPanelValid: isPanelValid,
  isQuestionValid: isQuestionValid
};
module.exports = exports['default'];