import React from 'react';

import { TextInput, SelectInput, IntegerInput, DecimalInput, RadioButtonYesNo } from '../index';

const getInputField = (fieldDefinition, formikProps) => {
  switch (fieldDefinition.type) {
    case 'Decimal':
      return <DecimalInput {...fieldDefinition} {...formikProps} />;

    case 'Integer':
      return <IntegerInput {...fieldDefinition} {...formikProps} />;

    case 'YesNo':
      return <RadioButtonYesNo {...fieldDefinition} {...formikProps} />;

    case 'Text':
      return <TextInput {...fieldDefinition} {...formikProps} />;

    case 'DropDown':
        const options = fieldDefinition.options;
        const disabledOption = options.some(option => option.disabled);
        return <SelectInput options={options} includeDefaultOption={!disabledOption} {...fieldDefinition} {...formikProps} />

    case 'Display':
      const displayTextWithLineBreaks = fieldDefinition.displayText.replace(/\n/g, '<br>');
      return <div dangerouslySetInnerHTML={{ __html: displayTextWithLineBreaks }} />;

    default:
      return null;
  }
}

export default getInputField;
