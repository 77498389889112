export default class ImportResult {
  constructor(options = {}) {
    this.lineNumber = options.lineNumber || 0;
    this.exception = options.exception || '';
    this.didError = options.didError || (options.exception !== '' && options.exception !== null);
  }

  stringify() {
    return JSON.stringify(this);
  }
}