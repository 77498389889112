import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IndexList from '../organization/IndexList.js';
import IndexActions from '../shared/indexList/IndexActions.js';
import OrganizationIndexActions from './OrganizationIndexActions';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import IndexItem from '../../DTOs/shared/IndexItem';
import { actionCreators } from '../../store/User';

import impersonate from '../../images/UserCircle.png';
import impersonateDown from '../../images/UserCircleFilled.png';
import impersonateHover from '../../images/UserCircleDark.png';

export class OrganizationIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      listData: []
    };

    this.handleSpecialActionClick = this.handleSpecialActionClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleUpdateDataClick= this.handleUpdateDataClick.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
  }

  componentDidMount() {
    api.getAllOrganizations()
      .then(result => {
        this.setState({
          organizations: result.organizations,

          listData: result.organizations.map(
            organization =>
              new IndexItem({
                title: organization.organizationName,
                itemId: organization.id,
                url: `/Organization/${organization.id}`,
                isDisabled: organization.isDisabled
              })
          )
        });
      })
      .catch(err => {
        message.error(`Error getting organizations: ${err}`);
      });
  }

  handleSpecialActionClick(e, id) {
    e.stopPropagation();
    e.preventDefault();
    this.props.startImpersonating(id);
    this.props.history.push('/Dashboard');
  }

  handleAddClick() {
    this.props.history.push('/Organization/AddOrganization');
  }

  handleUpdateDataClick() {
    this.props.history.push('/Organization/UpdateInvalidDataIndex');
  }

  onSearchSelect(selected) {
    this.props.history.push(`/Organization/${selected.value}`)
  }

  render() {

    return (
      <React.Fragment>
        <IndexActions>
          <OrganizationIndexActions
            addOrganizationClickHandler={this.handleAddClick}
            updateInvalidDataClickHandler={this.handleUpdateDataClick}
            selectResult={this.onSearchSelect}
          />
        </IndexActions>

        <IndexList
          listData={this.state.listData}
          iconDefaultSrc={impersonate}
          iconHoverSrc={impersonateHover}
          iconClickedSrc={impersonateDown}
          toolTipText='Log in as this organization'
          toolTipPlacement='left'
          specialActionClickHandler={this.handleSpecialActionClick}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({ effectiveId: state.user.effectiveOrganizationId }),
  dispatch => bindActionCreators(actionCreators, dispatch)
)(OrganizationIndex);
