import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import OrganizationDisplay from './OrganizationDisplay.js';
import OrganizationEdit from './OrganizationEdit.js';
import BottomTabs from './BottomTabs.js';
import { message } from '../shared/Message';

import api from '../../scripts/api';
import OrganizationDto from '../../DTOs/Organization/OrganizationDto.js';
import { licenseTypes, licenseLevels } from '../../constants.js';

export class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: new OrganizationDto(),
      showEdit: false
    };

    this.changeShowEditState = this.changeShowEditState.bind(this);
    this.editOrganizationSubmit = this.editOrganizationSubmit.bind(this);
  }

  initializeComponent(id) {
    api.getOrganizationById(id).then(result => {
      this.setState({
        organization: result
      });
    }).catch(err => {
      message.error(`Error getting organization: ${err}`);
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.initializeComponent(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;

    if (id !== prevProps.match.params.id) {
      this.initializeComponent(id);
    }
  }

  changeShowEditState() {
    this.setState({
      showEdit: !this.state.showEdit
    });
  }

  editOrganizationSubmit(values, actions) {
    message.dismissAll();

    const dto = new OrganizationDto({
      ...values,
      isDisabled: !values.isActive
    });

    api.updateOrganization(dto.stringify()).then(response => {
      this.setState({
        organization: response,
        showEdit: !this.state.showEdit
      });
    }).catch(err => {
      actions.setSubmitting(false);
      message.error(`Error updating organization: ${err}`);
    });
  }

  render() {
    const { organization, showEdit } = this.state;
    const canSponsor = organization.licenseType === licenseTypes.SPONSOR;

    return (
      <React.Fragment>
        <Container className="basic-card pb-3 mb-5">
          {showEdit
            ? <OrganizationEdit
              editAction="Edit"
              enableMfaControl={false}
              organization={organization}
              redirect={false}
              handleCancelClick={this.changeShowEditState}
              handleSubmitClick={this.editOrganizationSubmit}
            />
            : <OrganizationDisplay
              organization={organization}
              handleEditClick={this.changeShowEditState} />
          }
        </Container>

        <BottomTabs
          organizationId={organization.id}
          canSponsor={canSponsor}
        />
      </React.Fragment>
    );
  }
}

export default Organization;
