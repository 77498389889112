import React, { Component } from 'react';
import { withRouter } from '../../shared/RouterShim';
import IndexList from '../../shared/indexList/IndexList';
import IndexItem from '../../../DTOs/shared/IndexItem';
import api from '../../../scripts/api';
import { message } from '../../shared/Message';

const getStaffData = objs => {
  return objs.map(user => new IndexItem({
    title: `${user.lastName}, ${user.firstName}`,
    description: `Start Date: ${user.startDate}`,
    itemId: user.id,
    url: `/Staff/${user.id}`
  }))
}

class StaffList extends Component {
  state = {
    staff: [],
    data: [],
    apiError: null
  }

  async componentDidMount() {
    let newState = {
      staff: [],
      data: []
    };

    try {

      newState.staff = (this.props.showActive)
        ? (await api.getAllActiveStaff()).applicationUsers
        : (await api.getAllInactiveStaff()).applicationUsers;

      newState.data = getStaffData(newState.staff);
      
      this.setState(newState);
    }
    catch (err) {
      console.log(`Error getting staff list: ${err}`);
      message.error(`Error getting staff list: ${err}`);
    }
  }

  render() {
    return (
      <React.Fragment>
        <IndexList listData={this.state.data} />
      </React.Fragment>
    );
  }
}

export default withRouter(StaffList);
