import React, { useState, useEffect, useContext } from "react";
import auth0Base from '../../scripts/auth0Base';



export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);


export const Auth0Provider = ({ children, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loading, setLoading] = useState(true);
  const [auth0Client, setAuth0] = useState();
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0Client = await auth0Base.getAuth0Client();
      setAuth0(auth0Client);

      // 'code' is present in search when the user logs in on Auth0 and is redirected back
      if (window.location.search.includes("code=")) {
        const { appState } = await auth0Client.handleRedirectCallback();
        if (appState && appState.targetUrl) {
          setRedirectTo(appState.targetUrl);
        }
      }
      else if (window.location.pathname === "/") {
        setRedirectTo("/Dashboard");
      } else {
        //Handles external routing (manual refresh, typing a URL directly, linking from another site)
        setRedirectTo(window.location.pathname);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      setLoading(false);
    };
    initAuth0();
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        redirectTo,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
