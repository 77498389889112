import React from 'react';

const RadioButton = ({
  name,
  value,
  onChange,
  id,
  label,
  checked,
  className
}) => {
  return (
    <div className={className}>
      <input
        name={name}
        id={id}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-button"
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;