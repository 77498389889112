import React from 'react';
import { Container, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

function DisplayExternalAnalytics({ externalAnalytics, handleBackClick, handleEditClick, handleDeleteClick, currentUserRole }) {
  return (
    <Container className="basic-card pb-3"> 
      <Row className="mb-3">
        <Col>
          <h4 className="font-weight-bold">{externalAnalytics.displayName}</h4>
        </Col>
      </Row>

      { currentUserRole === "SiteAdministrator" ?
        <>
          <Row className="mb-3">
            <Col>
              External ID: {externalAnalytics.externalId}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              Replaces Default Analytics: {externalAnalytics.replacesDefaultAnalytics ? "Yes" : "No"}
            </Col>
          </Row>
        </>
      : null }

      <ButtonToolbar className="justify-content-between">
        <Button variant="outline-primary" size="sm" onClick={handleBackClick}>Back</Button>
        { currentUserRole === "SiteAdministrator" ? 
        <div md={8} className="d-flex justify-content-between">
          <Button
            size="sm"
            variant="outline-primary"
            className="mr-3"
            onClick={handleEditClick}
          >Edit</Button>
          <Button
            onClick={handleDeleteClick}
            size="sm"
            variant="outline-primary"
            className="mr-3"
          >Delete</Button>
        </div>
        : null }
      </ButtonToolbar>
    </Container>
  )
}

export default DisplayExternalAnalytics
