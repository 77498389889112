import React, { Component } from 'react';
import { ListGroup, Spinner, Alert } from 'react-bootstrap';
import { formatCurrency } from '../../scripts/numHelpers';
import { formatDisplayNumber } from '../../scripts/numHelpers';

export class CostPerSuccessPage extends Component {
  constructor(props) {
    super(props);
  }

  hasData = (data) => {
    return data && data.length > 0;
  }

  render() {
    const { listData, showSpinner } = this.props;

    if (showSpinner) {
      return (
        <Spinner animation="border" className="sureimpact__spinner"></Spinner>
      );
    }

    return (
      <React.Fragment>
        {this.hasData(listData)
          ? <ListGroup className="basic-card">
            {listData.map(list => (
              <ListGroup.Item className="d-flex list-group-item" key={list.programId}>
                <span className="text-right col-2">
                  <h2>{formatCurrency(list.costPerSuccess)}</h2>
                </span>
                <div className="ml-5">
                  <div>
                    <span className="font-weight-bold mt-0 program-title">{list.programName}</span>
                    <span className="font-weight-bold">({formatCurrency(list.programCost)} program cost with {formatDisplayNumber(list.totalParticipants)} total participants)</span>
                  </div>
                  <div className="description_color">{list.programDescription}</div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          : <Alert variant="info" className="print-card"> No Cost Per Success Data to Display </Alert>
        }
      </React.Fragment>
    );
  }
}

export default CostPerSuccessPage;
