import React from 'react';
import TextInput from './TextInput';
import { getFloatString, formatDisplayNumber } from '../../../scripts/numHelpers';
import { updateCursor, cleanInput, copyEvent } from './inputHelpers';

/**
 * The DecimalInput component for inputting decimal values.
 * This component allows any number of integers before the decimal place and 
 * either any number of integers or a specified max number of integers to the right of the decimal place.
 * 
 * While the input is formatted, the value returned from this component is a valid decimal value.
 *    Display: 1,000.50 ------ Returned value: 1000.50
 * 
 * Also note that a number may end with a decimal.  This means 1000. is a valid return value from this input
 */
const DecimalInput = ({ value, onChange, onBlur, ...props }) => {

  // Need to hold a reference to the O.G. value so we can reset if the new value is out of range
  const originalValue = value;

  const getCleanInput = (inputValue) => {
    return cleanInput(getFloatString(inputValue), originalValue, props);
  };

  // Handles when the input is changed
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);
    updateCursor(e, inputValue, formatDisplayNumber(cleanedInput));

    e.target.value = cleanedInput;

    if (onChange )onChange(e);
  }

  // Handles when the input is blurred
  const handleBlur = (e) => {
    const inputValue = e.target.value;
    const cleanedInput = getCleanInput(inputValue);

    let eventCopy = copyEvent(e);
    eventCopy.target.value = cleanedInput;

    if (onBlur) onBlur(eventCopy);
  }

  const displayValue = formatDisplayNumber(value);

  return (
    <TextInput
      {...props}
      type="text"
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export default DecimalInput;