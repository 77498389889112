import React from 'react';


const SectionHeader = ({ header, subHeader }) => {
  return (
    <div className="mb-4">
      <strong>{header}</strong>{subHeader && ` - ${subHeader}`}
    </div>
  );
}

export default SectionHeader;