import '../../../../styles/SureImpact.radio.css';

import React from 'react';
import classNames from 'classnames';

const InputFeedback = ({ error }) =>
  error ? <div className={classNames("input-feedback")}>{error}</div> : null;

const RadioButtonGroup = ({
  value,
  error,
  touched,
  className,
  children,
  wrapClasses,  //additional classes to be applied to the radio-group-wrap div
  ...props
}) => {
  const classes = classNames(
    'radio-group',
    {
      "is-success": value || (!error && touched), // handle pre-filled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
      <div>
        <div className={classNames('radio-group-wrap', wrapClasses)}>
          {children}
        </div>
        {touched && <InputFeedback error={error} />}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
