export default class OrganizationServiceDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.name = options.name || '';
    this.description = options.description || '';
    this.isDisabled = options.isDisabled || false;

    this.isActive = !options.isDisabled;  // reverse of isDisabled for UI purposes

    this.isSponsored = options.isSponsored || false
  }

  stringify() {
    return JSON.stringify(this);
  }
}