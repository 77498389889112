/**
 * Calculates how many characters are being added for display purposes
 *
 * @param {String} str the string we're going to look at
 * @param {Number} pos the beginning cursor position
 * @param {String} char character to look for within str
 * @return {Number} the number of characters that have been added.
 */

const getNumCharsAdded = (str, pos, char) => str.slice(0, pos).split(char).length - 1;

export default getNumCharsAdded;