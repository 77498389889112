
/**
 * Validation methods to use with the demographic inputs 
 */
export default {
  exists: value => {
    if (value === undefined || value === null) return false;
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === 'string' || value instanceof String) {
      return value !== '';
    }
    return true;
  },
  isNumber: value => {
    return !value || !isNaN(parseInt(value, 10));
  },
  isPhoneNumber: value => {
    if (!value) return true;
    const regex = /^\d{3}-\d{3}-\d{4}$/;
    return regex.test(value);
  },
  isEmail: value => {
    if (!value) return true;
    // regex from here http://zparacha.com/validate-email-address-using-javascript-regular-expression
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(value);
  },
  isDateMMDDYYYY: value => {
    if (!value) return true;
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(value) && !isNaN(Date.parse(value));
  },
  noSpecialCharacters: value => {
    if (!value) return true;
    const regex = /^[A-Za-z0-9., ]*$/;
    return regex.test(value);
  },
  isDecimalLength: (value, options) => {
    if (!value || !options) return true;

    const parts = value.toString().split('.');
    if ((options.wholeMaxDigits !== undefined && parts[0].length > options.wholeMaxDigits)
      || (options.decimalMaxDigits !== undefined && parts.length > 1 && parts[1].length > options.decimalMaxDigits)) {
      return false;
    }
    return true;
  },
  noFutureDate: value => {
    if (!value) return true;
    const dateVal = Date.parse(value);
    return !isNaN(dateVal) && dateVal < new Date();
  },
  isInRange: (value, options) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) return true;

    const minValue = parseFloat(options.min);
    const maxValue = parseFloat(options.max);

    if ((!isNaN(minValue) && parsedValue < minValue) || (!isNaN(maxValue) && parsedValue > maxValue)) {
      return false;
    }
    return true;
  }
}
