'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x2, _x3, _x4) { var _again = true; _function: while (_again) { var object = _x2, property = _x3, receiver = _x4; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x2 = parent; _x3 = property; _x4 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _inputTypes = require('./inputTypes');

var _inputTypes2 = _interopRequireDefault(_inputTypes);

var _libValidation = require('./lib/validation');

var _libValidation2 = _interopRequireDefault(_libValidation);

var _libUtils = require('./lib/utils');

/**
 * Gets the input component for the given question. If the component for the given
 * input type does not exist, an error is thrown.
 * 
 * @param {Question} question The question object
 * @returns {Component} the input component
 */
function getInput(question) {
  var input = question.input;

  var Input = _inputTypes2['default'][input.type];
  if (!Input && input.type !== undefined && input.type !== '') {
    throw new Error('Winterfell: Input Type "' + input + '" not defined as Winterfell Input Type');
  }
  return Input;
}

var Question = (function (_React$Component) {
  _inherits(Question, _React$Component);

  function Question() {
    _classCallCheck(this, Question);

    _get(Object.getPrototypeOf(Question.prototype), 'constructor', this).apply(this, arguments);
  }

  _createClass(Question, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // If we have a default answer, go ahead and set it. (assuming the user has not changed it yet)
      var _props = this.props;
      var question = _props.question;
      var questionAnswers = _props.questionAnswers;

      if (question.input['default'] !== undefined && questionAnswers[question.questionId] === undefined || question.input.type === 'checkboxInput' && questionAnswers[question.questionId] === undefined) {
        this.handleInputChange(question.questionId, question.input['default']);
      }
    }
  }, {
    key: 'handleInputChange',
    value: function handleInputChange(questionId, value) {
      var _props$question = this.props.question;
      var validateOn = _props$question.validateOn;
      var validations = _props$question.validations;

      value = (0, _libUtils.isEvent)(value) ? value.target.value : value;
      this.props.onAnswerChange(questionId, value, validations, validateOn);
    }
  }, {
    key: 'handleInputBlur',
    value: function handleInputBlur(questionId, value) {
      var _props$question2 = this.props.question;
      var validateOn = _props$question2.validateOn;
      var validations = _props$question2.validations;

      value = (0, _libUtils.isEvent)(value) ? value.target.value : value;
      this.props.onQuestionBlur(questionId, value, validations, validateOn);
    }
  }, {
    key: 'shouldComponentUpdate',
    value: function shouldComponentUpdate(nextProps) {
      // We will only re render the input if the value is different than the previous value
      // his is fine for lists / objects as well since a new object is created for a new value
      return nextProps.value !== this.props.value || nextProps.validationErrors !== this.props.validationErrors;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this = this;

      var _props2 = this.props;
      var validationErrors = _props2.validationErrors;
      var question = _props2.question;
      var questionAnswers = _props2.questionAnswers;

      var Input = getInput(question);
      var value = questionAnswers[question.questionId];

      var input = question.input;
      var labelId = question.questionId + '-label';
      var isInvalid = !_libValidation2['default'].isQuestionValid(validationErrors[question.questionId]);
      var errorMessage = isInvalid ? validationErrors[question.questionId][0].message : '';

      var getInputProps = function getInputProps() {
        var props = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
        return _extends({
          isInvalid: isInvalid,
          errorMessage: errorMessage,
          errors: validationErrors[question.questionId],
          name: question.questionId,
          id: question.questionId,
          text: question.text,
          labelId: labelId,
          value: value,
          options: input.options,
          placeholder: input.placeholder
        }, props, {
          onChange: (0, _libUtils.callAll)(props.onChange, _this.handleInputChange.bind(_this, question.questionId)),
          onBlur: (0, _libUtils.callAll)(props.onBlur, _this.handleInputBlur.bind(_this, question.questionId))
        }, input.props || {});
      };

      var props = {
        question: question.question,
        text: question.text,
        postText: question.postText,
        isInvalid: isInvalid,
        layout: question.layout,
        extra: _extends({}, question)
      };

      var element = this.props.children({
        Input: Input,
        props: props,
        getInputProps: getInputProps
      });
      var clonedElement = _react2['default'].cloneElement(element, { key: question.questionId });
      return clonedElement;
    }
  }]);

  return Question;
})(_react2['default'].Component);

;

Question.defaultProps = {
  question: {
    questionSetId: undefined,
    questionId: undefined,
    question: '',
    validateOn: 'blur',
    text: undefined,
    postText: undefined,
    layout: {},
    input: {
      'default': undefined,
      type: 'textInput',
      limit: undefined,
      placeholder: undefined,
      props: {}
    },
    validations: []
  },
  questionAnswers: {},
  validationErrors: {},
  onAnswerChange: function onAnswerChange() {},
  onQuestionBlur: function onQuestionBlur() {},
  onKeyDown: function onKeyDown() {}
};

exports['default'] = Question;
module.exports = exports['default'];