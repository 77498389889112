export default class ServiceHoursDetailsDto {
  constructor(options = {}) {
    this.organizationServiceId = options.organizationServiceId || 0;
    this.serviceHours = options.serviceHours || 0;
    this.serviceTitle = options.serviceTitle || '';
    this.serviceDescription = options.serviceDescription || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
