import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';

function SponsoredAnalyticsHeader({ handleAddPartnersClick, editSponsoredList }) {
    return (
        <Row className="my-3">
            <Col md={6}>
                <h5>Associated Partners</h5>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
                { !editSponsoredList ?
                    <Button
                        variant="outline-primary"
                        onClick={handleAddPartnersClick}
                    >
                        Add Partners
                    </Button>
                    : null }
            </Col>
        </Row>
    )
}

export default SponsoredAnalyticsHeader
