import React from 'react';

const noOp = () => { };

const DemographicsBuilderContext = React.createContext({
  currentSchemaVersion: null,
  schema: {},
  currentPanelId: '',

  updateCurrentSchemaVersion: noOp,
  updateCurrentPanel: noOp,
  updateSchema: noOp,

  isSchemaSelected: noOp,
  isPanelSeleted: noOp,

  showPreview: noOp,
  saveAsDraft: noOp,
});
DemographicsBuilderContext.displayName = 'DemographicsBuilderContext';

export default DemographicsBuilderContext;
