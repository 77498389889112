import React, { Component } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';

export default class FilterMultiSelectInput extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (filterName, selectedItems) => {
    this.props.form.setFieldValue(filterName, selectedItems.map(x => x.value), false);
    this.props.onFilterChange(filterName, selectedItems);
  }

  render() {
    const { filter, values, isLoading, isDisabled } = this.props;
    const options = filter.filterOptions.map(x => ({ value: x.value, label: x.text }));
    let initialValue = '';
    if (values[filter.name]) {
      initialValue = options.filter(x => +values[filter.name].includes(+x.value))
    }

    return (
      <Form.Group as={Col} md={4} className="pr-3">
        {isLoading ? <Spinner animation="border" className="sureimpact__spinner_export" /> : 
        <Select
            isMulti
            isDisabled={isDisabled}
            classNamePrefix="react-multiselect"
            name={filter.name}
            options={options}
            defaultValue={initialValue}
            onChange={(e) => this.handleChange(filter.name, e ? e : [])}
            placeholder={filter.displayName}
            closeMenuOnSelect={false}
          />
        }
      </Form.Group>
    );
  }
}
