import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { message } from '../shared/Message';
import { getEmbedParameters } from '../../scripts/api/analyticsApi';
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react';
import { models } from 'powerbi-client';

function AnalyticsReportIndex() {
  //Note:  the config is initialized this way to bootstrap the iframe loading
  const [embedConfig, setEmbedConfig] = useState({
    type: EmbedType.Report,
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed
  });

  const [report, setReport] = useState(null);

  const { id } = useParams();
  const currentUser = useSelector(state => state.user);
  const { effectiveOrganizationId, organizationId } = currentUser;

  const handlePrint = () => {
    report.print();
  }

  const handleFullscreen = () => {
    report.fullscreen();
  }

  const getFilterTarget = (target, orgId) => ({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: target.table,
      column: target.column
    },
    operator: "In",
    values: [orgId]
  });

  useEffect(() => {
    const fetchAnalytics = async (id) => {
      try {
        let response = await getEmbedParameters(id);
        let orgId = effectiveOrganizationId;

        if (!orgId) {
          orgId = organizationId;
        }

        const FILTER_TARGETS = [
          { table: 'ROLLUP_ORGANIZATION_DIMENSION', column: 'Rollup Organization Id' }
        ];

        const filters = FILTER_TARGETS?.map((target) => getFilterTarget(target, orgId));

        setEmbedConfig({
          type: EmbedType.Report,
          id: response.embedReport.reportId,
          embedUrl: response.embedReport.embedUrl,
          accessToken: response.embedToken.token,
          tokenType: models.TokenType.Embed,
          filters: filters,
          settings: {
            filterPaneEnabled: false,
          }
        });
      }
      catch (err) {
        message.error(`Error getting report: ${err.message}`);
      }
    }

    fetchAnalytics(id);
  }, [id, effectiveOrganizationId, organizationId]);

  return (
    <>
      <ButtonToolbar md={6} className="mb-4 d-flex justify-content-end">
        <Button variant="outline-primary" size="sm" onClick={handlePrint}>Print</Button>
        <Button variant="outline-primary" size="sm" onClick={handleFullscreen}>Full Screen</Button>
      </ButtonToolbar>

      <PowerBIEmbed
        embedConfig={embedConfig}
        cssClassName="pbi-report"
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport);
        }}
      />
    </>
  );
}

export default AnalyticsReportIndex;
