import ClientEventDto from './ClientEventDto';
export default class ClientEventLogDto {
  constructor(options = {}) {
    this.pageIndex = options.pageIndex || 0;
    this.pageSize = options.pageSize || 10;
    this.totalMatches = options.totalMatches || 0;
    this.history = options.history?.map(dto => new ClientEventDto(dto)) || [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
