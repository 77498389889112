import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import * as Inputs from './displayInputs';
import { FaArrowsAlt } from 'react-icons/fa';

const FormElement = ({ index, question, ...props }) => {
  const InputInfo = Inputs.InputsDictionary[question.type];

  if (!InputInfo)
    return null;

  let createDisplay = ({ isDragging = false, dragHandleProps = {}, isClone = false }) => {

    const containerClasses = classnames(
      'py-3',
      'form-elements-list__item',
      {
        'can-grab': !props.showDragHandle,
        'form-elements-list__item--hoverable': !props.isDragging, // Checks if something is being dragged, not necessarily this element
        'form-elements-list__item--active': isDragging,
        'form-elements-list__item--clone': isClone
      }
    );

    return (
      <Row
        className={containerClasses}
        onClick={props.onClick ? props.onClick : null}
      >
        <Col md={5}>
          <div className="form-elements-list__label">
            <div className="form-elements-list__label__text">{question.name}</div>
            {props.showDragHandle &&
              <div
                className="mr-2 can-drag"
                {...dragHandleProps}
              >
                <FaArrowsAlt color="#007bff" />
              </div>
            }
          </div>
        </Col>
        <Col md={7}>
          <InputInfo.display {...question.getDisplayProps()} />
        </Col>
      </Row>
    );
  }

  return (
    <Draggable
      draggableId={question.id}
      index={index}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...(!props.showDragHandle ? provided.dragHandleProps : {})}
          >
            {createDisplay({ isDragging: snapshot.isDragging, dragHandleProps: provided.dragHandleProps })}
          </div>
          {props.showPlaceholderOnDrag && snapshot.isDragging && (
            createDisplay({ isClone: true })
          )}
        </React.Fragment>
      )}
    </Draggable>

  );
}

export default FormElement;