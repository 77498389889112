import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import SponsoredOrganizations from './organizations/SponsoredOrganizations';

class BottomTabs extends Component {

  render() {
    const { service } = this.props;

    return (
      <Tab.Container id="service-tabs" defaultActiveKey="partners">
        <Nav variant="tabs" className="mt-5">
          <Nav.Item className="w-25 text-center">
            <Nav.Link eventKey="partners">Partners</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className="basic-card">
          <Tab.Pane eventKey="partners">

            <SponsoredOrganizations serviceId={service.id} />

          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    );
  }
}

export default BottomTabs;