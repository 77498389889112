export default class ImportDemographicValidationsDto {
    constructor(options = {}) {
        this.validationType = options.validationType || '';
        this.validationMessage = options.validationMessage || '';
        this.minValue = options.minValue || 0;
        this.maxValue = options.maxValue || 0;
        this.wholeMaxDigits = options.wholeMaxDigits || 0;
        this.decimalMaxDigits = options.decimalMaxDigits || 0;
    }

    stringify() {
        return JSON.stringify(this);
    }
}
