import ImportFieldDto from './ImportFieldDto';
import ImportDemographicDto from './ImportDemographicDto';
import ProgramEnrollmentDto from './ProgramEnrollmentDto';

export default class ImportFieldResultsDto {
    constructor(options = {}) {
        this.blobUri = options.blobUri || '';
        this.totalFields = options.totalFields || 0;
        this.importFields = options.importFields.map(f => new ImportFieldDto(f)) || [];
        this.importDemographics = options.importDemographics.map(d => new ImportDemographicDto(d)) || [];
        this.programEnrollments = options.programEnrollments.map(d => new ProgramEnrollmentDto(d)) || [];
    }

    stringify() {
        return JSON.stringify(this);
    }
}
