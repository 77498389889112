import React, { Component } from 'react';
import { message } from '../../shared/Message';
import FilePicker from '../../shared/inputs/FilePicker/FilePicker';
import api, { downloadFile } from "../../../scripts/api";
import OutlineButton from "../../shared/inputs/OutlineButton";
import { Card, Row, Col, FormLabel, Container, Spinner } from "react-bootstrap";
import ImportErrorCard from '../../shared/imports/ImportErrorCard';

class ServiceImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadInputId: 'file',
      chosenFileName: null,
      file: null,
      fileIsSelected: false,
      importing: false,
      importResults: null,
      importErrors: null,
      formValues: null,
      showImport: false,
      showError: false,
      showSpinner: false
    };
  }

  handleCancel = () => {
    message.dismissAll();
    this.setState({
      uploadInputId: 'file',
      chosenFileName: null,
      file: null,
      fileIsSelected: false,
      importResults: null,
      importErrors: null,
      formValues: null,
      keywords: null,
      showImport: false,
      showError: false,
      showSpinner: false
    });
  }

  chooseUploadFile = file => {
    this.setState({
      chosenFileName: file.name,
      file: file,
      fileIsSelected: true
    });
  };

  handleDownloadTemplate = async () => {
    try {
      api.downloadServiceActivityTemplate();
    } catch (err) {
      message.error(err);
    }
  };

  async handleImportClick(file) {
    try {
      message.dismissAll();
      this.setState({ showSpinner: true });
      const result = await api.uploadServiceImport(file);

      if (result.hasErrors) {
        this.setState({
          showSpinner: false,
          importErrors: result,
          showError: true
        });
      }
      else {
        this.handleCancel();
        message.success(`Imported ${result.totalImported} rows successfully!`);
      }
    } catch (err) {
      this.handleCancel();

      message.error(`An error occurred while processing the import: ${err.message}`);

    }
  }

  chooseUploadFile = file => {
    this.setState({
      chosenFileName: file.name,
      file: file,
      fileIsSelected: true
    });
  };

  async processFile() {
    try {
      message.dismissAll();
      this.setState({ showSpinner: true, showImport: false });
      const dto = this.state.importResults;
      const result = await api.processDemographicImport(dto.stringify());

      if (result.hasErrors) {
        this.setState({
          showSpinner: false,
          importErrors: result,
          showError: true
        });
      }
      else {
        this.handleCancel();
        message.success(`Imported ${result.totalImported} rows successfully!`);
      }
    } catch (err) {
      this.handleCancel();
      message.error(`An error occurred while processing the import: ${err.message}`);
    }

  }

  render() {
    const { importResults, importErrors, formValues, showSpinner, uploadInputId, fileIsSelected,
      chosenFileName, file, showImport, showError, importing } = this.state;

    if (showSpinner) {
      return (
        <Spinner animation="border" className="sureimpact__spinner"></Spinner>
      );
    }

    if (showError) {
      return (
        <Container>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <ImportErrorCard
                result={importErrors}
                totalProcessed={importErrors.totalImported}
                onClick={this.handleCancel}
                importType="service" />
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Card className="d-flex basic-card p-5 mt-2">
                <strong>
                  To bulk import service data, you must use the template below.
                </strong>

              <Row className="p-3">
                <Col md={2}>
                  <span title="Click here to download a template for import.">
                    <OutlineButton
                      label="Download Template"
                      onClick={this.handleDownloadTemplate}
                    />
                  </span>
                </Col>
              </Row>

              <Row className="mt-5 mb-3">
                <Col>
                  <strong>Upload completed template</strong>
                </Col>
              </Row>

              <FilePicker
                buttonClassName="btn-sm btn-outline-primary"
                uploadInputId={uploadInputId}
                onFileChosen={this.chooseUploadFile}
                onFileRemoved={this.handleCancel}
                fileIsSelected={fileIsSelected}
                chosenFileName={chosenFileName}
              />

              <Row className="p-0 mt-3">
                <Col md={2}>
                  <OutlineButton
                    label="Import"
                    onClick={() => this.handleImportClick(file)}
                    disabled={(!fileIsSelected) || importing}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ServiceImport;
