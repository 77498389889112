import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import withClientPaging from '../shared/paging/withClientPaging';
import ActionIcon from '../shared/ActionIcon.js';

import arrow from '../../images/FlyoutArrow.png';

export const IndexList = ({ listData, variant, noBasicCard, normalTitle, iconDefaultSrc, iconHoverSrc, iconClickedSrc, toolTipText, toolTipPlacement, specialActionClickHandler }) => {
  return (

    <ListGroup className={noBasicCard ? '' : 'basic-card'} variant={variant}>
      {listData.map(item => (
        <ListGroup.Item action key={item.itemId} className="list-group-item organization">
          <LinkContainer to={item.url} className="d-inline-flex pt-3 pb-3 pl-3 flex-grow-1">
            <div>
              <div className="d-flex flex-column text-truncate pr-3">

                {normalTitle
                  ? <div className="text-truncate">{item.title}</div>
                  : <strong className="text-truncate">{item.title}</strong>
                }

                <div className="text-truncate">
                  {item.description}
                </div>
              </div>

              <div className="d-flex flex-grow-1 pr-3 justify-content-end">
                <div className="d-flex index-list__end-text">
                  <strong className="d-flex">{item.endTextDisplay}</strong>
                </div>
                <div className="justify-content-end">
                  <img className="index-list__arrow" src={arrow} alt={`View ${item.title}`} />
                </div>
                </div>
            </div>
          </LinkContainer>
          <LinkContainer to="/Dashboard" onClick={(e) => specialActionClickHandler(e, item.itemId)} className="d-inline-flex pt-3 pb-3 pl-3 pr-3 justify-content-center border-left">
            <div>
              <ActionIcon
                src={iconDefaultSrc}
                srcOnHover={iconHoverSrc}
                srcOnClick={iconClickedSrc}
                imageClassName="index-list__special-button"
                toolTipText={toolTipText}
                toolTipPlacement={toolTipPlacement}
                onClick={specialActionClickHandler}
                itemId={item.itemId}
              />
            </div>
          </LinkContainer>
        </ListGroup.Item>
      ))
      }

    </ListGroup >

  );
}

export default withClientPaging(IndexList);

