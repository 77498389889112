'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = (function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ('value' in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; })();

var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _libValidation = require('./lib/validation');

var _libValidation2 = _interopRequireDefault(_libValidation);

var _libUtils = require('./lib/utils');

var QuestionPanel = (function (_React$Component) {
  _inherits(QuestionPanel, _React$Component);

  function QuestionPanel(props) {
    _classCallCheck(this, QuestionPanel);

    _get(Object.getPrototypeOf(QuestionPanel.prototype), 'constructor', this).call(this, props);

    this.state = {
      touched: {}
    };
  }

  _createClass(QuestionPanel, [{
    key: 'validateAnswer',
    value: function validateAnswer(questionId, questionAnswer, validations) {
      if (!validations || validations.length === 0) {
        return;
      }

      /*
       * Run the question through its validations and
       * show any error messages if invalid.
       */
      var questionAnswers = this.props.questionAnswers;

      var questionValidationErrors = validations.reduce(function (errors, validation) {
        var _Validation$validateAnswer = _libValidation2['default'].validateAnswer(questionAnswer, validation, questionAnswers);

        var _Validation$validateAnswer2 = _slicedToArray(_Validation$validateAnswer, 2);

        var valid = _Validation$validateAnswer2[0];
        var errorInfo = _Validation$validateAnswer2[1];

        if (!valid) {
          errors.push(_libValidation2['default'].createQuestionError(validation, errorInfo));
        }
        return errors;
      }, []);

      this.props.onValidationError(this.props.panel.panelId, questionId, questionValidationErrors);
    }
  }, {
    key: 'performAction',
    value: function performAction(nextAction) {
      var _props = this.props;
      var action = _props.panel.action;
      var questionAnswers = _props.questionAnswers;

      var conditions = action.conditions || [];
      conditions.forEach(function (condition) {
        var answer = questionAnswers[condition.questionId];
        if (answer === condition.value) {
          nextAction = (0, _libUtils.createAction)(condition.action, condition.target);
        }
      });

      /*
       * Decide which action to take depending on
       * the action decided upon.
       */
      switch (nextAction.action) {
        case 'GOTO':
          this.props.onSwitchPanel(nextAction.target);
          break;
        case 'SUBMIT':
          this.props.onSubmit();
          break;
        default:
          console.warn('Do not know how to perform action ' + nextAction.action);
      }
    }
  }, {
    key: 'handleAnswerChange',
    value: function handleAnswerChange(questionId, questionAnswer, validations, validateOn) {
      this.props.onAnswerChange(questionId, questionAnswer);
      if (validateOn === 'change') {
        this.validateAnswer(questionId, questionAnswer, validations);
      }
    }
  }, {
    key: 'handleQuestionBlur',
    value: function handleQuestionBlur(questionId, questionAnswer, validations, validateOn) {
      this.setState({
        touched: _extends({}, this.state.touched, _defineProperty({}, questionId, true))
      });
      if (validateOn === 'blur') {
        this.validateAnswer(questionId, questionAnswer, validations);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this = this;

      var _props2 = this.props;
      var panel = _props2.panel;
      var schema = _props2.schema;
      var questionAnswers = _props2.questionAnswers;
      var validationErrors = _props2.validationErrors;

      var props = {
        questionPanelHeader: panel.panelHeader,
        questionPanelText: panel.panelText,
        isInvalid: !_libValidation2['default'].isPanelValid(validationErrors)
      };

      var previousAction = panel.action ? panel.action.previous : undefined;
      var nextAction = panel.action ? panel.action.next : undefined;
      var getButtonProps = function getButtonProps() {
        return {
          previousAction: previousAction,
          nextAction: nextAction,
          mainButtonText: panel.button ? panel.button.text : undefined,
          backButtonText: panel.backButton ? panel.backButton.text : undefined,
          onMainButtonClick: _this.performAction.bind(_this, nextAction),
          onBackButtonClick: _this.performAction.bind(_this, previousAction)
        };
      };

      var questionSets = panel.questionSets.map(function (questionSetMeta) {
        var questionSet = schema.questionSets.find(function (qs) {
          return qs.questionSetId === questionSetMeta.questionSetId;
        });
        if (!questionSet) {
          return undefined;
        }

        var getQuestionSetProps = function getQuestionSetProps() {
          return {
            validationErrors: validationErrors,
            questionSet: questionSet,
            questionAnswers: questionAnswers,
            onAnswerChange: _this.handleAnswerChange.bind(_this),
            onQuestionBlur: _this.handleQuestionBlur.bind(_this)
          };
        };

        var props = {
          questionSetHeader: questionSet.questionSetHeader,
          questionSetText: questionSet.questionSetText
        };

        var element = _this.props.children({
          props: props,
          getButtonProps: getButtonProps,
          getQuestionSetProps: getQuestionSetProps
        });

        return _react2['default'].cloneElement(element, { key: questionSet.questionSetId });
      });

      return questionSets;
    }
  }]);

  return QuestionPanel;
})(_react2['default'].Component);

;

QuestionPanel.defaultProps = {
  panel: {
    panelId: undefined,
    panelIndex: undefined,
    panelHeader: undefined,
    panelText: undefined,
    questionSets: undefined,
    action: {
      'default': {},
      conditions: []
    },
    button: {
      text: 'Submit'
    },
    backButton: {
      text: 'Back'
    }
  },
  validationErrors: {},
  schema: {},
  questionAnswers: {},
  onAnswerChange: function onAnswerChange() {},
  onSwitchPanel: function onSwitchPanel() {}
};

exports['default'] = QuestionPanel;
module.exports = exports['default'];