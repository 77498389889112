import { format } from 'date-fns';
import { parseDate } from '../../scripts/safeDateParse';

export default class BaseFilterRequestDto {
  constructor(options = {}) {
    this.startDate = options.startDate ? format(parseDate(options.startDate), 'MM/dd/yyyy') : '';
    this.endDate = options.endDate ? format(parseDate(options.endDate), 'MM/dd/yyyy') : '';
    this.active = options.active !== null ? options.active : null;
    this.genders = options.genders || [];
    this.races = options.races || [];
    this.hispanicLatinos = options.hispanicLatinos || [];
    this.programIds = options.programIds || [];
    this.organizationIds = options.organizationIds || [];
    this.collaborativeOrganizationId = options.collaborativeOrganizationId !== null ? options.collaborativeOrganizationId : null;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
