import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Auth0Context } from './Auth0';

import { rolePermissionLevels, licenseLevels, licenseTypes } from './../../constants';
import { hasRequiredPermissions } from '../../scripts/permissionHelper.js';
import { withRouter } from './RouterShim';
import Loading from './Loading.js';

// this is used to check if userPermissions is empty
const checkObjLength = (obj) => {
  return obj && Object.keys(obj).length > 0;
};

class PrivateComponent extends React.Component {

  static contextType = Auth0Context;

  render() {
    const { component: Component, render, promptLogin, requiredPermissions, userPermissions, userLoading, ...rest } = this.props;
    const { isAuthenticated, loading, loginWithRedirect } = this.context;

    // To determine if the user can access this route, we first look at the Auth0Context 'loading'
    // property to determine if Auth0 is still performing its checks to see if there is a current logged
    // in user.  This is for both when the user was logged in and returning to the site and also for when 
    // the user is logging in and we are being redirected back to the site with a 'code=...' part in the URL
    // which is to be used by Auth0.  If we are loading, then we don't render anything yet as we are waiting.
    //
    // If Auth0 is not loading, then we either render the component if the user isAuthenticated, or a Redirect component
    // back to the home page or open the login prompt if the user is not authenticated.
    const homeRedirect = <Navigate to={{
      pathname: '/',
      state: { from: this.props.location }
    }} replace />;

    let retVal;

    if (loading || userLoading) {  // If loading, show the Loading component
      retVal = <Loading data={"initial data"} />;
    } else if (isAuthenticated) {
      if (checkObjLength(userPermissions) && hasRequiredPermissions(requiredPermissions, userPermissions)) {
        retVal = Component ? <Component {...this.props} /> : render(this.props);
      } else {
        retVal = homeRedirect;
      }
    } else {
      const currentUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`;

      if (promptLogin) {
        loginWithRedirect({
          appState: {
            targetUrl: currentUrl,
          },
        });
        retVal = <Loading message={"Redirecting to login"} />
      } else {
        retVal = homeRedirect;
      }
    }

    return retVal;
  }
}

PrivateComponent.defaultProps = {
  allowedRoles: ['']
}

const mapStateToProps = (state) => {
  return {
    userPermissions: state.user.permissions,
    userLoading: state.user.initializing
  };
}

const ConnectedPrivateComponent = withRouter(connect(
  mapStateToProps
)(PrivateComponent));

/*
 * setting up custom components for each possible "role collection" ([staff, manager, admin])
 */

const AnalyticsUser = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ANALYTICS_USER,
    licenseLevelPermission: licenseLevels.FREE,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const Staff = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ORG_STAFF,
    licenseLevelPermission: licenseLevels.FREE,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const Manager = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ORG_MANAGER,
    licenseLevelPermission: licenseLevels.FREE,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const Admin = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ORG_ADMIN,
    licenseLevelPermission: licenseLevels.FREE,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const PaidStaff = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ORG_STAFF,
    licenseLevelPermission: licenseLevels.PAID,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const PaidAdmin = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.ORG_ADMIN,
    licenseLevelPermission: licenseLevels.PAID,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

const RootAdmin = props => <ConnectedPrivateComponent
  {...props}
  requiredPermissions={{
    rolePermission: rolePermissionLevels.SITE_ADMIN,
    licenseLevelPermission: licenseLevels.FREE,
    licenseTypePermission: licenseTypes.INDIVIDUAL
  }} />;

export default {
  AnalyticsUser,
  Staff,
  Manager,
  Admin,
  PaidStaff,
  PaidAdmin,
  RootAdmin
};
