import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const customStyles = {
  option: (styles) => ({
    ...styles,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    '&:hover': {
      backgroundColor: '#5b21d2',
      color: 'white'
    }
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '#80bdff' : '#bbbcc6',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    '&:hover': {
      borderColor: '#80bdff'
    }
  })
}

const MultiSelect = (props) => {
  return (
    <AsyncPaginate
      { ...props }
      styles={customStyles}
      isMulti
    />
  );
}

export default MultiSelect;