import React, { Component } from 'react';
import { withRouter } from '../../shared/RouterShim';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';

import WarningModal from '../../shared/WarningModal'; 
import api from '../../../scripts/api';
import { message } from '../../shared/Message';
import PickListSelect from '../../shared/asyncSelect/PickListSelect';

export class AssignSponsorships extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.confirmUpdate = this.confirmUpdate.bind(this)
  }

  confirmUpdate = () => {
    this.props.saveHandler();
    this.setState({ showModal: false });
  }

  displayModal = () => {
    this.setState({ showModal: true })
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { showModal } = this.state;
    const { defaultOrganizations, availableOrganizations, handleAddToSelected, handleRemoveFromSelected, cancelHandler } = this.props;
    return (
      <React.Fragment>

        <Row className="my-3">
          <Col md={6} className="mb-3 align-self-end">
            <h5>Assign Sponsors</h5>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            Add/remove sponsored organizations (currently selected: {defaultOrganizations.length})
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <PickListSelect
              target={defaultOrganizations}
              source={availableOrganizations}
              addToTarget={handleAddToSelected}
              removeFromTarget={handleRemoveFromSelected}
            />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <ButtonToolbar>
              <Button variant="outline-primary"
                onClick={cancelHandler}>
                Cancel
              </Button>
              <Button variant="primary"
                onClick={() => this.displayModal()}>
                Save Sponsorships
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>

        { showModal &&
          <WarningModal
            show={showModal}
            onCancel={() => this.hideModal()}
            title={'Confirm Sponsor Assignment'}
            onConfirm={this.confirmUpdate}
          >
            Are you sure you want to make these updates to this organization's sponsors?
          </WarningModal>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(AssignSponsorships);
