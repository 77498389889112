import React, { useState, useRef } from 'react';

import Button from 'react-bootstrap/button';
import { Toast } from 'primereact/toast';

import api from '../../scripts/api';

function ApiKey() {
  const [showApiKey, setShowApiKey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const toast = useRef(null);

  const createApiKeyForUser = () => {
    api.createApiKeyForCurrentUser()
      .then(result => {
        setApiKey(result.apiKey);
      })
      .catch(error => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `An error occurred while attempting to get API keys: ${error.message}`,
          life: 3000
        })
      })
  }

  const handleViewClick = () => {
    setIsLoading(true);

    api.getApiKeysForCurrentUser()
      .then(result => {
        if (result === undefined || result.applicationUserApiKeys === undefined ||
          result.applicationUserApiKeys.length === 0) {
          createApiKeyForUser();
        }
        else {
          setApiKey(result.applicationUserApiKeys[0].apiKey);
        }

        setShowApiKey(true);
      })
      .catch(error => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: `An error occurred while attempting to get API keys: ${error.message}`,
          life: 3000
        })
      })

    setIsLoading(false);
  }

  const copyApiKeyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
  }

  return (
     <>
      {showApiKey ?
        <>
          <span className="mr-2">{apiKey}</span>
          <Button onClick={copyApiKeyToClipboard} variant="link" className="info-link--large--left">
            Copy to Clipboard
          </Button>
        </> :
        <Button onClick={handleViewClick} loading={isLoading.toString()} variant="link" className="info-link--large--left">
          Show API Key
        </Button>
      }
      <Toast ref={toast} position='bottom-left' />
    </> 
  );
}

export default ApiKey
