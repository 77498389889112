import { userRoles, rolePermissionLevels, licenseLevels, licenseTypes } from '../../constants.js'

export default class UserInfoDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.firstName = options.firstName || '';
    this.lastName = options.lastName || '';
    this.email = options.userId || '';
    this.organizationId = options.organizationId || 0;
    this.roles = options.roles ? options.roles.map(role => role.roleName) : [];
    this.licenseLevel = options.licenseLevel || licenseLevels.UNSPECIFIED;
    this.licenseType = options.licenseType || licenseTypes.UNSPECIFIED;
    this.permissions = options.roles ? this.createPermissions(this.roles, this.licenseType, this.licenseLevel) : {};
    this.activeDemographicsSchema = this.parseJson(options.activeDemographicsSchema);
  }

  parseJson(schema) {
    try {
      return JSON.parse(schema);
    } catch (err) {
      return null;
    }
  }

  stringify() {
    return JSON.stringify(this);
  }

  createPermissions(roles, licenseType, licenseLevel) {
    let permissions = {
      rolePermission: rolePermissionLevels.ANALYTICS_USER,
      licenseLevelPermission: licenseLevels.UNSPECIFIED,
      licenseTypePermission: licenseTypes.UNSPECIFIED
    };

    if (roles.includes(userRoles.ROOT_ADMIN)) {
      permissions.rolePermission = rolePermissionLevels.SITE_ADMIN;
    } else if (roles.includes(userRoles.ADMIN)) {
      permissions.rolePermission = rolePermissionLevels.ORG_ADMIN;
    } else if (roles.includes(userRoles.MANAGER)) {
      permissions.rolePermission = rolePermissionLevels.ORG_MANAGER;
    } else if (roles.includes(userRoles.STAFF_WITH_ALL_CLIENTS)) {
      permissions.rolePermission = rolePermissionLevels.STAFF_WITH_ALL_CLIENTS
    } else if (roles.includes(userRoles.STAFF)) {
      permissions.rolePermission = rolePermissionLevels.ORG_STAFF
    }

    // root admin always has highest license type regardless of what is set in organization record
    if (roles.includes(userRoles.ROOT_ADMIN)) {
      permissions.licenseLevelPermission = licenseLevels.PAID;
      permissions.licenseTypePermission = licenseTypes.SPONSOR;
    } else {
      permissions.licenseLevelPermission = licenseLevel;
      permissions.licenseTypePermission = licenseType;
    }

    return permissions;
  }
}
