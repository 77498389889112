import * as yup from 'yup';

// adding this method to yup in order to validate uniqueness in field name
yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
        return list.length === new Set(list.map(mapper)).size;
    });
});

export const addMeasureSchema = yup.object({
    name: yup.string().ensure().trim().required("Name is required"),
    description: yup.string().ensure().trim().required("Description is required"),
    outcomeCategoryValue: yup.string().required("Outcome Category is required"),
    typeValue: yup.string().required("Type is required"),
    fieldDefinitions: yup.object().shape({
        fields: yup.array().min(1).unique("Field name cannot contain duplicates", f => f.name).required("There must be at least one field")
    }),
    cutoffScoreValue: yup.string().ensure().trim()
        .when('cutoffScoreComparisonValue', {
            is: 'Count',
            then: yup.string().nullable().test({

                test: function (value) {
                    return !value || value.trim().length === 0;
                },
                message: "count values do not have a cutoff"
            }),
            otherwise: yup.string().required("cutoff score value is required")
        }),
    cutoffScoreField: yup.string().ensure().trim()
        .when('cutoffScoreComparisonValue', {
            is: (comparisonValue) => comparisonValue === 'Count',
            then: yup.string().nullable().test({
                name: 'countCheck',
                message: "Count values do not have a cutoff",
                test: function (value) {
                    return !value || value.trim().length === 0;
                }
            }),
            otherwise: yup.string().required("Cutoff Score Field is required").test({
                name: 'isRequiredValidation',
                test: function (value) {
                    const fields = this.parent.fieldDefinitions?.fields || [];
                    const targetField = fields.find(field => field.name.trim() === value.trim());
                    return targetField?.validations.some(validation => validation.type.toLowerCase() === 'required')
                        || this.createError({ message: `Field "${value}" not marked as required.` });
                }
            })
        }),
    cutoffScoreComparisonValue: yup.string().required("Cutoff Score Comparison is required")
})

export const fieldEditorSchema = yup.object({
    name: yup.string().ensure().trim()
        .matches(/^[a-zA-Z][a-zA-Z0-9 ]*$/, 'Only alphanumeric characters are allowed')
        .required("Name is required"),
    type: yup.string().required("Type is required"),
    minValue: yup.number("This field must be a number").notRequired(),
    maxValue: yup.number("This field must be a number").notRequired(),
    options: yup.array().when("type", {
        is: "DropDown",
        then: yup.array()
            .min(1, "If field type is Dropdown, there must be at least one option")
            .required("Option is required"),
        otherwise: yup.array().notRequired()
    })
})