import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import Clients from './clients/Clients';
import StaffForAssignment from './assignStaff/StaffForAssignment';

import { userRoles } from '../../constants';

class BottomTabs extends Component {

  render() {
    const { staff } = this.props;

    return (
      <React.Fragment>

        {staff.userRoleName === userRoles.STAFF &&
          <Clients staffId={staff.id} />
        }

        {staff.userRoleName === userRoles.MANAGER &&
          <Tab.Container id="staff-tabs" defaultActiveKey="staff">
            <Nav variant="tabs" className="mt-5">
              <Nav.Item className="w-25 text-center">
                <Nav.Link eventKey="staff">Staff</Nav.Link>
              </Nav.Item>

              <Nav.Item className="w-25 text-center">
                <Nav.Link eventKey="clients">Clients</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="basic-card">
              <Tab.Pane eventKey="staff">

                <StaffForAssignment managerId={staff.id} />

              </Tab.Pane>

              <Tab.Pane eventKey="clients">

                <Clients staffId={staff.id} />

              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        }

      </React.Fragment>
    );
  }
}

export default BottomTabs;
