import ExternalAnalyticsDto from './ExternalAnalyticsDto';

export default class ExternalAnalyticsListDto {
  constructor(options = {}) {
    this.externalAnalytics = options.externalAnalytics ? options.externalAnalytics.map(dto => new ExternalAnalyticsDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}
