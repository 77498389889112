import React from 'react';
import RadioButtonGroupInput from './RadioButtonGroupInput';
import RadioButtonInput from './RadioButtonInput';

const RadioButtonYesNo = ({
  className,
  name,
  id,
  labelYes,
  labelNo,
  value,
  error,
  touched,
  onChange,
  ...props
}) => {

  return (
    <RadioButtonGroupInput
      {...props}
      className={className}
      id={id}
      value={value}
      error={error}
      touched={touched}
      wrapClasses="d-flex flex-row p-0"
    >
      <RadioButtonInput
        name={name}
        id={`${id ? id : name}-yes`}
        label={labelYes ? labelYes : 'Yes'}
        value="Yes"
        checked={value === 'Yes'}
        onChange={onChange}
      />
      <RadioButtonInput
        name={name}
        id={`${id ? id : name}-no`}
        label={labelNo ? labelNo : 'No'}
        value="No"
        checked={value === 'No'}
        onChange={onChange}
      />
    </RadioButtonGroupInput>
  );
};

export default RadioButtonYesNo;