import React, { Component } from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import api from '../../scripts/api';
import { message } from '../shared/Message';
import BaseApiRequestFilter from '../shared/filter/BaseApiRequestFilter';
import { apiFilterTypes, localStorageCacheKeys, rolePermissionLevels, licenseLevels, licenseTypes} from '../../constants';

import ServiceHoursDetails from './ServiceHoursDetails';
import BaseFilterRequestDto from '../../DTOs/ApiFilter/BaseFilterRequestDto';
import { hasRequiredPermissions } from '../../scripts/permissionHelper';

const PERMISSIONS_FOR_ORG_FILTER_EXPORT = {
  rolePermission: rolePermissionLevels.ORG_ADMIN || rolePermissionLevels.SITE_ADMIN,
  licenseLevelPermission: [licenseLevels.PAID, licenseLevels.FREE],
  licenseTypePermission: licenseTypes.SPONSOR
};

//Filters that haven't been implemented by Snowflake yet are commented out here.
const availableFilterTypes = [
  apiFilterTypes.START_DATE,
  apiFilterTypes.END_DATE,
  apiFilterTypes.PROGRAM,
  apiFilterTypes.ACTIVE_STATUS,
  apiFilterTypes.RACE,
  apiFilterTypes.HISPANIC_LATINO,
  apiFilterTypes.GENDER
];

const adminOnlyFilterTypes = [
  apiFilterTypes.ORGANIZATION,
  apiFilterTypes.COLLABORATIVE_ORGANIZATION
]

export class ServiceHoursIndex extends Component {
  constructor(props) {
    super(props);
    let storedFilters = localStorage.getItem(localStorageCacheKeys.SERVICE_HOURS_FILTERS);
    localStorage.removeItem(localStorageCacheKeys.SERVICE_HOURS_FILTERS);
    storedFilters = storedFilters ? JSON.parse(storedFilters) : {};
    this.state = {
      listData: [],
      showSpinner: false,
      showFilters: this.filterHasValues(storedFilters),
      filterRequestDto: this.getApiRequest(storedFilters),
      storedFilters: storedFilters
    }
  }

  filterHasValues = (filtersDto) => {
    return filtersDto &&
        (filtersDto.activeStatus ||
        (filtersDto.startDate && filtersDto.endDate) ||
        (filtersDto.gender && filtersDto.gender.length > 0) ||
        (filtersDto.hispanicLatino && filtersDto.hispanicLatino.length > 0) ||
        (filtersDto.race && filtersDto.race.length > 0) ||
        (filtersDto.programIds && filtersDto.programIds.length > 0));
  }

  componentDidMount = () => {
    this.getServiceHours(this.state.filterRequestDto.stringify());
  }

  applyFilters = (values, setSubmitting) => {
    this.getServiceHours(this.getApiRequest(values).stringify(), setSubmitting);
  }

  getServiceHours = (filterRequest, setSubmitting) => {
    this.setState({ showSpinner: true });
    api.getAllServiceHoursDetails(filterRequest).then(result => {
      this.setState({ listData: result.serviceHoursDetails });
    }).catch(err => {
      message.error(`Error getting service hours details: ${err}`);
    }).finally(() => {
      this.setState({ showSpinner: false });
      if (setSubmitting) {
        setSubmitting(false);
      }
    });
  }

  displayFilters = () => {
    this.setState({ showFilters: true });
  }

  clearFilters = () => {
    const emptyFilterRequest = new BaseFilterRequestDto();
    this.setState({
      showFilters: false,
      filterRequestDto: emptyFilterRequest
    }, this.getServiceHours(emptyFilterRequest.stringify()));
  }

  getApiRequest = (values) => {
    return new BaseFilterRequestDto({
      organizationIds: values.organization,
      startDate: values.startDate,
      endDate: values.endDate,
      programIds: values.program,
      active: values.activeStatus ? values.activeStatus === '1' : null,
      genders: values.gender,
      races: values.race,
      hispanicLatinos: values.hispanicLatino,
      programIds: values.program,
      collaborativeOrganizationId: values.collaborativeOrganization
    });
  }

  render() {
    const { listData, showSpinner, showFilters, storedFilters } = this.state;
    const { userPermissions } = this.props;

    const filters = hasRequiredPermissions(PERMISSIONS_FOR_ORG_FILTER_EXPORT, userPermissions) ? availableFilterTypes.concat(adminOnlyFilterTypes) : availableFilterTypes;


    return (
      <React.Fragment>
        <div className="container">
          <BaseApiRequestFilter
            availableFilterTypes={filters}
            displayFilters={this.displayFilters}
            clearFilters={this.clearFilters}
            filterValues={storedFilters}
            applyFilters={this.applyFilters}
            showFilters={showFilters}
            includeAddFiltersButton={true}
          />
          <div className="d-flex justify-content-between mb-4">
            <h4 className="font-weight-bold">Service Hours</h4>

            <ButtonToolbar>
              <Button variant="outline-primary d-print-none" onClick={window.print}>Print Service Hours</Button>
            </ButtonToolbar>
          </div>

          <ServiceHoursDetails
            listData={listData}
            showSpinner={showSpinner}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    userPermissions: state.user.permissions,
  })
)(ServiceHoursIndex);

