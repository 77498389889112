import React, { useState } from "react";
import api from "../../../scripts/api";
import { message } from "../../shared/Message";

const useClientSearch = () => {
    const [pageSize] = useState(50);
    const [pageNum] = useState(0);

    const searchAllClients = async (searchTerm, loadedOptions, { pageNum, pageSize }) => {
        let result;
        try {
            result = await api.searchAllClients(searchTerm, false, pageNum, pageSize);
        } catch (err) {
            message.error(`Error searching for client: ${err}`);
            return { options: [] };
        }

        const searchResults = result.searchResults.map(dto => ({ value: dto.id, label: `${dto.lastName}, ${dto.firstName}` }));
        const hasMore = result.totalMatches > (pageNum * pageSize) + searchResults.length;

        return {
            options: searchResults,
            hasMore: hasMore,
            additional: {
                pageNum: pageNum + 1,
                pageSize: pageSize
            }
        }
    }

    return {
        pageNum,
        pageSize,
        searchAllClients
    }
}

export default useClientSearch;
