import { format } from 'date-fns';
import { parseDate } from '../../scripts/safeDateParse';
import formatCurrency from '../../scripts/numHelpers/formatCurrency';

export default class ProgramDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.name = options.name || '';
    this.description = options.description || '';
    this.cost = options.cost || '';
    this.startDate = options.startDate ? format(parseDate(options.startDate), 'MM/dd/yyyy') : '';
    this.endDate = options.endDate ? format(parseDate(options.endDate), 'MM/dd/yyyy') : '';
    this.canAssignToClients = options.canAssignToClients || false;

    this.isSponsored = options.isSponsored || false;

    const formattedCost = this.cost ? formatCurrency(this.cost) : '';

    this.displayCost = `Program Cost: ${formattedCost ? formattedCost : 'not entered'}`;
    this.displayStartDate = this.startDate ? `Started ${format(parseDate(this.startDate), 'MMMM dd, yyyy')}` : 'No begin date set';
    this.displayEndDate = this.endDate ? `Ended ${format(parseDate(this.endDate), 'MMMM dd, yyyy')}` : 'No end date set';
    this.displayCanAssign = this.canAssignToClients ? 'Client-Based Program' : 'Non-Client Program';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
