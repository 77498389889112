import { rolePermissionLevels } from '../../constants.js';

const siteAdminLevels = [
  rolePermissionLevels.SITE_ADMIN,
  rolePermissionLevels.ORG_ADMIN,
  rolePermissionLevels.ORG_MANAGER,
  rolePermissionLevels.STAFF_WITH_ALL_CLIENTS,
  rolePermissionLevels.ORG_STAFF,
  rolePermissionLevels.ANALYTICS_USER
];

const orgAdminLevels = [
  rolePermissionLevels.ORG_ADMIN,
  rolePermissionLevels.ORG_MANAGER,
  rolePermissionLevels.STAFF_WITH_ALL_CLIENTS,
  rolePermissionLevels.ORG_STAFF,
  rolePermissionLevels.ANALYTICS_USER
];

const orgManagerLevels = [
  rolePermissionLevels.ORG_MANAGER,
  rolePermissionLevels.STAFF_WITH_ALL_CLIENTS,
  rolePermissionLevels.ORG_STAFF,
  rolePermissionLevels.ANALYTICS_USER
];

const orgStaffWithClients = [
  rolePermissionLevels.STAFF_WITH_ALL_CLIENTS,
  rolePermissionLevels.ORG_STAFF,
  rolePermissionLevels.ANALYTICS_USER
];

const orgStaffLevels = [
  rolePermissionLevels.ORG_STAFF,
  rolePermissionLevels.ANALYTICS_USER
]

const analyticsUserLevel = [
  rolePermissionLevels.ANALYTICS_USER
]

const hasRequiredRoleLevel = (requiredLevel, currentLevel) => {

  // added after aging cookie did not have necessary state and returned undefined
  if (typeof requiredLevel === 'undefined' || typeof currentLevel === 'undefined') {
    return false;
  }

  const availableLevels = getPermissionsForLevel(currentLevel);

  return availableLevels.includes(requiredLevel);
}

function getPermissionsForLevel(currentLevel) {
  switch(currentLevel) {
    case rolePermissionLevels.SITE_ADMIN:
      return siteAdminLevels;
    case rolePermissionLevels.ORG_ADMIN:
      return orgAdminLevels;
    case rolePermissionLevels.ORG_MANAGER:
      return orgManagerLevels;
    case rolePermissionLevels.STAFF_WITH_ALL_CLIENTS:
      return orgStaffWithClients;
    case rolePermissionLevels.ORG_STAFF:
      return orgStaffLevels;
    case rolePermissionLevels.ANALYTICS_USER:
      return analyticsUserLevel;
    default:
      return []
  }
}

export default hasRequiredRoleLevel;