import React from 'react';
import classNames from 'classnames';

import arrow from '../../../images/purpleArrow.png';

const Paging = (props) => {
  const { currentPage, itemsPerPage, maxPages, totalItems, onPageClick, embedded } = props;

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const displayPager = totalPages > 1;

  const curPage = currentPage < 1 ? 1 : currentPage > totalPages ? totalPages : currentPage;

  const displayLeftArrow = curPage > 1;
  const displayRightArrow = curPage < totalPages;

  const pages = getPagesArray();

  // arrays are zero based but paging is 1 based
  const displayLeftEllipses = totalPages > maxPages && pages[1] > (pages[0] + 1);
  const displayRightEllipses = totalPages > maxPages && (pages[pages.length - 2] + 1) < (pages[pages.length - 1]);

  function getPagesArray() {
    // get all possible page numbers
    const calcPages = Array(totalPages).fill().map((x, i) => i + 1);

    if (totalPages <= maxPages) {
      return calcPages;
    } else {
      // total pages more than max so calculate start and end pages for use in displaying ellipses
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;

      let startPage;
      if (curPage <= maxPagesBeforeCurrentPage) {
        // current page near the start (using array indexes for these calculations)
        startPage = 1;
      }
      else if (curPage + maxPagesAfterCurrentPage > totalPages) {
        // current page near the end
        startPage = totalPages - (maxPages - 1);
      }
      else {
        // current page somewhere in the middle
        startPage = curPage - maxPagesAfterCurrentPage;
      }

      // startPage represents the next to the leftmost pageLink
      return [1, ...calcPages.slice(startPage, startPage + (maxPages - 2)), totalPages];
    }
  }

  return displayPager
    ? (
      <div className={`d-flex justify-content-center mt-md-4${embedded ? ' mb-md-4 mb-sm-2' : ''}`}>

        {displayLeftArrow &&
          <div className="d-flex justify-content-center paging-link">
            <a className="d-flex justify-content-center align-content-center paging-arrow__prev" onClick={() => onPageClick(curPage - 1)} data-page={curPage - 1}>
              <div className="paging-link__clickable">
                <img className="" src={arrow} alt="Previous" />
              </div>
            </a>
          </div>
        }

        <div className="d-flex justify-content-center paging-link">
          <a className="d-flex justify-content-center align-content-center paging-link__a" onClick={() => onPageClick(pages[0])} data-page={pages[0]}>
            <div className={classNames('paging-link__clickable', { 'paging-link__active': curPage === pages[0] })}>
              {pages[0]}
            </div>
          </a>
        </div>

        {displayLeftEllipses &&
          <div className="d-flex justify-content-center paging-link">
            <div className="d-flex">
              ...
          </div>
          </div>
        }

        {pages.map((elem, i) => {
          let retVal = null;

          if (i !== 0 && i !== (pages.length - 1)) {
            retVal = (<div className="d-flex justify-content-center paging-link" key={elem}>
              <a className="d-flex justify-content-center align-content-center paging-link__a" onClick={() => onPageClick(elem)} data-page={elem}>
                <div className={classNames('paging-link__clickable', { 'paging-link__active': curPage === elem })}>
                  {elem}
                </div>
              </a>
            </div>);
          }

          return retVal;
        })}

        {displayRightEllipses &&
          <div className="d-flex justify-content-center paging-link">
            <div className="d-flex">
              ...
          </div>
          </div>
        }

        <div className="d-flex justify-content-center paging-link">
          <a className="d-flex justify-content-center align-content-center paging-link__a" onClick={() => onPageClick(pages[pages.length - 1])} data-page={pages[pages.length - 1]}>
            <div className={classNames('paging-link__clickable', { 'paging-link__active': curPage === pages[pages.length - 1] })}>
              {pages[pages.length - 1]}
            </div>
          </a>
        </div>

        {displayRightArrow &&
          <div className="d-flex justify-content-center paging-link">
            <a className="d-flex justify-content-center align-content-center paging-arrow__next" onClick={() => onPageClick(curPage + 1)} data-page={curPage + 1}>
              <div className="paging-link__clickable">
                <img className="" src={arrow} alt="Next" />
              </div>
            </a>
          </div>
        }

      </div>
    )
    : null;
}

export default Paging;