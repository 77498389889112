export default class ServiceHoursDto {
  constructor(options = {}) {
    this.serviceMinutes = options.serviceMinutes || 0;
    this.serviceHours = options.serviceHours || 0;
  }

  stringify() {
    return JSON.stringify(this);
  }
};
