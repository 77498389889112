import { connect } from 'react-redux';

/**
 * Function which takes a component and wraps the component in a connect function which connects it to the Redux store.
 * Also properly sets the 'options' prop for the passed in component.  If the component has a 'category' props which has text,
 * the 'options' prop will be set to a list of values from the keywords data table which has that category as its category.  If
 * no 'category' prop is passed in or it is empty, then no more work is done. The property on the Keyword object whose value will
 * be used for the option value can also be specified by using the 'valueProperty' prop.  The default is to use the id property.
 * 
 * Another prop that is used in this function is the 'defaultOptionText' prop.  If the passed in component has this prop,
 * then a default option with an empty string as the value is added to the front of the keywords list.  This is only done
 * if the 'category' prop is defined.
 */
export default (WrappedComponent, { category, defaultOptionText, valueProperty } = {}) => {

  function mapState(state, ownProps) {
    const categoryUsed = category || ownProps.category;
    const defaultOptionTextUsed = defaultOptionText || ownProps.defaultOptionText;
    const valuePropertyUsed = valueProperty || 'id';
    const allKeywords = state.user.demographicsKeywords;

    // If a category is used, need to get the category.  If no category, just used the passed in options
    let newOptions;
    if (categoryUsed) {
      newOptions = allKeywords.filter(keyword => keyword.category === categoryUsed)
        .map(keyword => ({
          text: keyword.text,
          value: keyword[valuePropertyUsed]
        }));
    } else {
      newOptions = ownProps.options ? [...ownProps.options] : [];
    }

    if (defaultOptionTextUsed !== undefined) {
      // Creates a default option to put first in the list
      newOptions.unshift({
        text: defaultOptionTextUsed,
        value: ''
      });
    }

    return {
      options: newOptions // This overrides any 'options' props passed in manually
    }
  }

  return connect(mapState)(WrappedComponent);
}