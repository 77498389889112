export default class FilterOption {
  constructor(options = {}) {
    this.value = options.value || 0;
    this.text = options.text || '';
    this.sortOrder = options.sortOrder || 0;
  }

  stringify() {
    return JSON.stringify(this);
  }
}
