import { parseDate } from "../../scripts/safeDateParse";

export default class ClientEventDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.date = parseDate(options.date) || '';
    this.type = options.objectType || '';
    this.name = options.objectName || '';
    this.action = options.transactionType || '';
    this.user = options.userName || '';
  }

  stringify() {
    return JSON.stringify(this);
  }
}
