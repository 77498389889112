import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Component for text area input
 */
const TextAreaInput = (props) => {

  const value = props.value || '';

  return (
    <React.Fragment>
      <Form.Control
        as="textarea"
        type="text"
        name={props.name}
        value={value}
        label={props.label}
        rows={props.rows}
        placeholder={props.placeholder}
        isInvalid={props.isInvalid}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={props.className}
        readOnly={props.readOnly}
      />
      <Form.Control.Feedback type="invalid">
        {props.errorMessage}
      </Form.Control.Feedback>
    </React.Fragment>
  );
}

export default TextAreaInput;