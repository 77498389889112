import SponsoringOrganizationDto from './SponsoringOrganizationDto';

export default class SponsoringOrganizationListDto {
  constructor(options = {}) {
    this.organizations = options.organizations ? options.organizations.map(dto => new SponsoringOrganizationDto(dto)) : [];
  }

  stringify() {
    return JSON.stringify(this);
  }
}