import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import classNames from 'classnames';
import close from '../../../../images/Close.png';

/**
 * Component for displaying a list of relationship information a client has. This component also contains the functionality
 * for a user to remove a relationship with client.
 */
export default class RelationshipsIndex extends Component {

  static defaultProps = {
    relationships: [], // list of ClientRelationshipDtos
    onRelationshipRemoved: () => { },
    onError: (message) => { }
  }

  constructor(props) {
    super(props);

    this.removeRelationship = this.removeRelationship.bind(this);
  }

  removeRelationship(toClientId) {
    this.props.onRelationshipRemoved(toClientId);
  }

  render() {
    const { relationships } = this.props;

    return (
      <React.Fragment>
        <ListGroup variant="flush" className="relationships-list">
          {relationships.map(r => {
            let fullName = `${r.lastName}, ${r.firstName}`

            const classes = classNames('d-flex justify-content-center align-self-center relationships-list__close');

            return (
              <ListGroup.Item key={r.clientId} className="d-flex justify-content-between">
                <div className="d-flex flex-column text-truncate pr-3">
                  <strong className="text-truncate">{fullName}</strong>
                  <div className="text-truncate">{r.relationship}</div>
                </div>
                <div
                  className={classes}
                  onClick={() => this.removeRelationship(r.clientId)}
                >
                  <img className="relationships-list__close__icon" src={close} alt={`Remove ${fullName}`} />
                  <span className="d-flex align-self-center">Remove</span>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        <span style={{ display: 'none' }} />  {/* This element is here as a hack so 'list-group-flush:last-child' css selector is not triggered so a line displays under the last list item */}
      </React.Fragment>
    );
  }
}