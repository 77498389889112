import React, { Component } from 'react';
import { withRouter } from '../shared/RouterShim';
import IndexList from '../shared/indexList/IndexList';
import IndexItem from '../../DTOs/shared/IndexItem';
import api from '../../scripts/api';
import { message } from '../shared/Message';

const getServiceData = objs => {
  return objs.map(service => {
    const endText = service.isSponsored ? 'Sponsored' : undefined;
    return new IndexItem({
      title: service.name,
      description: service.description,
      itemId: service.id,
      url: `/OrganizationService/${service.id}`,
      endText: endText
    });
  }
  );
}

class ServiceList extends Component {
  constructor(props) {
    super(props);

    this.errorMessageId = null;
  }

  state = {
    data: []
  }

  async componentDidMount() {
    try {
      let tmp = (this.props.showActive)
        ? await api.getAllActiveServicesForOrganization()
        : await api.getAllInactiveServicesForOrganization();

      this.setState({ data: getServiceData(tmp.allServices) });
    }
    catch (err) {
      message.dismiss(this.errorMessageId);
      this.errorMessageId = message.error(`Error getting service list: ${err}`);
    }
  }

  render() {
    return (
      <React.Fragment>
        <IndexList listData={this.state.data} />
      </React.Fragment>
    );
  }
}

export default withRouter(ServiceList);
